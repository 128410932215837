import {CssBaseline} from '@mui/material';
import {StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {AuthUserClaims, Department, INACTIVITY_TIME, Store, useAppVersion} from '@ozark/common';
import {LayoutRouter, Loading, LoadingBoxGlobal} from '@ozark/common/components';
import {TicketCountRefreshProvider} from '@ozark/common/components/Tickets/hooks/useTicketCounters';
import {SnackbarProvider} from 'notistack';
import {useEffect, useState} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import {BrowserRouter as Router} from 'react-router-dom';
import {layoutRoutes} from '../../config/routes';
import {useStore} from '../../store/helpers';
import ThemeOld from '../../theme';
import AppRoute from '../AppRoute';

function App() {
  const {authUser, authProfile, logout, apiClient} = useStore();
  const [claims, setClaims] = useState<AuthUserClaims>();
  const {appVersion, refreshCacheAndReload, isLatestVersion} = useAppVersion();
  useIdleTimer({timeout: INACTIVITY_TIME, crossTab: true, onIdle: logout});

  useEffect(() => {
    if (authProfile.data?.role) {
      setClaims({
        role: authProfile.data?.role,
        department: authProfile.data?.department as Department,
      } as AuthUserClaims);
    }
  }, [authProfile.data?.role, authProfile.data?.department]);

  useEffect(() => {
    if (!isLatestVersion) {
      refreshCacheAndReload();
    }
  }, [isLatestVersion, refreshCacheAndReload]);

  if (authUser.promised || authProfile.promised || appVersion.promised) return <Loading />;
  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <ThemeProvider theme={ThemeOld}>
        <Store authUser={authUser} apiClient={apiClient} claims={claims}>
          <SnackbarProvider maxSnack={4}>
            <TicketCountRefreshProvider>
              <Router>
                {layoutRoutes.map((item, index) => (
                  <LayoutRouter
                    key={index}
                    component={item.layout}
                    routes={item.routes}
                    renderRoute={route => (
                      <AppRoute route={route} isLayoutProtected={item.isProtected} />
                    )}
                    renderLayoutRoute={(paths, children) => (
                      <AppRoute
                        route={{
                          path: paths,
                          component: () => children,
                          isProtected: !!item.isProtected,
                        }}
                      />
                    )}
                  />
                ))}
              </Router>
            </TicketCountRefreshProvider>
          </SnackbarProvider>
          <LoadingBoxGlobal />
        </Store>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
