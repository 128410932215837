import {
  Application,
  ApplicationStatusGroups,
  ApplicationView,
  AsyncState,
  Collections,
  getDispositions,
  selectApplicationView,
  useCollectionSnapshot,
  UserRoles,
  Workflows,
} from '@ozark/common';
import {useEffect, useState} from 'react';

export const useUnderwritingUnassignedAppByDeleted = (deletedValue: null | false) => {
  const {documents: underwritingUnassignedApps} = useCollectionSnapshot<
    ApplicationView,
    Application
  >(Collections.applications, selectApplicationView, undefined, query =>
    query
      .where('deleted', '==', deletedValue)
      .where('uid', '==', null)
      .where(
        'disposition',
        'in',
        getDispositions(ApplicationStatusGroups.open, Workflows[UserRoles.underwriter].viewable)
      )
  );

  return {
    underwritingUnassignedApps,
  };
};

export const useUnderwritingUnassignedApp = () => {
  const [underwritingUnassignedApps, setUnderwritingUnassignedApps] = useState<
    AsyncState<ApplicationView[]>
  >({
    promised: true,
  });
  const [underwritingUnassignedAppsCount, setUnderwritingUnassignedAppsCount] = useState(0);
  const {underwritingUnassignedApps: underwritingUnassignedAppsNull} =
    useUnderwritingUnassignedAppByDeleted(null);
  const {underwritingUnassignedApps: underwritingUnassignedAppsFalse} =
    useUnderwritingUnassignedAppByDeleted(false);

  useEffect(() => {
    if (underwritingUnassignedAppsNull.promised || underwritingUnassignedAppsFalse.promised) {
      return;
    }
    const apps = [];
    if (underwritingUnassignedAppsNull.data) {
      apps.push(...underwritingUnassignedAppsNull.data);
    }

    if (underwritingUnassignedAppsFalse.data) {
      apps.push(...underwritingUnassignedAppsFalse.data);
    }

    setUnderwritingUnassignedApps({promised: false, data: apps});
    setUnderwritingUnassignedAppsCount(apps.length);
  }, [
    underwritingUnassignedAppsNull.data,
    underwritingUnassignedAppsFalse.data,
    underwritingUnassignedAppsNull.promised,
    underwritingUnassignedAppsFalse.promised,
  ]);

  return {
    underwritingUnassignedApps,
    underwritingUnassignedAppsCount,
  };
};
