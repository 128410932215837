export const TICKETS = `/tickets`;
export const TICKET_EDIT = `/tickets/id/:id`;
export const TICKETS_VIEW = `/tickets/view/:view`;
export const TICKETS_VIEW_ALL = `/tickets/view/all`;
export const TICKET_TYPES_MANAGER_ROUTE = '/tickets/manager';
export const TICKETS_REPORT = '/tickets/report';
export const TICKET_TYPES_MANAGER_EDIT_CATEGORIES_ROUTE = '/tickets/manager/:id';
export const MERCHANTS_ROUTE = '/merchants';
export const MERCHANT_DETAILS_ROUTE = `${MERCHANTS_ROUTE}/:id`;
export const APPLICATIONS_ANALYTICS_DETAILS_ROUTE = '/application-analytics-details';
export const SALES_LEADS_ROUTE = '/sales-leads';
export const SALES_LEADS_DETAILS_ROUTE = `${SALES_LEADS_ROUTE}/:leadId`;
export const MFA_AUTH_ROUTE = '/mfa-auth';

export const PORTFOLIO_PCI = 'https://123pci.pcicompliance.ws/';
