export * from './ChargebackDetails';
export * from './ChargebackDetailsSoon';
export * from './ChargebacksSummary';
export * from './ChargebacksSummaryByMid';
export * from './Types';

import {format, utcToZonedTime} from 'date-fns-tz';
import {caseType, DATETIME_FORMAT_CHARGEBACK as DATETIME_FORMAT, itemType} from './Types';

export const getDate = (date: Date | undefined): string => {
  if (!date) return 'No Date';
  const dateTime = new Date(date);
  const localTime = utcToZonedTime(dateTime, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(localTime, DATETIME_FORMAT);
};

export const getItemType = (type: number): string => {
  return itemType[type];
};

export const getCaseType = (type: number): string => {
  return caseType[type];
};
export const getCardNumber = (number: string | undefined): string => {
  if (!number) return '';

  return number.replace(/[X]/g, '*');
};
