import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import {
  Button,
  Checkbox as MuiCheckbox,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {emptyStringToNull, MenuProps} from '@ozark/common/helpers';
import {
  InputAccept,
  Platforms,
  ProcessingTypes,
  ShippingSpeed,
  timeList,
} from '@ozark/functions/src/constants';
import {
  EquipmentAvailability,
  EquipmentConnectionType,
  EquipmentDurationType,
  EquipmentFileType,
  EquipmentTipMode,
  EquipmentViewableBy,
  EquipmentYesOrNo,
} from '@ozark/functions/src/documents';
import {Fragment, useCallback, useEffect, useState} from 'react';
import {UseFormReturn} from 'react-hook-form';
import * as yup from 'yup';
import {Checkbox, Loading, Select, TextField, TextFieldRichEditor, UploadButton} from '..';
import {useRatePrograms} from '../../hooks';
import ComingSoonImage from '../../static/images/comingsoon.png';
import {EquipmentFormData} from './hooks';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      marginTop: theme.spacing(2),
    },
    title: {
      fontSize: '1.2em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1em',
      },
    },
    displayContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
    },
    logo: {
      height: 96,
    },
    defaultLogo: {
      height: 96,
      width: 96,
      backgroundSize: 'cover',
    },
    gateway: {
      paddingTop: '9px !important',
    },
    upload: {
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(2, 0, 0, 0),
    },
    actionItem: {
      cursor: 'pointer',
      display: 'inherit',
    },
    listItem: {
      marginTop: -18,
      paddingTop: 0,
      paddingBottom: 0,
    },
  })
);

export const EquipmentFormSchema = yup.object({
  name: yup.string().required('Name is required'),
  price: yup.number().required().typeError('Price is required'),
  duration: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(false)
    .required()
    .typeError('Billing method is required'),
  platform: yup.string().required().typeError('Platform is required'),
  isGateway: yup.bool(),
  isEnabledScheduleABilling: yup.bool().optional(),
  viewableBy: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(false)
    .required()
    .typeError('Viewable By is required'),
  availability: yup
    .string()
    .transform(emptyStringToNull)
    .nullable(false)
    .typeError('Availability is required')
    .when('isGateway', {
      is: true,
      then: yup.string().nullable(true),
    }),
  processingTypes: yup
    .array()
    .of(yup.string())
    .required('Processing Types are required')
    .min(1, 'Processing Types are required'),
  defaultsAccessories: yup.string().transform(emptyStringToNull).nullable(),
  defaultsAutoBatch: yup
    .bool()
    .transform(emptyStringToNull)
    .nullable()
    .when('isGateway', {
      is: false,
      then: yup.bool().required().typeError('Auto Batch is required'),
    }),
  defaultsAutoBatchTime: yup
    .string()
    .transform(emptyStringToNull)
    .nullable()
    .when('defaultsAutoBatch', {
      is: true,
      then: yup
        .string()
        .transform(emptyStringToNull)
        .nullable(false)
        .required()
        .typeError('Batch Time is required'),
    }),
  defaultsCashDiscount: yup
    .bool()
    .transform(emptyStringToNull)
    .nullable()
    .when('isGateway', {
      is: false,
      then: yup.bool().required().typeError('Cash Discount is required'),
    }),
  defaultsCashDiscountPercent: yup
    .number()
    .transform(emptyStringToNull)
    .nullable()
    .when('defaultsCashDiscount', {
      is: true,
      then: yup
        .number()
        .transform(emptyStringToNull)
        .nullable(false)
        .required()
        .typeError('Cash Discount Percent is required'),
    }),
  defaultsContactless: yup
    .bool()
    .transform(emptyStringToNull)
    .nullable()
    .when('isGateway', {
      is: false,
      then: yup.bool().required().typeError('Contactless is required'),
    }),
  defaultsConnectionType: yup
    .string()
    .transform(emptyStringToNull)
    .nullable()
    .when('isGateway', {
      is: false,
      then: yup
        .string()
        .transform(emptyStringToNull)
        .nullable(false)
        .required()
        .typeError('Connection Type is required'),
    }),
  defaultsFileType: yup
    .string()
    .transform(emptyStringToNull)
    .nullable()
    .when('isGateway', {
      is: false,
      then: yup
        .string()
        .transform(emptyStringToNull)
        .nullable(false)
        .required()
        .typeError('File Type is required'),
    }),
  defaultsTipMode: yup
    .string()
    .transform(emptyStringToNull)
    .nullable()
    .when('defaultsFileType', {
      is: EquipmentFileType.restaurant,
      then: yup
        .string()
        .transform(emptyStringToNull)
        .nullable(false)
        .required()
        .typeError('Tip Mode is required'),
    }),
  defaultsShippingSpeed: yup
    .string()
    .transform(emptyStringToNull)
    .nullable()
    .when('isGateway', {
      is: false,
      then: yup
        .string()
        .transform(emptyStringToNull)
        .nullable(false)
        .required()
        .typeError('Sheeping Speed is required'),
    }),
  options: yup.object().shape({
    connectionType: yup
      .array()
      .of(yup.string())
      .test('connectionType', 'Connection Type is required', function (val) {
        // NOTE: (this as any).from[1] for access to parent object in the schema
        return (this as any).from[1].value.isGateway ? true : val !== undefined && val.length > 0;
      }),
    cashDiscount: yup
      .array()
      .of(yup.string())
      .test('cashDiscount', 'Cash Discount is required', function (val) {
        return (this as any).from[1].value.isGateway ? true : val !== undefined && val.length > 0;
      }),
  }),
});

type Props = {
  hookForm: UseFormReturn<EquipmentFormData>;
};

export const EquipmentForm = ({hookForm}: Props) => {
  const classes = useStyles();
  const {ratePrograms} = useRatePrograms();

  const [isGateway, setIsGateway] = useState<boolean>(false);
  const [isEnabledScheduleABilling, setIsEnabledScheduleABilling] = useState<boolean | undefined>(
    undefined
  );
  const [features, setFeatures] = useState<string[]>([]);
  const [accessories, setAccessories] = useState<string[]>([]);
  const [chosenPrograms, setChosenPrograms] = useState<string[]>([]);
  const [chosenCashDiscount, setChosenCashDiscount] = useState<string[]>([]);
  const [chosenConnectionType, setChosenConnectionType] = useState<string[]>([]);
  const [chosenProcessingTypes, setChosenProcessingTypes] = useState<string[]>([]);

  const {
    watch,
    formState: {errors},
    control,
    register,
    setValue,
  } = hookForm;

  useEffect(() => {
    register('image');
    register('imageUrl');
    register('programs');
    register('options.connectionType');
    register('options.cashDiscount');
    register('processingTypes');
  }, [register]);

  useEffect(() => {
    setIsGateway(control._defaultValues.isGateway || false);
    setFeatures((control._defaultValues.features as string[]) || []);
    setAccessories((control._defaultValues.options?.accessories as string[]) || []);
    setIsEnabledScheduleABilling(control._defaultValues.isEnabledScheduleABilling);

    // set Programs:
    const curPrograms: string[] | null = (control._defaultValues.programs as string[]) || null;
    if (curPrograms) {
      setChosenPrograms(curPrograms);
    }

    // set Cash Discount:
    const curCashDiscount: string[] | null =
      (control._defaultValues.options?.cashDiscount as string[]) || null;
    if (curCashDiscount) {
      const selectedCashDiscount = Object.entries(EquipmentYesOrNo)
        .filter(([key, value]) => curCashDiscount.includes(value))
        .map(([key, value]) => key);
      setChosenCashDiscount(selectedCashDiscount);
    }

    // set Connection Type:
    const curConnectionType: string[] | null =
      (control._defaultValues.options?.connectionType as string[]) || null;
    if (curConnectionType) {
      const selectedConnectionType = Object.entries(EquipmentConnectionType)
        .filter(([key, value]) => curConnectionType.includes(value))
        .map(([key, value]) => key);
      setChosenConnectionType(selectedConnectionType);
    }

    // set Processing Types:
    const curProcessingType: string[] | null =
      (control._defaultValues.processingTypes as string[]) || null;
    if (curProcessingType) {
      setChosenProcessingTypes(curProcessingType);
    }
  }, [control]);

  const imageUrl = watch('imageUrl');
  const image = watch('image');

  const watchAutoBatch = watch('defaultsAutoBatch');
  const watchDefaultsConnectionType = watch('defaultsConnectionType');
  const watchDefaultsCashDiscount = watch('defaultsCashDiscount');
  const watchDefaultsFileType = watch('defaultsFileType');

  const handleSelectFile =
    (name: keyof EquipmentFormData) => (result: {dataUrl: string; file: File}) => {
      setValue(name, result, {shouldDirty: true});
    };

  const handleProgramsChange = (event: SelectChangeEvent<string[]>) => {
    const programs = event.target.value as string[];
    setChosenPrograms(programs);
    setValue('programs', programs, {shouldDirty: true});
  };

  const handleCashDiscountChange = (event: SelectChangeEvent<string[]>) => {
    const cashDiscountTypes = event.target.value as string[];
    setChosenCashDiscount(cashDiscountTypes);

    const selectedCashDiscountValues = cashDiscountTypes.map(
      x => EquipmentYesOrNo[x as keyof typeof EquipmentYesOrNo]
    );

    setValue('options.cashDiscount', selectedCashDiscountValues, {shouldDirty: true});

    // reset defaults cash discount if value not exists in options cash discount
    const cashDiscountWatchedValue =
      watchDefaultsCashDiscount === true
        ? EquipmentYesOrNo.yes
        : watchDefaultsCashDiscount === false
        ? EquipmentYesOrNo.no
        : undefined;

    if (
      cashDiscountWatchedValue &&
      !selectedCashDiscountValues.includes(cashDiscountWatchedValue)
    ) {
      setValue('defaultsCashDiscount', undefined, {shouldDirty: true});
    }
  };

  const handleConnectionTypeChange = (event: SelectChangeEvent<string[]>) => {
    const connectionTypes = event.target.value as string[];
    setChosenConnectionType(connectionTypes);

    const selectedConnectionTypes = connectionTypes.map(
      x => EquipmentConnectionType[x as keyof typeof EquipmentConnectionType]
    );
    setValue('options.connectionType', selectedConnectionTypes, {shouldDirty: true});

    // reset defaults connection type if value not exists in options connection type
    if (!selectedConnectionTypes.includes(watchDefaultsConnectionType as EquipmentConnectionType)) {
      setValue('defaultsConnectionType', '', {shouldDirty: true});
    }
  };

  const handleProcessingTypesChange = (event: SelectChangeEvent<string[]>) => {
    const processingTypes = event.target.value as string[];
    setChosenProcessingTypes(processingTypes);
    setValue('processingTypes', processingTypes as ProcessingTypes[], {shouldDirty: true});
  };

  const displayUploadButton = () => (
    <UploadButton
      id="imageUploadButton"
      name="image"
      variant="outlined"
      color="secondary"
      className={classes.upload}
      errors={errors}
      fullWidth
      startIcon={<ImageIcon />}
      accept={InputAccept.image}
      onSelectFile={handleSelectFile('image')}
    >
      Upload Image
    </UploadButton>
  );

  const handleIsGatewayChange = () => {
    const newValue = !isGateway;
    setIsGateway(newValue);
    setValue('isGateway', newValue || undefined, {shouldDirty: true});
  };

  const handleIsEnabledScheduleABillingChange = () => {
    const newValue = !isEnabledScheduleABilling;
    setIsEnabledScheduleABilling(newValue);
    setValue('isEnabledScheduleABilling', newValue, {shouldDirty: true});
  };

  const handleFeatureItemChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    features[index] = event.target.value;
    setFeatures([...features]);
    setValue('features', [...features]);
  };

  const handleFeatureAdd = (index: number) => {
    features.splice(index + 1, 0, '');
    setFeatures([...features]);
    setValue('features', [...features]);
  };

  const handleFeatureDelete = (index: number) => {
    features.splice(index, 1);
    setFeatures([...features]);
    setValue('features', [...features]);
  };

  const handleAccessoryItemChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    accessories[index] = event.target.value;
    setAccessories([...accessories]);
    setValue('options.accessories', [...accessories]);
  };

  const handleAccessoryAdd = (index: number) => {
    accessories.splice(index + 1, 0, '');
    setAccessories([...accessories]);
    setValue('options.accessories', [...accessories]);
  };

  const handleAccessoryDelete = (index: number) => {
    accessories.splice(index, 1);
    setAccessories([...accessories]);
    setValue('options.accessories', [...accessories]);
  };

  const displayAccessories = (accessory: string, index: number) => {
    return (
      <ListItem className={classes.listItem} key={`accessory:${index}`}>
        <TextField
          label=""
          variant="outlined"
          name={`options.accessories.${index}`}
          errors={errors}
          control={control}
          defaultValue={accessory}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleAccessoryItemChange(event, index)
          }
        />
        <ListItemIcon>
          <Tooltip title={'Add accessory below'}>
            <span className={classes.actionItem}>
              <IconButton size="small" onClick={() => handleAccessoryAdd(index)}>
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={'Delete item'}>
            <span className={classes.actionItem}>
              <IconButton size="small" onClick={() => handleAccessoryDelete(index)}>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </ListItemIcon>
      </ListItem>
    );
  };

  const displayFeature = (feature: string, index: number) => {
    return (
      <ListItem className={classes.listItem} key={`feature:${index}`}>
        <TextField
          label=""
          variant="outlined"
          name={`features.${index}`}
          errors={errors}
          control={control}
          defaultValue={feature}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            handleFeatureItemChange(event, index)
          }
        />
        <ListItemIcon>
          <Tooltip title={'Add feature below'}>
            <span className={classes.actionItem}>
              <IconButton size="small" onClick={() => handleFeatureAdd(index)}>
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title={'Delete item'}>
            <span className={classes.actionItem}>
              <IconButton size="small" onClick={() => handleFeatureDelete(index)}>
                <DeleteIcon />
              </IconButton>
            </span>
          </Tooltip>
        </ListItemIcon>
      </ListItem>
    );
  };

  const displayYesNo = (name: string) => {
    return [
      <MenuItem key={`${name}-true`} value={'true'}>
        Yes
      </MenuItem>,
      <MenuItem key={`${name}-false`} value={'false'}>
        No
      </MenuItem>,
    ];
  };

  const displayErrorMessage = (message: string | undefined) => {
    return message && <FormHelperText error={true}>{message}</FormHelperText>;
  };

  const handleClearAuthBatch = useCallback(
    () => setValue('defaultsAutoBatch', undefined),
    [setValue]
  );

  if (ratePrograms.promised) return <Loading />;

  return (
    <Fragment>
      <Grid item xs={4}>
        <div className={classes.displayContainer}>
          {!image && !imageUrl && (
            <div
              className={classes.defaultLogo}
              style={{backgroundImage: `url(${ComingSoonImage})`}}
            />
          )}
          {!image && imageUrl && <img className={classes.logo} src={imageUrl} alt="logo" />}
          {image && <img className={classes.logo} src={image.dataUrl} alt="logo" />}
        </div>
      </Grid>
      <Grid item xs={8}>
        <TextField name="name" label="Name" required errors={errors} control={control} />
      </Grid>
      <Grid item xs={4}>
        {displayUploadButton()}
      </Grid>
      <Grid item xs={3} className={classes.gateway}>
        <Checkbox
          name="isGateway"
          label="Is Gateway"
          checked={isGateway}
          onClick={(event: any) => event.stopPropagation()}
          onFocus={(event: any) => event.stopPropagation()}
          onChange={handleIsGatewayChange}
          register={register}
          errors={errors}
        />
      </Grid>
      <Grid item xs={5} className={classes.gateway}>
        {isEnabledScheduleABilling !== undefined && (
          <Checkbox
            name="isEnabledScheduleABilling"
            label="Enable Schedule A Billing"
            checked={isEnabledScheduleABilling}
            onClick={(event: any) => event.stopPropagation()}
            onFocus={(event: any) => event.stopPropagation()}
            onChange={handleIsEnabledScheduleABillingChange}
            register={register}
            errors={errors}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="price"
          label="Price"
          required
          errors={errors}
          control={control}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Select name="duration" label="Billing Method" required errors={errors} control={control}>
          {Object.entries(EquipmentDurationType).map(([key, value]) => (
            <MenuItem key={`${key}`} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={4}>
        <Select
          name="platform"
          label="Platform"
          required
          errors={errors}
          control={control}
          multiple
        >
          {Object.entries(Platforms).map(([key, value]) => (
            <MenuItem key={`${key}`} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} fullWidth variant="outlined">
          <InputLabel id="programs-label">Programs</InputLabel>
          <MuiSelect
            name="programs"
            variant="outlined"
            labelId="programs-label"
            label="Programs"
            id="programs"
            multiple
            renderValue={selected => (selected as string[]).join(', ')}
            value={chosenPrograms}
            onChange={handleProgramsChange}
            MenuProps={MenuProps}
            fullWidth
          >
            {ratePrograms.data?.map(p => (
              <MenuItem key={p.name} value={p.name}>
                <MuiCheckbox checked={chosenPrograms.indexOf(p.name) > -1} color="primary" />
                <ListItemText primary={p.name} />
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <Select
          name="availability"
          label="Availability"
          required={!isGateway}
          errors={errors}
          control={control}
          multiple
        >
          {Object.entries(EquipmentAvailability).map(([key, value]) => (
            <MenuItem key={`${key}`} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <Select name="viewableBy" label="Viewable By" required errors={errors} control={control}>
          {Object.entries(EquipmentViewableBy).map(([key, value]) => (
            <MenuItem key={`${key}`} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} fullWidth variant="outlined">
          <InputLabel id="cashDiscount-label" required={!isGateway}>
            Cash Discount
          </InputLabel>
          <MuiSelect
            name="options.cashDiscount"
            variant="outlined"
            label="Cash Discount"
            labelId="cashDiscount-label"
            id="cashDiscount"
            multiple
            error={Boolean(errors.options?.cashDiscount)}
            renderValue={selected =>
              (selected as string[])
                .map(x => EquipmentYesOrNo[x as keyof typeof EquipmentYesOrNo])
                .join(', ')
            }
            value={chosenCashDiscount}
            onChange={handleCashDiscountChange}
            MenuProps={MenuProps}
            fullWidth
          >
            {Object.entries(EquipmentYesOrNo).map(([key, value]) => (
              <MenuItem key={`${key}`} value={key}>
                <MuiCheckbox checked={chosenCashDiscount.indexOf(key) > -1} color="primary" />
                <ListItemText primary={value} />
              </MenuItem>
            ))}
          </MuiSelect>
          {displayErrorMessage(errors.options?.cashDiscount?.message)}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} fullWidth variant="outlined">
          <InputLabel id="connectionType-label" required={!isGateway}>
            Connection Types
          </InputLabel>
          <MuiSelect
            name="options.connectionType"
            variant="outlined"
            labelId="connectionType-label"
            label="Connection Types"
            id="connectionType"
            multiple
            error={Boolean(errors.options?.connectionType)}
            renderValue={selected =>
              (selected as string[])
                .map(x => EquipmentConnectionType[x as keyof typeof EquipmentConnectionType])
                .join(', ')
            }
            value={chosenConnectionType}
            onChange={handleConnectionTypeChange}
            MenuProps={MenuProps}
            fullWidth
          >
            {Object.entries(EquipmentConnectionType).map(([key, value]) => (
              <MenuItem key={`${key}`} value={key}>
                <MuiCheckbox checked={chosenConnectionType.indexOf(key) > -1} color="primary" />
                <ListItemText primary={value} />
              </MenuItem>
            ))}
          </MuiSelect>
          {displayErrorMessage(errors.options?.connectionType?.message)}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <FormControl className={classes.formControl} fullWidth variant="outlined">
          <InputLabel id="processingTypes-label">Processing Types</InputLabel>
          <MuiSelect
            name="processingTypes"
            variant="outlined"
            labelId="processingTypes-label"
            label="Processing Types"
            id="processingTypes"
            multiple
            required
            error={Boolean(errors.processingTypes)}
            renderValue={selected =>
              (selected as string[])
                .map(x => ProcessingTypes[x as keyof typeof ProcessingTypes])
                .join(', ')
            }
            value={chosenProcessingTypes}
            onChange={handleProcessingTypesChange}
            MenuProps={MenuProps}
            fullWidth
          >
            {Object.entries(ProcessingTypes).map(([key, value]) => (
              <MenuItem key={`${key}`} value={key}>
                <MuiCheckbox checked={chosenProcessingTypes.indexOf(key) > -1} color="primary" />
                <ListItemText primary={value} />
              </MenuItem>
            ))}
          </MuiSelect>
          {displayErrorMessage(errors.processingTypes?.message)}
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Features:
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextFieldRichEditor
          name="description"
          label="Description"
          errors={errors}
          control={control}
        />
      </Grid>
      <Grid item xs={12}>
        {features.length === 0 && (
          <Button
            size="small"
            onClick={() => handleFeatureAdd(-1)}
            fullWidth
            startIcon={<AddIcon />}
          >
            Add feature
          </Button>
        )}
        {features && <List>{features.map((val, idx) => displayFeature(val, idx))}</List>}
      </Grid>
      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Accessories:
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={12}>
        {accessories.length === 0 && (
          <Button
            size="small"
            onClick={() => handleAccessoryAdd(-1)}
            fullWidth
            startIcon={<AddIcon />}
          >
            Add accessory
          </Button>
        )}
        {accessories && <List>{accessories.map((val, idx) => displayAccessories(val, idx))}</List>}
      </Grid>
      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Defaults:
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={6}>
        <Select
          name="defaultsAutoBatch"
          label="Auto Batch"
          enabled={!isGateway}
          errors={errors}
          control={control}
          onClear={handleClearAuthBatch}
        >
          {displayYesNo('defaultsAutoBatch')}
        </Select>
      </Grid>
      <Grid item xs={6}>
        {String(watchAutoBatch) === 'true' && (
          <Select
            name="defaultsAutoBatchTime"
            label="Batch Time"
            errors={errors}
            clearable
            control={control}
            options={String(watchAutoBatch) === 'true' ? timeList() : []}
            compareFn={() => 0}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <Select name="defaultsCashDiscount" label="Cash Discount" errors={errors} control={control}>
          {Object.entries(EquipmentYesOrNo).map(([key, value]) => {
            if (chosenCashDiscount.includes(key)) {
              return (
                <MenuItem
                  key={`${key}`}
                  value={`${value === EquipmentYesOrNo.yes ? 'true' : 'false'}`}
                >
                  {value}
                </MenuItem>
              );
            }
          })}
        </Select>
      </Grid>
      <Grid item xs={6}>
        <TextField
          name="defaultsCashDiscountPercent"
          label="Cash Discount Percent"
          errors={errors}
          control={control}
          type="number"
          InputProps={{
            endAdornment: <InputAdornment position="start">%</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <Select name="defaultsContactless" label="Contactless" errors={errors} control={control}>
          {isGateway && <MenuItem value={''}>&nbsp;</MenuItem>}
          {displayYesNo('defaultsContactless')}
        </Select>
      </Grid>
      <Grid item xs={4}>
        <Select name="defaultsFileType" label="File Type" errors={errors} control={control}>
          {isGateway && <MenuItem value={''}>&nbsp;</MenuItem>}
          {Object.entries(EquipmentFileType).map(([key, value]) => (
            <MenuItem key={`${key}`} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={4}>
        <Select
          name="defaultsTipMode"
          label="Tip Mode"
          enabled={watchDefaultsFileType === EquipmentFileType.restaurant}
          errors={errors}
          control={control}
        >
          {watchDefaultsFileType !== EquipmentFileType.restaurant && (
            <MenuItem value={''}>&nbsp;</MenuItem>
          )}
          {Object.entries(EquipmentTipMode).map(([key, value]) => (
            <MenuItem key={`${key}`} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      {accessories.length > 0 && (
        <Grid item xs={12}>
          <Select name="defaultsAccessories" label="Accessory" errors={errors} control={control}>
            <MenuItem value={''}>&nbsp;</MenuItem>
            {accessories.map(val => {
              return (
                <MenuItem key={`${val}`} value={val}>
                  {val}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
      )}
      {chosenConnectionType.length > 0 && (
        <Grid item xs={12}>
          <Select
            name="defaultsConnectionType"
            label="Connection Type"
            errors={errors}
            control={control}
          >
            {Object.entries(EquipmentConnectionType).map(([key, value]) => {
              if (chosenConnectionType.includes(key)) {
                return (
                  <MenuItem key={`${key}`} value={value}>
                    {value}
                  </MenuItem>
                );
              }
            })}
          </Select>
        </Grid>
      )}
      <Grid item xs={12}>
        <Select
          name="defaultsShippingSpeed"
          label="Shipping Speed"
          errors={errors}
          control={control}
        >
          {Object.entries(ShippingSpeed).map(([key, value]) => (
            <MenuItem key={`${key}`} value={value}>
              {value}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </Fragment>
  );
};
