import {isBefore, subDays} from 'date-fns';
import {Ticket, UniversalTimestamp} from '../../..';
import {FilterKey} from '../types';

export function filterTicketsByKey(tickets: Ticket[], filterKey?: FilterKey): Ticket[] {
  if (!tickets?.length || !filterKey) {
    return tickets;
  }

  let aggregatedTickets = tickets;

  switch (filterKey) {
    case FilterKey.openedMoreThan24hAgo: {
      aggregatedTickets = tickets.filter(t => {
        const openedAt = (t.openedAt as UniversalTimestamp | undefined)?.toDate();

        if (!openedAt) {
          return true;
        }

        return isBefore(openedAt, subDays(new Date(), 1));
      });

      break;
    }

    case FilterKey.auditStartedMoreThan24hAgo: {
      aggregatedTickets = tickets.filter(t => {
        const auditStartedAt = (t.auditStartedAt as UniversalTimestamp | undefined)?.toDate();

        if (!auditStartedAt) {
          return false;
        }

        return isBefore(auditStartedAt, subDays(new Date(), 1));
      });

      break;
    }

    case FilterKey.billingAuditStartedMoreThan24hAgo: {
      aggregatedTickets = tickets.filter(t => {
        const billingAuditStartedAt = (
          t.billingAuditStartedAt as UniversalTimestamp | undefined
        )?.toDate();

        if (!billingAuditStartedAt) {
          return false;
        }

        return isBefore(billingAuditStartedAt, subDays(new Date(), 1));
      });

      break;
    }
  }

  return aggregatedTickets;
}
