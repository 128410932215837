import {PortfolioStatistics} from '@ozark/functions/src/functions/express/private/types/Reports';
import {Column} from '../../api/Column';
import {formatNumber, formatPercent} from '../../util';
import {Table} from '../Table';

type DataRow = {
  id: string;
  title: string;
  [monthYear: string]: string;
};

type Props = {
  portfolioStatistics: PortfolioStatistics;
  flat?: boolean;
};

const formatDataForTable = (portfolioStatistics: PortfolioStatistics) => {
  const midsForPast12Months = portfolioStatistics.yearlySummary.reduce(
    (accumulator, summary) => {
      accumulator.total += Number(summary.boardedCount);
      accumulator.totalClosed += Number(summary.closedCount);

      return accumulator;
    },
    {total: 0, totalClosed: 0}
  );
  const startingMidTotal = portfolioStatistics.totalMids - midsForPast12Months.total;
  const startingClosedMidTotal =
    portfolioStatistics.totalClosedMids - midsForPast12Months.totalClosed;
  let midsCount: number = startingMidTotal;
  let closedMidsCount: number = startingClosedMidTotal;
  // rows will have : id | title | month 1 | month 2 | month n
  const data: DataRow[] = portfolioStatistics.yearlySummary.reduce(
    (acc, summary) => {
      const midsAdded = +summary.boardedCount;
      midsCount += midsAdded;

      let percentageAdded = 0;
      if (midsCount > 0) {
        percentageAdded = (midsAdded / midsCount) * 100;
      }

      if (+summary.closedCount > 0) {
        closedMidsCount += +summary.closedCount;
      }
      const activeMids = midsCount - closedMidsCount;

      acc[0][summary.monthYear] = formatNumber(midsAdded);
      acc[1][summary.monthYear] = formatNumber(midsCount);
      acc[2][summary.monthYear] = percentageAdded ? formatPercent(percentageAdded) : '0.00%';
      acc[3][summary.monthYear] = formatNumber(activeMids);

      return acc;
    },
    [
      {title: 'MIDs Added', id: 'mids-added'},
      {title: 'Number of MIDs', id: 'mids-total'},
      {title: 'Percent Added', id: 'mids-percent-add'},
      {title: 'Active MIDs', id: 'mids-active'},
    ] as DataRow[]
  );

  return data;
};

// Transpose data because each column contains month year.
// First column contains empty space, that's where each label go
const formatDataForColumns = (portfolioStatistics: PortfolioStatistics): Column<DataRow>[] => {
  const result = [
    {id: 'title', numeric: false, sortable: false, label: ''},
    ...portfolioStatistics.yearlySummary.map(row => ({
      id: row.monthYear,
      numeric: false,
      sortable: false,
      label: row.monthYear,
    })),
  ];

  return result;
};

export const PortfolioTable = ({portfolioStatistics, flat}: Props) => {
  return (
    <Table
      columns={formatDataForColumns(portfolioStatistics)}
      rows={formatDataForTable(portfolioStatistics)}
      sort={[['monthYear', 'DESC']]}
      flat={flat}
    />
  );
};
