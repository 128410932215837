import {subDays} from 'date-fns';
import {collection, getCountFromServer, query, where} from 'firebase/firestore';
import {useEffect, useState} from 'react';
import {Collections, Firebase, selectTicketView, TicketStatus, ViewableByType} from '../../../..';
import {useUserInfo} from '../../../../hooks';
import {stateEmpty, statePromised} from '../../constants/constants';
import {FilterKey, TFolder} from '../../types';
import {useTicketsViewableBy} from './useTicketsViewableBy';

export function useTicketsMyAssociationX(
  statusSet: TicketStatus[],
  merchantMids: string[],
  associationPath: string,
  promised: boolean = false // used to override promised when first call is awating mids
) {
  const {uid} = useUserInfo();
  const {filterOutInternalTickets} = useTicketsViewableBy();
  const [ticketsAssociated, setTicketsAssociated] = useState(statePromised);

  useEffect(() => {
    if (!merchantMids.length || statusSet.includes(TicketStatus.Draft)) {
      setTicketsAssociated({...stateEmpty, promised});
      return;
    }
    setTicketsAssociated(statePromised);

    let query = Firebase.firestore
      .collection(Collections.tickets)
      .where('author.id', '!=', uid) // creator tickets are returned by repoter query
      .where(associationPath, 'in', merchantMids.slice(0, 10)); //TODO: think about how to convert to onSnapshot batch

    if (statusSet.length === 1) {
      query = query.where('status', '==', statusSet[0]);
    }

    return query.onSnapshot(
      async snapshot => {
        if (snapshot.size === 0) {
          setTicketsAssociated({...stateEmpty, promised});
          return;
        }
        let tickets = filterOutInternalTickets(snapshot.docs.map(selectTicketView));
        if (statusSet.length > 1) {
          tickets = tickets.filter(ticket => ticket.status && statusSet.includes(ticket.status));
        }
        setTicketsAssociated({promised, data: tickets});
      },
      err => {
        console.error(err);
        setTicketsAssociated({promised, error: err});
      }
    );
  }, [statusSet, merchantMids, filterOutInternalTickets]);

  return {
    ticketsAssociated,
  };
}

type UseTicketsMyAssociationXCountProps = {
  statusSet: TFolder['statusSet'];
  filterKey?: TFolder['filterKey'];
  erpFilters?: TFolder['erpFilters'];
  merchantMids: string[];
  associationPath: string;
};
export function useTicketsMyAssociationXCount({
  statusSet,
  filterKey,
  erpFilters,
  merchantMids,
  associationPath,
}: UseTicketsMyAssociationXCountProps): {count: number; refetch: () => void} {
  const {uid, isMerchant, isErpUser, isAgent} = useUserInfo();
  const [ticketsAssociationXCount, setTicketsAssociationXCount] = useState<number>(0);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    async function fetchCount() {
      if (!merchantMids.length) {
        return 0;
      }

      const ref = collection(Firebase.firestore, Collections.tickets);
      let q = query(
        ref,
        where('author.id', '!=', uid),
        where(associationPath, 'in', merchantMids.slice(0, 10))
      );

      if (statusSet.length === 1) {
        q = query(q, where('status', '==', statusSet[0]));
      } else if (statusSet.length > 1) {
        q = query(q, where('status', 'in', statusSet));
      }

      if (!isErpUser) {
        if (isAgent) {
          q = query(q, where('viewableBy', 'in', [ViewableByType.agentOrErp, ViewableByType.all]));
        } else {
          q = query(q, where('viewableBy', '==', ViewableByType.all));
        }
      }

      if (isErpUser && erpFilters?.whereCount) {
        q = query(q, erpFilters.whereCount);
      }

      if (filterKey) {
        switch (filterKey) {
          case FilterKey.openedMoreThan24hAgo:
            q = query(q, where('openedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.auditStartedMoreThan24hAgo:
            q = query(q, where('auditStartedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.billingAuditStartedMoreThan24hAgo:
            q = query(q, where('billingAuditStartedAt', '<=', subDays(new Date(), 1)));
            break;
        }
      }

      const countQuery = await getCountFromServer(q);

      setTicketsAssociationXCount(countQuery.data().count);
    }

    fetchCount();
  }, [
    uid,
    isMerchant,
    isErpUser,
    isAgent,
    statusSet,
    merchantMids,
    associationPath,
    counter,
    erpFilters,
    filterKey,
  ]);

  return {count: ticketsAssociationXCount, refetch: () => setCounter(counter => counter + 1)};
}
