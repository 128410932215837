import {subDays} from 'date-fns';
import {collection, getCountFromServer, query, where} from 'firebase/firestore';
import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {Collections, Firebase, TicketStatus, useUserInfo, ViewableByType} from '../../..';
import {stateEmpty, statePromised} from '../constants/constants';
import {FilterKey, TFolder} from '../types';
import {whenAllTickets} from '../utils';
import {filterTicketsByKey} from '../utils/filters';
import {useTicketsAssociatedAgents} from './ticketsSlices/useTicketsAssociatedAgents';
import {useTicketsFiltersContainer} from './useTicketsFiltersContainer';
import {useTicketsQuery} from './useTicketsQuery';

export function useTicketsAllAgentMember(statusSet: TicketStatus[], filterKey?: FilterKey) {
  const {agentIdFilter} = useTicketsFiltersContainer();
  const {sortBy, sortDir} = useTicketsQuery();
  const [tickets, setTickets] = useState(statePromised);

  const {ticketsAssociatedAgents} = useTicketsAssociatedAgents(statusSet);

  useEffect(() => {
    !tickets.promised && setTickets(statePromised);

    whenAllTickets([ticketsAssociatedAgents], mergedTickets => {
      if (!mergedTickets.length) {
        setTickets(stateEmpty);
        return;
      }

      /**
       * This filter is related to order that we use here ticket_onChange_updateAssociatedAgents
       * We check author agent => assigned agent => associated agent => agent related to application => agent related to merchant
       */
      const ticketsPreFiltered = agentIdFilter
        ? mergedTickets.filter(t => t.associatedAgents?.[0] === agentIdFilter)
        : mergedTickets;

      const ticketsFiltered = filterTicketsByKey(ticketsPreFiltered, filterKey);
      const ticketsSorted = orderBy(ticketsFiltered, [sortBy], [sortDir]);
      setTickets({promised: false, data: ticketsSorted});
    });
  }, [agentIdFilter, sortBy, sortDir, ticketsAssociatedAgents]);

  return {
    tickets,
    counter: tickets.data?.length ?? 0,
  };
}

type UseTicketsAllAgentMemberCountProps = {
  statusSet: TFolder['statusSet'];
  filterKey?: TFolder['filterKey'];
  erpFilters?: TFolder['erpFilters'];
};
export function useTicketsAllAgentMemberCount({
  statusSet,
  filterKey,
  erpFilters,
}: UseTicketsAllAgentMemberCountProps): {count: number; refetch: () => void} {
  const {agentIdFilter} = useTicketsFiltersContainer();
  const [ticketsCount, setTicketsCount] = useState(0);
  const {isErpUser, isAgent, uid} = useUserInfo();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    async function fetchCount() {
      const ref = collection(Firebase.firestore, Collections.tickets);
      let q = query(ref, where('status', 'in', statusSet));

      if (uid) {
        q = query(q, where('associatedAgents', 'array-contains', uid));
      }

      if (agentIdFilter) {
        q = query(q, where('associatedAgents', 'array-contains', agentIdFilter));
      }

      if (filterKey) {
        switch (filterKey) {
          case FilterKey.openedMoreThan24hAgo:
            q = query(q, where('openedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.auditStartedMoreThan24hAgo:
            q = query(q, where('auditStartedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.billingAuditStartedMoreThan24hAgo:
            q = query(q, where('billingAuditStartedAt', '<=', subDays(new Date(), 1)));
            break;
        }
      }

      if (!isErpUser) {
        if (isAgent) {
          q = query(q, where('viewableBy', 'in', [ViewableByType.agentOrErp, ViewableByType.all]));
        } else {
          q = query(q, where('viewableBy', '==', ViewableByType.all));
        }
      }

      if (isErpUser && erpFilters?.whereCount) {
        q = query(q, erpFilters.whereCount);
      }

      const countQuery = await getCountFromServer(q);
      setTicketsCount(countQuery.data().count);
    }

    fetchCount();
  }, [statusSet, isErpUser, isAgent, filterKey, agentIdFilter, uid, counter]);

  return {count: ticketsCount, refetch: () => setCounter(counter => counter + 1)};
}
