import {Badge} from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {useTicketCounters} from '../hooks/useTicketCounters';
import {StatusFolderProps} from '../types';

export const TicketsListFolderAll = ({folder, isSelected}: StatusFolderProps) => {
  const counter = useTicketCounters({folder});

  const Icon = folder.icon;
  return (
    <ListItemButton selected={isSelected} sx={{pl: folder.indent ? 4 : 2}}>
      {folder.hasCounter && (
        <ListItemIcon>
          <Badge badgeContent={counter.count} max={999} color={isSelected ? 'primary' : 'default'}>
            {Icon && <Icon />}
          </Badge>
        </ListItemIcon>
      )}
      {!folder.hasCounter && <ListItemIcon>{Icon && <Icon />}</ListItemIcon>}
      <ListItemText
        primary={folder.folderName}
        primaryTypographyProps={{
          fontWeight: isSelected ? '500' : 'initial',
          color: isSelected ? 'black' : 'initial',
          fontStyle: folder.italic ? 'italic' : 'initial',
        }}
      />
    </ListItemButton>
  );
};
