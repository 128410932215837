import PublishIcon from '@mui/icons-material/Publish';
import {Avatar, Box, Grid, Link, Typography} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Collections, Firebase, useNotification} from '@ozark/common';
import {Title} from '@ozark/common/components';
import {Residual} from '@ozark/functions/src/documents/Residual';
import {useCallback, useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import {readString} from 'react-papaparse';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {Tab, Tabs} from '../Tabs';
import {Files} from './Files';
import {Import} from './Import';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    grow: {
      flex: 1,
    },
  })
);

const Residuals = () => {
  const classes = useStyles();
  const history = useHistory();

  const [tab, setTab] = useState(0);
  const [lastResidual, setLastResidual] = useState<Residual | null>(null);
  const [defaultValues, setDefaultValues] = useState<object | null>(null);
  const [dragActive, setDragActive] = useState(false);
  const [csvBuffer, setCsvBuffer] = useState<ArrayBuffer | null>(null);
  const [csvObjects, setCsvObjects] = useState<object[] | null>();
  const showSnackbar = useNotification();

  const handleTabChange = (_event: React.ChangeEvent<{}>, newTab: number) => {
    setTab(newTab);
  };

  useEffect(() => {
    (async () => {
      const snapshot = await Firebase.firestore
        .collection(Collections.residuals)
        .orderBy('createdAt', 'desc')
        .limit(1)
        .get();
      if (snapshot.empty) {
        setTab(1);
        return;
      }
      const doc = snapshot.docs[0];
      if (!doc) return;
      const residual = doc.data() as Residual;
      setLastResidual(residual);
    })();
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onerror = () => console.error('file reading has failed');
      reader.onload = async () => {
        setCsvBuffer(reader.result as ArrayBuffer);
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const {getRootProps, getInputProps} = useDropzone({onDrop, accept: ['csv', '.csv', 'text/csv']});

  const reset = () => {
    setDefaultValues(null);
    setCsvObjects(null);
    setCsvBuffer(null);
    setDragActive(false);
  };

  const handleSubmit = async (data: Residual) => {
    if (!csvBuffer) return;
    const snapshot = await Firebase.firestore
      .collection(Collections.residuals)
      .where('yearMonth', '==', data.yearMonth)
      .get();
    if (!snapshot.empty) {
      showSnackbar('error', 'Residual file for this date already exists');
      return;
    }
    await Firebase.firestore.collection(Collections.residuals).add(data);
    setTab(0);
    reset();
  };

  useEffect(() => {
    if (csvBuffer) {
      const encoding = new TextDecoder('utf-8');
      const text = encoding.decode(csvBuffer);
      (async () => {
        // @ts-ignore
        const csv = readString(text, {
          header: true,
          skipEmptyLines: true,
          transformHeader: (e: string) => {
            return e.trim();
          },
          transform: (e: string) => {
            return e.trim();
          },
          // @ts-ignore
        }).data;

        setCsvObjects(csv as any);
        if (lastResidual) {
          const firstObjectKeys = Object.keys(csv[0] as object);
          const defaultValues = Object.keys(lastResidual.columns).reduce(
            (accumulator: any, current) => {
              if (firstObjectKeys.indexOf(lastResidual.columns[current]) > -1) {
                accumulator[`columns.${current}`] = lastResidual.columns[current];
              }
              return accumulator;
            },
            {}
          );
          setDefaultValues(defaultValues);
        } else {
          setDefaultValues({
            'columns.group': 'Group',
            'columns.mid': 'Merchant ID',
            'columns.dba': 'DBA Name',
            'columns.merchantStatus': 'Merchant Status',
            'columns.bankcardSalesVolume': 'Bankcard Sales Volume',
            'columns.bankcardSalesVolumeAmex': 'Bankcard Sales Volume Amex',
            'columns.pinDebitSalesVolume': 'PIN Debit Volume',
            'columns.totalIncome': 'Total Income',
            'columns.processingExpense': 'Processing Expense (Interchange and Card Brand Fees)',
            'columns.transactionCount': 'Bankcard Transaction Count',
            'columns.transactionCountAmex': 'Bankcard Transaction Count Amex',
            'columns.pinDebitCount': 'PIN Debit Transactions',
            'columns.wirelessCount': 'Wireless Transaction Count',
            'columns.gatewayCount': 'Gateway Transaction Count',
            'columns.avsElectronicCount': 'AVS Electronic Count',
            'columns.avsVoiceCount': 'AVS Voice Count',
            'columns.batchCount': 'Batch Count',
            'columns.chargeBackCount': 'Chargeback Count',
            'columns.retrievalCount': 'Retrieval Count',
            'columns.debitAccessOccurrences': 'Debit Access Occurrences',
            'columns.accountOnFileOccurrences': 'Account on File Occurrences',
            'columns.monthlyMonitoringOccurrences': 'Monthly Monitoring Occurrences',
            'columns.irsRegulatoryFeeOccurrences': 'IRS Regulatory Fee Occurrences',
            'columns.wirelessTerminalMonthlyOccurrences': 'Wireless Terminal Occurrences',
            'columns.otherGatewayOccurrences': 'Other Gateway Occurrences',
            'columns.merchantOnlineAccessOccurrences': 'Merchant Online Access Occurrences',
            'columns.annualPCIOccurrences': 'Annual PCI Occurrences',
            'columns.annualPCIWithBreachInsuranceOccurrences': 'Annual PCI with Breach Occurrences',
            'columns.monthlyMinimumOccurrences': 'Monthly Minimum Occurrences',
            'columns.annualFeeOccurrences': 'Annual Fee Occurrences',
            'columns.gatewaySetupOccurrences': 'Gateway Setup Occurrences',
            'columns.merchantClubOccurrences': 'Merchant Club Occurrences',
            'columns.gatewayMonthlyOccurrences': 'Gateway Monthly Occurrences',
            'columns.agentCredits': 'Agent Credits',
            'columns.transactionIncome': 'Transaction Income',
            'columns.pinDebitEBTIncome': 'PIN Debit EBT Income',
            'columns.batchIncome': 'Batch Income',
            'columns.avsElectronicIncome': 'AVS Electronic Income',
            'columns.gatewayTransactionIncome': 'Gateway Transaction Income',
            'columns.wirelessTransactionIncome': 'Wireless Transaction Income',
            'columns.otherTransactionIncome': 'Other Transaction Income',
            'columns.chargeBackIncome': 'Chargeback Income',
            'columns.retrievalIncome': 'Retrieval Income',
            'columns.debitAccessIncome': 'Debit Access Income',
            'columns.accountOnFileIncome': 'Account on File Income',
            'columns.monthlyMonitoringIncome': 'Monthly Monitoring Income',
            'columns.irsRegulatoryFeeIncome': 'IRS Regulatory Fee Income',
            'columns.wirelessTerminalMonthlyIncome': 'Wireless Terminal Monthly Income',
            'columns.otherGatewayIncome': 'Other Gateway Income',
            'columns.merchantOnlineAccessIncome': 'Merchant Online Access Income',
            'columns.annualPCIIncome': 'Annual PCI Income',
            'columns.monthlyMinimumIncome': 'Monthly Minimum Income',
            'columns.annualFeeIncome': 'Monthly Minimum Income',
            'columns.gatewaySetupIncome': 'Gateway Setup Income',
            'columns.merchantClubIncome': 'Merchant Club Income',
            'columns.gatewayMonthlyIncome': 'Gateway Monthly Income',
          });
        }
      })();
    }
    // eslint-disable-next-line
  }, [csvBuffer]);

  return (
    <div className={classes.root}>
      <Title
        breadcrumbs={[
          <Link component="button" variant="body1" onClick={() => history.push(ROUTES.RESIDUALS)}>
            Residuals
          </Link>,
        ]}
      >
        <div className={classes.grow} />
        <Tabs value={tab} onChange={handleTabChange} centered>
          <Tab label="Residual Files" />
          <Tab label="Import" />
        </Tabs>
      </Title>
      {tab === 0 && <Files onEmptyResiduals={() => setTab(1)} />}
      {tab === 1 && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {!csvObjects && (
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box
                      {...getRootProps()}
                      marginTop={'20vh'}
                      width={500}
                      height={160}
                      display={'flex'}
                      px={6}
                      marginX={'auto'}
                      border={`dashed 4px ${dragActive ? '#1877f2' : '#b2c2cd'}`}
                      borderRadius="20px"
                      flexDirection={'column'}
                      alignItems={'center'}
                      justifyContent={'space-evenly'}
                      onDragOver={() => setDragActive(true)}
                      onDragLeave={() => setDragActive(false)}
                    >
                      <input {...getInputProps()} />
                      <Typography variant="h4">Import Residual File</Typography>
                      <Avatar>
                        <PublishIcon color={dragActive ? 'action' : 'inherit'} />
                      </Avatar>
                    </Box>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          {csvObjects && csvBuffer && defaultValues && (
            <Import
              onSubmit={handleSubmit}
              onCancel={reset}
              csvObjects={csvObjects}
              csvBuffer={csvBuffer}
              defaultValues={defaultValues}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Residuals;
