import {useCallback} from 'react';
import {
  AuthorType,
  Collections,
  Department,
  hasErpRole,
  Profile,
  selectProfileView,
  Ticket,
  TicketAssociation,
  TicketHistoryRecordType,
  TicketPriority,
  TicketStatus,
  TicketTypeAndCategory,
  UniversalSnapshot,
  useNotification,
  ViewableByType,
} from '../../..';
import {Firebase} from '../../../firebase';
import {useUserInfo} from '../../../hooks';
import {getMerchantNameAsync} from '../../../hooks/useMerchant';
import {getTicketSubject} from '../utils/getTicketSubject';
import {useTicketAutoAssignee} from './useTicketAutoAssignee';
import {getCategoryAutomationAppliedSubjectDescription} from './useTicketCategoryAutomation';
import {addHistoryRecordDirect} from './useTicketHistoryRecord';

const getUserErpDepartment = async (uid: string): Promise<Department | undefined> => {
  try {
    const profile = await Firebase.firestore.collection(Collections.profiles).doc(uid).get();

    if (!profile.exists) return;

    return selectProfileView(profile as UniversalSnapshot<Profile>).department as
      | Department
      | undefined;
  } catch (err) {
    console.error(err);
  }
};

export const useCreateNewTicket = () => {
  const {uid, userDisplayName, userRole, isMerchant, isErpUser, userGroupId} = useUserInfo();
  const showNotification = useNotification();
  const {autoSetAssignee} = useTicketAutoAssignee();

  const createNewTicket = useCallback(
    async (
      category: TicketTypeAndCategory,
      association: TicketAssociation,
      description: string,
      viewableBy: ViewableByType
    ) => {
      if (!uid || !userRole) {
        console.error('no user login data');
        return;
      }

      const authorDepartment = isErpUser ? await getUserErpDepartment(uid) : undefined;
      const authorName = isMerchant ? await getMerchantNameAsync(uid) : userDisplayName;
      let subject = getTicketSubject(category, association);
      const appliedSubjectDescription = await getCategoryAutomationAppliedSubjectDescription(
        category,
        association
      );
      if (appliedSubjectDescription) {
        subject = appliedSubjectDescription.subject;
      }

      const newTicketTemplate: Ticket = {
        author: {
          id: uid,
          name: authorName ?? 'n/a',
          type: hasErpRole(userRole) ? AuthorType.erpUser : (userRole as unknown as AuthorType),
          department: authorDepartment,
          groupId: userGroupId,
        },
        status: TicketStatus.Draft,
        priority: TicketPriority.Normal,
        createdAt: Firebase.FieldValue.now(),
        category,
        association,
        description,
        subject,
        viewableBy,
        openedAt: Firebase.FieldValue.now(),
        isAuditable: category.category?.isAuditable ?? false,
      };

      try {
        const docRef = await Firebase.firestore
          .collection(Collections.tickets)
          .add(newTicketTemplate);

        await addHistoryRecordDirect(docRef.id, {
          uid: uid,
          userName: authorName ?? 'n/a',
          userRole: userRole,
          department: authorDepartment,
          title: 'Ticket Created',
          createdAt: Firebase.FieldValue.now(),
          recordType: TicketHistoryRecordType.Create,
        });
        await autoSetAssignee(docRef.id, true, category);
        return docRef;
      } catch (error: any) {
        console.error('Error adding document: ', error);
        showNotification('error', 'Error adding ticket');
      }
    },
    [showNotification, uid, userDisplayName, userRole, isMerchant, isErpUser, userGroupId]
  );

  return {
    createNewTicket,
  };
};
