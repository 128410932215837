import {useHistory, useLocation, useParams} from 'react-router-dom';
import {ticketStatusFolders} from '../constants/ticketStatusFolders';
import {FilterKey, TicketsFolderId} from '../types';

export type TicketsSortBy = 'createdAt' | 'openedAt' | 'auditStartedAt' | 'billingAuditStartedAt';
export type TicketsSortDir = 'asc' | 'desc';

export function useTicketsQuery() {
  const history = useHistory();
  const {pathname, search} = useLocation();
  const {view} = useParams<{view: TicketsFolderId}>();
  const urlParams = new URLSearchParams(search);

  const getParam = (name: string) => {
    return urlParams.get(name);
  };

  const setParam = (params: Record<string, string>) => {
    if (!params) return;
    Object.keys(params).forEach(key => {
      urlParams.set(key, params[key]);
    });
    history.push({pathname, search: urlParams.toString()});
  };

  const sortDir = (urlParams.get('sortDir') ?? 'desc') as TicketsSortDir;

  let sortBy = (
    urlParams.get('sortBy') ?? view === TicketsFolderId.Draft ? 'createdAt' : 'openedAt'
  ) as TicketsSortBy;

  // force sortBy to prevent firebase error: inequality field should be 1st in order-clause
  if (view === TicketsFolderId.Audit || view === TicketsFolderId.Audit24) {
    sortBy = 'auditStartedAt';
  } else if (view === TicketsFolderId.BillingAudit || view === TicketsFolderId.BillingAudit24) {
    sortBy = 'billingAuditStartedAt';
  }

  const filterKey = (urlParams.get('filterKey') ?? undefined) as FilterKey | undefined;

  const folder = ticketStatusFolders.find(f => f.id === view);

  const statusSet = folder?.statusSet ?? [];

  return {
    getParam,
    setParam,
    statusSet,
    sortDir,
    sortBy,
    folderId: view,
    filterKey,
    folder,
  };
}
