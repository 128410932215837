import {DesktopDatePicker} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {Collections, Firebase, ScheduledJobType, useMidsContainer} from '@ozark/common';
import {AsyncState} from '@ozark/common/hooks/AsyncState';
import {AllMIDs, Statement} from '@ozark/functions/src/shared';
import {format} from 'date-fns';
import {useEffect, useState} from 'react';
import {Loading} from '../..';
import {ButtonEmailReport, ScheduleReportProps} from '../../common/ButtonEmailReport';
import {DialogMode} from '../../common/ScheduleJobDialog';
import {MidSelect} from '../../MidSelect/MidSelect';
import {Title} from '../../Title';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: 20,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 30,
    minHeight: '520px',
    position: 'relative',
  },
  noContent: {
    top: '40%',
    position: 'relative',
    textAlign: 'center',
  },
  yearSelect: {
    cursor: 'pointer !important',
    width: 100,
  },
  card: {
    padding: theme.spacing(1, 2, 1),
    color: '#4d6575',
    borderTop: `solid 4px ${theme.palette.primary.main}`,
  },
}));

export const StatementsPage = () => {
  const classes = useStyles();
  const {mids, selectedMid, handleSelectMid, forceSelectMidIfNeeded} = useMidsContainer();
  const [selectedDate, setDateChange] = useState<Date | null>(new Date());
  const [statements, setStatements] = useState<AsyncState<Statement[]>>({
    promised: true,
  });

  useEffect(() => {
    if (mids.promised || !mids.data) return;

    forceSelectMidIfNeeded();
  }, [mids, forceSelectMidIfNeeded]);

  useEffect(() => {
    if (!selectedDate) return;
    setStatements({promised: true});

    const year = selectedDate.getFullYear();
    // Get start and end dates as UTC time to avoid timezone problems
    const selectedStartDate = new Date(`${year}-01-01T00:00:00Z`);
    const selectedEndDate = new Date(`${year}-12-31T23:59:59Z`);

    const unsubscribe = Firebase.firestore
      .collection(Collections.statements)
      .where('mid', '==', selectedMid)
      .where('statementDate', '>=', selectedStartDate)
      .where('statementDate', '<=', selectedEndDate)
      .onSnapshot(
        snapshot => {
          if (snapshot.empty) {
            setStatements({promised: false});
            return;
          }
          const statements = snapshot.docs.map(doc => doc.data() as Statement);

          setStatements({promised: false, data: statements});
        },
        err => {
          console.error(err);
          setStatements({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, [selectedDate, selectedMid, setStatements]);

  const onDateChange = (date: Date | null) => {
    setDateChange(date as Date);
  };

  const handlePdfLink =
    (cloudPath: string, download: boolean = false) =>
    async () => {
      const storageRef = Firebase.storage.ref(cloudPath);
      const a = document.createElement('a');
      a.href = await storageRef.getDownloadURL();
      if (download) {
        a.setAttribute('download', 'statement.pdf');
      }
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

  const realMids = new Map(mids.data);
  realMids.delete('all');

  const scheduleReportProps: ScheduleReportProps = {
    jobType: ScheduledJobType.midReportingStatements,
    getJobParameters: () => {
      return {mid: selectedMid};
    },
  };

  if (mids.promised || !mids.data) return <Loading />;

  if (selectedMid === AllMIDs || !mids.data) return null;

  return (
    <>
      <Title breadcrumbs={[<Typography variant="body1">Merchant Statements</Typography>]}>
        <Box flex={1} />
        <Typography>Statements Year:</Typography>&emsp;
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            views={['year']}
            minDate={new Date(2021, 1, 1)}
            maxDate={new Date()}
            value={selectedDate}
            onChange={onDateChange}
            InputProps={{
              disableUnderline: true,
            }}
            renderInput={params => <TextField variant="standard" sx={{width: 100}} {...params} />}
          />
        </LocalizationProvider>
        <Divider orientation="vertical" flexItem variant="middle" sx={{mx: 2}} />
        <Typography>MID:</Typography>&emsp;
        <MidSelect
          selectedMid={selectedMid}
          handleSelectMid={handleSelectMid}
          mids={{
            promised: mids.promised,
            data: realMids,
          }}
        />
        <Divider orientation="vertical" sx={{mx: 2}} flexItem />
        <ButtonEmailReport
          scheduleReportProps={scheduleReportProps}
          dialogMode={DialogMode.Simple}
          simpleDialogMessage="By clicking SAVE, the Statement Report will be emailed to you once a month"
          hideSendNow={true}
        />
      </Title>

      {statements.promised && (
        <BoxStyled>
          <Box>
            <Typography className={classes.noContent}>
              Please wait. Statements are loading...
            </Typography>
            <Box textAlign="center" pt={2}>
              <CircularProgress color="primary" />
            </Box>
          </Box>
        </BoxStyled>
      )}
      {!statements.promised && !statements.data && (
        <BoxStyled>
          <Typography className={classes.noContent}>No statements available.</Typography>
        </BoxStyled>
      )}
      {!statements.promised && statements.data && (
        <Grid container spacing={4}>
          {statements.data.map((statement, index) => (
            <Grid item key={index}>
              <Card className={classes.card}>
                <CardContent>
                  <Typography variant="h6" component="h3">
                    {format(statement.statementDate.toDate(), 'MMMM yyyy')}
                  </Typography>
                  <Typography color="textSecondary">statement</Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" onClick={handlePdfLink(statement.cloudPathView)}>
                    View PDF
                  </Button>
                  <Button size="small" onClick={handlePdfLink(statement.cloudPathDownload, true)}>
                    Download PDF
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

const BoxStyled = styled(Box)({
  display: 'flex',
  minHeight: 616,
  justifyContent: 'center',
  alignItems: 'center',
});
