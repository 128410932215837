export enum PdfDocuments {
  BancorpTSYS = 'tpl_5EYnQHrEH7yzNe3Zhh',
  FirstData = 'tpl_YpS2pD4mZyy96FM4Jr',
  AvidiaTSYS = 'tpl_NpTQxeqFLLHeYZHAJt',
  AvidiaFirstData = 'tpl_qtCpHqRNbk373DZhAJ',
  EvolveApplication = 'tpl_S6ebxYqLxCL2DHcCkD',
  EvolveTSYS = 'tpl_qYSnEJC453qghMnHm3',
  EvolveTSYSNoLogo = 'tpl_YmTzXzcP9gtEZGZMn9',
  ChesapeakeTSYS = 'tpl_ZzCXZXF5PyargKaz9G',
  PathlyApplication = 'tpl_tfqG5bZqj93s6qHGk9',
  LetterOfAttestation = 'tpl_55QXEHC9aAnkaGxgDx',
}

export enum PdfTemplates {
  DisputeResponse = 'tpl_GkRnDq7kspznxEpnt5',
  SingleTextFieldDocument = 'tpl_cYffgXPPd776GnPD7x',
}
