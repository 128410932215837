import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import Amex from '../../static/images/american_express.png';
import Debit from '../../static/images/debit.png';
import Discover from '../../static/images/discover.png';
import EBT from '../../static/images/ebt_pos.png';
import JCB from '../../static/images/jcb.png';
import Mastercard from '../../static/images/mastercard.png';
import CreditCard from '../../static/images/other.png';
import PayPal from '../../static/images/paypal.png';
import Visa from '../../static/images/visa.png';
// TODO: fix errors

export enum CardImagesDictionaryType {
  Transactions = 'transactionUrl',
  Authorizations = 'authorizationUrl',
  Chargebacks = 'chargebacksUrl',
}

const cardTypeToUrl = {
  [CardImagesDictionaryType.Transactions]: (cardType: string) =>
    TransactionsCardImagesByType[cardType],
  [CardImagesDictionaryType.Authorizations]: (cardType: string) =>
    AuthorizationsCardImagesByType[cardType],
  [CardImagesDictionaryType.Chargebacks]: (cardType: string) =>
    ChargebacksCardImagesByType[cardType],
};

const AuthorizationsCardImagesByType: {[_: string]: any} = {
  2: Mastercard,
  3: Amex,
  4: Visa,
  5: Mastercard,
  7: JCB,
  8: Discover,
  9: CreditCard,
  K: EBT,
  P: PayPal,
};

const TransactionsCardImagesByType: {[_: string]: any} = {
  V: Visa,
  R: Discover,
  S: Amex,
  M: Mastercard,
  2: Mastercard,
  3: Amex,
  4: Visa,
  5: Mastercard,
  6: Discover,
  7: JCB,
  8: Discover,
  K: EBT,
};

export const TransactionsCardTextByType: {[_: string]: string} = {
  V: 'Visa',
  R: 'Discover',
  S: 'American Express',
  M: 'Mastercard',
  2: 'Mastercard',
  3: 'American Express',
  4: 'Visa',
  5: 'Mastercard',
  6: 'Discover',
  7: 'JCB',
  8: 'Discover',
  K: 'EBT POS',
};

const ChargebacksCardImagesByType: {[_: string]: any} = {
  2: Mastercard,
  3: Amex,
  4: Visa,
  5: Mastercard,
  6: Discover,
};

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      height: '3.4rem',
      margin: '-11px auto',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'left',
    },
  })
);

interface Props {
  cardType: string;
  dictionaryType: CardImagesDictionaryType;
  accountNumberFirstDigit?: string;
}

export const CardImage = ({cardType = '', dictionaryType, accountNumberFirstDigit}: Props) => {
  const classes = useStyles();
  // NOTE: 2 differrent formats for transactions card types:
  const cardTypeForMapping =
    dictionaryType === CardImagesDictionaryType.Transactions &&
    !['V', 'R', 'S', 'M'].includes(cardType) &&
    accountNumberFirstDigit
      ? accountNumberFirstDigit
      : cardType;

  let cardImageUrl = cardTypeToUrl[dictionaryType](cardTypeForMapping);
  if (cardImageUrl === undefined) {
    cardImageUrl = CreditCard;
  }

  return <div className={classes.card} style={{backgroundImage: `url(${cardImageUrl})`}} />;
};

export const DebitCardImage = () => {
  const classes = useStyles();
  return <div className={classes.card} style={{backgroundImage: `url(${Debit})`}} />;
};
