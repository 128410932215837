import {
  Agent,
  Announcement,
  AnnouncementView,
  Attachment,
  AttachmentInput,
  AttachmentMetadata,
  AttachmentView,
  Collections,
  Firebase,
  handleAttachmentRemove,
  NotificationCategory,
  ProfileView,
  selectAttachmentView,
  UniversalSnapshot,
  useCallable,
  useNotification,
} from '@ozark/common';
import firebase from 'firebase/compat';
import {useState} from 'react';
import {SubmitHandler} from 'react-hook-form';
import {useHistory} from 'react-router';
import * as ROUTES from '../../constants/routes';
import {useStore} from '../../store/helpers';

export type MIDOption = {
  id: string;
  dbaName: string;
};

export type FormAttachmentItem =
  | (AttachmentView & AttachmentMetadata)
  | ({cloudPath: string; name: string} & AttachmentMetadata);

export type AnnouncementFormData = Pick<
  Announcement,
  | 'title'
  | 'text'
  | 'type'
  | 'isImportantNotification'
  | 'link'
  | 'agentsAllSelected'
  | 'midsAllSelected'
  | 'groupAdminsAllSelected'
  | 'erpUsersAllSelected'
  | 'destinationERPUsersRoles'
> & {
  agents: Agent[];
  groupAdmins: Agent[];
  mids: MIDOption[];
  erpUsers: ProfileView[];
  attachments?: FormAttachmentItem[];
};

type AnnouncementsActionsHook = {
  loading: boolean;
  sendTestAnnouncement: (
    data: AnnouncementFormData | Announcement,
    attachments?: (Attachment | AttachmentInput)[]
  ) => Promise<void>;
  submitAnnouncementForm: SubmitHandler<AnnouncementFormData>;
  updateAnnouncement: (announcementId: string, data: AnnouncementFormData) => Promise<void>;
  deleteAnnouncement: (announcement: AnnouncementView) => Promise<void>;
  onDeleteAnnouncement: (announcement: AnnouncementView) => void;
  onCancelDelete: () => void;
  announcementToDelete: AnnouncementView | null;
};

export const useAnnouncementActions = (
  initialAttachments?: AttachmentView[]
): AnnouncementsActionsHook => {
  const [loading, setLoading] = useState(false);
  const {authUser} = useStore();
  const history = useHistory();
  const showNotification = useNotification();
  const {sendAppNotification, createAnnouncement} = useCallable();
  const [announcementToDelete, setAnnouncementToDelete] = useState<AnnouncementView | null>(null);

  const sendTestAnnouncement = async (
    data: AnnouncementFormData | Announcement,
    attachments?: (Attachment | AttachmentInput)[]
  ) => {
    if (!authUser?.data?.uid) {
      return;
    }

    const userId = authUser.data.uid;
    try {
      const payloadAttachments =
        attachments ??
        (data.attachments as FormAttachmentItem[])?.map(attachment =>
          getAttachmentDocument(attachment, userId)
        );
      const result = await sendAppNotification({
        notification: {
          title: data.title,
          text: data.text,
          type: data.type,
          isImportantNotification: data.isImportantNotification,
          link: data.link,
          status: 'new',
          isTestNotification: true,
          category: NotificationCategory.Announcements,
        },
        userIds: [userId],
        attachments: payloadAttachments,
      });

      if (result.status === 'ok') {
        showNotification(
          'success',
          'Test announcement has successfully been sent. You will get it in a few moments'
        );
      }
    } catch (error) {
      console.error('Failed to send test announcement to the current user', error);
      showNotification('error', 'Failed to send test announcement to the current user');
    }
  };

  const removeAnnouncementAttachmentFromStorage = async (
    announcementId: string,
    attachment: AttachmentView
  ) => {
    const attachmentStorageRef = Firebase.storage.ref(attachment.cloudPath);
    const metadata = await attachmentStorageRef.getMetadata();

    await Firebase.firestore
      .collection(Collections.announcements)
      .doc(announcementId)
      .collection(Collections.attachments)
      .doc(attachment.id)
      .delete();

    if (Number(metadata?.customMetadata?.customNotificationUsageCount ?? 1) === 1) {
      handleAttachmentRemove({cloudPath: attachment.cloudPath});

      return;
    }

    await Firebase.storage.ref(attachment.cloudPath).updateMetadata({
      customMetadata: {
        ...metadata.customMetadata,
        customNotificationUsageCount: (
          Number(metadata?.customMetadata?.customNotificationUsageCount ?? 1) - 1
        ).toString(),
      },
    });
  };

  const updateAnnouncementAttachments = async (
    announcementRef: firebase.firestore.DocumentReference,
    attachments: FormAttachmentItem[]
  ) => {
    if (!authUser?.data?.uid) {
      return;
    }

    const batch = Firebase.firestore.batch();

    for (const attachment of attachments) {
      const collection = announcementRef.collection(Collections.attachments);
      const attachmentViewData = attachment as AttachmentView;
      const attachmentRef = attachmentViewData.id
        ? collection.doc(attachmentViewData.id)
        : collection.doc();
      const attachmentDocument: AttachmentInput = getAttachmentDocument(
        attachment,
        authUser?.data?.uid
      );
      batch.set(attachmentRef, attachmentDocument);

      const attachmentStorageRef = Firebase.storage.ref(attachment.cloudPath);
      const metadata = await attachmentStorageRef.getMetadata();

      await Firebase.storage.ref(attachment.cloudPath).updateMetadata({
        customMetadata: {
          ...(metadata.customMetadata ?? {}),
          customNotificationUsageCount: (
            Number(metadata?.customMetadata?.customNotificationUsageCount ?? 0) + 1
          ).toString(),
        },
      });
    }

    await batch.commit();
  };

  const submitAnnouncementForm: SubmitHandler<AnnouncementFormData> = async data => {
    if (!authUser?.data?.uid) {
      return;
    }
    setLoading(true);

    try {
      const {agentsIds, mids, erpUsersIds, groupAdminsIds} = getDestinationUsersIdsFromAnnouncement(
        data as AnnouncementFormData
      );
      const atLeastOneUserSelected =
        agentsIds.length || mids.length || erpUsersIds.length || groupAdminsIds.length;
      const {
        title,
        text,
        type,
        isImportantNotification,
        link,
        destinationERPUsersRoles,
        agentsAllSelected,
        midsAllSelected,
        groupAdminsAllSelected,
        erpUsersAllSelected,
        attachments,
      } = data;

      if (
        !atLeastOneUserSelected &&
        !agentsAllSelected &&
        !midsAllSelected &&
        !erpUsersAllSelected &&
        !groupAdminsAllSelected &&
        !destinationERPUsersRoles.length
      ) {
        showNotification('error', 'Please select at least one user');
        return;
      }

      const result = await createAnnouncement({
        announcement: {
          title,
          text,
          type,
          isImportantNotification,
          link,
          agentsAllSelected,
          midsAllSelected,
          groupAdminsAllSelected,
          erpUsersAllSelected,
          agentsIds,
          mids,
          erpUsersIds,
          groupAdminsIds,
          destinationERPUsersRoles,
        },
      });

      if (result.status === 'ok') {
        const announcementRef = Firebase.firestore
          .collection(Collections.announcements)
          .doc(result.announcementId);

        if (attachments?.length) {
          await updateAnnouncementAttachments(announcementRef, attachments);
        }

        showNotification('success', 'Announcement is successfully sent to users');
        history.push(ROUTES.ANNOUNCEMENTS);
      }
    } catch (error) {
      console.error('Failed to send announcement to the users with an error', error);
      showNotification('error', 'Failed to announcement to the users');
      setLoading(false);
    }
  };

  const updateAnnouncement = async (announcementId: string, formData: AnnouncementFormData) => {
    setLoading(true);

    try {
      const {agentsIds, mids, erpUsersIds, groupAdminsIds} =
        getDestinationUsersIdsFromAnnouncement(formData);
      const atLeastOneUserSelected =
        agentsIds.length || mids.length || erpUsersIds.length || groupAdminsIds.length;

      const {
        title,
        text,
        type,
        isImportantNotification,
        link,
        destinationERPUsersRoles,
        agentsAllSelected,
        midsAllSelected,
        groupAdminsAllSelected,
        erpUsersAllSelected,
        attachments,
      } = formData;

      if (
        !atLeastOneUserSelected &&
        !agentsAllSelected &&
        !midsAllSelected &&
        !erpUsersAllSelected &&
        !groupAdminsAllSelected &&
        !destinationERPUsersRoles.length
      ) {
        showNotification('error', 'Please select at least one user');
        return;
      }

      const announcementRef = Firebase.firestore
        .collection(Collections.announcements)
        .doc(announcementId);

      await announcementRef.update({
        title,
        text,
        type,
        isImportantNotification,
        link,
        agentsAllSelected,
        midsAllSelected,
        groupAdminsAllSelected,
        erpUsersAllSelected,
        agentsIds,
        mids,
        erpUsersIds,
        groupAdminsIds,
        destinationERPUsersRoles,
      });

      if (attachments?.length) {
        await updateAnnouncementAttachments(announcementRef, attachments);
      }

      if (initialAttachments?.length) {
        for (const attachment of initialAttachments) {
          const isDeleted = !attachments?.find(
            item => (item as AttachmentView).id === attachment.id
          );
          if (isDeleted) {
            await removeAnnouncementAttachmentFromStorage(announcementId, attachment);
          }
        }
      }

      showNotification(
        'success',
        'Announcement is successfully updated. The changes will be applied to users notifications in few moments'
      );
      history.push(ROUTES.ANNOUNCEMENTS);
    } catch (error) {
      console.error('Failed to send test announcement to the current user', error);
      showNotification('error', 'Failed to send test announcement to the current user');
    } finally {
      setLoading(false);
    }
  };

  const deleteAnnouncement = async (announcement: AnnouncementView): Promise<void> => {
    setLoading(true);

    try {
      const {id} = announcement;

      const announcementRef = Firebase.firestore.collection(Collections.announcements).doc(id);

      const attachments = await announcementRef.collection(Collections.attachments).get();
      if (!attachments.empty) {
        for (const attachment of attachments.docs) {
          await removeAnnouncementAttachmentFromStorage(
            id,
            selectAttachmentView(attachment as UniversalSnapshot<Attachment>)
          );
        }
      }

      await announcementRef.delete();

      showNotification('success', 'The announcement is successfully deleted');
    } catch (error) {
      console.error('Failed to delete announcement with an error:', error);
      showNotification('error', 'Failed to delete announcement');
    } finally {
      setLoading(false);
    }
  };

  const onDeleteAnnouncement = (announcement: AnnouncementView) => {
    setAnnouncementToDelete(announcement);
  };

  const onCancelDelete = () => {
    setAnnouncementToDelete(null);
  };

  return {
    loading,
    sendTestAnnouncement,
    submitAnnouncementForm,
    updateAnnouncement,
    deleteAnnouncement,
    onDeleteAnnouncement,
    announcementToDelete,
    onCancelDelete,
  };
};

const getDestinationUsersIdsFromAnnouncement = (announcementFormData: AnnouncementFormData) => {
  const {agents, mids, groupAdmins, erpUsers} = announcementFormData;

  return {
    agentsIds: agents.map(agent => agent.id),
    mids: mids.map(option => option.id),
    groupAdminsIds: groupAdmins.map(agent => agent.id),
    erpUsersIds: erpUsers.map(profile => profile.id),
  };
};

const getAttachmentDocument = (attachment: FormAttachmentItem, userId: string) => ({
  name: attachment.name,
  label: `notification-${attachment.name}`,
  cloudPath: attachment.cloudPath,
  createdAt: new Date(),
  uid: userId,
});
