import {Autocomplete, Box, FormHelperText, TextField} from '@mui/material';
import {omit} from 'lodash';
import {useEffect, useState} from 'react';
import {
  Dispositions,
  getApplicationFieldAsync,
  TicketAssociation,
  TicketAssociationApplication,
  useGlobalSearch,
} from '../../../..';
import {FIELD_NAME_APPLICATION} from '../../constants/constants';
import {InputAssociationError} from '../../types';

type ApplicationSearchResult = {
  id: string;
  disposition: string;
  distinguishableId: string;
  doingBusinessAs: string;
  legalName?: string;
};

type Props = {
  draft: TicketAssociation;
  setDraft: (draft: TicketAssociation) => void;
  errors: Record<InputAssociationError, string>;
  setErrors: (errors: Record<InputAssociationError, string>) => void;
};

export const getSearchResultsAsync = (
  searchFunction: ReturnType<typeof useGlobalSearch>['search_DEPRECATED'],
  inputValue: string
): Promise<TicketAssociationApplication[]> => {
  return new Promise((resolve, reject) => {
    try {
      searchFunction({input: inputValue}, (results?: ApplicationSearchResult[]) => {
        let newOptions = [] as TicketAssociationApplication[];
        if (results) {
          const resultsOptions: TicketAssociationApplication[] = results
            .filter(a => a.disposition !== Dispositions.boarded)
            .map(a => ({
              id: a.id,
              distinguishableId: a.distinguishableId,
              name: a.doingBusinessAs,
              legalName: a.legalName,
            }));
          newOptions = resultsOptions;
        }
        resolve(newOptions);
      });
    } catch (error) {
      reject(error);
    }
  });
};

export const AssociationEditorValueApplication = ({draft, setDraft, errors, setErrors}: Props) => {
  const [loading, setLoading] = useState(false);
  const [applications, setApplications] = useState<TicketAssociationApplication[]>(
    draft.application ? [draft.application] : []
  );
  const [inputValue, setInputValue] = useState('');
  const search = useGlobalSearch({
    applicationSearchOptions: {
      attributesToRetrieve: [
        'id',
        'distinguishableId',
        'doingBusinessAs',
        'mid',
        'disposition',
        'legalName',
      ],
    },
  }).search_DEPRECATED;

  useEffect(() => {
    let active = true;
    if (inputValue === '' || inputValue.length < 3) {
      setApplications(draft.application ? [draft.application] : []);
      setLoading(false);
      return undefined;
    }
    setLoading(true);
    getSearchResultsAsync(search, inputValue)
      .then(options => {
        active && setApplications(options);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [inputValue]);

  return (
    <Box mt={2}>
      <Autocomplete
        id="application-search"
        loading={loading}
        inputValue={inputValue}
        onInputChange={(_, value) => setInputValue(value)}
        options={applications}
        value={draft.application}
        filterOptions={options => options}
        onChange={async (
          _: React.SyntheticEvent<Element, Event>,
          newValue: TicketAssociationApplication | null
        ) => {
          if (newValue?.id) {
            // we need to supply mid for an application association to enable merchants to see associated tickets
            newValue.mid = await getApplicationFieldAsync(newValue.id, 'mid');
          }
          setDraft({...draft, application: newValue ?? undefined});
          if (newValue) {
            setErrors(
              omit(errors, FIELD_NAME_APPLICATION) as Record<InputAssociationError, string>
            );
          }
        }}
        noOptionsText={'no matches found'}
        getOptionLabel={(item: TicketAssociationApplication) =>
          `${item?.name ?? ''} (${item?.distinguishableId})`
        }
        renderInput={params => (
          <TextField
            {...params}
            label="Associated Application"
            required
            variant="standard"
            error={Boolean(errors[FIELD_NAME_APPLICATION])}
            helperText={errors[FIELD_NAME_APPLICATION]}
          />
        )}
      />
      {!inputValue && (
        <FormHelperText id="application-search">
          Start entering DBA or Application ID for search
        </FormHelperText>
      )}
    </Box>
  );
};
