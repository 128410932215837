import {ApiBase, SearchCriteria} from '@ozark/common';
import {
  AgentChargebacks,
  AgentDeclines,
  AgentsSales,
  DispositionStatistic,
} from '@ozark/functions/src/functions/express/private/types';
import {ContextKey} from '@ozark/functions/src/functions/express/private/types/Context';
import {PaginatedResponse} from '@ozark/functions/src/shared';
import {CancelToken} from 'axios';
import {ActiveFilter} from '../components';
import {VolumeFilter} from '../components/FilterPeriodSelect';
import {toQuery} from '../util/FilterUtil';

export class AgentStatisticsApi extends ApiBase {
  getAgentsStatisticsSales = (
    pageInfo: SearchCriteria,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    const queryString = toQuery(pageInfo, filters);
    return this.get<AgentsSales | null>(`agent-statistics/sales?${queryString}`, {}, cancelToken);
  };

  getDispositionStatistics = (
    volumeFilter: VolumeFilter,
    searchCriteria: SearchCriteria,
    disposition: string,
    agentId?: string,
    cancelToken?: CancelToken
  ) => {
    const queryString = toQuery(searchCriteria);
    return this.post<PaginatedResponse<DispositionStatistic>>(
      `agent-statistics/dispositions?${queryString}`,
      {
        [ContextKey.AgentId]: agentId,
        disposition: disposition,
        [ContextKey.StartDateTime]: volumeFilter.startDate,
        [ContextKey.EndDateTime]: volumeFilter.endDate,
      },
      undefined,
      cancelToken
    );
  };

  getAgentsStatisticsChargebacks = (
    pageInfo: SearchCriteria,
    filters?: ActiveFilter[],
    groupId?: string,
    agentId?: string
  ) => {
    const queryString = toQuery(pageInfo, filters);
    return this.get<AgentChargebacks | null>(
      `agent-statistics/chargebacks?${queryString}&agentId=${agentId || ''}&groupId=${
        groupId || ''
      }`
    );
  };

  getAgentsDeclinesStatistics = (
    pageInfo: SearchCriteria,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    const queryString = toQuery(pageInfo, filters);
    return this.get<AgentDeclines | null>(
      `agent-statistics/declines?${queryString}`,
      {},
      cancelToken
    );
  };
}
