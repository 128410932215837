import PersonIcon from '@mui/icons-material/Person';
import {Box, InputBase} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, {useState} from 'react';

type Props<T> = {
  selected?: T;
  setSelected: (agent?: T) => void;
  icon?: boolean | React.ComponentType<React.PropsWithChildren<{style: React.CSSProperties}>>;
  placeholder: string;
  options: T[];
  autoFocus?: boolean;
  getOptionLabel: (item: T) => string;
  onItemSelect: (item: T | null) => void;
  comparer?: (a: T, b: T) => number;
  onBlur?: () => void;
  width?: number;
  getOptionKey?: (option: T) => string;
};

export function AutoCompleteInputBase<T>({
  selected,
  setSelected,
  icon,
  placeholder,
  options,
  autoFocus,
  getOptionLabel,
  onItemSelect,
  comparer,
  onBlur,
  getOptionKey,
  width = 300,
}: Props<T>) {
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const iconStyle: React.CSSProperties = {color: isFocused ? '#555' : '#A5A8AC'};

  return (
    <Box display="flex" alignItems="center">
      {icon && (
        <Box display="flex" alignItems="center" mr={2}>
          {icon === true ? (
            <PersonIcon style={iconStyle} />
          ) : (
            React.createElement(icon, {style: iconStyle}, null)
          )}
        </Box>
      )}
      <Autocomplete
        id="search-field"
        options={comparer ? options.sort(comparer) : options}
        getOptionLabel={getOptionLabel}
        value={selected || null}
        inputValue={inputValue}
        onChange={(_event: any, newValue: T | null) => {
          setSelected(newValue ?? undefined);
          onItemSelect(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          onBlur?.();
        }}
        blurOnSelect
        clearOnEscape
        openOnFocus
        style={{width}}
        renderInput={params => {
          const {InputLabelProps, InputProps, ...rest} = params;
          return (
            <InputBase
              placeholder={placeholder}
              {...params.InputProps}
              {...rest}
              autoFocus={autoFocus}
            />
          );
        }}
        renderOption={(props, option) => (
          <li {...props} key={getOptionKey ? getOptionKey(option) : getOptionLabel(option)}>
            {getOptionLabel(option)}
          </li>
        )}
      />
    </Box>
  );
}
