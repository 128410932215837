import {AsyncState} from '@ozark/common';
import {
  AgentStatisticsApi,
  ApplicationsApi,
  AttachmentsApi,
  ChargebacksApi,
  DashboardApi,
  DepositsCpyDaysApi,
  ExternalServicesApi,
  FraudAnalysisApi,
  ReportsApi,
  ReservesApi,
  ResidualsApi,
  TransactionsApi,
} from '@ozark/common/api';
import axios from 'axios';
import {format} from 'date-fns';
import firebase from 'firebase/compat/app';
import {useEffect, useRef} from 'react';
import {ActivationsApi} from '../api/ActivationsApi';
import {FinicityApi} from '../api/FinicityApi';
import {LeadsApi} from '../api/LeadsApi';
import {TicketsApi} from '../api/TicketsApi';
import {getAppApiBaseUrl} from '../helpers';

const instance = axios.create({
  baseURL: getAppApiBaseUrl(),
});

const cancelTokenInstance = axios.CancelToken;

export const useApiClient = (authUser: AsyncState<firebase.User | null>) => {
  const client = useRef(instance);
  const cancelToken = useRef(cancelTokenInstance);

  useEffect(() => {
    if (authUser.promised || !authUser.data) return;
    const interceptor = client.current.interceptors.request.use(
      async config => {
        const token = await authUser.data?.getIdToken();
        config.headers.common['Authorization'] = `Bearer ${token}`;
        config.headers.common['Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        config.headers.common['TimezoneOffset'] = format(new Date(), 'z');
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );

    return () => {
      client.current.interceptors.request.eject(interceptor);
    };
  }, [authUser.promised, authUser.data]);

  return {
    client: client.current,
    apiClient: {
      dashboard: new DashboardApi(client.current, cancelToken.current),
      activations: new ActivationsApi(client.current, cancelToken.current),
      applications: new ApplicationsApi(client.current, cancelToken.current),
      attachments: new AttachmentsApi(client.current, cancelToken.current),
      agentStatistics: new AgentStatisticsApi(client.current, cancelToken.current),
      transactions: new TransactionsApi(client.current, cancelToken.current),
      chargebacks: new ChargebacksApi(client.current, cancelToken.current),
      reports: new ReportsApi(client.current, cancelToken.current),
      depositsCpyDays: new DepositsCpyDaysApi(client.current, cancelToken.current),
      externalServices: new ExternalServicesApi(client.current, cancelToken.current),
      finicity: new FinicityApi(client.current, cancelToken.current),
      residuals: new ResidualsApi(client.current, cancelToken.current),
      leads: new LeadsApi(client.current, cancelToken.current),
      reserves: new ReservesApi(client.current, cancelToken.current),
      fraud: new FraudAnalysisApi(client.current, cancelToken.current),
      tickets: new TicketsApi(client.current, cancelToken.current),
    },
  };
};
