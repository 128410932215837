import {Agent, AgentView, Collections, Firebase, selectAgentView, UniversalSnapshot} from '../..';

// There is a web version of getAgents. In case of update, check sdk implementation.
type GetAgentsOptions = {
  activeOnly: boolean;
};
export const getAgents = async ({activeOnly}: GetAgentsOptions): Promise<AgentView[]> => {
  const query = Firebase.firestore.collection(Collections.agents);

  if (activeOnly) {
    query.where('isActive', '==', true);
  }
  const snapshot = await query.get();

  if (snapshot.empty) {
    return [];
  }

  return snapshot.docs.map(doc => selectAgentView(doc as UniversalSnapshot<Agent>));
};

// There is a web version of getAgentById. In case of update, check sdk implementation.
export const getAgentById = async (agentId: string): Promise<AgentView> => {
  const doc = await Firebase.firestore.collection(Collections.agents).doc(agentId).get();

  if (!doc.exists) {
    throw new Error('Agent not found');
  }

  return selectAgentView(doc as UniversalSnapshot<Agent>);
};
