/* eslint-disable react-hooks/exhaustive-deps */
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import SortIcon from '@mui/icons-material/Sort';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ApplicationLifeStatusFilter,
  ApplicationStatusGroups,
  AsyncState,
  Dispositions,
  getColor,
  getDispositions,
  SearchCriteria,
  useGroupsAndAgents,
  UserRoles,
} from '@ozark/common';
import {CancelOperationMessage} from '@ozark/common/api/Constants';
import {
  ActiveFilter,
  ApplicationFilters,
  ApplicationListData,
  Card,
  forceActiveFilter,
  getDispositionFilterValues,
  InfiniteEntities,
  InputSearch,
  PanelItem,
} from '@ozark/common/components';
import {ApplicationResponse} from '@ozark/functions/src/functions/express/private/types';
import {CancelTokenSource} from 'axios';
import {orderBy as orderByLodash} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {useHistory} from 'react-router';
import {useActiveProfiles} from '../../hooks/useActiveProfiles';
import {useStore} from '../../store/helpers';
import {ApplicationsExportButton} from '../ApplicationsExportButton';

const DEFAULT_VALUE = '0';

const useStyles = makeStyles(() =>
  createStyles({
    selectInput: {
      backgroundColor: 'transparent !important',
    },
  })
);

const defaultFilters = {
  showAll: true,
  vipOnly: false,
  lifeStatus: ApplicationLifeStatusFilter.activeOnly,
  statusGroup: ApplicationStatusGroups.all,
  disposition: null,
};

const defaultPaging: SearchCriteria = {
  order: 'desc',
  orderBy: 'createdAt',
  limit: 50, // page size
  offset: 1, // page
};

const addShowAllFilter = (target: ActiveFilter[], showAll: boolean, uid: string): void => {
  if (showAll) return;
  target.push(forceActiveFilter(ApplicationFilters, 'uid', '__eq', uid));
};

const addGroupIdFilter = (target: ActiveFilter[], groupId: string | null): void => {
  if (groupId == null || groupId === DEFAULT_VALUE) return;
  target.push(forceActiveFilter(ApplicationFilters, 'groupId', '__eq', groupId));
};

const addAgentFilter = (target: ActiveFilter[], agentId: string | null): void => {
  if (agentId == null || agentId === DEFAULT_VALUE) return;
  target.push(forceActiveFilter(ApplicationFilters, 'agentId', '__eq', agentId));
};

const addIsVipFilter = (target: ActiveFilter[], isVip: boolean): void => {
  if (!isVip) return;
  target.push(forceActiveFilter(ApplicationFilters, 'isVip', '__eq', 'true'));
};

const addUnderwriterFilter = (target: ActiveFilter[], underwriterId: string | null): void => {
  if (underwriterId == null || underwriterId === DEFAULT_VALUE) return;
  target.push(forceActiveFilter(ApplicationFilters, 'uid', '__eq', underwriterId));
};

const addDispositionsFilter = (
  target: ActiveFilter[],
  disposition: Dispositions | null,
  dispositionGroup: ApplicationStatusGroups
): void => {
  const dispositionFilterValues = getDispositionFilterValues(disposition, dispositionGroup);

  if (!dispositionFilterValues) {
    return;
  }

  target.push(
    forceActiveFilter(ApplicationFilters, 'dispositions', '__eq', dispositionFilterValues.join(','))
  );
};

const addLifeStatusFilter = (target: ActiveFilter[], status: ApplicationLifeStatusFilter): void => {
  let filter: ActiveFilter | null = null;
  switch (status) {
    case ApplicationLifeStatusFilter.activeOnly:
      filter = forceActiveFilter(ApplicationFilters, 'deleted', '__eq', 'false');
      break;
    case ApplicationLifeStatusFilter.deletedOnly:
      filter = forceActiveFilter(ApplicationFilters, 'deleted', '__eq', 'true');
      break;
  }
  if (filter != null) {
    target.push(filter);
  }
};

const buildFilters = (input: {
  uid: string;
  showAll: boolean;
  isVip: boolean;
  lifeStatus: ApplicationLifeStatusFilter;
  statusGroup: ApplicationStatusGroups;
  disposition: Dispositions | null;
  groupId: string | null;
  agentId: string | null;
  underwriterId: string | null;
}): ActiveFilter[] => {
  const result: ActiveFilter[] = [];

  addShowAllFilter(result, input.showAll, input.uid);
  addIsVipFilter(result, input.isVip);
  addDispositionsFilter(result, input.disposition, input.statusGroup);
  addLifeStatusFilter(result, input.lifeStatus);
  addGroupIdFilter(result, input.groupId);
  addAgentFilter(result, input.agentId);
  addUnderwriterFilter(result, input.underwriterId);
  return result;
};

const Applications = () => {
  const classes = useStyles();
  const history = useHistory();
  const {authProfile, isUserAdmin, apiClient, profiles, groupsMap} = useStore();
  const activeProfiles = useActiveProfiles(profiles);
  const isAdmin = isUserAdmin();
  const initialized = useRef(false);

  const {groups, onGroupChange, selectedGroup, filteredAgents, onAgentChange, selectedAgent} =
    useGroupsAndAgents();
  const [showAll, setShowAll] = useState(defaultFilters.showAll);
  const [vipOnly, setVipOnly] = useState(defaultFilters.vipOnly);
  const [lifeStatus, setLifeStatus] = useState(defaultFilters.lifeStatus);
  const [statusGroup, setStatusGroup] = useState(defaultFilters.statusGroup);
  const [disposition, setDisposition] = useState<Dispositions | null>(defaultFilters.disposition);
  const [underwriterId, setUnderwriterId] = useState(DEFAULT_VALUE);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchCriteria, setSearchCriteria] = useState(defaultPaging);

  const [isLoadingFirstPage, setIsLoadingFirstPage] = useState(false);
  const [_, setCancelTokenSource] = useState<CancelTokenSource | undefined>();
  const [applications, setApplications] = useState<AsyncState<ApplicationListData>>({
    promised: true,
  });

  useEffect(() => {
    // This is to prevent the initial load from firing twice in the strict mode.
    if (!initialized.current) {
      initialized.current = true;
      loadData(searchCriteria);
    }
  }, []);

  useEffect(() => {
    const newPaging = {...searchCriteria, offset: 1};

    if (disposition === Dispositions.boarded) {
      newPaging.orderBy = 'boardedAt';
    } else {
      newPaging.orderBy = 'createdAt';
    }

    setSearchCriteria(newPaging);
    loadData(newPaging);
  }, [
    showAll,
    vipOnly,
    lifeStatus,
    statusGroup,
    disposition,
    selectedGroup,
    selectedAgent,
    searchQuery,
    underwriterId,
  ]);

  const loadData = (paging: SearchCriteria, onLoaded?: () => void) => {
    if (paging.offset === 1) {
      setIsLoadingFirstPage(true);
    }

    const cancelSource = apiClient?.applications.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });

    const filters = buildFilters({
      uid: authProfile.data!.id,
      showAll,
      lifeStatus,
      statusGroup,
      isVip: vipOnly,
      disposition,
      underwriterId,
      groupId: selectedGroup,
      agentId: selectedAgent,
    });
    apiClient.applications
      .getApplications(paging, searchQuery, filters, cancelSource?.token)
      .then(result => {
        if (result == null) {
          setApplications({promised: false, data: {items: [], hasNextPage: false}});
          return;
        }

        const listData: ApplicationListData = {
          items:
            result.offset === 0
              ? result.data
              : [...(applications.data?.items ?? []), ...result.data],
          hasNextPage: result.data.length === result.limit || false,
          totalCount: result.totalCount,
        };

        setApplications({promised: false, data: listData});
        setIsLoadingFirstPage(false);
      })
      .catch((err: any) => {
        if (err?.message === CancelOperationMessage) {
          return;
        }
        console.error(err);
        setApplications({promised: false, error: err || {}});
        setIsLoadingFirstPage(false);
      })
      .finally(() => {
        onLoaded?.();
      });
  };

  const toggleOrder = () => {
    const newValue = {...searchCriteria, order: searchCriteria.order === 'asc' ? 'desc' : 'asc'};
    setSearchCriteria(newValue);
    loadData(newValue);
  };

  const toggleShowAll = () => {
    setUnderwriterId(DEFAULT_VALUE);
    setShowAll(!showAll);
  };

  const toggleShowVipOnly = (event: any) => {
    setUnderwriterId(event.target.value);
    setVipOnly(!vipOnly);
  };

  const handleUnderwriterChange = (event: any) => {
    setShowAll(defaultFilters.showAll);
    setUnderwriterId(event.target.value);
  };

  const handleStatusGroupChange = (event: any) => {
    setStatusGroup(event.target.value);
    setDisposition(null);
  };

  const handleDispositionChange = (event: any) =>
    setDisposition(event.target.value === 'null' ? null : event.target.value);

  const handleLifeStatusChange = (event: any) => setLifeStatus(event.target.value);

  const handleGroupChange = (event: any) => onGroupChange(event.target.value);

  const handleAgentChange = (event: any) => onAgentChange(event.target.value);

  const loadNextPage = (onLoaded?: () => void): void => {
    const newValue = {...searchCriteria, offset: searchCriteria.offset + 1};
    setSearchCriteria(newValue);
    loadData(newValue, onLoaded);
  };

  const fetchAll = async () => {
    let allApplications: ApplicationResponse[] = [];
    const cancelSource = apiClient?.applications.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });

    const filters = buildFilters({
      uid: authProfile.data!.id,
      showAll,
      lifeStatus,
      isVip: vipOnly,
      statusGroup,
      disposition,
      underwriterId,
      groupId: selectedGroup,
      agentId: selectedAgent,
    });

    try {
      const result = await apiClient.applications.getApplications(
        {...searchCriteria, limit: 0},
        searchQuery,
        filters,
        cancelSource?.token
      );

      if (result == null) {
        return [];
      }

      allApplications = result.data;
    } catch (err: any) {
      console.error(err);
    }

    return allApplications;
  };

  return (
    <Box sx={{height: '100%', minHeight: '100%', display: 'flex', flexDirection: 'column'}}>
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(158px, min(20%, 220px)))',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <PanelItem>
          <TextField
            value={underwriterId ?? DEFAULT_VALUE}
            onChange={handleUnderwriterChange}
            label="Filter by Underwriter"
            sx={{width: '100%'}}
            variant="standard"
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
          >
            <MenuItem value={DEFAULT_VALUE}>All</MenuItem>
            {(activeProfiles?.byRole[UserRoles.underwriter] ?? []).map(user => (
              <MenuItem key={user.id} value={user.id}>
                {user.displayName}
              </MenuItem>
            ))}
          </TextField>
        </PanelItem>
        <PanelItem dividerPosition="start">
          <TextField
            value={selectedGroup ?? DEFAULT_VALUE}
            onChange={handleGroupChange}
            label="Filter by Group"
            sx={{width: '100%'}}
            variant="standard"
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
          >
            <MenuItem value="0">All</MenuItem>
            {(groups.data ?? []).sortAndMap(
              group => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ),
              group => group.name
            )}
          </TextField>
        </PanelItem>
        <PanelItem dividerPosition="start">
          <TextField
            value={selectedAgent ?? DEFAULT_VALUE}
            onChange={handleAgentChange}
            label="Filter by Agent"
            sx={{width: '100%'}}
            variant="standard"
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
          >
            <MenuItem value="0">All</MenuItem>
            {orderByLodash(filteredAgents ?? [], ['firstName', 'lastName']).map(agent => (
              <MenuItem key={agent.id} value={agent.id}>
                {agent.firstName} {agent.lastName}
              </MenuItem>
            ))}
          </TextField>
        </PanelItem>
        <PanelItem dividerPosition="start">
          <TextField
            value={statusGroup}
            onChange={handleStatusGroupChange}
            label="Filter by Status"
            sx={{width: '100%'}}
            variant="standard"
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
          >
            {Object.values(ApplicationStatusGroups)
              .filter(e => getDispositions(e, Object.values(Dispositions)).length > 0)
              .sortAndMap(e => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
          </TextField>
        </PanelItem>
        <PanelItem dividerPosition="start">
          <TextField
            value={String(disposition)}
            onChange={handleDispositionChange}
            label="Filter by Disposition"
            sx={{width: '100%'}}
            variant="standard"
            InputProps={{
              classes: {
                input: classes.selectInput,
              },
              disableUnderline: true,
            }}
            select
          >
            <MenuItem value={String(null)}>
              {statusGroup === ApplicationStatusGroups.all ? 'All' : `All ${statusGroup}`}
            </MenuItem>
            {getDispositions(statusGroup, Object.values(Dispositions)).sortAndMap(e => (
              <MenuItem key={e} value={e}>
                <span style={{color: getColor(e)}}>&bull;&bull;&bull;&nbsp;&nbsp;</span>
                {e}
              </MenuItem>
            ))}
          </TextField>
        </PanelItem>
        {isAdmin && (
          <PanelItem dividerPosition="start">
            <TextField
              value={lifeStatus}
              onChange={handleLifeStatusChange}
              variant="standard"
              label="Filter by State"
              sx={{width: '100%'}}
              InputProps={{
                classes: {
                  input: classes.selectInput,
                },
                disableUnderline: true,
              }}
              select
            >
              {Object.values(ApplicationLifeStatusFilter).sortAndMap(e => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </TextField>
          </PanelItem>
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(158px, min(20%, 220px)))',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <PanelItem sx={{gridColumn: 'span 2'}}>
          <InputSearch
            fieldName="searchApplications"
            placeholder="Search..."
            onSearchChange={setSearchQuery}
            fullWidth
          />
        </PanelItem>
        <PanelItem dividerPosition="start">
          <Button
            size="small"
            onClick={toggleShowAll}
            startIcon={!showAll ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />}
          >
            Assigned to me
          </Button>
        </PanelItem>
        <PanelItem dividerPosition="start">
          <Button
            size="small"
            onClick={toggleShowVipOnly}
            startIcon={vipOnly ? <CheckBoxIcon color="primary" /> : <CheckBoxOutlineBlankIcon />}
          >
            VIP/Priority
          </Button>
        </PanelItem>
        <PanelItem dividerPosition="start">
          <Button
            size="small"
            onClick={toggleOrder}
            endIcon={
              searchCriteria.order === 'desc' ? (
                <SortIcon style={{transform: 'rotateX(180deg)'}} />
              ) : (
                <SortIcon />
              )
            }
          >
            {searchCriteria.order === 'desc' ? 'Newest at Top' : 'Oldest at Top'}
          </Button>
        </PanelItem>

        {isAdmin && (
          <ApplicationsExportButton
            filename="all-applications"
            getRows={fetchAll}
            rows={applications.data?.items}
            sx={{ml: 2, alignSelf: 'center', justifySelf: 'center'}}
          />
        )}
      </Box>
      <Divider sx={{margin: '5px 0'}} />

      {isLoadingFirstPage || applications.promised ? (
        <Box sx={{position: 'relative', top: '40%', m: '0 auto'}}>
          <CircularProgress color="primary" />
        </Box>
      ) : !applications.data?.items?.length ? (
        <Typography sx={{top: '40%', position: 'relative', textAlign: 'center'}}>
          No Applications
        </Typography>
      ) : (
        <InfiniteEntities
          showTotal
          data={applications.data}
          itemSize={270}
          loadNextPage={loadNextPage}
          onRender={(application: ApplicationResponse) => (
            <Card
              application={application}
              customizeTransferable
              onClick={() =>
                history.push(`applications/${application.id}`, {referrer: 'Applications'})
              }
              profiles={profiles}
              group={groupsMap[application.group.id]}
            />
          )}
        />
      )}
    </Box>
  );
};

export default Applications;
