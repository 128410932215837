import {DocumentBase, DocumentCreatedBy, TicketAssignee, TicketAssociation} from '.';
import {Department} from '../shared';
import {FieldValue, UniversalTimestamp} from './compat';

export enum TicketHistoryRecordType {
  Assignee = 'Assignee',
  Association = 'Association',
  Category = 'Category',
  Create = 'Create',
  Description = 'Description',
  Status = 'Status',
  Subject = 'Subject',
  Label = 'Label',
  ViewableBy = 'ViewableBy',
  LinkedTicket = 'LinkedTicket',
  Audit = 'Audit',
  BillingAudit = 'BillingAudit',
}

// Common type contains base props, extended bellow
export type TicketHistoryRecordDataCommon = {
  userRole: string;
  department?: Department;
  userName: string;
  title: string;
  titlePortal?: string;
  createdAt: UniversalTimestamp | FieldValue;
  hidden?: boolean;
  recordType: TicketHistoryRecordType;
  assignee?: TicketAssignee;
  association?: TicketAssociation;
};

// assignee payload is required for recordType = Assignee
export type TicketHistoryRecordDataAssignee = TicketHistoryRecordDataCommon & {
  recordType: TicketHistoryRecordType.Assignee;
  assignee: TicketAssignee;
};

// association payload is required for recordType = Assignee
export type TicketHistoryRecordDataAssociation = TicketHistoryRecordDataCommon & {
  recordType: TicketHistoryRecordType.Association;
  association: TicketAssociation;
};

// no extra payload for other record types
export type TicketHistoryRecordDataOther = TicketHistoryRecordDataCommon & {
  recordType: Exclude<TicketHistoryRecordType, 'Assignee' | 'Association'>;
};

// final union type with all record types
export type TicketHistoryRecordData =
  | TicketHistoryRecordDataOther
  | TicketHistoryRecordDataAssignee
  | TicketHistoryRecordDataAssociation;

export type TicketHistoryRecord = DocumentBase & DocumentCreatedBy & TicketHistoryRecordData;
