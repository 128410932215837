import {subDays} from 'date-fns';
import {collection, getCountFromServer, query, where} from 'firebase/firestore';
import {useEffect, useState} from 'react';
import {Collections, Firebase, selectTicketView, TicketStatus, ViewableByType} from '../../../..';
import {useUserInfo} from '../../../../hooks';
import {stateEmpty, statePromised} from '../../constants/constants';
import {FilterKey, TFolder} from '../../types';
import {useTicketsViewableBy} from './useTicketsViewableBy';

export function useTicketsMyAssignee(
  statusSet: TicketStatus[],
  erpFilters?: TFolder['erpFilters']
) {
  const {uid, isMerchant, isAgent, isErpUser} = useUserInfo();
  const {filterOutInternalTickets} = useTicketsViewableBy();
  const [ticketsMyAssignee, setTicketsMyAssignee] = useState(statePromised);

  useEffect(() => {
    if (statusSet.includes(TicketStatus.Draft)) {
      setTicketsMyAssignee(stateEmpty);
      return;
    }
    setTicketsMyAssignee(statePromised);

    let query = Firebase.firestore
      .collection(Collections.tickets)
      .where('status', 'in', statusSet)
      .where('author.id', '!=', uid); // creator tickets are returned by author query

    if (isMerchant) {
      query = query.where('assignee.merchant.id', '==', uid);
    } else if (isAgent) {
      query = query.where('associatedAgents', 'array-contains', uid);
    } else {
      query = query.where('assignee.erpUser.id', '==', uid);

      if (isErpUser && erpFilters?.whereQuery) {
        query = erpFilters.whereQuery(query);
      }
    }

    return query.onSnapshot(
      async snapshot => {
        if (snapshot.size === 0) {
          setTicketsMyAssignee(stateEmpty);
          return;
        }
        const tickets = filterOutInternalTickets(snapshot.docs.map(selectTicketView));
        setTicketsMyAssignee({promised: false, data: tickets});
      },
      err => {
        console.error(err);
        setTicketsMyAssignee({promised: false, error: err});
      }
    );
  }, [statusSet, isAgent, isMerchant, filterOutInternalTickets, isErpUser, erpFilters, uid]);

  return {
    ticketsMyAssignee,
  };
}

type UseTicketsMyAssigneeCountProps = {
  statusSet: TFolder['statusSet'];
  filterKey?: TFolder['filterKey'];
  erpFilters?: TFolder['erpFilters'];
};
export function useTicketsMyAssigneeCount({
  statusSet,
  filterKey,
  erpFilters,
}: UseTicketsMyAssigneeCountProps): {count: number; refetch: () => void} {
  const {uid, isMerchant, isErpUser, isAgent} = useUserInfo();
  const [ticketsMyAssigneeCount, setTicketsMyAssigneeCount] = useState<number>(0);
  const [counter, setCounter] = useState(0);
  useEffect(() => {
    async function fetchCount() {
      const ref = collection(Firebase.firestore, Collections.tickets);
      let q = query(ref, where('status', 'in', statusSet), where('author.id', '!=', uid));

      if (isMerchant) {
        q = query(q, where('assignee.merchant.id', '==', uid));
      } else if (isAgent) {
        q = query(q, where('associatedAgents', 'array-contains', uid));
      } else {
        q = query(q, where('assignee.erpUser.id', '==', uid));
      }

      if (!isErpUser) {
        if (isAgent) {
          q = query(q, where('viewableBy', 'in', [ViewableByType.agentOrErp, ViewableByType.all]));
        } else {
          q = query(q, where('viewableBy', '==', ViewableByType.all));
        }
      }

      if (isErpUser && erpFilters?.whereCount) {
        q = query(q, erpFilters.whereCount);
      }

      if (filterKey) {
        switch (filterKey) {
          case FilterKey.openedMoreThan24hAgo:
            q = query(q, where('openedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.auditStartedMoreThan24hAgo:
            q = query(q, where('auditStartedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.billingAuditStartedMoreThan24hAgo:
            q = query(q, where('billingAuditStartedAt', '<=', subDays(new Date(), 1)));
            break;
        }
      }

      const countQuery = await getCountFromServer(q);

      setTicketsMyAssigneeCount(countQuery.data().count);
    }

    fetchCount();
  }, [uid, isMerchant, isErpUser, isAgent, counter, statusSet, filterKey]);

  return {count: ticketsMyAssigneeCount, refetch: () => setCounter(counter => counter + 1)};
}
