import {Autocomplete, Box, TextField} from '@mui/material';
import {omit} from 'lodash';
import {useEffect, useState} from 'react';
import {
  AsyncState,
  Collections,
  Firebase,
  selectTicketTypeCategoryView,
  TicketTypeAndCategory,
  TicketTypeCategory,
  TicketTypeCategoryView,
  UniversalSnapshot,
} from '../../../..';
import {useUserInfo} from '../../../../hooks/useUserInfo';
import {FIELD_NAME_CATEGORY} from '../../constants/constants';
import {InputCategoryError} from '../../types';

type Props = {
  draft?: TicketTypeAndCategory;
  setDraft: (draft: TicketTypeAndCategory) => void;
  errors: Record<InputCategoryError, string>;
  setErrors: (errors: Record<InputCategoryError, string>) => void;
};

interface AcOption {
  id: string;
  name: string;
  info?: string;
}

const statePromised: AsyncState<TicketTypeCategoryView[]> = {
  promised: true,
  data: [],
};

export const CategoryEditorCategory = ({draft, setDraft, errors, setErrors}: Props) => {
  const {userRoleName} = useUserInfo();

  const [ticketTypeCategories, setTicketTypeCategories] = useState(statePromised);
  const [categories, setCategories] = useState<AcOption[]>([]);

  const getCategories = (id: string) => {
    let query = Firebase.firestore
      .collection(Collections.ticketTypes)
      .doc(id)
      .collection(Collections.ticketTypeCategories)
      .orderBy('isDeleted', 'asc')
      .orderBy('name', 'asc');

    query.onSnapshot(
      async snapshot => {
        if (snapshot.size === 0) {
          setTicketTypeCategories({promised: false, data: []});
          return;
        }
        const ticketTypes = snapshot.docs.map(doc =>
          selectTicketTypeCategoryView(doc as UniversalSnapshot<TicketTypeCategory>)
        );
        setTicketTypeCategories({promised: false, data: ticketTypes});
      },
      err => {
        setTicketTypeCategories({promised: false, data: []});
      }
    );
  };

  useEffect(() => {
    if (!draft?.type?.id) {
      return;
    }
    getCategories(draft?.type?.id);
  }, [draft?.type?.id]);

  useEffect(() => {
    if (!ticketTypeCategories.data) {
      return;
    }

    const filteredCategories =
      ticketTypeCategories.data
        .filter(
          cat =>
            cat.visibleToRoles.includes(userRoleName) &&
            (!cat.isDeleted || draft?.category?.id === cat.id)
        )
        .map(cat => ({
          id: cat.id,
          name: cat.name,
          info: cat.info,
          isAuditable: cat.isAuditable,
        }))
        .sort() ?? [];

    setCategories(filteredCategories);
    // NOTE: draft?.category?.id to remove deleted categories from the list if changed from deleted to available
  }, [ticketTypeCategories, draft?.category?.id]);

  return (
    <Box mt={2}>
      <Autocomplete
        options={categories}
        value={draft?.category ?? {id: '', name: ''}}
        onChange={(_: React.SyntheticEvent<Element, Event>, newValue: any) => {
          setDraft({...draft, category: newValue});
          if (newValue) {
            setErrors(omit(errors, FIELD_NAME_CATEGORY) as Record<InputCategoryError, string>);
          }
        }}
        disableClearable
        getOptionLabel={(option: AcOption) => option?.name || ''}
        renderOption={(props, option: AcOption) => <li {...props}>{option.name}</li>}
        renderInput={params => (
          <TextField
            {...params}
            label="Category"
            required
            variant="standard"
            error={Boolean(errors[FIELD_NAME_CATEGORY])}
            helperText={errors[FIELD_NAME_CATEGORY]}
          />
        )}
      />
    </Box>
  );
};
