import type {QueryConstraint} from '@firebase/firestore';
import firebase from 'firebase/compat';
import type {ComponentType, PropsWithChildren} from 'react';
import {
  AssigneeType,
  AssociationType,
  FieldValue,
  TicketLastCommentInfo,
  TicketStatus,
  TicketTypeAndCategory,
  UniversalTimestamp,
  UserRoles,
} from '../../..';

export type TicketAttachment = {
  id: string;
  fileName: string;
  filename: string;
  oldFilename: string;
  fileCloudPath: string;
  cloudPath: string;
  createdAt: UniversalTimestamp | FieldValue;
  uid: string;
};

export type InputAssigneeError = keyof typeof AssigneeType | 'type' | 'group';
export type InputAssociationError = keyof typeof AssociationType | 'type';
export type InputCategoryError = keyof TicketTypeAndCategory | 'type';

export type Option = {
  label: string;
  value?: string;
};

export enum TicketsFolderId {
  All = 'all',
  Draft = 'draft',
  Replied = 'replied',
  Open = 'open',
  InProgress = 'doing',
  InProgress24 = 'doing24',
  Audit = 'audit',
  Audit24 = 'audit24',
  BillingAudit = 'billingAudit',
  BillingAudit24 = 'billingAudit24',
  Closed = 'closed',
  Unresolved = 'unresolved',
  Deleted = 'deleted',
}

export type TFolder = {
  id: TicketsFolderId;
  statusSet: TicketStatus[];
  erpFilters?: {
    whereCount?: QueryConstraint;
    whereQuery?: (
      q: firebase.firestore.Query<firebase.firestore.DocumentData>
    ) => firebase.firestore.Query<firebase.firestore.DocumentData>;
    orderBy?: (
      q: firebase.firestore.Query<firebase.firestore.DocumentData>
    ) => firebase.firestore.Query<firebase.firestore.DocumentData>;
  };
  folderName: string;
  folderTipTitle: string;
  folderTipText: string;
  icon: ComponentType<PropsWithChildren<unknown>> | null;
  italic?: boolean;
  badgeColor?: string;
  indent?: boolean;
  hasDivider?: boolean;
  hasCounter: boolean;
  filterKey?: FilterKey;
  getIsVisible?: (userRole?: UserRoles) => boolean;
};

export type EditorType = 'subject' | 'description' | undefined;

export type StatusFolderProps = {
  folder: TFolder;
  isSelected: boolean;
};

export enum FilterKey {
  openedMoreThan24hAgo = '>24',
  auditStartedMoreThan24hAgo = 'audit>24',
  billingAuditStartedMoreThan24hAgo = 'billingAudit>24',
}

export interface LastCommentsInfo {
  lastPublicCommentInfo: TicketLastCommentInfo | FieldValue;
  lastInternalCommentInfo: TicketLastCommentInfo | FieldValue;
}
