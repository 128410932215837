import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {GroupInfo, Line, SummaryBox} from '@ozark/common/components';
import {
  ApplicationType,
  ApplicationView,
  formatCurrency,
  formatPercentWithExtraDecimals as formatPercent,
  ProcessingTypes,
} from '../../..';
import {getAdjustedDiscountRateBilledToMerchant} from '../../../helpers';

type Props = {
  application: ApplicationView;
};

export const SummaryBoxPricing = ({application}: Props) => {
  return <SummaryBox groups={getPricingInfo(application)} />;
};

const getPricingInfo = (application: ApplicationView): GroupInfo[] => {
  // resusable vals
  const qualifiedRate = formatPercent(
    application.rateSet?.qualifiedDiscountRateVisaMastercardDiscover
  );
  const midQualifiedRate = formatPercent(
    application.rateSet?.midQualifiedDiscountRateVisaMastercardDiscover
  );
  const nonQualifiedRate = formatPercent(
    application.rateSet?.nonQualifiedDiscountRateVisaMastercardDiscover
  );
  const qualifiedTransactionFee = formatCurrency(
    application.rateSet?.qualifiedTransactionFeeVisaMastercardDiscover
  );
  const nonQualifiedTransactionFee = formatCurrency(
    application.rateSet?.nonQualifiedTransactionFeeVisaMastercardDiscover
  );
  const amexQualifiedRate = formatPercent(application.rateSet?.qualifiedDiscountRateAmex);
  const amexMidQualifiedRate = formatPercent(application.rateSet?.midQualifiedDiscountRateAmex);
  const amexNonQualifiedRate = formatPercent(application.rateSet?.nonQualifiedDiscountRateAmex);
  const amexQualifiedTransactionFee = formatCurrency(
    application.rateSet?.qualifiedTransactionFeeAmex
  );
  const amexMidQualifiedTransactionFee = formatCurrency(
    application.rateSet?.midQualifiedTransactionFeeAmex
  );
  const amexNoneQualifiedTransactionFee = formatCurrency(
    application.rateSet?.nonQualifiedTransactionFeeAmex
  );

  const debitPinBasedRate = formatPercent(application.rateSet?.qualifiedDebitPINRate);
  const debitSignatureDebitNonPinRate = formatPercent(
    application.rateSet?.qualifiedDebitNonPINRate
  );
  const debitQualifiedTranFee = formatCurrency(application.rateSet?.qualifiedDebitPINFee);
  const debitNonQualifiedTranFee = formatCurrency(application.rateSet?.qualifiedDebitNonPINFee);
  const applicationType = application.rateSet?.applicationType;
  const processingType = application.processingType;

  let visaMasterDiscoverCol1: Line = {};
  let visaMasterDiscoverCol2: Line = {};
  let amexCol1: Line = {};
  let amexCol2: Line = {};
  let debitCol1: Line = {};
  let debitCol2: Line = {};

  const addDebitRatesFull = () => {
    if (processingType === ProcessingTypes.cardPresent) {
      debitCol1['Debit Pin Based Rate'] = debitPinBasedRate;
      if (applicationType === ApplicationType.dualPricingFlatRate) {
        debitCol1['Adjusted Debit Pin Based Rate Billed to Merchant'] =
          getAdjustedDiscountRateBilledToMerchant(application.rateSet?.qualifiedDebitPINRate);
      }
      debitCol2['Qualified Transaction Fee'] = debitQualifiedTranFee;
    }
    debitCol1['Signature Debit Non Pin Based Rate'] = debitSignatureDebitNonPinRate;
    if (applicationType === ApplicationType.dualPricingFlatRate) {
      debitCol1['Adjusted Signature Debit Non Pin Based Rate Billed to Merchant'] =
        getAdjustedDiscountRateBilledToMerchant(application.rateSet?.qualifiedDebitNonPINRate);
    }
    debitCol2['Non-Qualified Transaction Fee'] = debitNonQualifiedTranFee;
  };

  switch (applicationType) {
    case ApplicationType.err:
      visaMasterDiscoverCol1['Qualified Rate'] = qualifiedRate;
      visaMasterDiscoverCol2['Qualified Transaction Fee'] = qualifiedTransactionFee;
      visaMasterDiscoverCol1['Non-Qualified Surcharge'] = nonQualifiedRate;
      amexCol1['Qualified Rate'] = amexQualifiedRate;
      amexCol2['Qualified Transaction Fee'] = amexQualifiedTransactionFee;
      amexCol1['Non-Qualified Surcharge'] = amexNonQualifiedRate;
      addDebitRatesFull();
      break;

    case ApplicationType.cnpVCp:
      visaMasterDiscoverCol1['Qualified Rate'] = qualifiedRate;
      visaMasterDiscoverCol1['Non-Qualified Rate'] = nonQualifiedRate;
      visaMasterDiscoverCol2['Qualified Transaction Fee'] = qualifiedTransactionFee;
      visaMasterDiscoverCol2['Non-Qualified Transaction Fee'] = nonQualifiedTransactionFee;
      amexCol1['Qualified Rate'] = amexQualifiedRate;
      amexCol1['Non-Qualified Rate'] = amexNonQualifiedRate;
      amexCol2['Qualified Transaction Fee'] = amexQualifiedTransactionFee;
      amexCol2['Non-Qualified Transaction Fee'] = amexNoneQualifiedTransactionFee;
      if (processingType === ProcessingTypes.cardPresent) {
        debitCol1['Qualified Transaction Fee'] = debitQualifiedTranFee;
      }
      debitCol2['Non-Qualified Transaction Fee'] = debitNonQualifiedTranFee;
      break;

    case ApplicationType.flatRate:
    case ApplicationType.interchange:
      visaMasterDiscoverCol1['Qualified Rate'] = qualifiedRate;
      visaMasterDiscoverCol2['Qualified Transaction Fee'] = qualifiedTransactionFee;
      amexCol1['Qualified Rate'] = amexQualifiedRate;
      amexCol2['Qualified Transaction Fee'] = amexQualifiedTransactionFee;
      addDebitRatesFull();
      break;

    default:
      visaMasterDiscoverCol1['Qualified Rate'] = qualifiedRate;
      if (
        applicationType === ApplicationType.cashDiscount ||
        applicationType === ApplicationType.surcharge ||
        applicationType === ApplicationType.dualPricingPassThroughFees ||
        applicationType === ApplicationType.dualPricingFlatRate
      ) {
        visaMasterDiscoverCol1['Adjusted Discount Rate Billed to Merchant'] =
          getAdjustedDiscountRateBilledToMerchant(
            application.rateSet?.qualifiedDiscountRateVisaMastercardDiscover
          );
      }

      if (applicationType !== ApplicationType.dualPricingFlatRate) {
        visaMasterDiscoverCol1['Mid-Qualified Rate'] = midQualifiedRate;
        visaMasterDiscoverCol1['Non-Qualified Rate'] = nonQualifiedRate;
      }

      visaMasterDiscoverCol2['Qualified Transaction Fee'] = qualifiedTransactionFee;

      if (applicationType !== ApplicationType.dualPricingFlatRate) {
        visaMasterDiscoverCol2['Mid-Qualified Transaction Fee'] = formatCurrency(
          application.rateSet?.midQualifiedTransactionFeeVisaMastercardDiscover
        );
        visaMasterDiscoverCol2['Non-Qualified Transaction Fee'] = nonQualifiedTransactionFee;
      }

      amexCol1['Qualified Rate'] = amexQualifiedRate;

      if (applicationType === ApplicationType.dualPricingFlatRate) {
        amexCol1['Adjusted Discount Rate Billed to Merchant'] =
          getAdjustedDiscountRateBilledToMerchant(application.rateSet?.qualifiedDiscountRateAmex);
      }

      if (applicationType !== ApplicationType.dualPricingFlatRate) {
        amexCol1['Mid-Qualified Rate'] = amexMidQualifiedRate;
        amexCol1['Non-Qualified Rate'] = amexNonQualifiedRate;
      }

      amexCol2['Qualified Transaction Fee'] = amexQualifiedTransactionFee;

      if (applicationType !== ApplicationType.dualPricingFlatRate) {
        amexCol2['Mid-Qualified Transaction Fee'] = amexMidQualifiedTransactionFee;
        amexCol2['Non-Qualified Transaction Fee'] = amexNoneQualifiedTransactionFee;
      }

      addDebitRatesFull();
  }

  const sections: GroupInfo[] = [
    {
      icon: <AttachMoneyIcon />,
      title: 'Pricing Info',
      columns: [
        {
          'Pricing Type': application.rateSet?.applicationType,
        },
        {
          'Discount Method': application.rateSet?.discountDuration,
        },
      ],
    },
    {
      title: 'Visa/Mastercard/Discover Credit and Debit',
      columns: [visaMasterDiscoverCol1, visaMasterDiscoverCol2],
    },
    {
      title: 'American Express',
      columns: [amexCol1, amexCol2],
    },
    {
      title: 'Debit',
      columns: [debitCol1, debitCol2],
    },
    {
      title: 'Other Volume & Fees',
      columns: [
        {'Discount Rate': formatPercent(application.rateSet?.otherVolumeRate)},
        {'Transaction Fee': formatCurrency(application.rateSet?.otherItemFee)},
      ],
    },
  ];

  if (application.ebtFNS) {
    sections.push({
      title: 'EBT',
      columns: [
        {'Discount Rate': formatPercent(application.rateSet?.ebtDiscountRate)},
        {'Transaction Fee': formatCurrency(application.rateSet?.ebtTransactionFee)},
      ],
    });
  }

  return sections;
};
