import {Column} from '@ozark/common/api/Column';
import {SearchCriteria} from '@ozark/common/api/SearchCriteria';
import {Table} from '@ozark/common/components';
import {
  MccInfo,
  PaginatedMccInfo,
} from '@ozark/functions/src/functions/express/private/types/Reports';
import {formatAsPercent, formatterCurrency, formatterNumber} from '../../../util/format';

type Props = {
  onRetrieveData: (searchCriteria: SearchCriteria) => void;
  data: PaginatedMccInfo;
};

export const MccsBySalesTable = ({data, onRetrieveData}: Props) => {
  return (
    <Table
      data={data}
      columns={mccsBySalesColumnsConfig}
      onRetrieveData={onRetrieveData}
      paginate
      flat
    />
  );
};

export const mccsBySalesColumnsConfig: Column<MccInfo>[] = [
  {
    id: 'mcc',
    numeric: false,
    sortable: false,
    label: 'MCC',
    export: true,
  },
  {
    id: 'businessType',
    numeric: false,
    sortable: false,
    label: 'MCC Description',
    export: true,
  },
  {id: 'midsCount', numeric: true, sortable: true, label: 'MIDs', export: true},
  {
    id: 'sales',
    numeric: true,
    sortable: true,
    label: 'Sales',
    selector: (row: MccInfo) => formatterNumber.format(row.sales),
    export: true,
  },
  {
    id: 'salesAmount',
    numeric: true,
    sortable: true,
    label: 'Sales Amount',
    selector: (row: MccInfo) => formatterCurrency.format(row.salesAmount),
    export: true,
  },
  {
    id: 'averageTicket',
    numeric: true,
    sortable: false,
    label: 'Average Ticket',
    selector: (row: MccInfo) => formatterCurrency.format(row.averageTicket),
    export: true,
  },
  {
    id: 'salesAmountPercentage',
    numeric: true,
    sortable: false,
    label: '% of Total',
    selector: (row: MccInfo) => formatAsPercent(row.salesAmountPercentage ?? 0),
    export: true,
  },
  {
    id: 'salesPercentage',
    numeric: true,
    sortable: false,
    label: '% of Total Count',
    selector: (row: MccInfo) => formatAsPercent(row.salesPercentage ?? 0),
    export: true,
  },
];
