import {
  Collections,
  Firebase,
  Profile,
  ProfileView,
  selectProfileView,
  UniversalSnapshot,
} from '../..';

type GetErpUsersOptions = {
  activeOnly: boolean;
};
export const getERPUsers = async ({activeOnly}: GetErpUsersOptions): Promise<ProfileView[]> => {
  const query = Firebase.firestore.collection(Collections.profiles);

  if (activeOnly) {
    query.where('isActive', '==', true);
  }

  const snapshot = await query.get();

  if (snapshot.size) {
    return snapshot.docs.map(doc => selectProfileView(doc as UniversalSnapshot<Profile>));
  }

  return [];
};

export const getERPUserById = async (erpUserId: string): Promise<ProfileView> => {
  const doc = await Firebase.firestore.collection(Collections.profiles).doc(erpUserId).get();

  if (!doc.exists) {
    throw new Error('Profile not found');
  }

  return selectProfileView(doc as UniversalSnapshot<Profile>);
};
