import {Theme} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {SxProps} from '@mui/system';
import {
  currentFormatter as currentFormatterBase,
  formatMinorUnits as formatMinorUnitsBase,
} from '@ozark/functions/src/functions/common/reports/formatMinorUnits';
import {format} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import {ActiveFilter, FilterOption} from '../../components/Filters';

export const forceActiveFilter = (
  filterOptions: FilterOption[],
  id: string,
  operatorId: string,
  value: string | string[] | [Date, Date]
) => {
  const option = filterOptions.find(e => e.id === id);
  const operator = option?.operators.find(e => e.id === operatorId);
  if (!(option && operator)) {
    console.error('An error occurred while applying a filter with id =', id);
    throw Error(`invalid filter with name = ${id}`);
  }
  const activeFilter: ActiveFilter = {
    option,
    operator,
    value,
  };
  return activeFilter;
};

export const numberFormatter = new Intl.NumberFormat('en-US', {
  maximumFractionDigits: 3,
});

export const percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 3,
});

export const currentFormatter = currentFormatterBase;

export const formatMinorUnits = (value: number) => formatMinorUnitsBase(value);

export const formatDate = (initialDate: string | null, dateFormat: string = 'P'): string => {
  if (!initialDate) {
    return '-';
  }

  const date = new Date(initialDate);
  const localTime = utcToZonedTime(date, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(localTime, dateFormat);
};

export const formatDateTime = (date: string | null) => formatDate(date, 'P p');

export const ReportingPageStyles = {
  chip: {
    marginLeft: theme => theme.spacing(0.5),
    marginRight: theme => theme.spacing(0.5),
  } as SxProps<Theme>,
};

export const useReportingPageStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  rootMaxWidth: {
    height: '100%',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    // Nav width (224px) & padding (2 * 16px) + Main padding (2 * 24px)
    maxWidth: 'calc(100vw - (256px + 48px))',
    [theme.breakpoints.down('md')]: {
      // Main padding (2 * 24px)
      maxWidth: 'calc(100vw - 48px)',
    },
  },
  grow: {
    flex: 1,
  },
  space: {
    flexGrow: 1,
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    height: 400,
    alignItems: 'center',
  },
  menuPaper: {
    minWidth: 200,
  },
  divider: {
    margin: theme.spacing(2),
  },
  chip: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  midButton: {
    textTransform: 'none !important' as any,
  },
  midListItem: {
    padding: theme.spacing(0, 2),
  },
  noContent: {
    top: '40%',
    position: 'relative',
    textAlign: 'center',
  },
  selectInput: {
    backgroundColor: 'transparent !important',
  },
  inlineSelect: {
    display: 'block',
  },
}));
