export const PORTAL = '/portal';
export const PORTAL_BASICS = '/portal/basics';
export const PORTAL_BUSINESS = '/portal/business';
export const PORTAL_SIGNER = '/portal/signer';
export const PORTAL_DEPOSITS = '/portal/deposits';
export const PORTAL_PROGRAM = '/portal/program';

export const APPLICATION = '/';
export const START = '/start';

export const APPLICATION_ROOT = '/applications/:applicationId';
export const APPLICATION_DETAILS = `${APPLICATION_ROOT}/:step?`;
export const BASICS = `${APPLICATION_ROOT}/basics`;
export const BUSINESS = `${APPLICATION_ROOT}/business`;
export const SIGNER = `${APPLICATION_ROOT}/signer`;
export const DEPOSITS = `${APPLICATION_ROOT}/deposits`;
export const PROGRAM = `${APPLICATION_ROOT}/program`;
export const REVIEW = `${APPLICATION_ROOT}/review`;
export const INSTANT_ID_Q_AND_A = `${APPLICATION_ROOT}/instant-id-qanda`;
export const CONFIRMATION = '/confirmation';
export const SUBMITTED = '/submitted';
export const TRANSFERRED = '/transferred';

export const PROGRAM_REVIEW = '/program-review';

export const RESUME = '/resume';
export const TRANSFER = '/transfer';

export const PLAID_CALLBACK = '/plaid-callback';
export const NOT_AVAILABLE = '/not-available';
export const EXPIRED_LINK = '/expired-link';
