import {Box, Typography} from '@mui/material';
import {Route, Switch} from 'react-router-dom';
import {Title} from '../..';
import {TabsRouter} from '../../Tabs/TabsRouter';
import {AgentStatisticsCb} from '../AgentStatisticsCb';
import {AgentStatisticsDeclines} from '../AgentStatisticsDeclines';
import {AgentStatisticsDispositionStatisticsPage} from '../AgentStatisticsDispositionStatistics';
import {AgentStatisticsSales} from '../AgentStatisticsSales';
import {AgentStatisticsVolume} from '../AgentStatisticsVolume';

const AGENT_STATISTICS = '/agent-statistics';
const AGENT_STATISTICS_SALES = AGENT_STATISTICS;
const AGENT_STATISTICS_VOLUME = `${AGENT_STATISTICS}/volume`;
const AGENT_STATISTICS_DISPOSITION_STATISTICS = `${AGENT_STATISTICS}/disposition-statistics`;
const AGENT_STATISTICS_CHARGEBACKS = `${AGENT_STATISTICS}/cb`;
const AGENT_STATISTICS_DECLINES = `${AGENT_STATISTICS}/declines`;

export const AGENT_STATISTICS_ROUTES = [
  AGENT_STATISTICS_SALES,
  AGENT_STATISTICS_VOLUME,
  AGENT_STATISTICS_DISPOSITION_STATISTICS,
  AGENT_STATISTICS_CHARGEBACKS,
  AGENT_STATISTICS_DECLINES,
];

const tabs = [
  {
    label: 'Sales',
    href: AGENT_STATISTICS_SALES,
    component: AgentStatisticsSales,
    exact: true,
  },
  {
    label: 'Estimated VS Actual Volume',
    href: AGENT_STATISTICS_VOLUME,
    component: AgentStatisticsVolume,
  },
  {
    label: 'Disposition Statistics',
    href: AGENT_STATISTICS_DISPOSITION_STATISTICS,
    component: AgentStatisticsDispositionStatisticsPage,
  },
  {label: 'Chargebacks/Refund', href: AGENT_STATISTICS_CHARGEBACKS, component: AgentStatisticsCb},
  {label: 'Declines', href: AGENT_STATISTICS_DECLINES, component: AgentStatisticsDeclines},
];

export const AgentStatistics = () => {
  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Title breadcrumbs={[<Typography>Agent Statistics</Typography>]}>
        <Box flex={1} />
        <Box mb="-18px">
          <TabsRouter tabs={tabs} />
        </Box>
      </Title>
      <Switch>
        {tabs.map(tab => (
          <Route key={tab.href} path={tab.href} component={tab.component} exact />
        ))}
      </Switch>
    </Box>
  );
};
