import {subDays} from 'date-fns';
import {collection, getCountFromServer, query, where} from 'firebase/firestore';
import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {Collections, Firebase, TicketStatus, useUserInfo, ViewableByType} from '../../..';
import {stateEmpty, statePromised} from '../constants/constants';
import {FilterKey, TFolder} from '../types';
import {whenAllTickets} from '../utils';
import {filterTicketsByKey} from '../utils/filters';
import {useTicketsMyGroup} from './ticketsSlices/useTicketsMyGroup';
import {useTicketsFiltersContainer} from './useTicketsFiltersContainer';
import {useTicketsQuery} from './useTicketsQuery';

export function useTicketsAllAgentAdmin(statusSet: TicketStatus[], filterKey?: FilterKey) {
  const {sortBy, sortDir} = useTicketsQuery();
  const {agentIdFilter} = useTicketsFiltersContainer();
  const [tickets, setTickets] = useState(statePromised);

  const {ticketsMyGroup} = useTicketsMyGroup(statusSet);

  useEffect(() => {
    !tickets.promised && setTickets(statePromised);
    whenAllTickets([ticketsMyGroup], mergedTickets => {
      if (!mergedTickets.length) {
        setTickets(stateEmpty);
        return;
      }
      const ticketsSorted = orderBy(mergedTickets, [sortBy], [sortDir]);
      let ticketsFiltered = agentIdFilter
        ? ticketsSorted.filter(t => t.associatedAgents?.includes(agentIdFilter))
        : ticketsSorted;
      ticketsFiltered = filterTicketsByKey(ticketsFiltered, filterKey);
      setTickets({promised: false, data: ticketsFiltered});
    });
  }, [ticketsMyGroup, sortBy, sortDir, agentIdFilter]);

  return {
    tickets,
    counter: tickets.data?.length ?? 0,
  };
}

type UseTicketsAllAgentAdminCountProps = {
  statusSet: TFolder['statusSet'];
  filterKey?: TFolder['filterKey'];
  erpFilters?: TFolder['erpFilters'];
};

export function useTicketsAllAgentAdminCount({
  statusSet,
  filterKey,
  erpFilters,
}: UseTicketsAllAgentAdminCountProps): {count: number; refetch: () => void} {
  const {agentIdFilter} = useTicketsFiltersContainer();
  const [ticketsCount, setTicketsCount] = useState(0);
  const {userGroupId, isErpUser, isAgent} = useUserInfo();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    async function fetchCount() {
      const ref = collection(Firebase.firestore, Collections.tickets);
      let q = query(ref, where('status', 'in', statusSet));

      if (userGroupId) {
        q = query(q, where('associatedGroups', 'array-contains', userGroupId));
      }

      if (agentIdFilter) {
        q = query(q, where('associatedAgents', 'array-contains', agentIdFilter));
      }

      if (filterKey) {
        switch (filterKey) {
          case FilterKey.openedMoreThan24hAgo:
            q = query(q, where('openedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.auditStartedMoreThan24hAgo:
            q = query(q, where('auditStartedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.billingAuditStartedMoreThan24hAgo:
            q = query(q, where('billingAuditStartedAt', '<=', subDays(new Date(), 1)));
            break;
        }
      }

      if (!isErpUser) {
        if (isAgent) {
          q = query(q, where('viewableBy', 'in', [ViewableByType.agentOrErp, ViewableByType.all]));
        } else {
          q = query(q, where('viewableBy', '==', ViewableByType.all));
        }
      }

      if (isErpUser && erpFilters?.whereCount) {
        q = query(q, erpFilters.whereCount);
      }

      const countQuery = await getCountFromServer(q);
      setTicketsCount(countQuery.data().count);
    }
    fetchCount();
  }, [statusSet, isErpUser, isAgent, filterKey, userGroupId, agentIdFilter, counter]);

  return {count: ticketsCount, refetch: () => setCounter(counter => counter + 1)};
}
