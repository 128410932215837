export const stripHtml = (function () {
  // this prevents any overhead from creating the object each time
  const element = document.createElement('div');

  function decodeHTMLEntities(str?: string | null) {
    if (!str || typeof str !== 'string') return str;
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
    element.innerHTML = str;
    str = element.textContent;
    element.textContent = '';
    return str;
  }

  return decodeHTMLEntities;
})();

export const prepareTextPreview = (text?: string) => {
  return stripHtml(text)?.slice(0, 300);
};

export const camelCaseToTitleCase = (camelCase: string) => {
  return camelCase.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
};
