import {ApiBase} from '@ozark/common';
import {TicketsReportResponse} from '@ozark/functions/src/functions/express/private/types/TicketsReport';
import {CancelToken} from 'axios';

export class TicketsApi extends ApiBase {
  getReport = (year: number, month: number, limit?: number, cancelToken?: CancelToken) => {
    return this.post<TicketsReportResponse[] | null>(
      `tickets/reports`,
      {
        limit,
        year,
        month,
      },
      undefined,
      cancelToken
    );
  };
}
