export const downloadCsv = (csv: any, fileName: string) => {
  let blob = new Blob([csv], {type: 'text/csv;charset=utf8;'});

  let link = document.createElement('a');
  link.href = URL.createObjectURL(blob);

  link.setAttribute('visibility', 'hidden');
  link.download = fileName + '.csv';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
