import type {ScheduleAFeeRiskModelWithoutLabels} from '../documents';
import type {ScheduleAFeeRiskModel} from '../documents/ScheduleA';
import {RiskLevel} from './RiskLevel';

export type ScheduleAFeeDefinition = {
  [key in keyof ScheduleAFeeRiskModel]: {
    label: string;
    type: '%' | '$';
    defaultValues: {
      [_ in RiskLevel]: string;
    };
    labelFor?: keyof ScheduleAFeeRiskModel;
    labelTo?: keyof ScheduleAFeeRiskModel;
  };
};

export type EquipmentFees = 'dejavooEquipmentMonthlyFee' | 'valorEquipmentMonthlyFee';
export type EquipmentAdditionalTerminalsFees =
  | 'dejavooEquipmentMonthlyFeeAdditionalTerminals'
  | 'valorEquipmentMonthlyFeeAdditionalTerminals';

export type ScheduleAEquipmentFees = keyof Pick<
  ScheduleAFeeRiskModelWithoutLabels,
  EquipmentFees | EquipmentAdditionalTerminalsFees
>;

export type ScheduleAEquipmentFeeDefinition = {
  [key in ScheduleAEquipmentFees]?: {
    label: string;
    type: '%' | '$';
    defaultValues: {
      [_ in RiskLevel]: string;
    };
  };
};

export const ScheduleAFeeDefinitions: ScheduleAFeeDefinition = {
  monthlyProcessingVolumePercent: {
    label: 'Monthly Processing Volume',
    type: '%',
    defaultValues: {
      [RiskLevel.lowRisk]: '100',
      [RiskLevel.mediumRisk]: '85',
      [RiskLevel.highRisk]: '60',
    },
    labelTo: 'monthlyProcessingVolumePercentLabel',
  },
  monthlyProcessingVolumePercentLabel: {
    label: 'Monthly Processing Volume (Displayed)',
    type: '%',
    defaultValues: {
      [RiskLevel.lowRisk]: '',
      [RiskLevel.mediumRisk]: '',
      [RiskLevel.highRisk]: '',
    },
    labelFor: 'monthlyProcessingVolumePercent',
  },
  visaMCDiscBINSponsorPercent: {
    label: 'Visa/MC/Disc BIN Sponsor',
    type: '%',
    defaultValues: {
      [RiskLevel.lowRisk]: '0',
      [RiskLevel.mediumRisk]: '0',
      [RiskLevel.highRisk]: '.25',
    },
  },
  amexBINSponsorPercent: {
    label: 'Amex BIN Sponsor',
    type: '%',
    defaultValues: {
      [RiskLevel.lowRisk]: '0',
      [RiskLevel.mediumRisk]: '0',
      [RiskLevel.highRisk]: '.25',
    },
  },
  pinDebitSponsorPercent: {
    label: 'PIN Debit Sponsor',
    type: '%',
    defaultValues: {
      [RiskLevel.lowRisk]: '0',
      [RiskLevel.mediumRisk]: '0',
      [RiskLevel.highRisk]: '0',
    },
  },
  visaMCDiscBINTransaction: {
    label: 'Visa/MC/Disc BIN Transaction',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0',
      [RiskLevel.mediumRisk]: '0',
      [RiskLevel.highRisk]: '.05',
    },
  },
  amexBINTransaction: {
    label: 'Amex BIN Transaction',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0',
      [RiskLevel.mediumRisk]: '0',
      [RiskLevel.highRisk]: '.05',
    },
  },
  pinDebitEBT: {
    label: 'Pin Debit/EBT',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '.03',
      [RiskLevel.mediumRisk]: '.03',
      [RiskLevel.highRisk]: '.10',
    },
  },
  batch: {
    label: 'Batch',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0',
      [RiskLevel.mediumRisk]: '0',
      [RiskLevel.highRisk]: '.05',
    },
  },
  avsVoice: {
    label: 'AVS (Voice)',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '2.00',
      [RiskLevel.mediumRisk]: '2.00',
      [RiskLevel.highRisk]: '2.00',
    },
  },
  avsElectronic: {
    label: 'AVS (Electronic)',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '.05',
      [RiskLevel.mediumRisk]: '.05',
      [RiskLevel.highRisk]: '.05',
    },
  },
  wirelessTransaction: {
    label: 'Wireless Transaction',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '.05',
      [RiskLevel.mediumRisk]: '.05',
      [RiskLevel.highRisk]: '.10',
    },
  },
  otherTransaction: {
    label: 'Other Transaction',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0',
      [RiskLevel.mediumRisk]: '0',
      [RiskLevel.highRisk]: '0',
    },
  },
  chargeBack: {
    label: 'Chargeback',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '10.00',
      [RiskLevel.mediumRisk]: '12.50',
      [RiskLevel.highRisk]: '15.00',
    },
  },
  retrieval: {
    label: 'Retrieval',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '5.00',
      [RiskLevel.mediumRisk]: '6.00',
      [RiskLevel.highRisk]: '7.50',
    },
  },
  debitAccess: {
    label: 'Debit Access',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0.00',
      [RiskLevel.mediumRisk]: '0.00',
      [RiskLevel.highRisk]: '5.00',
    },
  },
  accountOnFile: {
    label: 'Account on File',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '5.00',
      [RiskLevel.mediumRisk]: '5.00',
      [RiskLevel.highRisk]: '15.00',
    },
  },
  monthlyMonitoring: {
    label: 'Monthly Monitoring',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0.00',
      [RiskLevel.mediumRisk]: '0.00',
      [RiskLevel.highRisk]: '50.00',
    },
  },
  irsRegulatoryFee: {
    label: 'IRS Regulatory Fee',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '1.00',
      [RiskLevel.mediumRisk]: '1.00',
      [RiskLevel.highRisk]: '5.00',
    },
  },
  wirelessTerminalMonthly: {
    label: 'Wireless Terminal Monthly',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '15.00',
      [RiskLevel.mediumRisk]: '15.00',
      [RiskLevel.highRisk]: '15.00',
    },
  },
  otherGateway: {
    label: 'Other Gateway',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0.00',
      [RiskLevel.mediumRisk]: '0.00',
      [RiskLevel.highRisk]: '25.00',
    },
  },
  merchantOnlineAccess: {
    label: 'Merchant Online Access',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0.00',
      [RiskLevel.mediumRisk]: '0.00',
      [RiskLevel.highRisk]: '5.00',
    },
  },
  annualPCI: {
    label: 'Annual PCI',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '36.00',
      [RiskLevel.mediumRisk]: '36.00',
      [RiskLevel.highRisk]: '36.00',
    },
  },
  annualPCIWithBreachInsurance: {
    label: 'Annual PCI with Breach Insurance',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '79.00',
      [RiskLevel.mediumRisk]: '79.00',
      [RiskLevel.highRisk]: '79.00',
    },
  },
  monthlyMinimum: {
    label: 'Monthly Minimum',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0.00',
      [RiskLevel.mediumRisk]: '0.00',
      [RiskLevel.highRisk]: '50.00',
    },
  },
  annualFee: {
    label: 'Annual Fee',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0.00',
      [RiskLevel.mediumRisk]: '0.00',
      [RiskLevel.highRisk]: '50.00',
    },
  },
  gatewaySetup: {
    label: 'Gateway Setup',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0.00',
      [RiskLevel.mediumRisk]: '0.00',
      [RiskLevel.highRisk]: '0.00',
    },
  },
  merchantClub: {
    label: 'Merchant Club',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '150.00',
      [RiskLevel.mediumRisk]: '150.00',
      [RiskLevel.highRisk]: '150.00',
    },
  },
  gatewayMonthly: {
    label: 'Gateway Monthly',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '10.00',
      [RiskLevel.mediumRisk]: '10.00',
      [RiskLevel.highRisk]: '10.00',
    },
  },
  gatewayTransaction: {
    label: 'Gateway Transaction',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '0.10',
      [RiskLevel.mediumRisk]: '0.10',
      [RiskLevel.highRisk]: '0.10',
    },
  },
  valorEquipmentMonthlyFee: {
    label: 'Valor Equipment Monthly Fee',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '4.95',
      [RiskLevel.mediumRisk]: '4.95',
      [RiskLevel.highRisk]: '4.95',
    },
  },
  dejavooEquipmentMonthlyFee: {
    label: 'Dejavoo Equipment Monthly Fee',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '4.95',
      [RiskLevel.mediumRisk]: '4.95',
      [RiskLevel.highRisk]: '4.95',
    },
  },
};

export const ScheduleAEquipmentFeeDefinitions: ScheduleAEquipmentFeeDefinition = {
  valorEquipmentMonthlyFee: {
    label: 'Valor Equipment Monthly Fee',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '4.95',
      [RiskLevel.mediumRisk]: '4.95',
      [RiskLevel.highRisk]: '4.95',
    },
  },
  valorEquipmentMonthlyFeeAdditionalTerminals: {
    label: 'Valor Equipment Monthly Fee Additional Terminals',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '2.00',
      [RiskLevel.mediumRisk]: '2.00',
      [RiskLevel.highRisk]: '2.00',
    },
  },
  dejavooEquipmentMonthlyFee: {
    label: 'Dejavoo Equipment Monthly Fee',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '4.95',
      [RiskLevel.mediumRisk]: '4.95',
      [RiskLevel.highRisk]: '4.95',
    },
  },
  dejavooEquipmentMonthlyFeeAdditionalTerminals: {
    label: 'Dejavoo Equipment Monthly Fee Additional Terminals',
    type: '$',
    defaultValues: {
      [RiskLevel.lowRisk]: '2.00',
      [RiskLevel.mediumRisk]: '2.00',
      [RiskLevel.highRisk]: '2.00',
    },
  },
};

export const FeeCategories: {[_: string]: Partial<ScheduleAFeeDefinition>} = {
  'Revenue Share Calculation': {
    monthlyProcessingVolumePercent: ScheduleAFeeDefinitions.monthlyProcessingVolumePercent,
    monthlyProcessingVolumePercentLabel:
      ScheduleAFeeDefinitions.monthlyProcessingVolumePercentLabel,
  },
  'Portfolio Fees': {
    visaMCDiscBINSponsorPercent: ScheduleAFeeDefinitions.visaMCDiscBINSponsorPercent,
    amexBINSponsorPercent: ScheduleAFeeDefinitions.amexBINSponsorPercent,
    pinDebitSponsorPercent: ScheduleAFeeDefinitions.pinDebitSponsorPercent,
  },
  'Transaction Fees': {
    visaMCDiscBINTransaction: ScheduleAFeeDefinitions.visaMCDiscBINTransaction,
    amexBINTransaction: ScheduleAFeeDefinitions.amexBINTransaction,
    pinDebitEBT: ScheduleAFeeDefinitions.pinDebitEBT,
    batch: ScheduleAFeeDefinitions.batch,
    avsVoice: ScheduleAFeeDefinitions.avsVoice,
    avsElectronic: ScheduleAFeeDefinitions.avsElectronic,
    wirelessTransaction: ScheduleAFeeDefinitions.wirelessTransaction,
    otherTransaction: ScheduleAFeeDefinitions.otherTransaction,
    chargeBack: ScheduleAFeeDefinitions.chargeBack,
    retrieval: ScheduleAFeeDefinitions.retrieval,
  },
  'Monthly/Annual Occurrence Fees': {
    debitAccess: ScheduleAFeeDefinitions.debitAccess,
    accountOnFile: ScheduleAFeeDefinitions.accountOnFile,
    monthlyMonitoring: ScheduleAFeeDefinitions.monthlyMonitoring,
    irsRegulatoryFee: ScheduleAFeeDefinitions.irsRegulatoryFee,
    wirelessTerminalMonthly: ScheduleAFeeDefinitions.wirelessTerminalMonthly,
    otherGateway: ScheduleAFeeDefinitions.otherGateway,
    merchantOnlineAccess: ScheduleAFeeDefinitions.merchantOnlineAccess,
    annualPCI: ScheduleAFeeDefinitions.annualPCI,
    annualPCIWithBreachInsurance: ScheduleAFeeDefinitions.annualPCIWithBreachInsurance,
    monthlyMinimum: ScheduleAFeeDefinitions.monthlyMinimum,
    annualFee: ScheduleAFeeDefinitions.annualFee,
  },
  'Optional Services': {
    gatewaySetup: ScheduleAFeeDefinitions.gatewaySetup,
    merchantClub: ScheduleAFeeDefinitions.merchantClub,
    gatewayMonthly: ScheduleAFeeDefinitions.gatewayMonthly,
    gatewayTransaction: ScheduleAFeeDefinitions.gatewayTransaction,
  },
};

export const EquipmentFeeCategory: {[_: string]: Partial<ScheduleAEquipmentFeeDefinition>} = {
  'Optional Equipment Monthly Fees': {
    valorEquipmentMonthlyFee: ScheduleAEquipmentFeeDefinitions.valorEquipmentMonthlyFee,
    valorEquipmentMonthlyFeeAdditionalTerminals:
      ScheduleAEquipmentFeeDefinitions.valorEquipmentMonthlyFeeAdditionalTerminals,
    dejavooEquipmentMonthlyFee: ScheduleAEquipmentFeeDefinitions.dejavooEquipmentMonthlyFee,
    dejavooEquipmentMonthlyFeeAdditionalTerminals:
      ScheduleAEquipmentFeeDefinitions.dejavooEquipmentMonthlyFeeAdditionalTerminals,
  },
};
