import {yupResolver} from '@hookform/resolvers/yup';
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Upload as UploadIcon,
  Warning as WarningIcon,
} from '@mui/icons-material';
import {LoadingButton} from '@mui/lab';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  IconButton,
  Stack,
  TextField as MuiTextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {TmpAttachment} from '@ozark/functions/src/functions/express/private/types/Attachments';
import {useMemo, useState} from 'react';
import {FileUploader} from 'react-drag-drop-files';
import {
  Control,
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from 'react-hook-form';
import * as yup from 'yup';
import {useNotification} from '../../hooks';
import {FileStatus, FileToUpload, useAttachmentsUpload} from '../../hooks/useAttachmentsUpload';
import {getErrorMessage} from '../../util';
import {acceptedExtensions} from './constants';

const useStyles = makeStyles(() =>
  createStyles({
    fileUploader: {
      maxWidth: '100%!important',
    },
  })
);

interface Props {
  onClose: () => void;
  initialFolderName: string | null;
  documentTypeOptions?: string[];
  folderNamesOptions: string[];
  acceptedExtensionsOptions?: string[];
  onSubmit: (tmpAttachments: TmpAttachment[]) => Promise<void>;
  handleFileUpload?: (file: File) => Promise<void>;
  hideFolder?: boolean;
  authToken?: string;
}

type FormValues = {
  defaultFolderName: string | null;
  filesToUpload: FileToUpload[];
};

interface UploadedFileProps {
  fileToUpload: FileToUpload;
  control: Control<FormValues, FormValues>;
  index: number;
  removeItem: (index: number) => void;
  documentTypeOptions?: string[];
  disabled: boolean;
  folderNamesOptions: string[];
  errors: any;
  hideFolder?: boolean;
}

interface DeleteFileProps {
  disabled: boolean;
  removeItem: () => void;
}

interface DocumentOptionsProps {
  disabled: boolean;
  index: number;
  documentTypeOptions: string[];
  errors: any;
  control: Control<FormValues, FormValues>;
}

const fileToUploadSchema = yup.object({
  uploadDocument: yup.string().required('Type is required').nullable(),
});

export const insertFormSchema = yup.object({
  filesToUpload: yup.array().of(fileToUploadSchema),
});

interface MuiTextFieldFileNameProps {
  fileName: string;
}

const DeleteFile = ({disabled, removeItem}: DeleteFileProps) => {
  return (
    <IconButton
      disabled={disabled}
      onClick={() => removeItem()}
      sx={{
        height: '24px',
        marginTop: 'auto!important',
        marginBottom: 'auto!important',
        padding: 0,
      }}
      color="secondary"
    >
      <DeleteIcon />
    </IconButton>
  );
};

const DocumentOptions = ({
  disabled,
  index,
  documentTypeOptions,
  errors,
  control,
}: DocumentOptionsProps) => {
  return (
    <Controller
      name={`filesToUpload.${index}.uploadDocument`}
      control={control}
      rules={{required: true}}
      render={({field: {onChange, ...props}}) => (
        <Autocomplete
          sx={{width: '100%'}}
          options={documentTypeOptions}
          disableClearable={!!props.value}
          disabled={disabled}
          onChange={(_e, value) => {
            onChange(value);
          }}
          renderInput={params => (
            <MuiTextField
              {...params}
              error={Boolean(getErrorMessage(`filesToUpload.${index}.uploadDocument`, errors))}
              helperText={getErrorMessage(`filesToUpload.${index}.uploadDocument`, errors)?.message}
              label="Type"
              required
              variant="outlined"
              margin="normal"
            />
          )}
          {...props}
        />
      )}
    />
  );
};

interface FileStatusIconProps {
  status?: FileStatus;
  progress?: number;
}
const FileStatusIcon = ({status, progress}: FileStatusIconProps) => {
  switch (status) {
    case FileStatus.processing:
      return <CircularProgress size={24} variant="determinate" value={progress} />;
    case FileStatus.completed:
      return <CheckIcon color="success" />;
    case FileStatus.error:
      return <WarningIcon color="error" />;
    default:
      return <UploadIcon color="disabled" />;
  }
};

const MuiTextFieldFileName = ({fileName}: MuiTextFieldFileNameProps) => {
  return (
    <MuiTextField
      variant="outlined"
      margin="normal"
      value={fileName}
      label="File Name"
      disabled
      fullWidth
    />
  );
};

interface FolderFieldProps {
  index: number;
  control: Control<FormValues, FormValues>;
  disabled: boolean;
  folderNamesOptions: string[];
}

const FolderField = ({index, control, folderNamesOptions, disabled}: FolderFieldProps) => {
  return (
    <Controller
      name={`filesToUpload.${index}.folderName`}
      control={control}
      render={({field: {onChange, ...props}}) => (
        <Autocomplete
          options={folderNamesOptions}
          freeSolo
          disabled={disabled}
          onChange={(_e, value) => {
            onChange(value);
          }}
          onInputChange={(_e, value) => {
            onChange(value);
          }}
          sx={{width: '100%'}}
          renderInput={params => (
            <MuiTextField
              {...params}
              fullWidth
              label="Folder Name"
              variant="outlined"
              margin="normal"
            />
          )}
          {...props}
        />
      )}
    />
  );
};

type UploadedFileGridItemKey = 'filename' | 'document' | 'folder';

const UploadedFile = ({
  fileToUpload,
  control,
  index,
  removeItem,
  documentTypeOptions,
  disabled,
  folderNamesOptions,
  errors,
  hideFolder,
}: UploadedFileProps) => {
  const fileState = useWatch({
    control,
    name: `filesToUpload.${index}.state`,
  });

  const {gridItems, gridSizes} = useMemo(() => {
    const gridSizes = [12];
    const gridItems: Array<UploadedFileGridItemKey> = ['filename'];

    if (documentTypeOptions && documentTypeOptions?.length > 0) {
      gridSizes.push(6);
      gridSizes[0] = 6;

      gridItems.push('document');
    }

    if (!hideFolder) {
      if (gridSizes.length === 1) {
        gridSizes.push(6);
      } else {
        gridSizes.push(3);
        gridSizes[1] = 3;
      }

      gridItems.push('folder');
    }

    return {gridItems, gridSizes};
  }, [hideFolder, documentTypeOptions]);

  const getComponentByGridItemKey = (key: UploadedFileGridItemKey) => {
    const isDisabled = ['completed', 'error'].includes(fileState.status);

    switch (key) {
      case 'filename':
        return <MuiTextFieldFileName fileName={fileToUpload.file.name} />;
      case 'document':
        return (
          <DocumentOptions
            documentTypeOptions={documentTypeOptions ?? []}
            disabled={disabled || isDisabled}
            index={index}
            control={control}
            errors={errors}
          />
        );
      case 'folder':
        return (
          <FolderField
            index={index}
            disabled={disabled || isDisabled}
            folderNamesOptions={folderNamesOptions}
            control={control}
          />
        );
    }
  };

  return (
    <>
      {gridItems.map((key, i) => {
        const isLast = gridItems.length === i + 1;

        return (
          <Grid key={`${index}-${key}`} item xs={gridSizes[i]}>
            {!isLast ? (
              <>{getComponentByGridItemKey(key)}</>
            ) : (
              <Stack direction="row" spacing={1} sx={{width: '100%'}}>
                {getComponentByGridItemKey(key)}
                <DeleteFile disabled={disabled} removeItem={() => removeItem(index)} />
                <Icon
                  sx={{
                    height: '24px',
                    marginTop: 'auto!important',
                    marginBottom: 'auto!important',
                    padding: 0,
                  }}
                  color="secondary"
                >
                  <FileStatusIcon status={fileState?.status} progress={fileState?.progress} />
                </Icon>
              </Stack>
            )}
          </Grid>
        );
      })}
      {fileState.error && (
        <Typography variant="subtitle1" color="red" width="100%" fontSize={12} paddingX={1}>
          {fileState.error}
        </Typography>
      )}
    </>
  );
};

export const InsertAttachmentsDialog = ({
  onClose,
  initialFolderName,
  documentTypeOptions,
  folderNamesOptions,
  acceptedExtensionsOptions,
  onSubmit,
  handleFileUpload,
  hideFolder,
  authToken,
}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isUploading, setIsUploading] = useState(false);

  const showNotification = useNotification();

  const {
    formState: {errors},
    control,
    handleSubmit,
    getValues,
    setValue,
  } = useForm<FormValues>({
    resolver: documentTypeOptions ? yupResolver(insertFormSchema) : undefined,
    defaultValues: {
      filesToUpload: [],
      defaultFolderName: initialFolderName,
    },
  });

  const {fields, append, remove} = useFieldArray({
    name: 'filesToUpload',
    control,
  });

  const {uploadFiles} = useAttachmentsUpload({
    token: authToken,
    onUploadFile: handleFileUpload,
    onProgressChange: (index, newFileState) =>
      setValue(`filesToUpload.${index}.state`, newFileState),
    onError: message => showNotification('error', message),
  });

  const handleChange = (filesToAdd: FileList) => {
    const defaultFolderName = getValues('defaultFolderName');

    const newFilesToUpload = Object.values(filesToAdd).map(file => {
      return {
        file,
        folderName: defaultFolderName,
        uploadDocument: null,
        state: {
          status: 'pending',
          filename: file.name,
          oldFilename: file.name,
        },
      } as FileToUpload;
    });
    append(newFilesToUpload);
  };

  const onSuccess: SubmitHandler<FormValues> = async data => {
    setIsUploading(true);
    try {
      const result = await uploadFiles(data.filesToUpload as Array<FileToUpload>);
      if (result.success) {
        await onSubmit(result.attachments);
        onClose();
      }
    } catch (error) {
      showNotification('error', String(error));
    } finally {
      setIsUploading(false);
    }
  };

  const onSubmitClick = async () => {
    await handleSubmit(onSuccess)();
  };

  const removeItem = (index: number) => {
    remove(index);
  };

  const handleClose = () => {
    if (isUploading) {
      return;
    }

    onClose();
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="create-dialog-title"
      fullScreen={fullScreen}
      maxWidth={'lg'}
    >
      <DialogTitle id="create-dialog-title">Add Attachments</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            [theme.breakpoints.up('md')]: {
              width: 900,
            },
          }}
        >
          <Grid container spacing={1}>
            {!hideFolder && (
              <Grid item xs={12}>
                <Controller
                  name="defaultFolderName"
                  control={control}
                  render={({field: {onChange, ...props}}) => (
                    <Autocomplete
                      options={folderNamesOptions}
                      freeSolo
                      disabled={isUploading}
                      onChange={(_e, value) => {
                        onChange(value);
                      }}
                      onInputChange={(_e, value) => {
                        onChange(value);
                      }}
                      renderInput={params => (
                        <MuiTextField
                          {...params}
                          label="Default Folder Name"
                          variant="outlined"
                          margin="normal"
                        />
                      )}
                      {...props}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <FileUploader
                multiple={true}
                handleChange={handleChange}
                name="file"
                classes={classes.fileUploader}
                types={
                  acceptedExtensionsOptions?.length ? acceptedExtensionsOptions : acceptedExtensions
                }
                disabled={isUploading}
              />
            </Grid>
            {fields.map((field, index) => (
              <UploadedFile
                key={field.id}
                fileToUpload={field as FileToUpload}
                control={control}
                index={index}
                removeItem={removeItem}
                documentTypeOptions={documentTypeOptions}
                disabled={isUploading}
                folderNamesOptions={folderNamesOptions}
                errors={errors}
                hideFolder={hideFolder}
              />
            ))}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} disabled={isUploading}>
          Back
        </Button>
        <LoadingButton
          onClick={onSubmitClick}
          color="primary"
          disabled={fields.length === 0}
          loading={isUploading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
