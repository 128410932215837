import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import {
  EquipmentForScheduleABillingBrands,
  ScheduleAEquipmentModel,
} from '@ozark/functions/src/documents';
import {capitalize} from 'lodash';
import {useState} from 'react';

type Props = {
  brand: EquipmentForScheduleABillingBrands;
  options: ScheduleAEquipmentModel[];
  selected: ScheduleAEquipmentModel[];
  onClose: () => void;
  onSelect: (selected: ScheduleAEquipmentModel[]) => void;
};

export const AdjustEquipmentModelsDialog = ({
  brand,
  options,
  selected: defaultSelected,
  onClose,
  onSelect,
}: Props) => {
  const [selected, setSelected] = useState<ScheduleAEquipmentModel[]>(defaultSelected);

  const isSelected = (id: string) => selected.map(({id}) => id).includes(id);

  const toggleHandler = (option: ScheduleAEquipmentModel, checked: boolean) => {
    const updated = checked ? [...selected, option] : selected.filter(({id}) => id !== option.id);

    setSelected(updated);
  };

  const saveHandler = () => {
    onSelect(selected);
    onClose();
  };

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle sx={{textAlign: 'center'}}>Select {capitalize(brand)} models</DialogTitle>
      <DialogContent style={{paddingTop: '8px'}}>
        <Grid item xs={12}>
          <List>
            {options.map(option => (
              <ListItem key={option.id}>
                <ListItemText primary={option.name} />

                <ListItemSecondaryAction sx={{right: -8}}>
                  <Switch
                    name={option.id}
                    checked={isSelected(option.id)}
                    onChange={(_, checked) => toggleHandler(option, checked)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={saveHandler}>Save</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
