import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import {EquipmentFees, ScheduleAEquipmentFeeDefinitions} from '@ozark/functions/src/constants';
import {SubmitHandler, useForm} from 'react-hook-form';
import {Switch} from '../Switch';

type Props = {
  selected: EquipmentFees[];
  onClose: () => void;
  onSelect: (selected: EquipmentFees[]) => void;
};

type EquipmentFeeForm = {
  [E in EquipmentFees]: boolean;
};

export const AddEquipmentFeeDialog = ({selected, onClose, onSelect}: Props) => {
  const hookForm = useForm<EquipmentFeeForm>({
    defaultValues: {
      dejavooEquipmentMonthlyFee: selected.includes('dejavooEquipmentMonthlyFee'),
      valorEquipmentMonthlyFee: selected.includes('valorEquipmentMonthlyFee'),
    },
  });

  const {control, handleSubmit} = hookForm;

  const onSuccess: SubmitHandler<EquipmentFeeForm> = async (data: EquipmentFeeForm) => {
    const selected = Object.entries(data)
      .filter(([key, value]) => value)
      .map(([key]) => key as EquipmentFees);
    onSelect(selected);
    onClose();
  };

  return (
    <Dialog open={true} fullWidth onClose={onClose}>
      <DialogTitle sx={{textAlign: 'center'}}>Select Equipment Fee</DialogTitle>
      <DialogContent style={{paddingTop: '8px'}}>
        <Grid item xs={12}>
          <List>
            <ListItem>
              <ListItemText
                primary={ScheduleAEquipmentFeeDefinitions['dejavooEquipmentMonthlyFee']?.label}
              />

              <ListItemSecondaryAction sx={{right: -8}}>
                <Switch name="dejavooEquipmentMonthlyFee" control={control} />
              </ListItemSecondaryAction>
            </ListItem>
            <ListItem>
              <ListItemText
                primary={ScheduleAEquipmentFeeDefinitions['valorEquipmentMonthlyFee']?.label}
              />

              <ListItemSecondaryAction sx={{right: -8}}>
                <Switch name="valorEquipmentMonthlyFee" control={control} />
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit(onSuccess)}>Save</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
