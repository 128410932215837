import {yupResolver} from '@hookform/resolvers/yup';
import {DatePicker} from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Firebase, Residual, ResidualStatus, StoragePath} from '@ozark/common';
import {BoxParentHeight, Select, Table} from '@ozark/common/components';
import clsx from 'clsx';
import firebase from 'firebase/compat/app';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
    titleWrapper: {
      marginBottom: theme.spacing(2),
    },
    title: {
      fontSize: '1.2em',
      [theme.breakpoints.down('md')]: {
        fontSize: '1em',
      },
    },
    marginTop: {
      marginTop: theme.spacing(2),
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& > *': {
        marginLeft: theme.spacing(4),
      },
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

export const Schema = yup.object().shape({
  columns: yup.object({
    dba: yup.string().required(),
    group: yup.string().required(),
    merchantStatus: yup.string().required(),
    mid: yup.string().required(),
    bankcardSalesVolume: yup.string().required(),
    bankcardSalesVolumeAmex: yup.string().required(),
    pinDebitSalesVolume: yup.string().required(),
    totalIncome: yup.string().required(),
    processingExpense: yup.string().required(),
    transactionCount: yup.string().required(),
    transactionCountAmex: yup.string().required(),
    pinDebitCount: yup.string().required(),
    wirelessCount: yup.string().required(),
    gatewayCount: yup.string().required(),
    avsElectronicCount: yup.string().required(),
    avsVoiceCount: yup.string().required(),
    batchCount: yup.string().required(),
    chargeBackCount: yup.string().required(),
    retrievalCount: yup.string().required(),
    debitAccessOccurrences: yup.string().required(),
    accountOnFileOccurrences: yup.string().required(),
    monthlyMonitoringOccurrences: yup.string().required(),
    irsRegulatoryFeeOccurrences: yup.string().required(),
    wirelessTerminalMonthlyOccurrences: yup.string().required(),
    otherGatewayOccurrences: yup.string().required(),
    merchantOnlineAccessOccurrences: yup.string().required(),
    annualPCIOccurrences: yup.string().required(),
    annualPCIWithBreachInsuranceOccurrences: yup.string().required(),
    monthlyMinimumOccurrences: yup.string().required(),
    annualFeeOccurrences: yup.string().required(),
    gatewaySetupOccurrences: yup.string().required(),
    merchantClubOccurrences: yup.string().required(),
    gatewayMonthlyOccurrences: yup.string().required(),
    agentCredits: yup.string().required(),
  }),
});

interface Props {
  onSubmit: (data: Residual) => void;
  onCancel: () => void;
  csvObjects: any[];
  csvBuffer: ArrayBuffer;
  defaultValues: any;
}

export const Import = ({onSubmit, onCancel, csvObjects, csvBuffer, defaultValues}: Props) => {
  const classes = useStyles();

  const hookForm = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {...defaultValues},
    shouldUnregister: true,
  });

  const {
    handleSubmit,
    formState: {errors},
    control,
  } = hookForm;

  const [loading, setLoading] = useState(false);
  const [yearMonth, setYearMonth] = useState<Date | null>(new Date());

  const getYearMonthString = () => {
    const month = (yearMonth?.getMonth() || 0) + 1;
    return `${yearMonth?.getFullYear()}${month?.toString().padStart(2, '0')}`;
  };

  const onSuccess = async (data: any) => {
    if (!yearMonth) return;

    setLoading(true);

    const yearMonthString = getYearMonthString();
    const fileUrl = await upload(StoragePath.residuals, `${yearMonthString}.csv`, csvBuffer);
    const date = Firebase.Timestamp.now();

    onSubmit({
      ...data,
      yearMonth: yearMonthString,
      fileUrl: fileUrl,
      createdAt: date,
      updatedAt: date,
      status: ResidualStatus.processing,
    });

    setLoading(false);
  };

  const onError = (_data: any) => {};

  const upload = (path: string, name: string, arrayBuffer: ArrayBuffer) => {
    return new Promise((resolve, reject) => {
      const task = Firebase.storage.ref(`${path}/${name}`).put(arrayBuffer);
      task.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: any) => {
          const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          console.log(`uploading: ${progress}`);
        },
        (err: any) => {
          console.error(err);
          reject(err);
        },
        () => {
          Firebase.storage
            .ref(path)
            .child(name)
            .getDownloadURL()
            .then(url => {
              resolve(url);
            });
        }
      );
    });
  };

  const columnKeyItems =
    csvObjects &&
    Object.keys(csvObjects[0])
      .sort()
      .map(key => (
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      ));

  const generateColumnSelect = (name: string, label: string) => {
    return (
      <Select name={`columns.${name}`} label={label} required errors={errors} control={control}>
        {columnKeyItems}
      </Select>
    );
  };

  return (
    <BoxParentHeight component={Paper} display="flex" flexDirection="column" p={2} overflow="unset">
      <Grid
        container
        spacing={4}
        direction="row"
        alignItems="stretch"
        height="100%"
        overflow="auto"
      >
        <Grid item xs={4} height="100%">
          <Box
            overflow="auto"
            height="100%"
            pb={0}
            mb={0}
            borderBottom="1px solid rgba(224, 224, 224, 1)"
          >
            <Box>
              <div className={classes.titleWrapper}>
                <Typography className={classes.title} variant="caption" gutterBottom>
                  File Month &amp; Year
                </Typography>
                <Divider />
              </div>
              <Grid container spacing={1}>
                <Grid item xs={12} pr={1}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      views={['year', 'month']}
                      label="Year and Month"
                      value={yearMonth}
                      onChange={setYearMonth}
                      renderInput={params => <TextField fullWidth margin="normal" {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <div className={clsx(classes.titleWrapper, classes.marginTop)}>
                <Typography className={classes.title} variant="caption" gutterBottom>
                  Column Mapping
                </Typography>
                <Divider />
              </div>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Box pr={1}>
                    {generateColumnSelect('group', 'Group')}
                    {generateColumnSelect('mid', 'Merchant ID')}
                    {generateColumnSelect('dba', 'DBA Name')}
                    {generateColumnSelect('merchantStatus', 'Merchant Status')}
                    {generateColumnSelect('bankcardSalesVolume', 'Bankcard Sales Volume')}
                    {generateColumnSelect('bankcardSalesVolumeAmex', 'Bankcard Sales Volume Amex')}
                    {generateColumnSelect('pinDebitSalesVolume', ' PIN Debit Volume ')}
                    {generateColumnSelect('totalIncome', ' Total Income ')}
                    {generateColumnSelect(
                      'processingExpense',
                      ' Processing Expense (Interchange and Card Brand Fees) '
                    )}
                    {generateColumnSelect('transactionCount', 'Bankcard Transaction Count')}
                    {generateColumnSelect(
                      'transactionCountAmex',
                      'Bankcard Transaction Count Amex'
                    )}
                    {generateColumnSelect('pinDebitCount', 'PIN Debit Transactions')}
                    {generateColumnSelect('wirelessCount', 'Wireless Transaction Count')}
                    {generateColumnSelect('gatewayCount', 'Gateway Transaction Count')}
                    {generateColumnSelect('avsElectronicCount', 'AVS Electronic Count')}
                    {generateColumnSelect('avsVoiceCount', 'AVS Voice Count')}
                    {generateColumnSelect('batchCount', 'Batch Count')}
                    {generateColumnSelect('chargeBackCount', 'Chargeback Count')}
                    {generateColumnSelect('retrievalCount', 'Retrieval Count')}
                    {generateColumnSelect('debitAccessOccurrences', 'Debit Access Occurrences')}
                    {generateColumnSelect(
                      'accountOnFileOccurrences',
                      'Account on File Occurrences'
                    )}
                    {generateColumnSelect(
                      'monthlyMonitoringOccurrences',
                      'Monthly Monitoring Occurrences'
                    )}
                    {generateColumnSelect(
                      'irsRegulatoryFeeOccurrences',
                      'IRS Regulatory Fee Occurrences'
                    )}
                    {generateColumnSelect(
                      'wirelessTerminalMonthlyOccurrences',
                      'Wireless Terminal Occurrences'
                    )}
                    {generateColumnSelect('otherGatewayOccurrences', 'Other Gateway Occurrences')}
                    {generateColumnSelect(
                      'merchantOnlineAccessOccurrences',
                      'Merchant Online Access Occurrences'
                    )}
                    {generateColumnSelect('annualPCIOccurrences', 'Annual PCI Occurrences')}
                    {generateColumnSelect(
                      'annualPCIWithBreachInsuranceOccurrences',
                      'Annual PCI with Breach Occurrences'
                    )}
                    {generateColumnSelect(
                      'monthlyMinimumOccurrences',
                      'Monthly Minimum Occurrences'
                    )}
                    {generateColumnSelect('annualFeeOccurrences', 'Annual Fee Occurrences')}
                    {generateColumnSelect('gatewaySetupOccurrences', 'Gateway Setup Occurrences')}
                    {generateColumnSelect('merchantClubOccurrences', 'Merchant Club Occurrences')}
                    {generateColumnSelect(
                      'gatewayMonthlyOccurrences',
                      'Gateway Monthly Occurrences'
                    )}
                    {generateColumnSelect('agentCredits', 'Agent Credits')}
                    {generateColumnSelect('transactionIncome', 'Transaction Income')}
                    {generateColumnSelect('pinDebitEBTIncome', 'PIN Debit EBT Income')}
                    {generateColumnSelect('batchIncome', 'Batch Income')}
                    {generateColumnSelect('avsElectronicIncome', 'AVS Electronic Income')}
                    {generateColumnSelect('gatewayTransactionIncome', 'Gateway Transaction Income')}
                    {generateColumnSelect(
                      'wirelessTransactionIncome',
                      'Wireless Transaction Income'
                    )}
                    {generateColumnSelect('otherTransactionIncome', 'Other Transaction Income')}
                    {generateColumnSelect('chargeBackIncome', 'ChargeBack Income')}
                    {generateColumnSelect('retrievalIncome', 'Retrieval Income')}
                    {generateColumnSelect('debitAccessIncome', 'Debit Access Income')}
                    {generateColumnSelect('accountOnFileIncome', 'Account On File Income')}
                    {generateColumnSelect('monthlyMonitoringIncome', 'Monthly Monitoring Income')}
                    {generateColumnSelect('irsRegulatoryFeeIncome', 'IRS Regulatory Fee Income')}
                    {generateColumnSelect(
                      'wirelessTerminalMonthlyIncome',
                      'Wireless Terminal Monthly Income'
                    )}
                    {generateColumnSelect('otherGatewayIncome', 'Other Gateway Income')}
                    {generateColumnSelect(
                      'merchantOnlineAccessIncome',
                      'Merchant Online Access Income'
                    )}
                    {generateColumnSelect('annualPCIIncome', 'Annual PCI Income')}

                    {generateColumnSelect('monthlyMinimumIncome', 'Monthly Minimum Income')}
                    {generateColumnSelect('annualFeeIncome', 'Annual Fee Income')}
                    {generateColumnSelect('gatewaySetupIncome', 'Gateway Setup Income')}
                    {generateColumnSelect('merchantClubIncome', 'Merchant Club Income')}
                    {generateColumnSelect('gatewayMonthlyIncome', 'Gateway Monthly Income')}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8} height="100%">
          <Box display="flex" flexDirection="column" height="100%">
            <div className={classes.titleWrapper}>
              <Typography className={classes.title} variant="caption" gutterBottom>
                File Content Preview
              </Typography>
              <Divider />
            </div>
            <Box
              maxWidth="600px"
              minWidth="100%"
              overflow="auto"
              flexGrow="1"
              sx={{'& > div': {height: '100%'}}}
            >
              <Table
                rows={csvObjects.slice(0, 10).map((e, i) => ({...e, id: i}))}
                columns={Object.keys(csvObjects[0]).map((e: string) => ({
                  id: e,
                  label: e,
                }))}
                noWrap
                flat
                scrollableBody
                stickyHeader
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Divider sx={{my: 2}} />
      <div className={classes.actions}>
        <Button onClick={onCancel} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit(onSuccess, onError)}
          color="primary"
          variant="contained"
          disabled={loading}
        >
          {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
          Save
        </Button>
      </div>
    </BoxParentHeight>
  );
};
