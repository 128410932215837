import {FormHelperText, TextField} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import {ReactNode, SyntheticEvent, useEffect, useState} from 'react';
import {TicketSearchItemModel} from '../../../..';
import {useAlgoliaSearchTickets} from '../../../../hooks/useAlgoliaSearchTickets';

type SearchProps = {
  onChange: (option: TicketSearchItemModel | null) => void;
  renderOption: (option: TicketSearchItemModel) => ReactNode;
};

export const LinkedTicketSearch = ({onChange, renderOption}: SearchProps) => {
  const [value, setValue] = useState<TicketSearchItemModel | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<any[]>([]);
  const {search} = useAlgoliaSearchTickets();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return;
    }
    setLoading(true);
    search({input: inputValue}, (results?: TicketSearchItemModel[]) => {
      let newOptions = [] as TicketSearchItemModel[];

      if (value) {
        newOptions = [value];
      }

      if (results) {
        newOptions = [...newOptions, ...results];
      }

      setOptions(newOptions);
      setLoading(false);
    });
  }, [value, inputValue, search]);

  return (
    <>
      <Autocomplete
        id="ticket-search"
        value={value}
        loading={loading}
        options={options}
        filterSelectedOptions
        filterOptions={results => results}
        noOptionsText={'No results'}
        getOptionLabel={option => `${option.distinguishableId ?? ''}: ${option.subject}`}
        getOptionDisabled={option => !option.id}
        groupBy={option => option.searchGroup}
        onChange={(
          _event: SyntheticEvent<Element, Event>,
          option: TicketSearchItemModel | null
        ) => {
          onChange(option);
          setValue(option);
        }}
        onInputChange={(_event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={params => (
          <TextField
            {...params}
            label="Linked Ticket"
            placeholder="Search for tickets..."
            variant="standard"
          />
        )}
        renderOption={(props, option: TicketSearchItemModel) => (
          <li {...props}>{renderOption(option)}</li>
        )}
      />
      {!inputValue && (
        <FormHelperText id="ticket-search">
          Start entering DBA, Legal Name, Application ID or Ticket ID for search
        </FormHelperText>
      )}
    </>
  );
};
