import * as yup from 'yup';
import {MAX_TSYSXML_ADDRESS_FIELD_LENGTH} from '../../../lib/integrations/tsys/consts';
import {OwnerTitles} from '../../OwnerTitles';
import {StateHash} from '../../USStates';
import {
  getBooleanTypeError,
  getCustomErrorMessage,
  getNumberTypeError,
  oneOfAllowEmpty,
} from '../Common';
import {getDateOfBirthValidation} from './Common';

const getOwnerValidationObject = (useCustomErrorMessage: boolean, ssnValidationObject: any) => {
  return {
    firstName: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'First Name is required')),
    lastName: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Last Name is required')),
    title: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Title is required'))
      .oneOf(Object.values(OwnerTitles)),
    ...ssnValidationObject,
    dateOfBirth: getDateOfBirthValidation(useCustomErrorMessage),
    homeAddress1: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Address is required')),
    homeCity: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'City is required')),
    homeState: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'State is required'))
      .oneOf(Object.keys(StateHash)),
    homeZip: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Zip Code is required'))
      .min(5, getCustomErrorMessage(useCustomErrorMessage, 'Must be a valid zip code')),
    percentOwnership: yup
      .number()
      .min(
        0,
        getCustomErrorMessage(useCustomErrorMessage, 'Ownership percentage cannot be less than 0%')
      )
      .max(
        100,
        getCustomErrorMessage(useCustomErrorMessage, 'Ownership percentage cannot exceed 100%')
      )
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Ownership Percentage is required'))
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Ownership Percentage is required')),
    driverLicenseState: yup
      .string()
      .when('$dobSsnTaxIdAndBankInfoNotRequired', (dobSsnTaxIdAndBankInfoNotRequired, schema) => {
        if (!dobSsnTaxIdAndBankInfoNotRequired) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Driver License State is required')
          );
        }
        return;
      })
      .test(oneOfAllowEmpty(['Passport', ...Object.keys(StateHash)])),
    driverLicenseNumber: yup
      .string()
      .when('$dobSsnTaxIdAndBankInfoNotRequired', (dobSsnTaxIdAndBankInfoNotRequired, schema) => {
        if (!dobSsnTaxIdAndBankInfoNotRequired) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Driver License Number is required')
          );
        }
        return;
      }),
  };
};
const getOwnerSchema = (useCustomErrorMessage: boolean, ssnValidationObject: any) =>
  yup.object().shape(getOwnerValidationObject(useCustomErrorMessage, ssnValidationObject));

const webSsnValidationObject = {
  socialSecurityNumber: yup
    .string()
    .when(['encryptedSocialSecurityNumber', '$dobSsnTaxIdAndBankInfoNotRequired'], {
      is: (encryptedSocialSecurityNumber: string, dobSsnTaxIdAndBankInfoNotRequired?: boolean) => {
        return !encryptedSocialSecurityNumber && !dobSsnTaxIdAndBankInfoNotRequired;
      },
      then: (schema: any) => schema.required('SSN is required').min(11, 'Must be a valid SSN'),
      otherwise: (schema: any) =>
        schema.test(
          'socialSecurityNumber',
          'Must be a valid SSN',
          (socialSecurityNumber: string) => {
            return !socialSecurityNumber || socialSecurityNumber.length === 11;
          }
        ),
    }),
};

const apiSsnValidationObject = {
  socialSecurityNumber: yup
    .string()
    .required()
    .min(11)
    .matches(/\d{3}-\d{2}-\d{4}$/),
};

const getSignerPageValidationObject = (
  useCustomErrorMessage: boolean,
  ssnValidationObject: any
) => {
  return {
    firstName: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'First Name is required')),
    lastName: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Last Name is required')),
    title: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Title is required'))
      .oneOf(Object.values(OwnerTitles)),
    ...ssnValidationObject,
    dateOfBirth: getDateOfBirthValidation(useCustomErrorMessage),
    homeAddress1: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Address is required')),
    homeAddress2: yup
      .string()
      .optional()
      .test(
        'combined-address-length',
        'Combined address lines cannot exceed 32 characters',
        function (value) {
          const {homeAddress1} = this.parent;
          return (
            (homeAddress1?.length ?? 0) + (value?.length ?? 0) <=
            MAX_TSYSXML_ADDRESS_FIELD_LENGTH - 1 // -1 for space between address lines
          );
        }
      ),
    homeCity: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'City is required')),
    homeState: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'State is required'))
      .test(oneOfAllowEmpty(Object.keys(StateHash))),
    homeZip: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Zip Code is required'))
      .min(5, getCustomErrorMessage(useCustomErrorMessage, 'Must be a valid zip code')),
    percentOwnership: yup
      .number()
      .min(
        0,
        getCustomErrorMessage(useCustomErrorMessage, 'Ownership percentage cannot be less than 0%')
      )
      .max(
        100,
        getCustomErrorMessage(useCustomErrorMessage, 'Ownership percentage cannot exceed 100%')
      )
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Ownership Percentage is required'))
      .typeError(getNumberTypeError(useCustomErrorMessage, 'Ownership Percentage is required')),
    driverLicenseState: yup
      .string()
      .when('$dobSsnTaxIdAndBankInfoNotRequired', (dobSsnTaxIdAndBankInfoNotRequired, schema) => {
        if (!dobSsnTaxIdAndBankInfoNotRequired) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Driver License State is required')
          );
        }
        return;
      })
      .test(oneOfAllowEmpty(['Passport', ...Object.keys(StateHash)])),
    driverLicenseNumber: yup
      .string()
      .when('$dobSsnTaxIdAndBankInfoNotRequired', (dobSsnTaxIdAndBankInfoNotRequired, schema) => {
        if (!dobSsnTaxIdAndBankInfoNotRequired) {
          return schema.required(
            getCustomErrorMessage(useCustomErrorMessage, 'Driver License Number is required')
          );
        }
        return;
      }),
    cellPhone: yup
      .string()
      .required(getCustomErrorMessage(useCustomErrorMessage, 'Cell Phone is required')),
    otherOwners: yup.boolean().when('percentOwnership', (percentOwnership: number, schema: any) => {
      if (percentOwnership < 75) {
        return schema
          .required()
          .typeError(getBooleanTypeError(useCustomErrorMessage, 'You must select an option'));
      }
    }),
    otherOwnersData: yup.array().when('otherOwners', (otherOwners: boolean, schema: any) => {
      if (otherOwners) {
        return schema.of(getOwnerSchema(useCustomErrorMessage, ssnValidationObject));
      }
    }),
  };
};
export const SignerPageSchema = yup
  .object()
  .shape(getSignerPageValidationObject(true, webSsnValidationObject));

export const getApiSignerPageValidationObject = () => {
  return getSignerPageValidationObject(false, apiSsnValidationObject);
};
