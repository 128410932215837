import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {Box, Button, ButtonTypeMap, FormControlLabel, Switch} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {AttachmentView, Collections, EquipmentAttachment} from '@ozark/common';
import {useCallback, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {Firebase} from '../../../firebase';
import {EquipmentDescriptionDialog} from './EquipmentDescriptionDialog';
import {FieldVarSheet} from './FieldVarSheet';

type Props = {
  applicationId: string;
  isAdditional: boolean;
  targetFieldName: string;
  readonly: boolean;
  canManageEquipmentActivity: boolean;
  handleEquipmentRemove?: () => void;
  onBeforeUpload: () => Promise<boolean>;
  saveApplication: () => void;
};

export const EquipmentListItemMenu = ({
  applicationId,
  isAdditional,
  targetFieldName,
  readonly,
  canManageEquipmentActivity,
  handleEquipmentRemove,
  onBeforeUpload,
  saveApplication,
}: Props) => {
  const {watch, getValues, setValue, control} = useFormContext();
  const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false);

  const watchDescription = watch(`${targetFieldName}.description`, '');
  const watchVarSheetAttachment = watch(`${targetFieldName}.VARSheetAttachmentUrl`, null);
  const watchIsActive = watch(`${targetFieldName}.isActive`, undefined);

  const handleDescriptionEdit = useCallback(
    (description: string) => {
      setValue(`${targetFieldName}.description`, description, {shouldDirty: true});
      setDescriptionDialogOpen(false);
    },
    [targetFieldName, setValue, setDescriptionDialogOpen]
  );

  const handleDescriptionDialogClose = useCallback(() => {
    setDescriptionDialogOpen(false);
  }, [setDescriptionDialogOpen]);

  const buttonProps: ButtonTypeMap['props'] = {
    disabled: readonly,
    variant: 'text',
    color: 'secondary',
  };

  const handleOnAttachmentUpload = useCallback(async (attachment?: AttachmentView) => {
    if (!attachment) {
      return;
    }
    const currentAttachment = getValues(`${targetFieldName}.attachment`);

    const equipmentAttachment: EquipmentAttachment = {
      attachmentId: attachment.id,
      cloudPath: attachment.cloudPath,
      name: attachment.name,
    };
    setValue(`${targetFieldName}.attachment`, equipmentAttachment, {shouldDirty: true});
    const url = await Firebase.storage.ref(attachment.cloudPath).getDownloadURL();
    setValue(`${targetFieldName}.VARSheetAttachmentUrl`, url, {shouldDirty: true});
    if (!!currentAttachment && !!currentAttachment.attachmentId) {
      try {
        await Firebase.firestore
          .collection(Collections.applications)
          .doc(applicationId)
          .collection(Collections.attachments)
          .doc(currentAttachment.attachmentId)
          .update({deleted: true});
      } catch (e) {
        console.error(e);
      }
    }

    saveApplication();
  }, []);

  return (
    <Box display="flex" onClick={e => e.stopPropagation()}>
      <Controller
        control={control}
        name={`${targetFieldName}.isActive`}
        render={({field}) => (
          <FormControlLabel
            control={
              <Switch checked={field.value} disabled={!canManageEquipmentActivity} {...field} />
            }
            label={'Active'}
          />
        )}
      />
      <ButtonStyled {...buttonProps} startIcon={<CloudUploadIcon />}>
        <FieldVarSheet
          fieldNameFull={`${targetFieldName}.VARSheetAttachmentUrl`}
          applicationId={applicationId}
          attachmentLabel={`Equipment ${watchDescription} VAR Sheet`}
          onAttachmentUpload={handleOnAttachmentUpload}
          onBeforeUpload={onBeforeUpload}
        >
          Upload VAR Sheet
        </FieldVarSheet>
      </ButtonStyled>
      {watchVarSheetAttachment && (
        <ButtonStyled
          {...buttonProps}
          disabled={false}
          startIcon={<CloudDownloadIcon color="primary" />}
        >
          <a href={watchVarSheetAttachment} target="_blank" rel="noreferrer">
            Download VAR Sheet
          </a>
        </ButtonStyled>
      )}
      {isAdditional ? (
        <ButtonStyled
          {...buttonProps}
          onClick={() => setDescriptionDialogOpen(true)}
          startIcon={<EditIcon />}
        >
          Edit Description
        </ButtonStyled>
      ) : null}
      {isAdditional ? (
        <ButtonStyled
          {...buttonProps}
          onClick={() => handleEquipmentRemove?.()}
          startIcon={<DeleteIcon />}
        >
          Delete
        </ButtonStyled>
      ) : null}
      <EquipmentDescriptionDialog
        open={descriptionDialogOpen}
        dialogTitle="Edit Description"
        okTitle="Save"
        defaultValue={watchDescription}
        onOk={handleDescriptionEdit}
        onClose={handleDescriptionDialogClose}
      />
    </Box>
  );
};

const ButtonStyled = withStyles({
  root: {
    textTransform: 'none',
    marginLeft: '10px',
    padding: '5px 10px',
  },
})(Button);
