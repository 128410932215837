import {subDays} from 'date-fns';
import {collection, getCountFromServer, query, where} from 'firebase/firestore';
import {useEffect, useState} from 'react';
import {
  Collections,
  Firebase,
  selectTicketView,
  Ticket,
  TicketStatus,
  UniversalSnapshot,
  ViewableByType,
} from '../../../..';
import {useUserInfo} from '../../../../hooks';
import {stateEmpty, statePromised} from '../../constants/constants';
import {FilterKey, TFolder} from '../../types';
import {useTicketsQuery} from '../useTicketsQuery';
import {useTicketsViewableBy} from './useTicketsViewableBy';

export function useTicketsMyCreator(statusSet: TicketStatus[], erpFilters?: TFolder['erpFilters']) {
  const {uid, isErpUser} = useUserInfo();
  const [ticketsMyCreator, setTicketsMyCreator] = useState(statePromised);
  const {filterOutInternalTickets} = useTicketsViewableBy();

  const {folder} = useTicketsQuery();

  useEffect(() => {
    setTicketsMyCreator(statePromised);

    let query = Firebase.firestore
      .collection(Collections.tickets)
      .where('author.id', '==', uid)
      .where('status', 'in', statusSet);

    if (isErpUser && erpFilters?.whereQuery) {
      query = erpFilters.whereQuery(query);
    }

    return query.onSnapshot(
      async snapshot => {
        if (snapshot.size === 0) {
          setTicketsMyCreator(stateEmpty);
          return;
        }
        const docs = snapshot.docs as UniversalSnapshot<Ticket>[];
        const tickets = filterOutInternalTickets(docs.map(selectTicketView));
        setTicketsMyCreator({promised: false, data: tickets});
      },
      err => {
        console.error(err);
        setTicketsMyCreator({promised: false, error: err});
      }
    );
  }, [statusSet, filterOutInternalTickets, folder, isErpUser, erpFilters, uid]);

  return {
    ticketsMyCreator,
  };
}

type UseTicketsMyCreatorCountProps = {
  statusSet: TFolder['statusSet'];
  filterKey?: TFolder['filterKey'];
  erpFilters?: TFolder['erpFilters'];
};
export function useTicketsMyCreatorCount({
  statusSet,
  filterKey,
  erpFilters,
}: UseTicketsMyCreatorCountProps): {count: number; refetch: () => void} {
  const {uid} = useUserInfo();
  const {isErpUser, isAgent} = useUserInfo();
  const [ticketsMyCreatorCount, setTicketsMyCreatorCount] = useState<number>(0);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    async function fetchCount() {
      const ref = collection(Firebase.firestore, Collections.tickets);
      let q = query(ref, where('status', 'in', statusSet), where('author.id', '==', uid));

      if (!isErpUser) {
        if (isAgent) {
          q = query(q, where('viewableBy', 'in', [ViewableByType.agentOrErp, ViewableByType.all]));
        } else {
          q = query(q, where('viewableBy', '==', ViewableByType.all));
        }
      }

      if (isErpUser && erpFilters?.whereCount) {
        q = query(q, erpFilters.whereCount);
      }

      if (filterKey) {
        switch (filterKey) {
          case FilterKey.openedMoreThan24hAgo:
            q = query(q, where('openedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.auditStartedMoreThan24hAgo:
            q = query(q, where('auditStartedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.billingAuditStartedMoreThan24hAgo:
            q = query(q, where('billingAuditStartedAt', '<=', subDays(new Date(), 1)));
            break;
        }
      }

      const countQuery = await getCountFromServer(q);

      setTicketsMyCreatorCount(countQuery.data().count);
    }

    fetchCount();
  }, [statusSet, isErpUser, isAgent, uid, filterKey, counter, erpFilters]);

  return {count: ticketsMyCreatorCount, refetch: () => setCounter(counter => counter + 1)};
}
