import {EquipmentFeeCategory, FeeCategories, RiskLevel, SplitMethodType} from '@ozark/common';
import * as yup from 'yup';
import {emptyStringToNull} from '../../../helpers';

const createRiskLevelModelSchema = (
  allowNegatives: boolean,
  riskLevel: RiskLevel,
  splitMethod?: SplitMethodType
) => {
  const arraysOfKeys = Object.values(FeeCategories)
    .map(e => Object.keys(e))
    .flat();
  const arraysOfOptionalKeys = Object.values(EquipmentFeeCategory)
    .map(e => Object.keys(e))
    .flat();
  const yupObject = arraysOfKeys.reduce((previousValue: any, currentValue: string) => {
    return {
      ...previousValue,
      [currentValue]: [
        (y: any) => y.number().nullable(),
        (y: any) => {
          if (currentValue === 'monthlyProcessingVolumePercent') {
            if (splitMethod === SplitMethodType.direct) {
              return y.when(
                '$monthlyProcessingVolumePercent',
                (monthlyProcessingVolumePercent: any, schema: any) => {
                  const maxValue = monthlyProcessingVolumePercent[riskLevel];
                  return maxValue ? schema.max(maxValue, `Cannot be greater than ${maxValue}`) : y;
                }
              );
            }
            return y;
          }
          if (allowNegatives) {
            return y;
          }
          return y.min(0, 'Value cannot be negative.');
        },
        (y: any) => y.transform(emptyStringToNull),
        (y: any) => {
          if (currentValue.endsWith('Label')) {
            return y;
          }
          return y.required('Value is Required');
        },
      ].reduce((yupObject, applyRuleFunction) => {
        return applyRuleFunction(yupObject);
      }, yup),
    };
  }, {});
  const optionalYupObject = arraysOfOptionalKeys.reduce(
    (previousValue: any, currentValue: string) => {
      return {
        ...previousValue,
        [currentValue]: [
          (y: any) => y.number().nullable().optional(),
          (y: any) => {
            if (allowNegatives) {
              return y;
            }
            return y.min(0, 'Value cannot be negative.');
          },
          (y: any) => y.transform(emptyStringToNull),
        ].reduce((yupObject, applyRuleFunction) => {
          return applyRuleFunction(yupObject);
        }, yup),
      };
    },
    {}
  );
  return {...yupObject, ...optionalYupObject};
};

type RiskLevelSchemas = {
  [key in RiskLevel]: any;
};

const createRiskModelSchema = (
  allowNegatives: boolean,
  splitMethod: SplitMethodType | undefined
) => {
  const result: RiskLevelSchemas = {} as RiskLevelSchemas;
  for (const riskLevel of Object.values(RiskLevel)) {
    result[riskLevel] = yup.object(
      createRiskLevelModelSchema(allowNegatives, riskLevel, splitMethod)
    );
  }
  return result;
};

export const createScheduleASchema = (allowNegatives: boolean) =>
  yup.object().shape({
    riskModels: yup
      .object()
      .required()
      .when('splitMethod', (splitMethod: SplitMethodType | undefined) => {
        return yup.object().shape(createRiskModelSchema(allowNegatives, splitMethod));
      }),
  });
