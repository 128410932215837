import ImageIcon from '@mui/icons-material/Image';
import {
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  AgentView,
  Dispositions,
  GroupView,
  InputAccept,
  ScorecardServiceName,
  StateArray,
  StateHash,
} from '@ozark/common';
import {
  AutoCompleteAddressTextField,
  Select,
  TextField,
  TextFieldColorPicker,
  UploadButton,
} from '@ozark/common/components';
import PhoneNumber from 'awesome-phonenumber';
import {Fragment} from 'react';
import {ColorResult} from 'react-color';
import {UseFormReturn} from 'react-hook-form';
import * as yup from 'yup';
import {PayTo} from '../../../functions/src/constants/PayTo';
import {emptyStringToNull} from '../../helpers';
import {Switch} from '../Switch';
import {PaymentPlacementTypeSwitch} from './PaymentPlacementTypeSwitch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      marginTop: theme.spacing(2),
    },
    title: {
      fontSize: '1.2em',
      [theme.breakpoints.down('sm')]: {
        fontSize: '1em',
      },
    },
    displayContainer: {
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(2, 0),
    },
    logo: {
      height: 48,
    },
    upload: {
      marginTop: theme.spacing(1.5),
    },
    swatch: {
      height: theme.spacing(3),
      width: theme.spacing(6),
      background: '#fff',
      padding: theme.spacing(0.5),
      borderRadius: 1,
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    color: {
      height: '100%',
      width: '100%',
    },
  })
);

export const groupViewToFormData = (groupView: GroupView): GroupFormData => ({
  name: groupView.name,
  address1: groupView.address1,
  address2: groupView.address2,
  city: groupView.city,
  state: groupView.state,
  zipCode: groupView.zipCode,
  phone: groupView.phone,
  appDomain: groupView.appDomain,
  portalDomain: groupView.portalDomain,
  logoUrl: groupView.logoUrl,
  termsUrl: groupView.termsUrl,
  backgroundUrl: groupView.backgroundUrl,
  hexColorPrimary: groupView.hexColorPrimary,
  hexColorTextPrimary: groupView.hexColorTextPrimary,
  ownerAgentUid: groupView.ownerAgentUid,
  applicationSettings: groupView.applicationSettings,
  institutionServiceProvider: groupView.institutionServiceProvider,
  finicityExperienceId: groupView.finicityExperienceId,
});

export type GroupFormData = {
  name: string;
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zipCode: string;
  phone: string;
  appDomain: string;
  portalDomain: string;
  image?: {file: File; dataUrl: string};
  logoUrl?: string;
  termsUrl?: string;
  background?: {
    file: File;
    dataUrl: string;
  };
  backgroundUrl?: string;
  hexColorPrimary: string;
  hexColorTextPrimary: string;
  ownerAgentUid?: string;
  institutionServiceProvider: 'Plaid' | 'Finicity';
  finicityExperienceId?: string | null;
  applicationSettings?: {
    enableMFA?: boolean;
    enableRegistration?: boolean;
    onCompleteDisposition?: Dispositions;
    enableEquipmentFileDialog?: boolean;
    enableMerchantsToAddNewAccounts?: boolean;
    enablePersonaIdVerification?: boolean;
    dobSsnTaxIdAndBankInfoNotRequired?: boolean;
    rentalPaymentTypeEnabled?: boolean;
    freePlacementPaymentTypeEnabled?: boolean;
    billToResidualsPaymentTypeEnabled?: boolean;
    enableAgentProfileCreation?: boolean;
    payTo?: PayTo;
    allowAgentsUpdateResidualCalculation?: boolean;
    newAgentDefaultPayTo?: PayTo;
    splitAdditionalServiceFee?: boolean;
    allowSplitNegativeResiduals?: boolean;
    enableOnlineApplicationLink?: boolean;
    enableOptionalScheduleAFees?: boolean;
    allowResidualSplitPercentageLabels?: boolean;
  };
};

export const GroupFormSchema = yup.object().shape({
  name: yup.string().required('Group Name is required'),
  address1: yup.string().required('Address is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  zipCode: yup.string().required('Zip Code is required').min(5, 'Must be a valid zip code'),
  phone: yup
    .string()
    .min(12, 'Must be a valid phone number') // validating against e164 format (+18002333333)
    .matches(/^\+1[2-9]{1}[0-9]{9}$/, 'Must be a valid phone number')
    .transform(value => {
      const phone = value ? new PhoneNumber(value, 'US').getNumber('e164') : value;
      return phone;
    })
    .required('Phone is required'),
  appDomain: yup.string().required('Application Domain is required'),
  portalDomain: yup.string().required('Portal Domain is required'),
  image: yup.object().when('logoUrl', (logoUrl: string, schema: any) => {
    if (!logoUrl) {
      return schema.required('Logo is required');
    }
  }),
  termsUrl: yup.string().required('Terms and Conditions url is required'),
  hexColorPrimary: yup
    .string()
    .required('Primary Color is required')
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Must be a valid hex color'),
  hexColorTextPrimary: yup
    .string()
    .required('Primary Text Color is required')
    .matches(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/, 'Must be a valid hex color'),
  ownerAgentUid: yup.string(),
  institutionServiceProvider: yup.string().required('Institution Service Provider is required'),
  applicationSettings: yup.object().shape({
    enableMFA: yup.boolean(),
    enableRegistration: yup.boolean(),
    onCompleteDisposition: yup.string(),
    enableEquipmentFileDialog: yup.boolean(),
    enableMerchantsToAddNewAccounts: yup.boolean(),
    enablePersonaIdVerification: yup.boolean(),
    dobSsnTaxIdAndBankInfoNotRequired: yup.boolean(),
    rentalPaymentTypeEnabled: yup.boolean(),
    freePlacementPaymentTypeEnabled: yup.boolean(),
    billToResidualsPaymentTypeEnabled: yup.boolean(),
    enableAgentProfileCreation: yup.boolean(),
    payTo: yup.string(),
    allowAgentsUpdateResidualCalculation: yup.boolean(),
    newAgentDefaultPayTo: yup.string(),
    splitAdditionalServiceFee: yup.boolean(),
    allowSplitNegativeResiduals: yup.boolean(),
    enableOnlineApplicationLink: yup.boolean(),
    enableOptionalScheduleAFees: yup.boolean(),
    allowResidualSplitPercentageLabels: yup.boolean(),
  }),
  finicityExperienceId: yup.string().transform(emptyStringToNull).nullable(),
});

export const GroupForm = ({
  hookForm,
  isAuthUserAdmin,
  agents,
}: {
  hookForm: UseFormReturn<GroupFormData, any>;
  isAuthUserAdmin: boolean;
  agents?: AgentView[] | null;
}) => {
  const classes = useStyles();
  const {
    watch,
    control,
    register,
    setValue,
    formState: {errors},
  } = hookForm;

  register('image');
  register('background');

  const handleSelectFile =
    (name: 'image' | 'background') => (result: {dataUrl: string; file: File}) => {
      setValue(name, result, {shouldDirty: true});
    };

  const handleAddressAutoFill = (city: string, state: string, zip: string) => {
    setValue(`address2`, '', {shouldDirty: true});
    setValue(`city`, city || '', {shouldDirty: true});
    setValue(`state`, state || '', {shouldDirty: true});
    setValue(`zipCode`, zip || '', {shouldDirty: true});
  };

  const institutionServiceProvider = watch('institutionServiceProvider');

  const hexColorPrimary = watch('hexColorPrimary', '#1877f2');
  const hexColorTextPrimary = watch('hexColorTextPrimary', '#ffffff');

  const image = watch('image');
  const background = watch('background');

  return (
    <Fragment>
      <Grid item xs={12}>
        <TextField
          name="name"
          label="Group Name"
          required
          errors={errors}
          control={control}
          disabled={!isAuthUserAdmin}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="phone"
          label="Phone Number"
          required
          errors={errors}
          control={control}
          disabled={!isAuthUserAdmin}
          transform={{
            pattern: '(999) 999-9999',
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Group Address
          </Typography>
          <Divider />
        </div>
      </Grid>

      <Grid item xs={12}>
        <AutoCompleteAddressTextField
          setAutofillHandler={handleAddressAutoFill}
          name="address1"
          label="Address Line 1"
          required
          errors={errors}
          control={control}
          disabled={!isAuthUserAdmin}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          name="address2"
          label="Address Line 2"
          errors={errors}
          control={control}
          disabled={!isAuthUserAdmin}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="city"
          label="City"
          required
          errors={errors}
          control={control}
          disabled={!isAuthUserAdmin}
        />
      </Grid>

      <Grid item xs={4}>
        <Select
          name="state"
          label="State"
          required
          errors={errors}
          disabled={!isAuthUserAdmin}
          control={control}
        >
          {StateArray.sortAndMap(
            state => (
              <MenuItem key={`${state}`} value={state}>
                {StateHash[state]}
              </MenuItem>
            ),
            state => StateHash[state]
          )}
        </Select>
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="zipCode"
          label="Zip Code"
          errors={errors}
          control={control}
          disabled={!isAuthUserAdmin}
        />
      </Grid>

      {agents !== undefined && (
        <>
          <Grid item xs={12}>
            <div className={classes.titleWrapper}>
              <Typography className={classes.title} variant="caption" gutterBottom>
                Group Owner
              </Typography>
              <Divider />
            </div>
          </Grid>

          <Grid item xs={12}>
            <Select
              disabled={!isAuthUserAdmin}
              name="ownerAgentUid"
              label="Agent"
              errors={errors}
              control={control}
              onChangeSuccess={() => {}}
            >
              <MenuItem value={''}>{'None'}</MenuItem>
              {agents?.sortAndMap(
                a => (
                  <MenuItem key={a.id} value={a.id}>
                    {a.firstName} {a.lastName}
                  </MenuItem>
                ),
                a => `${a.firstName} ${a.lastName}`
              )}
            </Select>
          </Grid>
        </>
      )}

      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Domain Names
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="appDomain"
          label="Application Domain"
          required
          disabled={!isAuthUserAdmin}
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="portalDomain"
          label="Portal Domain"
          required
          disabled={!isAuthUserAdmin}
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Logo
          </Typography>
          <Divider />
        </div>
      </Grid>
      {image && (
        <Grid item xs={12}>
          <div className={classes.displayContainer}>
            <img className={classes.logo} src={image.dataUrl} alt="logo" />
          </div>
        </Grid>
      )}

      <Grid item xs={12}>
        <UploadButton
          id="imageUploadButton"
          name="image"
          variant="outlined"
          color="secondary"
          className={classes.upload}
          errors={errors}
          fullWidth
          startIcon={<ImageIcon />}
          accept={InputAccept.image}
          onSelectFile={handleSelectFile('image')}
          disabled={!isAuthUserAdmin}
        >
          Upload Group Logo
        </UploadButton>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Login Background
          </Typography>
          <Divider />
        </div>
      </Grid>
      {background && (
        <Grid item xs={12}>
          <div className={classes.displayContainer}>
            <img className={classes.logo} src={background.dataUrl} alt="background" />
          </div>
        </Grid>
      )}
      <Grid item xs={12}>
        <UploadButton
          id="backgroundUploadButton"
          name="background"
          variant="outlined"
          color="secondary"
          className={classes.upload}
          errors={errors}
          fullWidth
          startIcon={<ImageIcon />}
          accept={InputAccept.image}
          onSelectFile={handleSelectFile('background')}
          verifyFileSize={{
            confirmationText: `The image you have selected is larger than 5MB and may cause delayed load times. We recommend reducing the size of this image before uploading. Are you sure you want to continue?`,
            maxFileSize: 5242880,
          }}
          disabled={!isAuthUserAdmin}
        >
          Upload Group Login Background
        </UploadButton>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Theme
          </Typography>
          <Divider />
        </div>
      </Grid>

      <Grid item xs={6}>
        <TextFieldColorPicker
          name="hexColorPrimary"
          label="Primary Color"
          defaultValue="#1877f2"
          hookForm={hookForm}
          hexColor={hexColorPrimary}
          onColorChange={(color: ColorResult) =>
            hookForm.setValue('hexColorPrimary', color.hex, {shouldDirty: true})
          }
          disabled={!isAuthUserAdmin}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldColorPicker
          name="hexColorTextPrimary"
          label="Primary Text Color"
          defaultValue="#ffffff"
          hookForm={hookForm}
          hexColor={hexColorTextPrimary}
          onColorChange={(color: ColorResult) =>
            hookForm.setValue('hexColorTextPrimary', color.hex, {shouldDirty: true})
          }
          disabled={!isAuthUserAdmin}
        />
      </Grid>

      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Application Settings
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={12}>
        <List>
          <ListItem>
            <ListItemText
              primary="Enable MFA for group"
              secondary="Enabling will require Agents and Merchants to multifactor authentication."
            />

            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.enableMFA"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Enable Merchant Registration"
              secondary="Enabling will require applicants to register or sign in to create an application."
            />

            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.enableRegistration"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Enable Equipment File Dialog"
              secondary="Enabling will prompt merchants to fill out the equipment file dialog."
            />

            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.enableEquipmentFileDialog"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Allow Merchants to Add New Accounts"
              secondary="Enabling will allow approved merchants to apply for an additional accounts."
            />

            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.enableMerchantsToAddNewAccounts"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Enable Persona ID Verification"
              secondary="Enabling will require applicants to take a photo of their Driver’s License front and back during an application."
            />

            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.enablePersonaIdVerification"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Default Disposition"
              secondary="Specifies which disposition to assign to an application upon completion."
              sx={{
                pr: 23,
              }}
            />

            <ListItemSecondaryAction sx={{right: 0}}>
              <Select
                disabled={!isAuthUserAdmin}
                name="applicationSettings.onCompleteDisposition"
                label="Disposition"
                errors={errors}
                control={control}
                sx={{width: 200}}
                defaultValue={Dispositions.asReceived}
                options={{
                  [Dispositions.asReceived]: Dispositions.asReceived,
                  [Dispositions.uwReceived]: Dispositions.uwReceived,
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Make sensitive info not mandatory in the agent facing app"
              secondary="DOB/SSN/TAX ID/Drivers License and Banking Info not mandatory in the agent facing app, but mandatory when transferred to a merchant for esignature, and mandatory for an agent to submit a wet-signed app."
            />

            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.dobSsnTaxIdAndBankInfoNotRequired"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemText
              primary="Payment and Placement Types"
              secondary="Enabling these Payment and Placement types allow the Group Admin to configure their Agent profiles with these options."
            />
          </ListItem>

          <ListItem sx={{pl: 6}}>
            <ListItemText primary="Rental" />

            <ListItemSecondaryAction sx={{right: -8}}>
              <PaymentPlacementTypeSwitch
                name="applicationSettings.rentalPaymentTypeEnabled"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem sx={{pl: 6}}>
            <ListItemText primary="Free Placement - Prior Approval Needed" />

            <ListItemSecondaryAction sx={{right: -8}}>
              <PaymentPlacementTypeSwitch
                name="applicationSettings.freePlacementPaymentTypeEnabled"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem sx={{pl: 6}}>
            <ListItemText primary="Bill to Residuals" />

            <ListItemSecondaryAction sx={{right: -8}}>
              <PaymentPlacementTypeSwitch
                name="applicationSettings.billToResidualsPaymentTypeEnabled"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Enable Online Application Link"
              secondary="Enabling will allow the agent to use the merchant self service online application link."
            />

            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.enableOnlineApplicationLink"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Integration Settings
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={12}>
        <List>
          <ListItem>
            <ListItemText
              primary="Institution Service Provider"
              secondary="Specifies which service provider to use for banking verification & services."
              sx={{
                pr: 23,
              }}
            />
            <ListItemSecondaryAction sx={{right: 0}}>
              <Select
                disabled={!isAuthUserAdmin}
                name="institutionServiceProvider"
                label="Service Provider"
                errors={errors}
                control={control}
                sx={{width: 200}}
                defaultValue={ScorecardServiceName.finicity}
                options={{
                  None: 'None',
                  [ScorecardServiceName.finicity]: ScorecardServiceName.finicity,
                  [ScorecardServiceName.plaid]: ScorecardServiceName.plaid,
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>

      {institutionServiceProvider === ScorecardServiceName.finicity && (
        <Grid item xs={12}>
          <TextField
            name="finicityExperienceId"
            label="Finicity Experience ID"
            disabled={!isAuthUserAdmin}
            errors={errors}
            control={control}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Residual Settings
          </Typography>
          <Divider />
        </div>
      </Grid>

      <Grid item xs={12}>
        <List>
          <ListItem>
            <ListItemText
              primary="Agent Member and Sub Agent Residual Setting"
              secondary="Enabling this will allow the Agent Admin to update the Agent Member's Residual calculation from Split of a Split to Direct Split, Additionally the Agent Member can update their Sub Agent's Residual calculation from Split of a Split to Direct Split Calculations."
            />
            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.allowAgentsUpdateResidualCalculation"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Split Negative Residuals"
              secondary="Enabling this will allow the Group Admin to configure the Agent profiles with the option to split negative residuals."
            />
            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.allowSplitNegativeResiduals"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemText
              primary="Residual Display vs Calculated"
              secondary="Enabling this feature will allow the Group Admin to set a 'Displayed Residual Split' and a separate 'Calculcated Residual Split' for their agent's schedule A profiles."
            />
            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.allowResidualSplitPercentageLabels"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>

          <ListItem>
            <ListItemText
              primary="Optional Schedule A Fees"
              secondary="Enabling this will allow the Group Admin to configure the Agent profile with optional Schedule A Fees."
            />
            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.enableOptionalScheduleAFees"
                disabled={!isAuthUserAdmin}
                control={control}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Pay To"
              secondary="Specifies Agent Payment Type."
              sx={{
                pr: 23,
              }}
            />
            <ListItemSecondaryAction sx={{right: 0}}>
              <Select
                disabled={!isAuthUserAdmin}
                name="applicationSettings.payTo"
                label="Pay To"
                errors={errors}
                control={control}
                sx={{width: 200}}
                defaultValue={PayTo.payToGroup}
                options={{
                  [PayTo.payToGroup]: PayTo.payToGroup,
                  [PayTo.payToAgent]: PayTo.payToAgent,
                  [PayTo.payToAgentSubAgent]: PayTo.payToAgentSubAgent,
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="New Agent Default Pay To"
              secondary="Specifies New Agent Default Pay To."
              sx={{
                pr: 23,
              }}
            />
            <ListItemSecondaryAction sx={{right: 0}}>
              <Select
                disabled={!isAuthUserAdmin}
                name="applicationSettings.newAgentDefaultPayTo"
                label="Default Pay To"
                errors={errors}
                control={control}
                sx={{width: 200}}
                defaultValue={PayTo.payToGroup}
                options={{
                  [PayTo.payToGroup]: PayTo.payToGroup,
                  [PayTo.payToAgent]: PayTo.payToAgent,
                  [PayTo.payToAgentSubAgent]: PayTo.payToAgentSubAgent,
                }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Additional Service Fees Revenue Share"
              secondary="Enabling this will calculate the Additional Service Fees at the Agents Revenue Share Percentage"
            />

            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.splitAdditionalServiceFee"
                disabled={!isAuthUserAdmin}
                control={control}
                defaultValue={true}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            User Settings
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={12}>
        <List>
          <ListItem>
            <ListItemText
              primary="Agent Profile Creation"
              secondary="Enabling this will allow Agent Members to create additional Agent Users"
            />

            <ListItemSecondaryAction sx={{right: -8}}>
              <Switch
                name="applicationSettings.enableAgentProfileCreation"
                disabled={!isAuthUserAdmin}
                control={control}
                defaultValue={true}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </Grid>

      <Grid item xs={12}>
        <div className={classes.titleWrapper}>
          <Typography className={classes.title} variant="caption" gutterBottom>
            Terms &amp; Conditions
          </Typography>
          <Divider />
        </div>
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="termsUrl"
          label="Terms Url"
          required
          disabled={!isAuthUserAdmin}
          errors={errors}
          control={control}
          InputProps={{
            startAdornment: <InputAdornment position="start">https://</InputAdornment>,
          }}
        />
      </Grid>
    </Fragment>
  );
};
