import {Box, Divider, Typography} from '@mui/material';
import {BoxFlexed} from '../../../common';

export const LinkedTicketSearchItemHeader = () => (
  <Typography variant="body2" width="100%" fontSize={14} component="div">
    <BoxFlexed justifyContent="space-between" width="100%">
      <Box width="20%" pr={1} textAlign="center">
        DBA
      </Box>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Box width="20%" pr={1} textAlign="center">
        Legal Name
      </Box>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Box width="10%" pr={1} textAlign="center">
        Id
      </Box>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Box width="20%" pr={1} textAlign="center">
        Type
      </Box>
      <Divider orientation="vertical" flexItem variant="middle" />
      <Box width="30%" px={1} textAlign="center">
        Subject
      </Box>
    </BoxFlexed>
  </Typography>
);
