import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import {Box, Grid, InputAdornment, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {Theme} from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  EquipmentAdditionalTerminalsFees,
  EquipmentFeeCategory,
  EquipmentFees,
  EquipmentForScheduleABillingBrands,
  RiskLevel,
  ScheduleAEquipmentFeeDefinitions,
  ScheduleAEquipmentFees,
  ScheduleAEquipmentModel,
} from '@ozark/common';
import {camelCase, capitalize} from 'lodash';
import {Fragment, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useEquipment} from '../../hooks/useEquipment';
import {TextField} from '../TextField';
import {AddEquipmentFeeDialog} from './AddEquipmentFeeDialog';
import {AdjustEquipmentModelsDialog} from './AdjustEquipmentModelsDialog';
import {ScheduleAProps} from './types/ScheduleAProps';

const FeeAndEquipmentBrandMap: Record<
  EquipmentFees | EquipmentAdditionalTerminalsFees,
  EquipmentForScheduleABillingBrands
> = {
  dejavooEquipmentMonthlyFee: 'dejavoo',
  valorEquipmentMonthlyFee: 'valor',
  dejavooEquipmentMonthlyFeeAdditionalTerminals: 'dejavoo',
  valorEquipmentMonthlyFeeAdditionalTerminals: 'valor',
};

type Props = Pick<ScheduleAProps, 'readonly'> & {
  isAddEquipmentFeeDialogOpen: boolean;
  onAddEquipmentFeeDialogClose: () => void;
};

export const ScheduleAEquipment = ({
  readonly,
  isAddEquipmentFeeDialogOpen,
  onAddEquipmentFeeDialogClose,
}: Props) => {
  const classes = useStyles();
  const {setValue, unregister, watch, formState, control} = useFormContext();

  /* State of the main added fees (dejavooEquipmentMonthlyFee and/or valorEquipmentMonthlyFee) to the Schedule A */
  const [addedEquipmentFees, setAddedEquipmentFees] = useState<EquipmentFees[]>([]);
  /* Selected brand (Dejavoo/Valor) for adjusting models for charging fees */
  const [selectedEquipmentBrand, setSelectedEquipmentBrand] =
    useState<EquipmentForScheduleABillingBrands | null>(null);
  /* State of the dialog (opened/closed) for adjusting equipment models */
  const [isEquipmentModelsDialogOpen, setIsEquipmentModelsDialogOpen] = useState<boolean>(false);
  /* Lists of enabled Dejavoo and Valor models for charging fees */
  const [enabledForBillingEquipment, setEnabledForBillingEquipment] = useState<{
    [key in EquipmentForScheduleABillingBrands]: ScheduleAEquipmentModel[];
  }>({dejavoo: [], valor: []});

  const {equipment} = useEquipment();

  const formValues = watch();

  /* Define which main fees have been added to the Schedule A */
  useEffect(() => {
    const addedEquipmentFees: EquipmentFees[] = [];

    if (formValues?.riskModels?.low?.dejavooEquipmentMonthlyFee) {
      addedEquipmentFees.push('dejavooEquipmentMonthlyFee');
    }

    if (formValues?.riskModels?.low?.valorEquipmentMonthlyFee) {
      addedEquipmentFees.push('valorEquipmentMonthlyFee');
    }

    setAddedEquipmentFees(addedEquipmentFees);
  }, []);

  /* Initiate lists of enabled Dejavoo and Valor models for charging fees */
  useEffect(() => {
    const enabledForBillingEquipment: {
      [key in EquipmentForScheduleABillingBrands]: ScheduleAEquipmentModel[];
    } = {dejavoo: [], valor: []};

    for (const {id, name, isEnabledScheduleABilling} of equipment?.data || []) {
      const loweredCaseName = name.toLowerCase();
      if (isEnabledScheduleABilling) {
        if (loweredCaseName.includes('dejavoo')) {
          enabledForBillingEquipment.dejavoo.push({id, name});
        }

        if (loweredCaseName.includes('valor')) {
          enabledForBillingEquipment.valor.push({id, name});
        }
      }
    }

    setEnabledForBillingEquipment(enabledForBillingEquipment);
  }, [equipment]);

  const selectEquipmentFeeHandler = (selected: EquipmentFees[]) => {
    /* Add main fees (dejavooEquipmentMonthlyFee and/or valorEquipmentMonthlyFee) */
    for (const fee of selected) {
      if (!addedEquipmentFees.includes(fee)) {
        for (const riskLevel of Object.values(RiskLevel)) {
          const riskLevelKey = camelCase(riskLevel);
          setValue(
            `riskModels.${riskLevelKey as RiskLevel}.${fee}`,
            Number(ScheduleAEquipmentFeeDefinitions[fee]?.defaultValues[riskLevel]),
            {shouldDirty: true}
          );
        }
      }
    }

    /* Remove main fees (dejavooEquipmentMonthlyFee and/or valorEquipmentMonthlyFee).
     * Remove correspondent additional fees (dejavooEquipmentMonthlyFeeAdditionalTerminals and/or valorEquipmentMonthlyFeeAdditionalTerminals)
     * and correspondent equipment models if they were selected */
    for (const fee of addedEquipmentFees) {
      if (!selected.includes(fee)) {
        for (const riskLevel of Object.values(RiskLevel)) {
          const riskLevelKey = camelCase(riskLevel);
          const additionalTerminalsFee: EquipmentAdditionalTerminalsFees = `${fee}AdditionalTerminals`;
          const equipmentBrand: EquipmentForScheduleABillingBrands =
            FeeAndEquipmentBrandMap[additionalTerminalsFee];
          unregister(`riskModels.${riskLevelKey as RiskLevel}.${fee}`);
          unregister(`riskModels.${riskLevelKey as RiskLevel}.${additionalTerminalsFee}`);
          setValue(`equipmentModelsForBilling.${equipmentBrand}`, [], {shouldDirty: true});
        }
      }
    }

    setAddedEquipmentFees(selected);
  };

  /* Add additional fees (dejavooEquipmentMonthlyFeeAdditionalTerminals or valorEquipmentMonthlyFeeAdditionalTerminals) */
  const addAdditionalTerminalsHandler = (fee: EquipmentFees) => {
    for (const riskLevel of Object.values(RiskLevel)) {
      const riskLevelKey = camelCase(riskLevel);
      setValue(
        `riskModels.${riskLevelKey as RiskLevel}.${fee}AdditionalTerminals`,
        Number(
          ScheduleAEquipmentFeeDefinitions[`${fee}AdditionalTerminals`]?.defaultValues[riskLevel]
        ),
        {shouldDirty: true}
      );
    }
  };

  /* Remove additional fees (dejavooEquipmentMonthlyFeeAdditionalTerminals or valorEquipmentMonthlyFeeAdditionalTerminals)
   * and correspondent equipment models if they were selected */
  const removeAdditionalTerminalsHandler = (fee: EquipmentAdditionalTerminalsFees) => {
    for (const riskLevel of Object.values(RiskLevel)) {
      const riskLevelKey = camelCase(riskLevel);
      unregister(`riskModels.${riskLevelKey as RiskLevel}.${fee}`);
    }
  };

  /* Select a brand (Dejavoo or Valor) for selecting models for charging fees */
  const adjustEquipmentModelsButtonClickHandler = (
    equipmentBrand: EquipmentForScheduleABillingBrands
  ) => {
    setSelectedEquipmentBrand(equipmentBrand);
    setIsEquipmentModelsDialogOpen(true);
  };

  /* Update the state of selected equipment models for charging fees */
  const adjustEquipmentModelsHandler = (selected: ScheduleAEquipmentModel[]) => {
    setValue(`equipmentModelsForBilling.${selectedEquipmentBrand}`, selected, {shouldDirty: true});
  };

  /* Remove the main fee (dejavooEquipmentMonthlyFee or valorEquipmentMonthlyFee).
   * Remove the correspondent additional fee (dejavooEquipmentMonthlyFeeAdditionalTerminals or valorEquipmentMonthlyFeeAdditionalTerminals)
   * and correspondent equipment models if they were selected */
  const removeMainFeeHandler = (fee: EquipmentFees) => {
    const equipmentBrand: EquipmentForScheduleABillingBrands = FeeAndEquipmentBrandMap[fee];
    setValue(`equipmentModelsForBilling.${equipmentBrand}`, [], {shouldDirty: true});

    for (const riskLevel of Object.values(RiskLevel)) {
      const riskLevelKey = camelCase(riskLevel);
      const additionalTerminalsFee: EquipmentAdditionalTerminalsFees = `${fee}AdditionalTerminals`;
      unregister(`riskModels.${riskLevelKey as RiskLevel}.${fee}`);
      unregister(`riskModels.${riskLevelKey as RiskLevel}.${additionalTerminalsFee}`);
    }

    setAddedEquipmentFees((addedEquipmentFees: EquipmentFees[]) =>
      addedEquipmentFees.filter((f: EquipmentFees) => f !== fee)
    );
  };

  return (
    <>
      {addedEquipmentFees.length > 0 &&
        Object.keys(EquipmentFeeCategory).map((category: string) => {
          const riskFieldObject = EquipmentFeeCategory[category];
          const categoryFieldKeys = Object.keys(riskFieldObject);
          return (
            <Grid key={`category.${category}`} item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <div className={classes.categoryTitleWrapper}>
                    <Typography className={classes.categoryTitle} variant="body1" gutterBottom>
                      {category}
                    </Typography>
                  </div>
                </Grid>

                {categoryFieldKeys.map((fieldKey: string) => {
                  if (
                    formValues?.riskModels.low[fieldKey as ScheduleAEquipmentFees] === undefined
                  ) {
                    return null;
                  }

                  const fieldObject =
                    EquipmentFeeCategory[category][fieldKey as ScheduleAEquipmentFees];

                  const isMainFee =
                    !fieldKey.includes(
                      'AdditionalTerminals'
                    ); /* dejavooEquipmentMonthlyFee or valorEquipmentMonthlyFee */
                  const isAdditionalTerminalsFee =
                    fieldKey.includes(
                      'AdditionalTerminals'
                    ); /* dejavooEquipmentMonthlyFeeAdditionalTerminals or valorEquipmentMonthlyFeeAdditionalTerminals */

                  /* The correspondent additional fee is added */
                  const isAdditionalTerminalsAdded = Boolean(
                    formValues?.riskModels.low[
                      `${fieldKey}AdditionalTerminals` as EquipmentAdditionalTerminalsFees
                    ]
                  );

                  /* Show a button for adding the additional fee */
                  const canAddAdditionalTerminalsFee =
                    !readonly && isMainFee && !isAdditionalTerminalsAdded;
                  /* dejavoo or valor */
                  const equipmentBrand =
                    FeeAndEquipmentBrandMap[
                      fieldKey as EquipmentFees | EquipmentAdditionalTerminalsFees
                    ];
                  const selectedEquipmentModels =
                    formValues?.equipmentModelsForBilling?.[
                      equipmentBrand as 'dejavoo' | 'valor'
                    ] ?? [];
                  /* Show a button for removing the main fee */
                  const canRemoveMainFee = !readonly && isMainFee;
                  /* Show a button for removing the additional fee */
                  const canRemoveAdditionalTerminalsFee = !readonly && isAdditionalTerminalsFee;
                  const isThereEnabledEquipmentForBilling =
                    enabledForBillingEquipment[
                      FeeAndEquipmentBrandMap[
                        fieldKey as EquipmentFees | EquipmentAdditionalTerminalsFees
                      ]
                    ]?.length > 0;
                  /* Show a button for adjusting the state of models for charging fees */
                  const canAdjustEquipmentModelsList =
                    !readonly && isMainFee && isThereEnabledEquipmentForBilling;

                  return (
                    <Fragment key={fieldKey}>
                      {Object.values(RiskLevel).map((riskLevel: RiskLevel, index: number) => {
                        const riskLevelKey = camelCase(riskLevel);

                        return (
                          <Fragment key={`${fieldKey}-${riskLevel}`}>
                            {index === 0 && (
                              <Grid item xs={3} container>
                                <Grid item xs={8}>
                                  <Box display="flex" alignItems="center" height="100%">
                                    <Typography variant="body1">{fieldObject?.label}</Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={4}>
                                  <Box display="flex" alignItems="center" height="100%">
                                    {canAddAdditionalTerminalsFee && (
                                      <Tooltip title="+ Additional Terminals" placement="top">
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            addAdditionalTerminalsHandler(fieldKey as EquipmentFees)
                                          }
                                        >
                                          <AddIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {canRemoveAdditionalTerminalsFee && (
                                      <Tooltip title="Remove Additional Terminals" placement="top">
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            removeAdditionalTerminalsHandler(
                                              fieldKey as EquipmentAdditionalTerminalsFees
                                            )
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {canAdjustEquipmentModelsList && (
                                      <Tooltip title="Adjust Terminals" placement="top">
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            adjustEquipmentModelsButtonClickHandler(equipmentBrand)
                                          }
                                        >
                                          <PlaylistAddIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                    {canRemoveMainFee && (
                                      <Tooltip title="Remove Fee" placement="top">
                                        <IconButton
                                          color="primary"
                                          onClick={() =>
                                            removeMainFeeHandler(fieldKey as EquipmentFees)
                                          }
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </Box>
                                </Grid>
                              </Grid>
                            )}
                            <Grid item xs={3}>
                              <TextField
                                name={`riskModels.${riskLevelKey}.${fieldKey}`}
                                errors={formState.errors}
                                control={control}
                                type="number"
                                defaultValue={fieldObject?.defaultValues?.[riskLevel]}
                                disabled={readonly}
                                InputProps={{
                                  startAdornment:
                                    fieldObject?.type === '$' ? (
                                      <InputAdornment position="start">$</InputAdornment>
                                    ) : null,
                                  endAdornment:
                                    fieldObject?.type === '%' ? (
                                      <InputAdornment position="end">%</InputAdornment>
                                    ) : null,
                                }}
                              />
                            </Grid>
                          </Fragment>
                        );
                      })}
                      {(isAdditionalTerminalsFee || (isMainFee && !isAdditionalTerminalsAdded)) && (
                        <Grid container item xs={12}>
                          <Grid item xs={3} container>
                            <Grid item xs={9}>
                              <Box display="flex" alignItems="center" height="100%">
                                <Typography variant="body1">
                                  Selected {equipmentBrand && capitalize(equipmentBrand)} Models
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Grid container item xs={9}>
                            <Grid item xs={8}>
                              <Box display="flex" alignItems="center" height="100%">
                                <Typography variant="body1">
                                  {isThereEnabledEquipmentForBilling
                                    ? selectedEquipmentModels.length > 0
                                      ? selectedEquipmentModels
                                          .map((eq: ScheduleAEquipmentModel) => eq.name)
                                          .join(', ')
                                      : 'Not selected yet'
                                    : `There is no enabled ${
                                        equipmentBrand && capitalize(equipmentBrand)
                                      } models for billing`}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Fragment>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      {isAddEquipmentFeeDialogOpen && (
        <AddEquipmentFeeDialog
          selected={addedEquipmentFees}
          onClose={onAddEquipmentFeeDialogClose}
          onSelect={selectEquipmentFeeHandler}
        />
      )}
      {isEquipmentModelsDialogOpen && selectedEquipmentBrand && (
        <AdjustEquipmentModelsDialog
          selected={formValues?.equipmentModelsForBilling?.[selectedEquipmentBrand] ?? []}
          brand={selectedEquipmentBrand}
          options={enabledForBillingEquipment[selectedEquipmentBrand]}
          onClose={() => setIsEquipmentModelsDialogOpen(false)}
          onSelect={adjustEquipmentModelsHandler}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoryTitle: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(2, 0, 2),
      padding: theme.spacing(2, 1),
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      '& > *': {
        marginRight: theme.spacing(1),
      },
    },
    categoryTitleWrapper: {
      marginTop: theme.spacing(2),
    },
  })
);
