import {VDMPChargebackReport} from '@ozark/functions/src/functions/express/private/types/Reports';

export enum CBWarningType {
  standard = 'standard-warning',
  early = 'early-warning',
  none = 'none',
}

const createNewWarning = (maxCountValue: number, maxRatioValue: number) => {
  return {
    isCountLevelExceed(value: number | null) {
      if (value === null) {
        return false;
      }

      return value >= maxCountValue;
    },
    isRatioLevelExceed(value: number | null) {
      if (value === null) {
        return false;
      }

      return value >= maxRatioValue;
    },
  };
};

const STANDARD_WARNING_MAX_LEVEL_CHARGEBACK_COUNT = 100;
const STANDARD_WARNING_MAX_LEVEL_RATIO = 0.009; // 0.9%

const EARLY_WARNING_MAX_LEVEL_CHARGEBACK_COUNT = 75;
const EARLY_WARNING_MAX_LEVEL_RATIO = 0.0065; // 0.65%

const StandardWarning = createNewWarning(
  STANDARD_WARNING_MAX_LEVEL_CHARGEBACK_COUNT,
  STANDARD_WARNING_MAX_LEVEL_RATIO
);
const EarlyWarning = createNewWarning(
  EARLY_WARNING_MAX_LEVEL_CHARGEBACK_COUNT,
  EARLY_WARNING_MAX_LEVEL_RATIO
);

export type VDMPValue = {
  warning: CBWarningType | null;
  value: number | null;
};

export type VDMPChargebackFormattedReport = Pick<
  VDMPChargebackReport,
  'mid' | 'legalBusinessName' | 'agent' | 'mcc' | 'applicationId' | 'doingBusinessAs'
> & {
  rowWarning: CBWarningType[];
  visaChargebacksCount: VDMPValue[];
  visaChargebacksRatioByCount: VDMPValue[];
  visaChargebacksRatioByVolume: VDMPValue[];
  mcChargebacksCount: VDMPValue[];
  mcChargebacksRatioByCount: VDMPValue[];
  mcChargebacksRatioByVolume: VDMPValue[];
  rdrCount: VDMPValue[];
};

const getWarningOfCell = (
  isEarlyWarningExceed: boolean,
  isStandardWarningExceed: boolean
): CBWarningType | null => {
  if (isStandardWarningExceed) {
    return CBWarningType.standard;
  }

  if (isEarlyWarningExceed) {
    return CBWarningType.early;
  }

  return null;
};

export const getHighlightedReportRowData = (
  report: VDMPChargebackReport
): VDMPChargebackFormattedReport => {
  const {...otherData} = report;

  const formattedChargebacks = {
    rowWarning: new Array<CBWarningType>(),
    visaChargebacksCount: new Array<VDMPValue>(),
    visaChargebacksRatioByCount: new Array<VDMPValue>(),
    visaChargebacksRatioByVolume: new Array<VDMPValue>(),
    mcChargebacksCount: new Array<VDMPValue>(),
    mcChargebacksRatioByCount: new Array<VDMPValue>(),
    mcChargebacksRatioByVolume: new Array<VDMPValue>(),
    rdrCount: new Array<VDMPValue>(),
  };

  report.chargebacks.map(chargeback => {
    let rowWarning = null;

    const {
      visaChargebacksCount,
      visaChargebacksRatioByCount,
      visaChargebacksRatioByVolume,
      mcChargebacksCount,
      mcChargebacksRatioByCount,
      mcChargebacksRatioByVolume,
      rdrCount,
    } = chargeback;

    const visaCountEarlyWarningExceed = EarlyWarning.isCountLevelExceed(visaChargebacksCount);
    const visaCountStandardWarningExceed = StandardWarning.isCountLevelExceed(visaChargebacksCount);

    const visaVolumeEarlyWarningExceed = EarlyWarning.isRatioLevelExceed(
      visaChargebacksRatioByVolume
    );
    const visaVolumeStandardWarningExceed = StandardWarning.isRatioLevelExceed(
      visaChargebacksRatioByVolume
    );

    const visaRatioCountEarlyWarningExceed = EarlyWarning.isRatioLevelExceed(
      visaChargebacksRatioByCount
    );
    const visaRatioCountStandardWarningExceed = StandardWarning.isRatioLevelExceed(
      visaChargebacksRatioByCount
    );

    const mcCountEarlyWarningExceed = EarlyWarning.isCountLevelExceed(mcChargebacksCount);
    const mcCountStandardWarningExceed = StandardWarning.isCountLevelExceed(mcChargebacksCount);

    const mcVolumeEarlyWarningExceed = EarlyWarning.isRatioLevelExceed(mcChargebacksRatioByVolume);
    const mcVolumeStandardWarningExceed = StandardWarning.isRatioLevelExceed(
      mcChargebacksRatioByVolume
    );

    const mcRatioCountEarlyWarningExceed =
      EarlyWarning.isRatioLevelExceed(mcChargebacksRatioByCount);
    const mcRatioStandardWarningExceed =
      StandardWarning.isRatioLevelExceed(mcChargebacksRatioByCount);

    const visaEarlyWarningExceed =
      visaCountEarlyWarningExceed &&
      (visaVolumeEarlyWarningExceed || visaRatioCountEarlyWarningExceed);
    const mcEarlyWarningExceed =
      mcCountEarlyWarningExceed && (mcVolumeEarlyWarningExceed || mcRatioCountEarlyWarningExceed);
    const visaStandardWarningExceed =
      visaCountStandardWarningExceed &&
      (visaVolumeStandardWarningExceed || visaRatioCountStandardWarningExceed);
    const mcStandardWarningExceed =
      mcCountStandardWarningExceed &&
      (mcVolumeStandardWarningExceed || mcRatioStandardWarningExceed);

    if (visaEarlyWarningExceed || mcEarlyWarningExceed) {
      rowWarning = CBWarningType.early;
    }

    if (visaStandardWarningExceed || mcStandardWarningExceed) {
      rowWarning = CBWarningType.standard;
    }

    formattedChargebacks.rowWarning.push(rowWarning ?? CBWarningType.none);
    formattedChargebacks.visaChargebacksCount.push({
      value: visaChargebacksCount,
      warning: getWarningOfCell(visaCountEarlyWarningExceed, visaCountStandardWarningExceed),
    });
    formattedChargebacks.visaChargebacksRatioByCount.push({
      value: visaChargebacksRatioByCount,
      warning: getWarningOfCell(
        visaRatioCountEarlyWarningExceed,
        visaRatioCountStandardWarningExceed
      ),
    });
    formattedChargebacks.visaChargebacksRatioByVolume.push({
      value: visaChargebacksRatioByVolume,
      warning: getWarningOfCell(visaVolumeEarlyWarningExceed, visaVolumeStandardWarningExceed),
    });
    formattedChargebacks.mcChargebacksCount.push({
      value: mcChargebacksCount,
      warning: getWarningOfCell(mcCountEarlyWarningExceed, mcCountStandardWarningExceed),
    });
    formattedChargebacks.mcChargebacksRatioByCount.push({
      value: mcChargebacksRatioByCount,
      warning: getWarningOfCell(mcRatioCountEarlyWarningExceed, mcRatioStandardWarningExceed),
    });
    formattedChargebacks.mcChargebacksRatioByVolume.push({
      value: mcChargebacksRatioByVolume,
      warning: getWarningOfCell(mcVolumeEarlyWarningExceed, mcVolumeStandardWarningExceed),
    });
    formattedChargebacks.rdrCount.push({
      value: rdrCount,
      warning: null,
    });
  });

  return {
    ...otherData,
    ...formattedChargebacks,
  };
};
