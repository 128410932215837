export enum Platforms {
  chesapeakeTsys = 'Chesapeake Tsys',
  evolve5846 = 'Evolve 5846',
  evolve5848 = 'Evolve 5848',
  avidiaTsys = 'Avidia TSYS',
  avidiaFD = 'Avidia FD',
  cbsFD = 'CBC FD',
  metaPathly = 'Meta Bank',
}

export const ActivePlatforms = [Platforms.evolve5846];

export const manuallyBoardedPlatforms = [Platforms.metaPathly];

export type CommonNameKey =
  | 'gam'
  | 'evolve' // 5848
  | 'bancorp' // 5846
  | 'avidia'
  | 'avidia_fd'
  | 'cbs'
  | 'meta';

export const platformCommonNameKey: Record<Platforms, CommonNameKey> = {
  [Platforms.chesapeakeTsys]: 'gam',
  [Platforms.evolve5848]: 'evolve', // 5848
  [Platforms.evolve5846]: 'bancorp', // 5846
  [Platforms.avidiaTsys]: 'avidia',
  [Platforms.avidiaFD]: 'avidia_fd',
  [Platforms.cbsFD]: 'cbs',
  [Platforms.metaPathly]: 'meta',
};
