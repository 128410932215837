import {createContext, useCallback, useContext, useEffect, useState} from 'react';
import {useUserInfo} from '../../../hooks';
import {TFolder} from '../types';
import {useTicketsAllAgentAdminCount} from './useTicketsAllAgentAdmin';
import {useTicketsAllAgentMemberCount} from './useTicketsAllAgentMember';
import {useTicketsAllErpCount} from './useTicketsAllErp';
import {useTicketsAllMerchantCount} from './useTicketsAllMerchant';

interface RefreshContextType {
  refreshKey: number;
  refreshAll: () => void;
}

const TicketsCountRefreshContext = createContext<RefreshContextType>({
  refreshKey: 0,
  refreshAll: () => {},
});

export function TicketCountRefreshProvider({children}: {children: React.ReactNode}) {
  const [refreshKey, setRefreshKey] = useState(0);

  const refreshAll = useCallback(() => {
    setRefreshKey(prev => prev + 1);
  }, []);

  // refetch every 60 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshAll();
    }, 60000);
    return () => clearInterval(intervalId);
  }, [refreshAll]);

  // when window is focused
  useEffect(() => {
    const onFocus = () => {
      refreshAll();
    };
    window.addEventListener('focus', onFocus);
    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [refreshAll]);

  return (
    <TicketsCountRefreshContext.Provider value={{refreshKey, refreshAll}}>
      {children}
    </TicketsCountRefreshContext.Provider>
  );
}

export function useTicketsCountRefreshContext() {
  return useContext(TicketsCountRefreshContext);
}

export function useTicketCounters({folder}: {folder: TFolder}): {
  count: number;
  refetch: () => void;
} {
  const {isMerchant, isAgentAdmin, isAgentMember} = useUserInfo();

  const refreshContext = useTicketsCountRefreshContext();

  const {count, refetch} = folder.hasCounter
    ? isMerchant
      ? useTicketsAllMerchantCount({
          statusSet: folder.statusSet,
          filterKey: folder.filterKey,
          erpFilters: folder.erpFilters,
        })
      : isAgentAdmin
      ? useTicketsAllAgentAdminCount({
          statusSet: folder.statusSet,
          filterKey: folder.filterKey,
          erpFilters: folder.erpFilters,
        })
      : isAgentMember
      ? useTicketsAllAgentMemberCount({
          statusSet: folder.statusSet,
          filterKey: folder.filterKey,
          erpFilters: folder.erpFilters,
        })
      : useTicketsAllErpCount({
          statusSet: folder.statusSet,
          filterKey: folder.filterKey,
          erpFilters: folder.erpFilters,
        })
    : {count: 0, refetch: () => {}};

  useEffect(() => {
    refetch();
  }, [refreshContext.refreshKey, folder]);

  return {count, refetch: refreshContext.refreshAll};
}
