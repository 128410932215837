import {yupResolver} from '@hookform/resolvers/yup';
import {
  AccordionActions,
  Alert,
  Box,
  Button,
  CircularProgress,
  Fade,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Switch as MuiSwitch,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  copyWithoutRef,
  RiskLevel,
  ScheduleABase,
  ScheduleAView,
  SplitMethodType,
  useDefaultScheduleA,
  useNotification,
} from '@ozark/common';
import {ConfirmationDialog, Loading, TextField} from '@ozark/common/components';
import {AgentRole, FeeCategories} from '@ozark/functions/src/constants';
import camelcase from 'lodash/camelCase';
import startcase from 'lodash/startCase';
import {Fragment, useEffect, useState} from 'react';
import {FormProvider, useForm} from 'react-hook-form';
import {Switch} from '../Switch';
import {AgentCalculationMethod} from './AgentCalculationMethod';
import {CopyAgentScheduleADialog} from './CopyAgentScheduleADialog';
import {ScheduleAEquipment} from './ScheduleAEquipment';
import {ScheduleAProps} from './types/ScheduleAProps';
import {createScheduleASchema} from './utils/createScheduleASchema';

const fields = FeeCategories;

export type ScheduleALossCheckResult = {
  field: string;
  data: {
    [_ in RiskLevel]: boolean;
  };
};

export const ScheduleA = ({
  agent,
  document,
  parentScheduleAParams,
  set,
  setVisibility,
  applyDefaults = false,
  readonly = false,
  allowNegatives = false,
  riskTogglesEnabled = true,
  displayCalculationMethod = false,
  disableAgentCalculationMethod = false,
  canCopyParentScheduleA = false,
  canCopyGroupAgentsScheduleA = false,
  canCopySubAgentsScheduleA = false,
  canCopyAllAgentsScheduleA = false,
  canEnableSplitNegativeResiduals = false,
  canAddOptionalFees = false,
  canManageVisibility = false,
  displayedSplitFeatureEnabled = false,
  isERPUser = false,
  agentRole = undefined,
}: ScheduleAProps) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [isCopyDialogOpen, setIsCopyDialogOpen] = useState(false);
  const [isAddEquipmentFeesDialogOpen, setIsAddEquipmentFeesDialogOpen] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState<(() => Promise<void>) | null>(null);
  const [scheduleALossCheck, setScheduleALossCheck] = useState<{
    hasLoss: boolean;
    data?: ScheduleALossCheckResult[];
  }>({hasLoss: false});
  const [defaultValuesAlertInfo, setDefaultValuesAlertInfo] = useState<{
    display: boolean;
    text?: string;
  }>(
    !document && applyDefaults && !parentScheduleAParams
      ? {
          display: true,
          text: 'Default values have been applied. You must still save this schedule A by clicking the button below.',
        }
      : {display: false}
  );
  const {document: parentScheduleA} = useDefaultScheduleA(parentScheduleAParams);

  const formMethods = useForm<ScheduleAView>({
    resolver: yupResolver(createScheduleASchema(allowNegatives)) as any,
    context: {
      monthlyProcessingVolumePercent: {
        [RiskLevel.lowRisk]:
          parentScheduleA.data?.riskModels[RiskLevel.lowRisk]?.monthlyProcessingVolumePercent,
        [RiskLevel.mediumRisk]:
          parentScheduleA.data?.riskModels[RiskLevel.mediumRisk]?.monthlyProcessingVolumePercent,
        [RiskLevel.highRisk]:
          parentScheduleA.data?.riskModels[RiskLevel.highRisk]?.monthlyProcessingVolumePercent,
      },
    },
    defaultValues: {...copyWithoutRef(document)},
  });

  const {formState, reset, handleSubmit} = formMethods;
  const {isDirty} = formState;

  const canCopyAgentsScheduleA =
    canCopyGroupAgentsScheduleA || canCopySubAgentsScheduleA || canCopyAllAgentsScheduleA;

  const getDefaultScheduleATitle =
    parentScheduleAParams?.type === 'group' ? 'group' : 'master agent';
  const defaultParentAppliedMessage = `Default ${getDefaultScheduleATitle} schedule A values have been applied. You must still save this schedule A by clicking the button below.`;

  useEffect(() => {
    if (parentScheduleA.promised || document || !applyDefaults) return;

    // parent schedule A
    setDefaultValuesAlertInfo({
      display: true,
      text: parentScheduleA.data
        ? defaultParentAppliedMessage
        : `Cannot set default values. No ${getDefaultScheduleATitle} schedule A exists.`,
    });
  }, [document, parentScheduleA]);

  useEffect(() => {
    if (!document || !parentScheduleA?.data) return;

    const arrayOfFees = Object.values(fields)
      .map(e => Object.keys(e))
      .flat();
    const riskLevels = Object.values(RiskLevel);

    let hasPotentialLoss = false;
    const lossCheckData = arrayOfFees.map(feeKey => {
      const levelsCheck = {};

      /* Don't show a loss warning if the Monthly Processing Volume values are lower than parent's ones */
      if (
        feeKey === 'monthlyProcessingVolumePercent' ||
        feeKey === 'monthlyProcessingVolumePercentLabel'
      ) {
        return {
          field: feeKey,
          data: levelsCheck,
        } as ScheduleALossCheckResult;
      }

      riskLevels.forEach(riskLevel => {
        const defaultValue = (parentScheduleA.data?.riskModels as any)[riskLevel]?.[feeKey];
        const currentValue = (document.riskModels as any)[riskLevel]?.[feeKey];
        const hasLoss = currentValue < defaultValue;
        if (hasLoss) {
          hasPotentialLoss = true;
        }
        (levelsCheck as any)[riskLevel] = hasLoss;
      });

      return {
        field: feeKey,
        data: levelsCheck,
      } as ScheduleALossCheckResult;
    });

    const scheduleALossCheckResult = {
      hasLoss: hasPotentialLoss,
      data: lossCheckData,
    };

    setScheduleALossCheck(scheduleALossCheckResult);
  }, [document, parentScheduleA]);

  const showNotification = useNotification();

  const onError = (data: any) => {
    console.error('form errors', data);
  };

  const onSuccess = async ({
    splitMethod,
    splitNegativeResiduals,
    riskModels,
    equipmentModelsForBilling,
  }: ScheduleABase) => {
    setLoading(true);
    try {
      /**
       * Replace null values with Firebase.FieldValue.delete() to remove them from the document.
       * This only should apply to labels, or properties that end with 'Label'.
       * Commented out for now, as it's not needed yet.
       */
      // const updatedRiskModels = Object.fromEntries(
      //   Object.entries(riskModels).map(([riskLevel, model]) => [
      //     riskLevel,
      //     Object.fromEntries(
      //       Object.entries(model).map(([key, value]) => [
      //         key,
      //         key.endsWith("Label") && value === null ? Firebase.FieldValue.delete() : value,
      //       ])
      //     ),
      //   ])
      // ) as ScheduleABase["riskModels"];

      await set({splitMethod, splitNegativeResiduals, riskModels, equipmentModelsForBilling});

      setDefaultValuesAlertInfo({
        display: false,
      });
      reset({splitMethod, splitNegativeResiduals, riskModels, equipmentModelsForBilling});
      showNotification('success', 'Schedule A successfully updated.');
    } catch (err) {
      showNotification('error', 'Failed to update.');
    } finally {
      setLoading(false);
    }
  };

  const handleCopyScheduleA = async () => {
    reset({...parentScheduleA.data});
    setDefaultValuesAlertInfo({
      display: true,
      text: defaultParentAppliedMessage,
    });
    // we can't have the potential loss after copying the parent schedule A
    setScheduleALossCheck({
      hasLoss: false,
    });
  };

  const watch = {
    'riskModels.low.monthlyProcessingVolumePercent': formMethods.watch(
      'riskModels.low.monthlyProcessingVolumePercent'
    ) as number,
    'riskModels.medium.monthlyProcessingVolumePercent': formMethods.watch(
      'riskModels.medium.monthlyProcessingVolumePercent'
    ) as number,
    'riskModels.high.monthlyProcessingVolumePercent': formMethods.watch(
      'riskModels.high.monthlyProcessingVolumePercent'
    ) as number,
  } as any;

  const handleToggleRiskChange =
    (riskLevel: RiskLevel) => (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      const value = (checked ? 0 : -1) as any;
      formMethods.setValue(`riskModels.${riskLevel}.monthlyProcessingVolumePercent`, value, {
        shouldDirty: true,
      });
    };

  if (parentScheduleAParams && parentScheduleA.promised) {
    return <Loading />;
  }

  return (
    <FormProvider {...formMethods}>
      <Paper className={classes.paper}>
        <Grid container spacing={2}>
          {(canManageVisibility || canCopyParentScheduleA || canCopyAgentsScheduleA) && (
            <Grid item xs={12} className={classes.actionBar}>
              <>
                {canManageVisibility && (
                  <Button
                    id="manageVisibilityButton"
                    name="manageVisibility"
                    onClick={() => setVisibility?.(!agent?.isScheduleAVisible)}
                    variant="outlined"
                    color="secondary"
                    disabled={readonly}
                  >
                    {agent?.isScheduleAVisible
                      ? 'Hide from the Agent'
                      : 'Make Visible for the Agent'}
                  </Button>
                )}
                {canCopyAgentsScheduleA && (
                  <Button
                    id="copyAgentScheduleAButton"
                    name="copyAgentScheduleA"
                    onClick={() => setIsCopyDialogOpen(true)}
                    variant="outlined"
                    color="secondary"
                    disabled={readonly}
                  >
                    Copy Agent Schedule A
                  </Button>
                )}
                {canCopyParentScheduleA && parentScheduleA.data && (
                  <Button
                    id="copyScheduleAButton"
                    name="copyScheduleA"
                    onClick={() => {
                      setConfirmationAction(() => handleCopyScheduleA);
                    }}
                    variant="outlined"
                    color="secondary"
                    disabled={readonly}
                  >
                    {`Copy ${getDefaultScheduleATitle} Schedule A`}
                  </Button>
                )}
              </>
            </Grid>
          )}
          {displayCalculationMethod && (
            <Grid item xs={12}>
              <AgentCalculationMethod
                errors={formMethods.formState.errors}
                control={formMethods.control}
                disabled={
                  disableAgentCalculationMethod ||
                  (parentScheduleAParams?.type === 'agent' &&
                    parentScheduleA.data?.splitMethod !== SplitMethodType.direct)
                }
                defaultValue={parentScheduleA?.data?.splitMethod}
              />
            </Grid>
          )}
          {canEnableSplitNegativeResiduals && (
            <Grid item xs={12}>
              <List>
                <ListItem sx={{paddingLeft: 1}}>
                  <ListItemText
                    primary="Split Negative Residuals"
                    secondary="Enabling this will split negative residuals according to the split percentages."
                  />
                  <ListItemSecondaryAction sx={{right: -8}}>
                    <Switch name="splitNegativeResiduals" control={formMethods.control} />
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          )}
          {scheduleALossCheck.hasLoss && (
            <>
              <Grid item xs={12}>
                <Alert severity="warning">
                  {`Schedule A fee value is less than the parent. To avoid potential loss copy the ${getDefaultScheduleATitle} schedule A.`}
                </Alert>
              </Grid>
            </>
          )}
          {!agent?.isScheduleAVisible && (
            <>
              <Grid item xs={12}>
                <Alert severity="info">
                  Schedule A is hidden from the Agent. To make it visible click the button above.
                </Alert>
              </Grid>
            </>
          )}
          {defaultValuesAlertInfo.display && (
            <>
              <Grid item xs={12}>
                <Alert severity="info">{defaultValuesAlertInfo.text}</Alert>
              </Grid>
            </>
          )}
          <Grid item xs={3}>
            <Box>
              <Typography variant="caption">Current Version: {document?.version}</Typography>
            </Box>
          </Grid>
          {Object.values(RiskLevel).map(riskLevel => (
            <Grid key={`riskLevel.${riskLevel}`} item xs={3}>
              <Box pt={1}>
                <Typography align="center" variant="h6">
                  {startcase(riskLevel)} Risk{' '}
                  {riskTogglesEnabled && (
                    <MuiSwitch
                      checked={watch[`riskModels.${riskLevel}.monthlyProcessingVolumePercent`] >= 0}
                      onChange={handleToggleRiskChange(riskLevel)}
                      color="primary"
                      disabled={readonly}
                      inputProps={{'aria-label': 'primary checkbox'}}
                    />
                  )}
                </Typography>
              </Box>
            </Grid>
          ))}
          {Object.keys(fields).map((category: string) => {
            const riskFieldObject = (fields as any)[category];
            const categoryFieldKeys = Object.keys(riskFieldObject);
            return (
              <Grid key={`category.${category}`} item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <div className={classes.categoryTitleWrapper}>
                      <Typography className={classes.categoryTitle} variant="body1" gutterBottom>
                        {category}
                      </Typography>
                    </div>
                  </Grid>

                  {categoryFieldKeys
                    .map(fieldKey => {
                      const fieldObject = (fields as any)[category][fieldKey];

                      let fieldKeyToUse = fieldKey;
                      let labelToUse = fieldObject.label;

                      const isNonLabelField = !!fieldObject.labelTo;
                      const isLabelField = !!fieldObject.labelFor;

                      /**
                       * ERP User -
                       *   Monthly Processing Volume showing actual value
                       *   Displayed showing the actual value
                       *
                       * Agent - feature disabled - user is an admin
                       *   "Monthly Processing Volume (Calulated)" showing the displayed split value
                       *   Displayed is hidden
                       *   ** ISSUE WITH ADMINS SAVING THE FIELD WHEN IT'S DISPLAY VALUE
                       *
                       * Agent - feature disabled - user is a member
                       *   "Monthly Processing Volume (Calulated)" showing the displayed split value
                       *   Displayed is hidden
                       *
                       * Agent - feature enabled - user is a member
                       *   "Monthly Processing Volume (Calulated)" showing the displayed split value
                       *   Displayed is hidden
                       *
                       * Agent - feature enabled - user is an admin
                       *   "Monthly Processing Volume (Calulated)" showing the actual value
                       *    Displayed is showing the actual value
                       *
                       * Agent - feature enabled - user is a sub agent
                       *   "Monthly Processing Volume (Calulated)" showing the actual value (read only)
                       *   Displayed is hidden
                       */

                      if (isERPUser) {
                        fieldKeyToUse = fieldKey;
                        labelToUse = isNonLabelField
                          ? `${fieldObject.label} (Calculated)`
                          : fieldObject.label;
                      } else {
                        /**
                         * Agents
                         */
                        /**
                         * Feature Enabled
                         */
                        if (displayedSplitFeatureEnabled) {
                          if (agentRole === AgentRole.admin) {
                            labelToUse = isNonLabelField
                              ? `${fieldObject.label} (Calculated)`
                              : fieldObject.label;
                            fieldKeyToUse = fieldKey;
                          } else if (agentRole === AgentRole.member) {
                            if (agent?.masterUid) {
                              if (isLabelField) {
                                return null;
                              }
                              labelToUse = fieldObject.label;
                              fieldKeyToUse = fieldKey;
                            } else {
                              if (isNonLabelField) {
                                labelToUse = fieldObject.label;
                                fieldKeyToUse = fieldObject.labelTo;
                              } else if (isLabelField) {
                                return null;
                              } else {
                                labelToUse = fieldObject.label;
                                fieldKeyToUse = fieldKey;
                              }
                            }
                          }
                        } else {
                          /**
                           * Feature Disabled
                           */
                          if (isNonLabelField) {
                            labelToUse = fieldObject.label;
                            fieldKeyToUse = fieldKey;
                          } else if (isLabelField) {
                            return null;
                          } else {
                            labelToUse = fieldObject.label;
                            fieldKeyToUse = fieldKey;
                          }
                        }
                      }

                      return Object.values(RiskLevel).map((riskLevel, index) => {
                        const riskLevelKey = camelcase(riskLevel); // Low / Medium / High
                        const isRiskEnabled =
                          watch[`riskModels.${riskLevel}.monthlyProcessingVolumePercent`] >= 0;
                        const isLabel = fieldKey.endsWith('Label');
                        const resolvedLabelDefaultValue =
                          watch[`riskModels.${riskLevel}.${fieldObject?.labelFor}`] || '';

                        return (
                          <Fragment key={`${fieldKey}-${riskLevel}`}>
                            {index === 0 && (
                              <Grid item xs={3}>
                                <Box display="flex" alignItems="center" height="100%">
                                  <Typography variant="body1">{labelToUse}</Typography>
                                </Box>
                              </Grid>
                            )}
                            <Grid item xs={3}>
                              <TextField
                                name={`riskModels.${riskLevelKey}.${fieldKeyToUse}`}
                                errors={formMethods.formState.errors}
                                control={formMethods.control}
                                type="number"
                                placeholder={(isLabel && resolvedLabelDefaultValue) ?? undefined}
                                defaultValue={
                                  isLabel
                                    ? resolvedLabelDefaultValue // Label default value is empty
                                    : !document &&
                                      applyDefaults &&
                                      (parentScheduleA.data
                                        ? // display parent schedule A if exist
                                          (parentScheduleA.data?.riskModels as any)[riskLevel]?.[
                                            fieldKey
                                          ]
                                        : !parentScheduleAParams
                                        ? // display hardcoded fees if parent schedule A params are not exist
                                          fieldObject?.defaultValues?.[riskLevel]
                                        : false)
                                }
                                disabled={readonly || !isRiskEnabled}
                                style={{display: !isRiskEnabled ? 'none' : 'inherit'}}
                                InputProps={{
                                  startAdornment:
                                    fieldObject.type === '$' ? (
                                      <InputAdornment position="start">$</InputAdornment>
                                    ) : null,
                                  endAdornment:
                                    fieldObject.type === '%' ? (
                                      <InputAdornment position="end">%</InputAdornment>
                                    ) : null,
                                  classes: {
                                    notchedOutline:
                                      scheduleALossCheck.hasLoss &&
                                      scheduleALossCheck.data?.find(x => x.field === fieldKey)
                                        ?.data[riskLevel] === true
                                        ? classes.potentialLoss
                                        : '',
                                  },
                                }}
                              />
                            </Grid>
                          </Fragment>
                        );
                      });
                    })
                    .filter(e => e !== null)}
                </Grid>
              </Grid>
            );
          })}
          <ScheduleAEquipment
            readonly={readonly}
            isAddEquipmentFeeDialogOpen={isAddEquipmentFeesDialogOpen}
            onAddEquipmentFeeDialogClose={() => setIsAddEquipmentFeesDialogOpen(false)}
          />
        </Grid>
        {!readonly && (
          <Fade
            in={canAddOptionalFees || isDirty || defaultValuesAlertInfo.display}
            unmountOnExit={false}
          >
            <AccordionActions classes={{root: classes.buttonsWrapper}}>
              <>
                <Box mt={2}>
                  {document && canAddOptionalFees && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => setIsAddEquipmentFeesDialogOpen(true)}
                    >
                      Add Schedule A Line Item
                    </Button>
                  )}
                </Box>
                <Box mt={2}>
                  {(isDirty || defaultValuesAlertInfo.display) && (
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={handleSubmit(onSuccess, onError)}
                      disabled={loading}
                    >
                      {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
                      Save Changes
                    </Button>
                  )}
                </Box>
              </>
            </AccordionActions>
          </Fade>
        )}
        <ConfirmationDialog
          title="Confirmation"
          maxWidth="sm"
          message={`Are you sure you want to copy the ${getDefaultScheduleATitle} schedule A?`}
          onClose={() => setConfirmationAction(null)}
          onConfirm={confirmationAction}
        />
        {canCopyAgentsScheduleA && agent && (
          <CopyAgentScheduleADialog
            agent={agent}
            canCopySubAgentsScheduleA={canCopySubAgentsScheduleA}
            canCopyGroupAgentsScheduleA={canCopyGroupAgentsScheduleA}
            canCopyAllAgentsScheduleA={canCopyAllAgentsScheduleA}
            open={isCopyDialogOpen}
            onSelect={onSuccess}
            onClose={() => setIsCopyDialogOpen(false)}
          />
        )}
      </Paper>
    </FormProvider>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(3, 2, 2, 2),
    },
    potentialLoss: {
      borderColor: 'red',
    },
    categoryTitle: {
      display: 'flex',
      alignItems: 'center',
      margin: theme.spacing(2, 0, 2),
      padding: theme.spacing(2, 1),
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      '& > *': {
        marginRight: theme.spacing(1),
      },
    },
    categoryTitleWrapper: {
      marginTop: theme.spacing(2),
    },
    actionBar: {
      display: 'flex',
      justifyContent: 'flex-end',
      gap: '15px',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    buttonsWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  })
);
