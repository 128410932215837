import {SVGProps} from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width="1em"
    fill="currentColor"
    height="1em"
    viewBox="0 0 490.646 490.646"
    {...props}
  >
    <path d="M245.216 224.096c-5.888 0-10.667 4.779-10.667 10.667v85.333c0 5.888 4.779 10.667 10.667 10.667s10.667-4.779 10.667-10.667v-85.333c0-5.888-4.779-10.667-10.667-10.667zM216.971 224.779c-5.504-2.048-11.669.704-13.739 6.229l-22.016 58.709L159.2 231.03c-2.069-5.504-8.256-8.256-13.739-6.229-5.525 2.069-8.32 8.213-6.229 13.739l32 85.333a10.663 10.663 0 0 0 9.984 6.912c4.437 0 8.427-2.752 9.984-6.912l32-85.333a10.662 10.662 0 0 0-6.229-13.761zM287.883 224.096c-5.888 0-10.667 4.779-10.667 10.667v85.333c0 5.888 4.779 10.667 10.667 10.667s10.667-4.779 10.667-10.667v-32h10.667c17.643 0 32-14.357 32-32s-14.357-32-32-32h-21.334zm32 32c0 5.888-4.8 10.667-10.667 10.667H298.55V245.43h10.667c5.866 0 10.666 4.778 10.666 10.666z" />
    <path d="M490.08 188.555a10.692 10.692 0 0 0-8.704-7.147l-154.496-20.48-72.021-144.384c-1.792-3.605-5.504-5.888-9.536-5.888s-7.723 2.304-9.536 5.909l-72.043 144.363L9.27 181.408c-4.011.533-7.381 3.307-8.704 7.147a10.656 10.656 0 0 0 2.56 10.965l113.301 113.301-30.869 154.39a10.73 10.73 0 0 0 3.989 10.581 10.643 10.643 0 0 0 11.243 1.067l144.555-72.277L389.9 478.859c1.536.747 3.157 1.131 4.779 1.131 2.283 0 4.587-.747 6.421-2.197a10.702 10.702 0 0 0 3.989-10.581L374.22 312.823l113.3-113.303c2.88-2.858 3.862-7.125 2.56-10.965zM355.147 301.771c-2.517 2.539-3.627 6.144-2.923 9.643l27.733 138.624-129.834-64.918c-1.515-.747-3.157-1.131-4.779-1.131s-3.264.363-4.779 1.131L110.73 450.037l27.733-138.624a10.68 10.68 0 0 0-2.923-9.643L33.483 199.712l138.688-18.389a10.68 10.68 0 0 0 8.149-5.803l65.024-130.304 65.003 130.304a10.585 10.585 0 0 0 8.149 5.803l138.709 18.389-102.058 102.059z" />
  </svg>
);
export {SvgComponent as VipIcon};
