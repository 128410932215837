import AllInboxIcon from '@mui/icons-material/AllInbox';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EmailIcon from '@mui/icons-material/Email';
import FolderDeleteIcon from '@mui/icons-material/FolderDelete';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ReplyIcon from '@mui/icons-material/Reply';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import Update from '@mui/icons-material/Update';
import {where} from 'firebase/firestore';
import {ERPUserRoles, TicketStatus, TicketStatusName, UserRoles} from '../../..';
import {FilterKey, TFolder, TicketsFolderId} from '../types';

export const ticketStatusFolders: TFolder[] = [
  {
    id: TicketsFolderId.All,
    statusSet: [TicketStatus.Open, TicketStatus.InProgress, TicketStatus.Replied],
    folderName: 'All Active',
    folderTipTitle: 'New and In Progress',
    folderTipText: 'New and In Progress tickets',
    icon: AllInboxIcon,
    hasCounter: true,
  },
  {
    id: TicketsFolderId.Open,
    statusSet: [TicketStatus.Open],
    folderName: TicketStatusName[TicketStatus.Open],
    folderTipTitle: 'New: (Requires Attention)',
    folderTipText:
      "Indicates that no action has been taken on the ticket. Once a New ticket's status has been changed, it can never be set back to New.",
    icon: EmailIcon,
    hasCounter: true,
  },
  {
    id: TicketsFolderId.Draft,
    statusSet: [TicketStatus.Draft],
    folderName: TicketStatusName[TicketStatus.Draft],
    folderTipTitle: 'Draft',
    folderTipText: 'A ticket being prepared for publication',
    icon: DriveFileRenameOutlineIcon, //DraftsIcon,
    italic: true,
    hasCounter: true,
  },
  {
    id: TicketsFolderId.Replied,
    statusSet: [TicketStatus.Replied],
    folderName: TicketStatusName[TicketStatus.Replied],
    folderTipTitle: 'Replied',
    folderTipText: 'A ticket with reply',
    icon: ReplyIcon, //DraftsIcon,
    hasCounter: true,
  },
  {
    id: TicketsFolderId.InProgress,
    statusSet: [TicketStatus.InProgress],
    folderName: TicketStatusName[TicketStatus.InProgress],
    folderTipTitle: 'In Progress: (Under Review)',
    folderTipText:
      'Indicates a ticket has been assigned to a user and is in progress. It is waiting for action by the user, When the assigned rep reads and begins working on the ticket they should update the ticket to this status.',
    icon: RotateRightIcon,
    hasCounter: true,
  },
  {
    id: TicketsFolderId.InProgress24,
    statusSet: [TicketStatus.InProgress],
    folderName: '> 24hrs',
    folderTipTitle: 'In Progress more than 24 hours: (Under Review)',
    folderTipText: 'In Progress more than 24 hours: (Under Review)',
    icon: Update,
    indent: true,
    filterKey: FilterKey.openedMoreThan24hAgo,
    hasCounter: true,
  },
  {
    id: TicketsFolderId.Audit,
    statusSet: [TicketStatus.Closed], // Audit flags are available after ticket become Resolved
    erpFilters: {
      whereCount: where('audit', '==', true),
      whereQuery: q => q.where('audit', '==', true),
    },
    folderName: 'Audit',
    folderTipTitle: 'Audit',
    folderTipText: 'Audit',
    icon: RotateRightIcon,
    hasCounter: true,
    getIsVisible: (userRole?: UserRoles) => !!userRole && ERPUserRoles.includes(userRole),
  },
  {
    id: TicketsFolderId.Audit24,
    statusSet: [TicketStatus.Closed], // Audit flags are available after ticket become Resolved
    erpFilters: {
      whereCount: where('audit', '==', true),
      whereQuery: q => q.where('audit', '==', true),
    },
    folderName: '> 24hrs',
    folderTipTitle: 'In Audit more than 24 hours',
    folderTipText: 'In Audit more than 24 hours',
    icon: Update,
    indent: true,
    filterKey: FilterKey.auditStartedMoreThan24hAgo,
    hasCounter: true,
    getIsVisible: (userRole?: UserRoles) => !!userRole && ERPUserRoles.includes(userRole),
  },
  {
    id: TicketsFolderId.BillingAudit,
    statusSet: [TicketStatus.Closed], // Audit flags are available after ticket become Resolved
    erpFilters: {
      whereCount: where('billingAudit', '==', true),
      whereQuery: q => q.where('billingAudit', '==', true),
    },
    folderName: 'Billing Audit',
    folderTipTitle: 'Billing Audit',
    folderTipText: 'Billing Audit',
    icon: Update,
    hasCounter: true,
    getIsVisible: (userRole?: UserRoles) => !!userRole && ERPUserRoles.includes(userRole),
  },
  {
    id: TicketsFolderId.BillingAudit24,
    statusSet: [TicketStatus.Closed], // Audit flags are available after ticket become Resolved
    erpFilters: {
      whereCount: where('billingAudit', '==', true),
      whereQuery: q => q.where('billingAudit', '==', true),
    },
    folderName: '> 24hrs',
    folderTipTitle: 'In Billing Audit more than 24 hours',
    folderTipText: 'In Billing Audit more than 24 hours',
    icon: Update,
    indent: true,
    filterKey: FilterKey.billingAuditStartedMoreThan24hAgo,
    hasCounter: true,
    getIsVisible: (userRole?: UserRoles) => !!userRole && ERPUserRoles.includes(userRole),
  },
  {
    id: TicketsFolderId.Closed,
    statusSet: [TicketStatus.Closed],
    folderName: TicketStatusName[TicketStatus.Closed],
    folderTipTitle: 'Resolved: (Completed)',
    folderTipText:
      'Indicates the user has submitted a solution. Once the ticket has been resolved, the user who opened the ticket will have access to see the resolution notes and will receive a notification in their portal',
    icon: CheckCircleIcon, //CheckCircleOutlineIcon, //AssignmentTurnedInIcon,
    hasCounter: false,
  },
  {
    id: TicketsFolderId.Unresolved,
    statusSet: [TicketStatus.Unresolved],
    folderName: TicketStatusName[TicketStatus.Unresolved],
    folderTipTitle: 'Unresolved: (Unable to Complete)',
    folderTipText: 'Indicates the user was unable to complete the ticket',
    icon: HighlightOffIcon, //CancelIcon, //DoNotDisturbIcon,
    hasCounter: false,
  },
  {
    id: TicketsFolderId.Deleted,
    statusSet: [TicketStatus.Deleted],
    folderName: TicketStatusName[TicketStatus.Deleted],
    folderTipTitle: 'Deleted',
    folderTipText: 'Deleted',
    icon: FolderDeleteIcon,
    hasDivider: true,
    hasCounter: false,
  },
];
