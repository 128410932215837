import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {CardTypeMap} from '@ozark/functions/src/constants/CardTypeMap';
import {useMemo} from 'react';
import {CardBrand, CardVolumeSummary} from '../../..';
import {CardImage, CardImagesDictionaryType, DebitCardImage} from '../../CardImage';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

const numberFormatter = new Intl.NumberFormat('en-US');

type Props = {summaryByCardType: CardVolumeSummary[]};

const getAmountFormatted = (num?: string | number) => formatter.format(Number(num ?? 0));
const getNumFormatted = (num?: string | number) => numberFormatter.format(Number(num ?? 0));
const getNum = (num?: string | number) => Number(num ?? 0);

export const VolumeSummaryByCardTypeTable = ({summaryByCardType}: Props) => {
  const {totals, bankTotals} = useMemo(() => {
    const totals = {
      totalSalesCount: 0,
      totalSalesAmount: 0,
      totalCreditCount: 0,
      totalCreditAmount: 0,
      totalCbCount: 0,
      totalCbVolume: 0,
      totalRdrCount: 0,
      totalRdrVolume: 0,
    };
    const bankTotals = {...totals};

    const bankCardTypes = [
      ...CardTypeMap[CardBrand.visa],
      ...CardTypeMap[CardBrand.mastercard],
      ...CardTypeMap[CardBrand.discover],
    ];

    summaryByCardType.forEach(v => {
      totals.totalSalesCount += getNum(v.salesCount);
      totals.totalSalesAmount += getNum(v.salesAmount);
      totals.totalCreditCount += getNum(v.creditCount);
      totals.totalCreditAmount += getNum(v.creditAmount);
      totals.totalCbCount += getNum(v.cbCount);
      totals.totalCbVolume += getNum(v.cbVolume);
      totals.totalRdrCount += getNum(v.rdrCount);
      totals.totalRdrVolume += getNum(v.rdrVolume);

      if (bankCardTypes.includes(v.cardType)) {
        bankTotals.totalSalesCount += getNum(v.salesCount);
        bankTotals.totalSalesAmount += getNum(v.salesAmount);
        bankTotals.totalCreditCount += getNum(v.creditCount);
        bankTotals.totalCreditAmount += getNum(v.creditAmount);
        bankTotals.totalCbCount += getNum(v.cbCount);
        bankTotals.totalCbVolume += getNum(v.cbVolume);
        bankTotals.totalRdrCount += getNum(v.rdrCount);
        bankTotals.totalRdrVolume += getNum(v.rdrVolume);
      }
    });

    return {totals, bankTotals};
  }, [summaryByCardType]);

  return (
    <TableContainer>
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell align="right">Sales Count</TableCell>
            <TableCell align="right">Sales Amount</TableCell>
            <TableCell align="right">Average Ticket</TableCell>
            <TableCell align="right">Credit Count</TableCell>
            <TableCell align="right">Credit Amount</TableCell>
            <TableCell align="right">Chargeback Count</TableCell>
            <TableCell align="right">Chargeback Volume</TableCell>
            <TableCell align="right">RDR Count</TableCell>
            <TableCell align="right">RDR Volume</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {summaryByCardType.map(row => (
            <TableRow key={row.cardType}>
              <TableCell component="th" scope="row">
                <Box width={52} mr={2}>
                  {row.cardType === 'DB' ? (
                    <DebitCardImage />
                  ) : (
                    <CardImage
                      cardType={row.cardType}
                      dictionaryType={CardImagesDictionaryType.Transactions}
                    />
                  )}
                </Box>
              </TableCell>
              <TableCell align="right">{getNumFormatted(row.salesCount)}</TableCell>
              <TableCell align="right">{getAmountFormatted(row.salesAmount)}</TableCell>
              <TableCell align="right">{getAmountFormatted(row.averageTicket)}</TableCell>
              <TableCell align="right">{getNumFormatted(row.creditCount)}</TableCell>
              <TableCell align="right">{getAmountFormatted(row.creditAmount)}</TableCell>
              <TableCell align="right">{getNumFormatted(row.cbCount)}</TableCell>
              <TableCell align="right">{getAmountFormatted(row.cbVolume)}</TableCell>
              <TableCell align="right">{getNumFormatted(row.rdrCount)}</TableCell>
              <TableCell align="right">{getAmountFormatted(row.rdrVolume)}</TableCell>
            </TableRow>
          ))}
          <TableRow key="total">
            <TableCell component="th" scope="row">
              <Typography variant="h6">Total</Typography>
            </TableCell>
            <TableCell align="right">{getNumFormatted(totals.totalSalesCount)}</TableCell>
            <TableCell align="right">{getAmountFormatted(totals.totalSalesAmount)}</TableCell>
            <TableCell align="right">
              {getAmountFormatted(
                totals.totalSalesCount > 0 ? totals.totalSalesAmount / totals.totalSalesCount : 0
              )}
            </TableCell>
            <TableCell align="right">{getNumFormatted(totals.totalCreditCount)}</TableCell>
            <TableCell align="right">{getAmountFormatted(totals.totalCreditAmount)}</TableCell>
            <TableCell align="right">{getNumFormatted(totals.totalCbCount)}</TableCell>
            <TableCell align="right">{getAmountFormatted(totals.totalCbVolume)}</TableCell>
            <TableCell align="right">{getNumFormatted(totals.totalRdrCount)}</TableCell>
            <TableCell align="right">{getAmountFormatted(totals.totalRdrVolume)}</TableCell>
          </TableRow>
          <TableRow key="bankTotal">
            <TableCell component="th" scope="row">
              <Typography variant="h6">Bank Card Total</Typography>
            </TableCell>
            <TableCell align="right">{getNumFormatted(bankTotals.totalSalesCount)}</TableCell>
            <TableCell align="right">{getAmountFormatted(bankTotals.totalSalesAmount)}</TableCell>
            <TableCell align="right">
              {getAmountFormatted(
                bankTotals.totalSalesCount > 0
                  ? totals.totalSalesAmount / totals.totalSalesCount
                  : 0
              )}
            </TableCell>
            <TableCell align="right">{getNumFormatted(bankTotals.totalCreditCount)}</TableCell>
            <TableCell align="right">{getAmountFormatted(bankTotals.totalCreditAmount)}</TableCell>
            <TableCell align="right">{getNumFormatted(bankTotals.totalCbCount)}</TableCell>
            <TableCell align="right">{getAmountFormatted(bankTotals.totalCbVolume)}</TableCell>
            <TableCell align="right">{getNumFormatted(bankTotals.totalRdrCount)}</TableCell>
            <TableCell align="right">{getAmountFormatted(bankTotals.totalRdrVolume)}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
