import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Switch, {SwitchProps} from '@mui/material/Switch';
import {
  AUTOMATION_TICKET_COMMENT_AUTHOR,
  AUTOMATION_TICKET_COMMENT_ROLE,
  AUTOMATION_TICKET_COMMENT_UID,
  camelCaseToTitleCase,
  Collections,
  Firebase,
  Ticket,
  TicketHistoryRecordType,
} from '@ozark/common';
import {ReactNode, useCallback, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import {useNotification} from '../../../../hooks';
import {FIELD_NAME_AUDIT, FIELD_NAME_BILLING_AUDIT} from '../../constants/constants';
import {useHistoryRecord} from '../../hooks/useHistoryRecord';
import {addHistoryRecordDirect} from '../../hooks/useTicketHistoryRecord';
import {useTicketId} from '../../hooks/useTicketId';

type FieldName = typeof FIELD_NAME_AUDIT | typeof FIELD_NAME_BILLING_AUDIT;

const AuditSwitch = ({
  isSaving,
  label,
  ...props
}: SwitchProps & {isSaving: boolean; label: string}) => {
  return (
    <>
      {isSaving && (
        <CircularProgress
          size={16}
          sx={{
            marginRight: '16px',
          }}
        />
      )}

      {label}

      <Switch
        disabled={isSaving}
        sx={{
          marginLeft: '24px',
        }}
        {...props}
      />
    </>
  );
};

const GridRow = ({children}: {children: ReactNode}) => (
  <Grid
    item
    xs={12}
    sx={{
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'right',
      width: 0,
    }}
  >
    {children}
  </Grid>
);

const TicketAudits = () => {
  const {ticketId} = useTicketId();
  const {register, unregister, setValue, watch} = useFormContext();

  const showNotification = useNotification();
  const {addHistoryRecord} = useHistoryRecord();

  const [isAuditSaving, setIsAuditSaving] = useState(false);
  const [isBillingAuditSaving, setIsBillingAuditSaving] = useState(false);

  const auditWatch = watch(FIELD_NAME_AUDIT);
  const billingAuditWatch = watch(FIELD_NAME_BILLING_AUDIT);

  const setAuditField = useCallback(
    async (
      fieldName: FieldName,
      isEnabled: boolean,
      historyRecordType: TicketHistoryRecordType,
      setIsLoading: (isLoading: boolean) => void
    ) => {
      const fieldTitle = camelCaseToTitleCase(fieldName);

      try {
        setIsLoading(true);

        const ref = Firebase.firestore.collection(Collections.tickets).doc(ticketId);
        const snapshot = await ref.get();
        if (!snapshot.exists) {
          throw Error('Ticket not found');
        }

        const data = snapshot.data() as Ticket;

        const nextData: Pick<
          Ticket,
          'audit' | 'auditStartedAt' | 'billingAudit' | 'billingAuditStartedAt' | 'updatedAt'
        > = {
          [fieldName]: isEnabled,
          [`${fieldName}StartedAt`]: Firebase.now(), // Check document type before update this field template
          updatedAt: Firebase.now(),
        };

        // Do not allow to enable both toggles at the same time
        if (fieldName === FIELD_NAME_AUDIT && isEnabled && !!data.billingAudit) {
          nextData['billingAudit'] = false;
          nextData['billingAuditStartedAt'] = Firebase.FieldValue.delete();

          await addHistoryRecordDirect(ticketId, {
            uid: AUTOMATION_TICKET_COMMENT_UID,
            userName: AUTOMATION_TICKET_COMMENT_AUTHOR,
            userRole: AUTOMATION_TICKET_COMMENT_ROLE,
            department: undefined,
            title: `Disabled ${camelCaseToTitleCase(FIELD_NAME_BILLING_AUDIT)}`,
            recordType: TicketHistoryRecordType.Audit,
            createdAt: Firebase.now(),
            titlePortal: `Disabled ${camelCaseToTitleCase(FIELD_NAME_BILLING_AUDIT)}`,
          });

          setValue(FIELD_NAME_BILLING_AUDIT, false); // to affect the UI immediately
        } else if (fieldName === FIELD_NAME_BILLING_AUDIT && isEnabled && !!data.audit) {
          nextData['audit'] = false;
          nextData['auditStartedAt'] = Firebase.FieldValue.delete();

          await addHistoryRecordDirect(ticketId, {
            uid: AUTOMATION_TICKET_COMMENT_UID,
            userName: AUTOMATION_TICKET_COMMENT_AUTHOR,
            userRole: AUTOMATION_TICKET_COMMENT_ROLE,
            department: undefined,
            title: `Disabled ${camelCaseToTitleCase(FIELD_NAME_AUDIT)}`,
            recordType: TicketHistoryRecordType.Audit,
            createdAt: Firebase.now(),
            titlePortal: `Disabled ${camelCaseToTitleCase(FIELD_NAME_AUDIT)}`,
          });

          setValue(FIELD_NAME_AUDIT, false); // to affect the UI immediately
        }

        await ref.update(nextData);

        await addHistoryRecord(
          `${isEnabled ? 'Enabled' : 'Disabled'} ${fieldTitle}`,
          historyRecordType,
          true
        );

        setValue(fieldName, isEnabled); // to affect the UI immediately
      } catch (err) {
        console.error(`Set ${fieldName} error`, err);
        showNotification('error', `An error occurred while setting ${fieldTitle}`);
      } finally {
        setIsLoading(false);
      }
    },
    [addHistoryRecord]
  );

  useEffect(() => {
    register(FIELD_NAME_AUDIT);
    register(FIELD_NAME_BILLING_AUDIT);
  }, [register]);

  return (
    <Grid container spacing={2}>
      <GridRow>
        <AuditSwitch
          label="Audit"
          isSaving={isAuditSaving}
          checked={!!auditWatch}
          onChange={(_event, checked) => {
            setAuditField(
              FIELD_NAME_AUDIT,
              checked,
              TicketHistoryRecordType.Audit,
              setIsAuditSaving
            );
          }}
        />
      </GridRow>

      <br />

      <GridRow>
        <AuditSwitch
          label={'Billing Audit'}
          isSaving={isBillingAuditSaving}
          checked={!!billingAuditWatch}
          onChange={(_event, checked) => {
            setAuditField(
              FIELD_NAME_BILLING_AUDIT,
              checked,
              TicketHistoryRecordType.BillingAudit,
              setIsBillingAuditSaving
            );
          }}
        />
      </GridRow>
    </Grid>
  );
};

export {TicketAudits};
