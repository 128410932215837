import {
  SortDirection,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Theme,
  Tooltip,
} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Column, COLUMN_DEFAULT_WIDTH} from '@ozark/common/api/Column';
import clsx from 'clsx';
import React from 'react';
import {TooltipPlacement} from '../..';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    clickable: {
      color: `${theme.palette.primary.main} !important`,
    },
    nowrap: {
      whiteSpace: 'nowrap',
    },
    root: {
      pointerEvents: 'auto !important' as 'auto',
      '&.Mui-disabled:hover': {
        color: 'initial',
      },
    },
  })
);

interface Props {
  columns: Column<any>[];
  columnLabelNoWrap?: boolean;
  order: SortDirection;
  orderBy: string;
  actions: any;
  isActionsBeforeColumns?: boolean;
  historyColumn: any;
  onRequestSort: any;
  actionsCaption?: string;
}

export const Head = ({
  columns,
  columnLabelNoWrap,
  order,
  orderBy,
  actions,
  isActionsBeforeColumns,
  historyColumn,
  onRequestSort,
  actionsCaption,
}: Props) => {
  const classes = useStyles();

  const getSortHandler = (property: string) => (event: React.MouseEvent<HTMLSpanElement>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {isActionsBeforeColumns && (
          <>
            {actions && <TableCell>{!!actionsCaption ? actionsCaption : ''}</TableCell>}
            {historyColumn && <TableCell></TableCell>}
          </>
        )}
        {(() => {
          let offset = 0;
          return columns.map((column: Column<any>) => {
            let oldOffset = offset;
            const disabled = !column.sortable;
            if (column.horizontallySticky) {
              offset = offset + (column?.width ?? COLUMN_DEFAULT_WIDTH);
            }

            return (
              <TableCell
                key={column.id}
                align={column.align || (column.numeric ? 'right' : 'left')}
                padding={column.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === column.id ? order : false}
                className={column.className}
                style={column.width ? {minWidth: column.width} : undefined}
                sx={
                  column.horizontallySticky
                    ? {
                        position: 'sticky',
                        left: oldOffset,
                        minWidth: column.width ?? COLUMN_DEFAULT_WIDTH,
                        zIndex: 5,
                      }
                    : {}
                }
              >
                <Tooltip
                  title={column.headingTooltip ?? (column.sortable ? 'Sort' : '')}
                  placement={
                    column.numeric ? TooltipPlacement.BottomEnd : TooltipPlacement.BottomStart
                  }
                  enterDelay={300}
                >
                  <TableSortLabel
                    color="primary"
                    active={orderBy === column.id}
                    direction={order || undefined}
                    disabled={!column.sortable}
                    className={clsx(
                      column.sortable && classes.clickable,
                      columnLabelNoWrap && classes.nowrap
                    )}
                    classes={{
                      root: classes.root,
                    }}
                    onClick={disabled ? undefined : getSortHandler(column.id)}
                    hideSortIcon={disabled}
                  >
                    {column.label}
                  </TableSortLabel>
                </Tooltip>
                {column.info && column.info}
              </TableCell>
            );
          });
        })()}

        {!isActionsBeforeColumns && (
          <>
            {actions && <TableCell>{!!actionsCaption ? actionsCaption : ''}</TableCell>}
            {historyColumn && <TableCell></TableCell>}
          </>
        )}
        {historyColumn && <TableCell></TableCell>}
      </TableRow>
    </TableHead>
  );
};
