import {Typography} from '@mui/material';
import {Column} from '@ozark/common/api/Column';
import {
  ButtonExportCsv,
  Filter,
  FilterOption,
  forceActiveFilter,
  Table,
} from '@ozark/common/components';
import {MidsByChargebacksInfo} from '@ozark/functions/src/functions/express/private/types/Chargebacks';
import {add, endOfDay, startOfDay} from 'date-fns';
import {useEffect, useState} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {useApiContainer} from '../../..';
import {formatAsPercent} from '../../../util';
import {LoadingStatus} from '../common/LoadingStatus';
import {ReportingMonthTitle} from '../common/ReportingMonthTitle';
import {useDataSorted} from '../common/useDataSorted';
import {useQueryMonthYear} from '../common/useQueryMonthYear';

type PropSorted = Pick<MidsByChargebacksInfo, 'chargebacks_visa_count' | 'chargebacks_mc_count'>;

const TITLE = 'MIDs by Chargebacks';

export const MidsByChargebacksTable = ({
  breadcrumbs,
  setBreadcrumbs,
}: {
  breadcrumbs: React.ReactNode[];
  setBreadcrumbs: (els: React.ReactNode[]) => void;
}) => {
  const api = useApiContainer();
  const history = useHistory();
  const {path} = useRouteMatch();
  const {year, month} = useQueryMonthYear();
  const [filters, setFilters] = useState<Filter>({});
  const [data, setData] = useState<MidsByChargebacksInfo[]>([]);
  const {dataSorted, sort, hasData, handleRetrieveData} = useDataSorted<PropSorted>(data);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setBreadcrumbs([<Typography variant="body1">{TITLE}</Typography>]);
  }, [setBreadcrumbs]);

  useEffect(() => {
    const startingDate = startOfDay(new Date(year, month - 1, 1));
    const dateLoaded = forceActiveFilter(MidsByChargebacksFilters, 'dateLoaded', '__between', [
      startingDate,
      endOfDay(add(startingDate, {months: 1})),
    ]);
    setFilters(previous => ({...previous, dateLoaded}));
  }, [year, month]);

  useEffect(() => {
    if (filters.dateLoaded) {
      setLoading(true);
      api?.chargebacks
        .getMidsByChargebacks(Object.values(filters))
        .then(result => {
          setLoading(false);
          setData(result || []);
        })
        .catch(err => {
          setLoading(false);
          console.error(err);
        });
    } else {
      setData([]);
    }
  }, [filters]);

  const columnsConfig = getColumnsConfig();

  return (
    <>
      <ReportingMonthTitle breadcrumbs={breadcrumbs}>
        <ButtonExportCsv
          columnsConfig={columnsConfig}
          disabled={loading}
          filename="mids-by-chargebacks"
          rows={data}
        />
      </ReportingMonthTitle>
      {(loading || !hasData) && <LoadingStatus loading={loading} hasData={hasData} />}
      {!loading && hasData && (
        <div>
          <Table
            columns={columnsConfig}
            data={{
              sort,
              data: dataSorted as any, // todo: remove any and fix type errors
              limit: 10,
              offset: 0,
              totalCount: 10,
            }}
            onRetrieveData={handleRetrieveData}
            onRowClick={({mid}) => history.push(`${path}/${mid}?year=${year}&month=${month}`)}
          />
        </div>
      )}
    </>
  );
};

const getColumnsConfig = (): Column<MidsByChargebacksInfo>[] => [
  {
    id: 'mid',
    numeric: false,
    sortable: true,
    label: 'MID',
    export: true,
  },
  {
    id: 'dba_name',
    numeric: false,
    sortable: true,
    label: 'DBA Name',
    selector: row => row.merchant_name || 'n/a',
    export: row => row.merchant_name || 'n/a',
  },
  {
    id: 'chargebacks_visa_count',
    numeric: true,
    sortable: true,
    label: 'Visa CB Count',
    selector: row => row.chargebacks_visa_count || '-',
    export: true,
  },
  {
    id: 'visa_chargebacks_ratio_by_count',
    numeric: true,
    sortable: true,
    label: 'Total Visa CB Ratio by Count',
    headingTooltip: 'Percentage of Visa chargebacks of all transactions for the reporting month.',
    selector: row => formatAsPercent(row.visa_chargebacks_ratio_by_count),
    export: row => formatAsPercent(row.visa_chargebacks_ratio_by_count),
  },
  {
    id: 'chargebacks_mc_count',
    numeric: true,
    sortable: true,
    label: 'MC CB Count',
    selector: row => row.chargebacks_mc_count || '-',
    export: true,
  },
  {
    id: 'mc_chargebacks_ratio_by_volume',
    numeric: true,
    sortable: true,
    label: 'Total MC CB Ratio by Volume',
    headingTooltip:
      'Percentage of Mastercard chargebacks of all transactions for the reporting month.',
    selector: row => formatAsPercent(row.mc_chargebacks_ratio_by_volume),
    export: row => formatAsPercent(row.mc_chargebacks_ratio_by_volume),
  },
];

const MidsByChargebacksFilters: FilterOption[] = [
  {
    id: 'dateLoaded',
    column: 'dateLoaded',
    label: 'Date Range',
    type: 'dateRange',
    operators: [
      {
        id: '__between',
        label: 'is between',
      },
    ],
  },
];
