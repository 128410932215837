import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import {useCallback, useEffect, useState} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {
  Collections,
  Firebase,
  Ticket,
  TicketAssociation,
  TicketHistoryRecordType,
  TicketTypeAndCategory,
  useUserInfo,
} from '../../../..';
import {
  FIELD_NAME_ASSIGNEE,
  FIELD_NAME_ASSOCIATION,
  FIELD_NAME_CATEGORY,
  FIELD_NAME_IS_AUDITABLE,
  FIELD_NAME_SUBJECT,
} from '../../constants/constants';
import {useHistoryRecord} from '../../hooks/useHistoryRecord';
import {useIsEditingDisabled} from '../../hooks/useIsEditingDisabled';
import {useTicketAutoAssignee} from '../../hooks/useTicketAutoAssignee';
import {useTicketCategoryAutomation} from '../../hooks/useTicketCategoryAutomation';
import {useTicketId} from '../../hooks/useTicketId';
import {useTicketStatus} from '../../hooks/useTicketStatus';
import {saveSubject} from '../../TicketEditContent/InputSubject';
import {InputCategoryError} from '../../types';
import {validateCategory} from '../../utils/validation';
import {CategoryEditorTypeAndCategory} from './CategoryEditorTypeAndCategory';

export const CategoryEditor = () => {
  const {ticketId} = useTicketId();
  const {isDraft} = useTicketStatus();
  const [dialogOpen, setDialogOpen] = useState(false);
  const {setValue, clearErrors} = useFormContext();
  const category = useWatch({name: FIELD_NAME_CATEGORY});
  const [draft, setDraft] = useState<TicketTypeAndCategory | undefined>(category);
  const [draftErrors, setDraftErrors] = useState({} as Record<InputCategoryError, string>);
  const {addHistoryRecord} = useHistoryRecord();
  const {isEditingDisabled} = useIsEditingDisabled();
  const {triggerCheckCategoryAutomation} = useTicketCategoryAutomation();
  const [subjectDialogOpen, setSubjectDialogOpen] = useState(false);
  const watchAssociation: TicketAssociation | undefined = useWatch({name: FIELD_NAME_ASSOCIATION});
  const watchSubject: string | undefined = useWatch({name: FIELD_NAME_SUBJECT});
  const {userDisplayName} = useUserInfo();
  const [subjectToDisplay, setSubjectToDisplay] = useState(watchSubject);
  const {autoSetAssignee} = useTicketAutoAssignee();

  const handleClose = () => {
    setDialogOpen(false);
    setDraft(category);
  };

  const getSubject = (ticketCategory: TicketTypeAndCategory | undefined): string | undefined => {
    const newSubject = [];
    if (watchAssociation?.agent) {
      newSubject.push(watchAssociation.agent.name);
      newSubject.push(watchAssociation.group?.name);
    }
    if (watchAssociation?.application) {
      const item = watchAssociation?.application;
      newSubject.push(`${item.name ?? ''} (${item.distinguishableId})`.trim());
    }
    if (watchAssociation?.erpUser) {
      newSubject.push(watchAssociation.erpUser.name);
    }
    if (watchAssociation?.merchant) {
      newSubject.push(watchAssociation.merchant.dba);
      newSubject.push(`MID:${watchAssociation.merchant.mid}`);
    }

    if (ticketCategory?.category?.name) {
      newSubject.push(ticketCategory?.category.name);
    }

    const newSubjectString = newSubject.filter(i => i).join(' | ');
    return newSubjectString;
  };

  const saveTypeAndCategory = async (
    ticketId: string,
    nextTicketCategory?: TicketTypeAndCategory | null
  ) => {
    try {
      const nextTicketData: Partial<Ticket> = {
        category: nextTicketCategory || undefined,
        isAuditable: nextTicketCategory?.category?.isAuditable ?? false,
        updatedAt: Firebase.now(),
      };

      if (!nextTicketCategory?.category?.isAuditable) {
        nextTicketData.audit = false;
        nextTicketData.billingAudit = false;
        nextTicketData.auditStartedAt = Firebase.FieldValue.delete();
        nextTicketData.billingAuditStartedAt = Firebase.FieldValue.delete();
      }

      await Firebase.firestore.collection(Collections.tickets).doc(ticketId).update(nextTicketData);

      setValue(FIELD_NAME_CATEGORY, nextTicketCategory);
      setValue(FIELD_NAME_IS_AUDITABLE, nextTicketCategory?.category?.isAuditable ?? false);
      await addHistoryRecord(
        `Category Changed to ${nextTicketCategory?.type?.name}: ${nextTicketCategory?.category?.name}`,
        TicketHistoryRecordType.Category
      );

      if (nextTicketCategory) {
        clearErrors(FIELD_NAME_CATEGORY);
        const autoAssignedAssignee = await autoSetAssignee(ticketId, isDraft, nextTicketCategory);
        setValue(FIELD_NAME_ASSIGNEE, autoAssignedAssignee);
        clearErrors(FIELD_NAME_ASSIGNEE);
      }
    } catch (error) {
      console.error('Error writing document (InputCategory): ', error);
    }
  };

  const saveNewSubject = async (ticketId: string, newSubjectString: string) => {
    setValue(FIELD_NAME_SUBJECT, newSubjectString, {shouldDirty: false});
    await saveSubject(ticketId, newSubjectString);
  };

  const saveNewSubjectHandler = async () => {
    try {
      const isTicketCategoryAutomationApplied = await triggerCheckCategoryAutomation();
      if (!isTicketCategoryAutomationApplied) {
        const newSubjectString = getSubject(draft);
        if (newSubjectString) {
          await saveNewSubject(ticketId, newSubjectString);
        } else {
          throw new Error();
        }
        // Adds history record if user click 'Save' in the popup
        if (subjectDialogOpen) {
          addHistoryRecord(
            `${userDisplayName} edited Ticket Subject`,
            TicketHistoryRecordType.Subject,
            undefined,
            'Ticket Subject Change'
          );
        }
      }
    } catch (error) {
      console.error('Error writing document (InputGroup): ', error);
    } finally {
      setSubjectDialogOpen(false);
    }
  };

  const handleSubmit = useCallback(async () => {
    if (!draft) {
      return;
    }

    const getTicketCategoryFirestore = async () => {
      const doc = await Firebase.firestore.collection(Collections.tickets).doc(ticketId).get();
      return (doc.data() as Ticket).category;
    };
    const ticketCategory = await getTicketCategoryFirestore();

    if (validateCategory(draft, setDraftErrors)) {
      saveTypeAndCategory(ticketId, draft);
      setDialogOpen(false);

      const generatedSubject = getSubject(ticketCategory);
      if (
        generatedSubject === watchSubject ||
        ((generatedSubject === undefined || generatedSubject === '') && watchSubject === undefined)
      ) {
        setSubjectDialogOpen(false);
        saveNewSubjectHandler();
      } else {
        setSubjectToDisplay(watchSubject);
        setSubjectDialogOpen(true);
      }
    }
  }, [draft, setDraftErrors, ticketId, watchSubject, saveTypeAndCategory]);

  useEffect(() => {
    setDraft(category);
  }, [category]);

  return (
    <div>
      <IconButton onClick={() => setDialogOpen(true)} disabled={isEditingDisabled}>
        <EditIcon />
      </IconButton>
      <Dialog open={dialogOpen} onClose={handleClose}>
        <DialogTitle>Category</DialogTitle>
        <DialogContent>
          <CategoryEditorTypeAndCategory
            draft={draft}
            setDraft={setDraft}
            errors={draftErrors}
            setErrors={setDraftErrors}
          />
        </DialogContent>
        <DialogActions sx={{mt: 4, mb: 1, mx: 1}}>
          <Box>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={handleSubmit}>Save</Button>
          </Box>
        </DialogActions>
      </Dialog>
      {subjectDialogOpen && (
        <Dialog open={subjectDialogOpen} onClose={() => setSubjectDialogOpen(false)}>
          <DialogTitle>Would you like to update the ticket subject line?</DialogTitle>
          <DialogContent>
            <DialogContentText>
              "{subjectToDisplay}" -{'>'} "{getSubject(draft)}"
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{mt: 4, mb: 1, mx: 1}}>
            <Box>
              <Button onClick={() => setSubjectDialogOpen(false)}>Cancel</Button>
              <Button onClick={saveNewSubjectHandler}>Save</Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};
