import {Redirect, Route, Switch} from 'react-router-dom';
import {
  TICKETS_REPORT,
  TICKETS_VIEW,
  TICKETS_VIEW_ALL,
  TICKET_EDIT,
  TICKET_TYPES_MANAGER_EDIT_CATEGORIES_ROUTE,
  TICKET_TYPES_MANAGER_ROUTE,
} from '../../constants/routes';
import {BreadcrumbsContainer} from '../common/Breadcrumbs';
import {ErrorBoundary} from '../common/ErrorBoundary';
import {TicketEditContainerProvider} from './hooks/useTicketEditContainer';
import {TicketsFiltersContainer} from './hooks/useTicketsFiltersContainer';
import {TicketEdit} from './TicketEdit/TicketEdit';
import {TicketReports} from './TicketReports';
import {TicketsListView} from './TicketsList/TicketsListView';
import {TicketTypeCategories} from './TicketTypeCategories';
import {TicketTypes} from './TicketTypes';

export const Tickets = () => {
  return (
    <ErrorBoundary>
      <BreadcrumbsContainer.Provider initialState={[{text: 'Tickets', url: TICKETS_VIEW_ALL}]}>
        <Switch>
          <Route path={TICKETS_VIEW}>
            <TicketsFiltersContainer.Provider>
              <TicketsListView />
            </TicketsFiltersContainer.Provider>
          </Route>
          <Route path={TICKET_EDIT}>
            <TicketEditContainerProvider>
              <TicketEdit />
            </TicketEditContainerProvider>
          </Route>
          <Route path={TICKET_TYPES_MANAGER_EDIT_CATEGORIES_ROUTE}>
            <TicketTypeCategories />
          </Route>
          <Route path={TICKET_TYPES_MANAGER_ROUTE}>
            <TicketTypes />
          </Route>
          <Route path={TICKETS_REPORT}>
            <TicketReports />
          </Route>
          <Redirect from="*" to={TICKETS_VIEW_ALL} />
        </Switch>
      </BreadcrumbsContainer.Provider>
    </ErrorBoundary>
  );
};
