export enum ApplicationDocument {
  advancedSearch = 'Advanced Search',
  addendums = 'Addendums',
  accountMaintenanceFormApproved = 'Account Maintenance Form - Approved',
  accountMaintenanceFormDeclined = 'Account Maintenance Form - Declined',
  bankBalanceSummary = 'Bank Balance Summary',
  boardingParameters = 'Boarding Parameters',
  bankLetter = 'Bank Letter',
  bankStatement = 'Bank Statement',
  bankUpdateFormApproved = 'Bank Update Form - Approved',
  bankUpdateFormDeclined = 'Bank Update Form - Declined',
  batchDetails = 'Batch Details',
  binInformation = 'BIN Information',
  BRAM = 'BRAM',
  businessLicense = 'Business License',
  bbbReport = 'BBB Report',
  businessSummary = 'Business Summary',
  cardBrandNotificationMC = 'Card Brand Notification - MC',
  cardBrandNotificationVisa = 'Card Brand Notification - Visa',
  cardHistory = 'Card History',
  cardholderId = 'Cardholder ID',
  chargebackDetail = 'Chargeback Detail',
  checkingAccountVerification = 'Checking Account Verification',
  creditReport = 'Credit Report',
  crmAgreement = 'CRM Agreement',
  erpAgreement = 'ERP Agreement',
  customerInvoice = 'Customer Invoice',
  customerServiceAgreement = 'Customer Service Agreement',
  depositDetail = 'Deposit Detail',
  email = 'Emails',
  executiveSummary = 'Executive Summary',
  evolveUnderwritingNotes = 'Evolve Underwriting Notes',
  financials = 'Financials',
  fulfillmentAgreement = 'Fulfillment Agreement',
  GIACTReport = 'GIACT Report',
  googleSearch = 'Google Search',
  governmentId = 'Government ID',
  highRiskRegistrationForm = 'High Risk Registration Form',
  inventoryPhoto = 'Inventory Photo',
  invoice = 'Invoice(s)',
  internalNotes = 'Internal Notes',
  irsSs4 = 'IRS SS4 (EIN)',
  irs501c3 = 'IRS 501c3',
  justia = 'Justia',
  legalOpinionLetter = 'Legal Opinion Letter',
  legitscriptG2 = 'Legitscript/G2/WebShield',
  letterOfAttestation = 'Letter of Attestation',
  letterofExplanation = 'Letter of Explanation',
  lexisNexis = 'LexisNexis',
  lexisNexisReport = 'LexisNexis Report',
  lexisNexisBusinessReport = 'LexisNexis Business Report',
  legalLetterofOpinion = 'Legal Letter of Opinion',
  locationConfirmation = 'Location Confirmation',
  matchReport = 'Match Report',
  merchantApplication = 'Merchant Application',
  marketingMaterials = 'Marketing Materials',
  ofacSearch = 'OFAC Search',
  ofacSearchMonthly = 'OFAC Search Monthly',
  onlineServices = 'Online Services',
  onlineSearched = 'Online Searches',
  openCorporates = 'Open Corporates',
  orderForm = 'Order Form',
  otherAgreements = 'Other Agreements',
  periodicReviewAdHoc = 'Periodic Review Ad Hoc',
  periodicReview3Months = 'Periodic Review 3 Months',
  periodicReview6Months = 'Periodic Review 6 Months',
  periodicReview12Months = 'Periodic Review 12 Months',
  privacyPolicy = 'Privacy Policy',
  processingGrid = 'Processing Grid',
  processingSnapshot = 'Processing Snapshot',
  processingStatement = 'Processing Statement',
  professionalLicense = 'Professional License',
  receipt = 'Receipt',
  reserve = 'Reserve',
  reserveCalculations = 'Reserve Calculations',
  reserveReview = 'Reserve Review',
  ripOffReport = 'Rip Off Report',
  riskReviewInvestigation = 'Risk Review Investigation',
  retroMatch = 'RetroMatch',
  sampleContract = 'Sample Contract',
  scamAdviser = 'ScamAdviser',
  secretShop = 'Secret Shop',
  siteSurvey = 'Site Survey',
  secretaryOfState = 'Secretary of State',
  socialMedia = 'Social Media',
  spyDialer = 'SpyDialer',
  subscription = 'Subscription',
  tinCheck = 'TIN Check',
  termsOfService = 'Terms of Service',
  trackingProofOfDelivery = 'Tracking (Proof of Delivery)',
  transactionDetails = 'Transaction Detail(s)',
  utilityBill = 'Utility Bill',
  voidedCheck = 'Voided Check',
  whoIsReport = 'WhoIs Report',
  website = 'Website',
  websiteCheckout = 'Website - Checkout',
  websiteImage = 'Website Image',
  varSheet = 'VAR Sheet',
}

export const applicationDocumentsHiddenFromUi = [
  ApplicationDocument.lexisNexisBusinessReport,
  ApplicationDocument.legalLetterofOpinion,
  ApplicationDocument.erpAgreement,
];

export const ApplicationDocumentBoardedOnly: ApplicationDocument[] = [
  ApplicationDocument.advancedSearch,
  ApplicationDocument.evolveUnderwritingNotes,
  ApplicationDocument.accountMaintenanceFormApproved,
  ApplicationDocument.accountMaintenanceFormDeclined,
  ApplicationDocument.bankUpdateFormApproved,
  ApplicationDocument.bankUpdateFormDeclined,
  ApplicationDocument.batchDetails,
  ApplicationDocument.binInformation,
  ApplicationDocument.BRAM,
  ApplicationDocument.cardBrandNotificationMC,
  ApplicationDocument.cardBrandNotificationVisa,
  ApplicationDocument.cardHistory,
  ApplicationDocument.cardholderId,
  ApplicationDocument.chargebackDetail,
  ApplicationDocument.depositDetail,
  ApplicationDocument.email,
  ApplicationDocument.GIACTReport,
  ApplicationDocument.googleSearch,
  ApplicationDocument.invoice,
  ApplicationDocument.legitscriptG2,
  ApplicationDocument.locationConfirmation,
  ApplicationDocument.ofacSearchMonthly,
  ApplicationDocument.periodicReviewAdHoc,
  ApplicationDocument.periodicReview3Months,
  ApplicationDocument.periodicReview6Months,
  ApplicationDocument.periodicReview12Months,
  ApplicationDocument.privacyPolicy,
  ApplicationDocument.processingSnapshot,
  ApplicationDocument.receipt,
  ApplicationDocument.reserveReview,
  ApplicationDocument.retroMatch,
  ApplicationDocument.riskReviewInvestigation,
  ApplicationDocument.secretShop,
  ApplicationDocument.socialMedia,
  ApplicationDocument.spyDialer,
  ApplicationDocument.subscription,
  ApplicationDocument.termsOfService,
  ApplicationDocument.trackingProofOfDelivery,
  ApplicationDocument.transactionDetails,
  ApplicationDocument.websiteCheckout,
  ApplicationDocument.websiteImage,
];

export const getNotBoardedApplicationDocumentValues = () => {
  const ApplicationDocumentBoardedOnlyValues = ApplicationDocumentBoardedOnly.map(x =>
    x.toString()
  );
  return Object.values(ApplicationDocument)
    .filter(
      x =>
        !ApplicationDocumentBoardedOnlyValues.includes(x) &&
        !applicationDocumentsHiddenFromUi.includes(x)
    )
    .sort();
};

export const getBoardedApplicationDocumentValues = () => {
  return Object.values(ApplicationDocument)
    .filter(x => !applicationDocumentsHiddenFromUi.includes(x))
    .sort();
};

export const MAX_INVALID_MERCHANT_REG_ATTEMPTS = 3;
export const NOT_SYNCED_APPLICATION_UID = 'NOT_SYNCED_APPLICATION_UID';
