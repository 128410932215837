import {Autocomplete, Box, Divider, Grid, TextField, Typography} from '@mui/material';
import {
  ButtonExportCsv,
  mccsBySalesColumnsConfig,
  MccsBySalesPie,
  MccsBySalesTable,
} from '@ozark/common/components';
import {
  MccInfo,
  MccTotalsInfo,
  PaginatedMccInfo,
} from '@ozark/functions/src/functions/express/private/types/Reports';
import {ReactNode, useEffect, useMemo, useState} from 'react';
import {SearchCriteria, useApiContainer, useSimpleBusinessTypes} from '../../..';
import {LoadingStatus} from '../common/LoadingStatus';
import {ReportingMonthTitle} from '../common/ReportingMonthTitle';
import {ReportingPaper} from '../common/ReportingPaper';
import {useQueryMonthYear} from '../common/useQueryMonthYear';

const TITLE = "MCC's by Sales";

const addPercentageValues = (d: MccInfo, totals?: MccTotalsInfo) => ({
  ...d,
  salesPercentage: totals ? Number((d.sales / totals.sales).toFixed(4)) : 0,
  salesAmountPercentage: totals ? Number((d.salesAmount / totals.salesAmount).toFixed(4)) : 0,
});

export const MccsBySalesPage = () => {
  const api = useApiContainer();
  const {year, month} = useQueryMonthYear();
  const [data, setData] = useState<PaginatedMccInfo>();
  const [totals, setTotals] = useState<MccTotalsInfo>();
  const [loading, setLoading] = useState(true);
  const [mccFilter, setMccFilter] = useState<string>();

  const {mccCodes} = useSimpleBusinessTypes({activeOnly: true});

  const fetchAllData = async () => {
    const result = await api?.reports.getMccsBySales(year, month, '', true);

    if (!result?.data || result.data.length === 0) {
      return [];
    }

    return result.data.map(d => addPercentageValues(d, totals));
  };

  const fetchData = async (searchCriteria?: SearchCriteria) => {
    setLoading(true);
    const result = await api?.reports.getMccsBySales(year, month, mccFilter, false, searchCriteria);
    if (result) {
      setData({
        ...result,
        data: result.data.map(d => addPercentageValues(d, totals)),
      });
    }
    setLoading(false);
  };

  const handleMccFilter = (mcc?: string) => {
    if (!mcc || mcc === '' || mcc === '0') {
      setMccFilter(undefined);
    } else {
      setMccFilter(mcc);
    }
  };

  useEffect(() => {
    if (Boolean(year && month)) {
      setLoading(true);
      api?.reports
        .getMccsBySalesTotals(year, month)
        .then(result => {
          setTotals(result ?? undefined);
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [year, month]);

  useEffect(() => {
    fetchData();
  }, [totals, mccFilter]);

  const sortedMccCodes = useMemo(() => {
    if (!mccCodes || Object.keys(mccCodes).length === 0) {
      return [];
    }

    const sortedMccCodes = Object.entries(mccCodes)
      .map(([businessType, mcc]) => ({
        label: `${businessType} (${mcc})`,
        id: mcc,
      }))
      .sort((a, b) => (a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1));

    return sortedMccCodes;
  }, [mccCodes]);

  const breadcrumbs: ReactNode[] = [<Typography variant="body1">{TITLE}</Typography>];

  return (
    <>
      <ReportingMonthTitle breadcrumbs={breadcrumbs}>
        <Autocomplete
          sx={{width: '100%', maxWidth: 360, marginBottom: 2}}
          options={sortedMccCodes}
          onChange={(_, mcc) => handleMccFilter(mcc?.id ?? '')}
          renderInput={params => (
            <TextField
              {...params}
              name="businessType"
              label="Filter by MCC"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
              }}
            />
          )}
        />
        <Divider orientation="vertical" flexItem variant="middle" light sx={{mx: 2}} />
        <ButtonExportCsv
          columnsConfig={mccsBySalesColumnsConfig}
          disabled={loading}
          filename="mccs-by-sales"
          rows={data?.data}
          getRows={fetchAllData}
        />
      </ReportingMonthTitle>

      {!loading && data && totals ? (
        <ReportingPaper>
          <LoadingStatus loading={loading} hasData={data.data.length > 0} />
          {!loading && data.data.length > 0 && (
            <Grid item container>
              <Grid item lg={12} xl={7}>
                <MccsBySalesTable
                  data={data}
                  onRetrieveData={searchCriteria => fetchData(searchCriteria)}
                />
              </Grid>
              <Grid item lg={12} xl={5}>
                <Box my={1} height={550}>
                  <MccsBySalesPie data={data.data} totals={totals} />
                </Box>
              </Grid>
            </Grid>
          )}
        </ReportingPaper>
      ) : (
        <LoadingStatus loading={loading} hasData={data && data.data.length > 0} />
      )}
    </>
  );
};
