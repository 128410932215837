import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Collapse,
  Paper,
  SortDirection,
  Table as MuiTable,
  TableBody,
  TableCell,
  TableCellProps,
  TableHead,
  TablePagination,
  TableProps,
  TableRow,
  TableRowProps,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {checkIsEmpty} from '@ozark/common';
import React, {Fragment} from 'react';
import {PaginatedResponse, SearchCriteria} from '../..';
import {Column, COLUMN_DEFAULT_WIDTH} from '../../api/Column';
import {Head} from './Head';

export interface IHistorySubtable<T, F> {
  title: string;
  calculatedTitle?: (parentRow: F) => string;
  buttonText: string;
  historyProperty: string;
  columns: Column<T>[];
  hideIfEmpty?: boolean;
  useLoadingBtn?: boolean;
}

interface Props<T, F> {
  columns: Column<T>[];
  historyColumns?: IHistorySubtable<F, T>;
  rows?: T[];
  sort?: [string, 'ASC' | 'DESC'][];
  data?: PaginatedResponse<T>;
  summary?: any;
  onRowClick?: (row: T) => void;
  onRetrieveData?: (searchCriteria: SearchCriteria) => void;
  selectedId?: string;
  paginate?: boolean;
  useOffsetAsPage?: boolean;
  actions?: (row: T) => JSX.Element | null;
  isActionsBeforeColumns?: boolean;
  caption?: string;
  noWrap?: boolean;
  flat?: boolean;
  stickyHeader?: boolean;
  scrollableBody?: boolean;
  getRowProps?: (row: T, index: number) => Partial<TableRowProps>;
  getCellProps?: (column: Column<T>, row: T) => Partial<TableCellProps>;
  tableProps?: Partial<TableProps>;
  customHeight?: string;
  hideHistoryBorderBottom?: boolean;
  skipActionsStopPropagation?: boolean;
  actionsCaption?: string;
  paperSx?: any;
}

export const Table = <T, F>({
  columns,
  historyColumns,
  rows,
  sort = [],
  data: tableData,
  selectedId,
  summary,
  onRowClick,
  onRetrieveData,
  paginate,
  useOffsetAsPage,
  actions,
  isActionsBeforeColumns,
  caption,
  noWrap,
  flat,
  stickyHeader,
  scrollableBody,
  getRowProps,
  getCellProps,
  tableProps = {},
  customHeight,
  hideHistoryBorderBottom,
  skipActionsStopPropagation,
  actionsCaption,
  paperSx,
}: Props<T, F>) => {
  if (!tableData && !rows) return null;

  const data = (
    rows
      ? {
          data: rows,
          limit: rows.length,
          offset: 0,
          sort,
          totalCount: rows.length,
        }
      : tableData
  ) as PaginatedResponse<any>;

  const classes = useStyles();

  const handleRowClick = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>, row: T) => {
    e.stopPropagation();
    onRowClick?.(row);
  };

  const handleRequestSort = (_event: React.MouseEvent<HTMLButtonElement>, property: string) => {
    let newOrder =
      data.sort[0] && data.sort[0][0] === property && data.sort[0][1].toLocaleLowerCase() === 'desc'
        ? 'asc'
        : 'desc';
    const searchCriteria = {
      order: newOrder,
      orderBy: property,
      offset: 1,
      limit: data.limit,
    };
    onRetrieveData?.(searchCriteria);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    page: number
  ) => {
    const searchCriteria = {
      orderBy: data.sort[0]?.[0],
      order: data.sort[0]?.[1].toLocaleLowerCase(),
      offset: page + 1,
      limit: data.limit,
    };
    onRetrieveData?.(searchCriteria);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const searchCriteria = {
      orderBy: data.sort[0][0],
      order: data.sort[0][1].toLocaleLowerCase(),
      offset: 1,
      limit: parseInt(event.target.value),
    };
    onRetrieveData?.(searchCriteria);
  };

  const Row = (props: {row: any; index: number}) => {
    const {row, index} = props;
    const [open, setOpen] = React.useState(false);

    const isEmptyHistory =
      historyColumns && (row[historyColumns.historyProperty] as Array<any>)?.length === 0;
    const isLoadingHistory =
      historyColumns && (row[historyColumns.historyProperty] as Array<any>) === null;
    const isLoadingOrHasHistory =
      isLoadingHistory ||
      (historyColumns && (row[historyColumns.historyProperty] as Array<any>)?.length > 0);

    const renderHistoryAndActions = () => {
      return (
        <>
          {actions && (
            <TableCell
              {...getCellProps?.({id: 'actions', label: ''}, row)}
              onClick={e => {
                if (skipActionsStopPropagation) {
                  return;
                }
                // to avoid onRowClick action execution
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              {actions(row)}
            </TableCell>
          )}
          {historyColumns && (
            <TableCell
              onClick={event => {
                event.stopPropagation();
                event.preventDefault();
              }}
              sx={{
                cursor: 'default',
              }}
              {...getCellProps?.({id: 'history', label: ''}, row)}
            >
              {!historyColumns.useLoadingBtn &&
                (!historyColumns.hideIfEmpty || !isEmptyHistory) && (
                  <Box sx={{mr: -1, mb: -1}}>
                    <Button
                      variant="contained"
                      disabled={isEmptyHistory}
                      onClick={event => {
                        setOpen(!open);
                        // to avoid onRowClick action execution
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                      size="small"
                      sx={{mr: 1, mb: 1}}
                    >
                      {historyColumns.buttonText}
                    </Button>
                  </Box>
                )}
              {historyColumns.useLoadingBtn &&
                (!historyColumns.hideIfEmpty || isLoadingOrHasHistory) && (
                  <Box sx={{mr: -1, mb: -1}}>
                    <LoadingButton
                      variant="contained"
                      disabled={isEmptyHistory}
                      onClick={event => {
                        setOpen(!open);
                        // to avoid onRowClick action execution
                        event.stopPropagation();
                        event.preventDefault();
                      }}
                      loading={isLoadingHistory}
                      size="small"
                      sx={{mr: 1, mb: 1}}
                    >
                      {historyColumns.buttonText}
                    </LoadingButton>
                  </Box>
                )}
            </TableCell>
          )}
        </>
      );
    };

    return (
      <Fragment>
        <TableRow
          key={row.id ?? index}
          hover={!!onRowClick}
          className={classes.tableRow}
          onClick={event => handleRowClick(event, row)}
          tabIndex={-1}
          style={{cursor: checkIsEmpty(onRowClick) ? 'inherit' : 'pointer'}}
          selected={Boolean(selectedId && selectedId === row.id)}
          {...getRowProps?.(row, index)}
        >
          <>
            {isActionsBeforeColumns && renderHistoryAndActions()}

            {(() => {
              let offset = 0;
              return columns.map(column => {
                let oldOffset = offset;
                if (column.horizontallySticky) {
                  offset = offset + (column?.width ?? COLUMN_DEFAULT_WIDTH);
                }
                const value =
                  typeof column.selector === 'function'
                    ? column.selector(row, column.id)
                    : row[column.id];
                return (
                  <TableCell
                    key={column.id}
                    align={column.align || (column.numeric ? 'right' : 'left')}
                    {...getCellProps?.(column, row)}
                    className={column.className}
                    sx={
                      column.horizontallySticky
                        ? {
                            position: 'sticky',
                            left: oldOffset,
                            minWidth: column.width ?? COLUMN_DEFAULT_WIDTH,
                            zIndex: 5,
                            backgroundColor: '#fff',
                          }
                        : {}
                    }
                  >
                    {value}
                  </TableCell>
                );
              });
            })()}
          </>
          {!isActionsBeforeColumns && renderHistoryAndActions()}
        </TableRow>
        {historyColumns && (
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                borderBottom:
                  hideHistoryBorderBottom && !row[historyColumns.historyProperty]
                    ? 'none'
                    : 'inherited',
              }}
              colSpan={columns.length + (actions ? 2 : 1)}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{margin: 1}}>
                  <Typography variant="h6" gutterBottom component="div">
                    {historyColumns.calculatedTitle
                      ? historyColumns.calculatedTitle(row)
                      : historyColumns.title}
                  </Typography>
                  <MuiTable size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        {historyColumns.columns.map((column: Column<any>) => {
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align || (column.numeric ? 'right' : 'left')}
                              padding={column.disablePadding ? 'none' : 'normal'}
                              sortDirection={false}
                              className={column.className}
                              style={column.width ? {minWidth: column.width} : undefined}
                            >
                              {column.label}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row[historyColumns.historyProperty]?.map((historyRow: any) => (
                        <TableRow key={historyRow.id}>
                          {historyColumns.columns.map((column: Column<any>) => {
                            const value =
                              typeof column.selector === 'function'
                                ? column.selector(historyRow, column.id)
                                : historyRow[column.id];

                            return (
                              <TableCell
                                key={column.id}
                                align={column.align || (column.numeric ? 'right' : 'left')}
                                {...getCellProps?.(column, historyRow)}
                                className={column.className}
                              >
                                {value}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      ))}
                    </TableBody>
                  </MuiTable>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </Fragment>
    );
  };

  return (
    <Paper
      className={classes.paper}
      elevation={flat ? 0 : 1}
      sx={[
        Boolean(scrollableBody) && {display: 'flex', flexDirection: 'column'},
        Boolean(paperSx) && {...paperSx},
      ]}
    >
      <Box
        sx={[
          Boolean(scrollableBody) && {width: '100%', flex: '1', overflow: 'auto'},
          Boolean(customHeight) && {maxHeight: customHeight},
        ]}
      >
        <MuiTable
          stickyHeader={stickyHeader}
          className={classes.table}
          style={{whiteSpace: noWrap ? 'nowrap' : 'normal'}}
          aria-labelledby="tableTitle"
          {...tableProps}
        >
          {caption && <caption>{caption}</caption>}
          <Head
            columns={columns}
            order={data.sort[0]?.[1].toLocaleLowerCase() as SortDirection}
            orderBy={data.sort[0]?.[0]}
            onRequestSort={handleRequestSort}
            actions={actions}
            isActionsBeforeColumns={isActionsBeforeColumns}
            historyColumn={historyColumns ? true : undefined}
            columnLabelNoWrap={noWrap}
            actionsCaption={actionsCaption}
          />
          <TableBody>
            {data.data.map((row: any, i: number) => (
              <Row row={row} index={i} key={i} />
            ))}
            {summary && (
              <TableRow>
                {(() => {
                  let offset = 0;
                  return columns.map((column: any) => {
                    let oldOffset = offset;
                    if (column.horizontallySticky) {
                      offset = offset + (column?.width ?? COLUMN_DEFAULT_WIDTH);
                    }
                    const value = summary[column.id] || '';
                    return (
                      <TableCell
                        key={column.id}
                        align={column.align || (column.numeric ? 'right' : 'left')}
                        sx={
                          column.horizontallySticky
                            ? {
                                position: 'sticky',
                                left: oldOffset,
                                minWidth: column.width ?? COLUMN_DEFAULT_WIDTH,
                                zIndex: 5,
                                backgroundColor: '#fff',
                              }
                            : {}
                        }
                      >
                        <b>{value}</b>
                      </TableCell>
                    );
                  });
                })()}
              </TableRow>
            )}
          </TableBody>
        </MuiTable>
      </Box>
      {paginate && (
        <TablePagination
          rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
          component="div"
          rowsPerPage={data.limit}
          page={useOffsetAsPage ? data.offset - 1 : data.offset / data.limit}
          count={data.totalCount}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </Paper>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '600',
    },
    paper: {
      margin: 'auto',
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: 'block',
    },
    downloadButton: {
      marginLeft: theme.spacing(2),
    },
    tableRow: {
      '&:hover td': {
        backgroundColor: 'rgb(245 245 245)',
      },
    },
  })
);
