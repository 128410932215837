import {Box, Paper} from '@mui/material';
import {PieTooltipProps, ResponsivePie} from '@nivo/pie';
import {MccInfo, MccTotalsInfo} from '@ozark/functions/src/functions/express/private/types/Reports';
import {useMemo} from 'react';
import {truncate} from '../../..';
import {formatAsPercent, formatterCurrency} from '../../../util/format';

type Props = {
  data: MccInfo[];
  totals: MccTotalsInfo;
};

type MccsBySalesPieData = {
  id: string;
  label: string;
  value: number;
  businessType: string;
  percentage: number;
};

export const MccsBySalesPie = ({data, totals}: Props) => {
  const dataFormatted: MccsBySalesPieData[] = useMemo(() => {
    let dataTotal = 0;
    const result = data.map((d: MccInfo) => {
      dataTotal += d.salesAmount;

      return {
        id: d.mcc,
        label: d.businessType,
        value: d.salesAmount,
        businessType: d.businessType,
        percentage: d.salesAmount / totals.salesAmount,
      };
    });

    const othersTotal = totals.salesAmount - dataTotal;
    if (othersTotal > 1) {
      result.push({
        id: '-',
        label: 'Others',
        value: othersTotal,
        businessType: 'Others',
        percentage: othersTotal / totals.salesAmount,
      });
    }

    return result;
  }, [data, totals]);

  return (
    <ResponsivePie
      colors={{scheme: 'set2'}}
      data={dataFormatted}
      margin={{top: 40, right: 140, bottom: 80, left: 140}}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      activeOuterRadiusOffset={8}
      borderWidth={1}
      borderColor={{from: 'color', modifiers: [['darker', 0.2]]}}
      enableArcLinkLabels={true}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      arcLinkLabelsThickness={2}
      arcLinkLabelsColor={{from: 'color'}}
      arcLinkLabel={d =>
        d.data.label ? truncate(d.data.label.toString()) ?? '' : '...' + d.id.toString().slice(8)
      }
      valueFormat={value => formatterCurrency.format(value)}
      arcLabelsSkipAngle={10}
      arcLabelsTextColor={{from: 'color', modifiers: [['darker', 2]]}}
      tooltip={Tooltip}
    />
  );
};

const Tooltip = ({datum}: PieTooltipProps<MccsBySalesPieData>) => (
  <Paper square elevation={1}>
    <Box p={1} borderLeft={`5px solid ${datum.color}`}>
      <Box pb={1}>
        <div>
          <b>
            {datum.data.businessType} ({datum.data.id})
          </b>
        </div>
      </Box>
      <div>Sales Amount:&emsp;{formatterCurrency.format(datum.data.value)}</div>
      <div>% of Total:&emsp;&emsp;&emsp;{formatAsPercent(datum.data.percentage)}</div>
    </Box>
  </Paper>
);
