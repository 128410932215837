import PublishIcon from '@mui/icons-material/Publish';
import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import {Theme} from '@mui/material/styles';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {
  ActivePlatforms,
  ApplicationAchDelayOptions,
  ApplicationView,
  Collections,
  createAuditNonce,
  Department,
  Dispositions,
  Firebase,
  IndustryVertical,
  IndustryVerticalLabel,
  manuallyBoardedPlatforms,
  PlatformIndustryVerticals,
  ReservePercentCappedOptions,
  ReservePercentDisplayOptions,
  ReservePercentOption,
  ReservePercentUpfrontOptions,
  RiskLevel,
  useCallable,
  UserRoles,
} from '@ozark/common';
import {
  ConfirmationDialog,
  ConfirmationDialogField,
  ConfirmationDialogFieldProps,
  Loading,
} from '@ozark/common/components';
import {DisputeReason} from '@ozark/common/components/ApplicationDisputeHandlers';
import {conditionallyApproved} from '@ozark/common/components/ApplicationDisputeHandlers/reasons/conditionallyApproved';
import {useDebouncedCallback} from '@ozark/common/hooks';
import {useAgentResponsibilityCode} from '@ozark/common/hooks/useAgentResponsibilityCode';
import startcase from 'lodash/startCase';
import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';
import {useNotification} from '../../../hooks/useNotification';
import {useStore} from '../../../store/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      height: '100%',
      padding: theme.spacing(2),
      color: '#4d6575',
    },
    title: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      margin: theme.spacing(1, 0, 2),
      '& > *': {
        margin: theme.spacing(0, 1),
      },
    },
    content: {
      padding: theme.spacing(2, 0, 0),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    fieldSelect: {
      marginTop: theme.spacing(1),
    },
    selectInput: {
      backgroundColor: 'transparent !important',
    },
  })
);

type BoardingProps = {
  application: ApplicationView;
  locked: boolean;
  loading: boolean;
  setLoading: any;
};

export const Boarding = ({application, locked, loading, setLoading}: BoardingProps) => {
  const classes = useStyles();
  const {boardAccount} = useCallable();
  const showNotification = useNotification();

  const [boardingConfirmationAction, setBoardingConfirmationAction] = useState<
    ((fields: any) => any | Promise<void>) | null
  >(null);
  const [reserveCapped, setReserveCapped] = useState('');
  const [reserveUpfront, setReserveUpfront] = useState('');

  useEffect(() => {
    setReserveCapped(application?.reserveCapped?.toString() ?? '');
  }, [application?.reserveCapped]);

  useEffect(() => {
    setReserveUpfront(application?.reserveUpfront?.toString() ?? '');
  }, [application?.reserveUpfront]);

  const agentResponsibilityCode = useAgentResponsibilityCode();

  const [fields, setFields] = useState<ConfirmationDialogFieldProps[]>();
  const reasons = conditionallyApproved[Dispositions.boarded] as DisputeReason[];

  const {authProfile} = useStore();
  const isAdmin = authProfile.data?.role === UserRoles.admin;
  const isRiskUserDepartment = authProfile.data?.department === Department.risk;
  const isUnderwriter = authProfile.data?.role === UserRoles.underwriter;

  const equipmentIsSet = !!application.equipment || !!application.equipmentAdditional;

  const ready =
    application.disposition === Dispositions.uwApproved &&
    application.industryVertical &&
    application.salesResponsibilityCode &&
    application.platform &&
    !manuallyBoardedPlatforms.includes(application.platform) &&
    equipmentIsSet;

  const handleEnqueueClick = async (form: any) => {
    if (locked) {
      return;
    }

    const hasConditions = form?.hasConditions;
    const boardingNotes = form?.otherReason;
    const boardingNotesConditions = getBoardingNotesConditions(form).map(
      (value: DisputeReason) => value.name
    );
    const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);

    const isConditionallyApproved = hasConditions === 'true';

    await Firebase.firestore
      .collection(Collections.applications)
      .doc(application.id)
      .set(
        {
          isConditionallyApproved,
          boardingNotes:
            isConditionallyApproved && boardingNotes ? boardingNotes : Firebase.FieldValue.delete(),
          boardingNotesConditions:
            isConditionallyApproved && boardingNotesConditions && boardingNotesConditions.length > 0
              ? boardingNotesConditions
              : Firebase.FieldValue.delete(),
          auditNonce,
          updatedAt: new Date(),
        },
        {merge: true}
      );

    const boardingConditionsHtml = getBoardingConditions(form);

    setLoading(true);
    await boardAccount({
      applicationId: application.id,
      boardingConditionsHtml: boardingConditionsHtml,
    });

    setLoading(false);
    showNotification('info', `${application.legalBusinessName} has been enqueued for boarding.`);
  };

  const updateBoardingField = useCallback(
    async (name: string, fieldValue: string | ReturnType<typeof Firebase.FieldValue.delete>) => {
      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      const data = {[name]: fieldValue, auditNonce, updatedAt: new Date()};
      if (name === 'platform') {
        data.industryVertical = Firebase.FieldValue.delete();
        data.salesResponsibilityCode = Firebase.FieldValue.delete();
      }
      await Firebase.firestore
        .collection(Collections.applications)
        .doc(application.id)
        .set(data, {merge: true});
    },
    [application.id]
  );

  const debouncedFieldChange = useDebouncedCallback(updateBoardingField, 1000);

  const handleBoardingFieldChange =
    (name: string) => async (event: React.ChangeEvent<HTMLInputElement>) => {
      const fieldValue =
        event.target.value === '' ? Firebase.FieldValue.delete() : event.target.value;
      await updateBoardingField(name, fieldValue);
    };

  const handleDebouncedNumberFieldChange = useCallback(
    (name: string, setAction: Dispatch<SetStateAction<string>>) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const numberVal = Number(event.target.value);
        let stringNumber = event.target.value;
        if (numberVal < 0) {
          stringNumber = '0';
        }
        setAction(stringNumber);
        const fieldValue = stringNumber === '' ? Firebase.FieldValue.delete() : stringNumber;
        debouncedFieldChange(name, fieldValue);
      },
    [debouncedFieldChange]
  );

  const handleReserveFieldChange = () => async (event: React.ChangeEvent<HTMLInputElement>) => {
    const fieldValue =
      event.target.value === '' ? Firebase.FieldValue.delete() : event.target.value;
    await updateBoardingField('reservePercentOption', fieldValue);
    if (!ReservePercentCappedOptions.includes(fieldValue as ReservePercentOption)) {
      await updateBoardingField('reserveCapped', Firebase.FieldValue.delete());
    }
    if (!ReservePercentUpfrontOptions.includes(fieldValue as ReservePercentOption)) {
      await updateBoardingField('reserveUpfront', Firebase.FieldValue.delete());
    }
  };

  const handleSalesResponsibilityCodeChanged =
    () => async (event: React.ChangeEvent<HTMLInputElement>) => {
      const salesResponsibilityCodeExt = event.target.value === '' ? undefined : event.target.value;
      const salesResponsibilityCode = (agentResponsibilityCode.data || []).find(
        x => x.agentAndSubAgentCode === salesResponsibilityCodeExt
      )?.agentCode;

      await updateBoardingField(
        'salesResponsibilityCodeExt',
        salesResponsibilityCodeExt ?? Firebase.FieldValue.delete()
      );
      await updateBoardingField(
        'salesResponsibilityCode',
        salesResponsibilityCode ?? Firebase.FieldValue.delete()
      );
    };

  useEffect(() => {
    const getFields = (fieldReasons: DisputeReason[]): ConfirmationDialogFieldProps[] => {
      let fieldsToSet: ConfirmationDialogFieldProps[] = [];

      const menuField = new ConfirmationDialogField(
        'hasConditions',
        'Conditionally Approve',
        application.isConditionallyApproved?.toString() || 'false',
        (
          <TextField
            name="hasConditions"
            label="Approve with conditions?"
            margin="normal"
            fullWidth
            variant="outlined"
            select
          >
            <MenuItem value="false">Board as Normal</MenuItem>
            <MenuItem value="true">Board as Conditionally Approved</MenuItem>
          </TextField>
        )
      );

      fieldsToSet.push(menuField);

      const reasonFields = fieldReasons.map(
        (value: DisputeReason): ConfirmationDialogFieldProps => {
          const isReasonSelected = application.boardingNotesConditions?.includes(value.name);

          return new ConfirmationDialogField(
            value.name,
            value.title,
            value.name === 'otherReason'
              ? application.boardingNotes || ''
              : isReasonSelected
              ? 'true'
              : 'false',
            null,
            value.inputType,
            ['hasConditions', 'true']
          );
        }
      );

      let unionFields = [...fieldsToSet, ...reasonFields];

      return unionFields;
    };

    const fieldsSet = getFields(reasons);

    setFields(fieldsSet);
  }, [
    reasons,
    application.boardingNotes,
    application.boardingNotesConditions,
    application.isConditionallyApproved,
  ]);

  const getBoardingConditions = (form: any): string[] => {
    return getBoardingNotesConditions(form)
      .map((reason: DisputeReason) => {
        const disputeReasonMarkup = `${reason?.descriptionHtml}`;
        const fieldMarkup =
          typeof form[reason.name] === 'string' && reason.inputType === 'rich-text'
            ? `<p class="dispute-text">${form[reason.name]}</p>`
            : '';
        return `<div class="dispute-reason">${disputeReasonMarkup}${fieldMarkup}</div>`;
      })
      .filter(field => field !== null) as string[];
  };

  const getBoardingNotesConditions = (form: any): DisputeReason[] => {
    return reasons.filter(
      reason =>
        (form[reason.name] === 'true' && reason.inputType === 'checkbox') ||
        (form[reason.name] && reason.inputType === 'rich-text')
    );
  };

  if (!fields || agentResponsibilityCode.promised) return <Loading />;

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title} variant="body1" component="div" noWrap>
        <PublishIcon /> <b>Boarding Status</b>
      </Typography>
      <Divider />
      <div className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Processor"
              className={classes.fieldSelect}
              value={application.platform ?? ''}
              onChange={handleBoardingFieldChange('platform')}
              error={!application.platform}
              disabled={locked || application.disposition === Dispositions.boarded}
              fullWidth
              InputProps={{
                classes: {
                  input: classes.selectInput,
                },
              }}
              select
            >
              <MenuItem value={''}>&nbsp;</MenuItem>
              {Object.values(ActivePlatforms).sortAndMap((e, i) => (
                <MenuItem key={`${e}-${i}`} value={e}>
                  {e}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Industry Vertical"
              className={classes.fieldSelect}
              value={application.industryVertical ?? ''}
              onChange={handleBoardingFieldChange('industryVertical')}
              error={!application.industryVertical}
              disabled={locked || application.disposition === Dispositions.boarded}
              fullWidth
              InputProps={{
                classes: {
                  input: classes.selectInput,
                },
              }}
              select
            >
              <MenuItem value={''}>&nbsp;</MenuItem>
              {(application.platform
                ? PlatformIndustryVerticals[application.platform] || []
                : Object.values(IndustryVertical)
              ).sortAndMap(
                (e, i) => (
                  <MenuItem key={`${e}-${i}`} value={e}>
                    {IndustryVerticalLabel[e]}
                  </MenuItem>
                ),
                e => IndustryVerticalLabel[e]
              )}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Sales Responsibility Code"
              className={classes.fieldSelect}
              value={
                application.salesResponsibilityCodeExt ?? application.salesResponsibilityCode ?? ''
              }
              onChange={handleSalesResponsibilityCodeChanged()}
              error={!application.salesResponsibilityCode}
              disabled={locked || application.disposition === Dispositions.boarded}
              fullWidth
              InputProps={{
                classes: {
                  input: classes.selectInput,
                },
              }}
              select
            >
              <MenuItem value={''}>&nbsp;</MenuItem>
              {(agentResponsibilityCode.data || []).sortAndMap(
                (e, i) => (
                  <MenuItem key={`${e.agentAndSubAgentCode}-${i}`} value={e.agentAndSubAgentCode}>
                    {e.agentFirstName} {e.agentLastName} - {e.agentAndSubAgentCode}
                  </MenuItem>
                ),
                e => `${e.agentFirstName} ${e.agentLastName} - ${e.agentAndSubAgentCode}`
              )}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Risk Level"
              className={classes.fieldSelect}
              value={application.riskLevel ?? ''}
              onChange={handleBoardingFieldChange('riskLevel')}
              error={!application.riskLevel}
              disabled={!isAdmin && (locked || application.disposition === Dispositions.boarded)}
              fullWidth
              InputProps={{
                classes: {
                  input: classes.selectInput,
                },
              }}
              select
            >
              <MenuItem value={''}>&nbsp;</MenuItem>
              {Object.values(RiskLevel).map((e, i) => (
                <MenuItem key={`${e}-${i}`} value={e}>
                  {startcase(e)} Risk
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="ACH Delay"
              className={classes.fieldSelect}
              value={application.applicationAchDelay ?? ''}
              onChange={handleBoardingFieldChange('applicationAchDelay')}
              error={!application.applicationAchDelay}
              disabled={
                !(isAdmin || (isRiskUserDepartment && !isUnderwriter)) &&
                (locked || application.disposition === Dispositions.boarded)
              }
              fullWidth
              InputProps={{
                classes: {
                  input: classes.selectInput,
                },
              }}
              select
            >
              <MenuItem value={''}>&nbsp;</MenuItem>
              {ApplicationAchDelayOptions.map((e, i) => (
                <MenuItem key={`${e.applicationAchDelay}-${i}`} value={e.applicationAchDelay}>
                  {e.text}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Reserve"
              className={classes.fieldSelect}
              value={application.reservePercentOption ?? ''}
              onChange={handleReserveFieldChange()}
              error={!application.reservePercentOption}
              disabled={
                !(isAdmin || (isRiskUserDepartment && !isUnderwriter)) &&
                (locked || application.disposition === Dispositions.boarded)
              }
              fullWidth
              InputProps={{
                classes: {
                  input: classes.selectInput,
                },
              }}
              select
            >
              <MenuItem value={''}>&nbsp;</MenuItem>
              {ReservePercentDisplayOptions.map((e, i) => (
                <MenuItem key={`${e.reservePercentOption}-${i}`} value={e.reservePercentOption}>
                  {e.text}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          {application.reservePercentOption &&
            ReservePercentCappedOptions.includes(application.reservePercentOption) && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Reserve Cap"
                  className={classes.selectInput}
                  value={reserveCapped}
                  onChange={handleDebouncedNumberFieldChange('reserveCapped', setReserveCapped)}
                  error={!application.reserveCapped}
                  disabled={
                    !(isAdmin || (isRiskUserDepartment && !isUnderwriter)) &&
                    (locked || application.disposition === Dispositions.boarded)
                  }
                  fullWidth
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
            )}
          {application.reservePercentOption &&
            ReservePercentUpfrontOptions.includes(application.reservePercentOption) && (
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  label="Upfront Reserve"
                  className={classes.selectInput}
                  value={reserveUpfront}
                  onChange={handleDebouncedNumberFieldChange('reserveUpfront', setReserveUpfront)}
                  error={!application.reserveUpfront}
                  disabled={
                    !(isAdmin || (isRiskUserDepartment && !isUnderwriter)) &&
                    (locked || application.disposition === Dispositions.boarded)
                  }
                  fullWidth
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
            )}
          <Grid item xs={12}>
            <Button
              color="primary"
              variant="contained"
              onClick={() =>
                setBoardingConfirmationAction(() => (form: any) => handleEnqueueClick(form))
              }
              endIcon={<PublishIcon />}
              disabled={locked || loading || !ready}
              fullWidth
            >
              {loading && <CircularProgress className={classes.buttonProgress} size={24} />}
              {ready ? `Board to ${application.platform}` : 'Not Ready'}
            </Button>

            {!equipmentIsSet && (
              <Alert severity="warning" sx={{marginTop: 2}}>
                Equipment information is required before boarding.
              </Alert>
            )}
          </Grid>
        </Grid>
      </div>
      <ConfirmationDialog
        title="Confirmation"
        maxWidth="sm"
        message={`Are you sure you want to board this account?`}
        fields={fields}
        onClose={() => setBoardingConfirmationAction(null)}
        onConfirm={boardingConfirmationAction}
      />
    </Paper>
  );
};
