import {ApiBase, SearchCriteria} from '@ozark/common';
import {ActiveFilter} from '@ozark/common/components';
import {
  Batch,
  MidActivity,
  PaginatedAuthorizationsResponse,
  PaginatedTransactionsResponse,
  Transaction,
} from '@ozark/functions/src/functions/express/private/types';
import {ContextKey} from '@ozark/functions/src/functions/express/private/types/Context';
import {MinMaxBatchInfo, PaginatedResponse} from '@ozark/functions/src/shared';
import {CancelToken} from 'axios';
import {toQuery} from '../util/FilterUtil';

export type MinMaxQuery = {
  search?: string;
  groupId?: string | null;
  agentId?: string | null;
  riskAssigneeUid?: string | null;
  filters?: ActiveFilter[];
};

export class TransactionsApi extends ApiBase {
  getBatches = (searchCriteria: SearchCriteria, mid?: string, filters?: ActiveFilter[]) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedResponse<Batch>>(`batches?${queryString}`, {
      [ContextKey.Mid]: mid,
    });
  };
  getMinMaxBatches = (
    pagination: SearchCriteria,
    mids: string[] | null,
    query?: MinMaxQuery,
    cancelToken?: CancelToken
  ) => {
    const {groupId, agentId, riskAssigneeUid, filters, search} = query ?? {};
    const queryString = toQuery(pagination, filters);

    let midsQuery = '';
    if (mids !== null) {
      midsQuery = `&mids=${mids.join(',')}`;
    }

    let filtersQuery: Record<string, string> = {};
    if (!!search && search.trim().length > 0) {
      filtersQuery.search = search;
    }

    if (groupId) {
      filtersQuery.groupId = groupId;
    }

    if (agentId) {
      filtersQuery.agentId = agentId;
    }

    if (riskAssigneeUid) {
      filtersQuery.riskAssigneeUid = riskAssigneeUid;
    }

    const filtersQueryString = new URLSearchParams(filtersQuery).toString();

    return this.get<PaginatedResponse<MinMaxBatchInfo>>(
      `batches/min-max?${queryString}${midsQuery}&${filtersQueryString}`,
      undefined,
      cancelToken
    );
  };
  getMidActivityLastMonth = (filters?: ActiveFilter[]) => {
    const queryString = toQuery(null, filters);
    return this.get<MidActivity[]>(`activity?${queryString}`);
  };
  getTransactions = (
    searchCriteria: SearchCriteria,
    mid?: string,
    filters?: ActiveFilter[],
    cancelToken?: CancelToken
  ) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedTransactionsResponse>(
      `transactions?${queryString}`,
      {
        [ContextKey.Mid]: mid,
      },
      cancelToken
    );
  };
  getAuthorizations = (searchCriteria: SearchCriteria, mid?: string, filters?: ActiveFilter[]) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<PaginatedAuthorizationsResponse>(`authorizations?${queryString}`, {
      [ContextKey.Mid]: mid,
    });
  };
  exportTransactions = (searchCriteria: SearchCriteria, mid?: string, filters?: ActiveFilter[]) => {
    const queryString = toQuery(searchCriteria, filters);
    return this.get<Transaction[]>(`transactions/export?${queryString}`, {[ContextKey.Mid]: mid});
  };
}
