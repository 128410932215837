import {ReportExportColumn} from '@ozark/functions/src/functions/common/reports/ReportExportColumn';
import React, {ReactNode} from 'react';

export type Column<TResponseData> = ReportExportColumn<TResponseData> & {
  numeric?: boolean;
  align?: 'left' | 'right' | 'center';
  disablePadding?: boolean;
  sortable?: boolean;
  horizontallySticky?: boolean;
  className?: string;
  info?: React.ReactNode;
  headingTooltip?: ReactNode;
  width?: number;
  selector?: (row: TResponseData, id: string) => ReactNode;
};

export const COLUMN_DEFAULT_WIDTH = 100;
