/* eslint-disable react-hooks/exhaustive-deps */
import {
  Application,
  ApplicationView,
  AsyncState,
  Collections,
  Firebase,
  getWorkflow,
  Profile,
  ProfileView,
  selectProfileView,
  UniversalSnapshot,
  UserRoles,
} from '@ozark/common';
import {MidAndDba as MidInfo} from '@ozark/common/components';
import {MerchantPortfolioShortInfo} from '@ozark/common/types/MerchantInfo';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useAuthUser} from './useAuthUser';

export const useAuthProfile = (authUserStore: ReturnType<typeof useAuthUser>) => {
  const [authProfile, setAuthProfile] = useState<AsyncState<ProfileView>>({promised: true});
  const [impostorRole, setImpostorRole] = useState<UserRoles>();
  const actualRoleRef = useRef<UserRoles>();
  const {authUser} = authUserStore;

  useEffect(() => {
    // user logged out
    if (!authProfile.promised && !authProfile.data?.uid) {
      setImpostorRole(undefined);
      actualRoleRef.current = undefined;
    }
  }, [authProfile.promised, authProfile.data?.uid]);

  useEffect(() => {
    if (authUser.promised) return;
    const unsubscribe = Firebase.firestore
      .collection(Collections.profiles)
      .doc(authUser.data?.uid)
      .onSnapshot(
        snapshot => {
          if (!snapshot.exists) {
            setAuthProfile({promised: false});
            return;
          }
          const data = selectProfileView(snapshot as UniversalSnapshot<Profile>);
          actualRoleRef.current = data.role;
          setAuthProfile({
            promised: false,
            data: {
              ...data,
              role: impostorRole || data.role,
              workflow: impostorRole ? getWorkflow(impostorRole) : data.workflow,
            } as ProfileView,
          });
        },
        err => {
          console.error(err);
          setAuthProfile({promised: false, error: err});
        }
      );
    return () => {
      unsubscribe();
    };
  }, [authUser.promised, authUser.data?.uid, impostorRole]);

  const impersonateRole = useCallback(
    (role: UserRoles | undefined) => {
      if (authProfile.promised || actualRoleRef.current !== UserRoles.admin) return;
      setImpostorRole(role);
    },
    [authProfile.promised]
  );

  const isAllowedToEditApplication = (application: ApplicationView) => {
    const profile = authProfile.data;
    if (!profile || !profile.role || !profile.workflow) {
      return false;
    }

    return (
      profile.workflow.viewable.includes(application.disposition) ||
      profile.role === UserRoles.admin ||
      profile.role === UserRoles.support ||
      profile.role === UserRoles.agentSupport ||
      application.uid === profile.uid
    );
  };

  const getAuthorizedMerchantPortfolio = async (
    id: string
  ): Promise<MerchantPortfolioShortInfo> => {
    return Firebase.firestore
      .collection(Collections.applications)
      .doc(id)
      .get()
      .then(ref => {
        const data = ref.data() as Application;
        return {
          id: ref.id,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          mid: {
            mid: data.mid,
            doingBusinessAs: data.doingBusinessAs,
          } as MidInfo,
          phoneNumber: data.businessPhone,
          createdAt: data.createdAt,
          customerServiceEmail: data.customerServiceEmail,
          accountStatus: data.accountStatus,
        } as MerchantPortfolioShortInfo;
      });
  };

  const isUserAdmin = () => authProfile?.data?.role === UserRoles.admin;
  const isUserSupport = () => authProfile?.data?.role === UserRoles.support;

  return {
    authProfile,
    isUserAdmin,
    isUserSupport,
    impersonateRole,
    allowImpersonation: actualRoleRef.current === UserRoles.admin,
    isAllowedToEditApplication,
    getAuthorizedMerchantPortfolio,
  };
};
