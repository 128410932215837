import {CircularProgress, Grid} from '@mui/material';
import {Square} from '@ozark/common/components';
import {currentFormatter} from '../Reporting';

type Props = {
  totalNetDeposits?: number;
  totalDailyFees?: number;
  totalDisputes?: number;
  totalReserveFunds?: number;
};

const getFormattedOrLoading = (numberStr: number | undefined) => {
  return numberStr === undefined ? <CircularProgress /> : currentFormatter.format(numberStr);
};

export const DepositDayTotals = ({
  totalNetDeposits,
  totalDailyFees,
  totalDisputes,
  totalReserveFunds,
}: Props) => {
  return (
    <>
      <Grid item xs={12} container spacing={1} direction="row">
        <Grid item xs={3}>
          <Square
            lines={{
              'Total Net Deposits': getFormattedOrLoading(totalNetDeposits),
            }}
            center
          />
        </Grid>
        <Grid item xs={3}>
          <Square
            lines={{
              'Total Daily Fees': getFormattedOrLoading(totalDailyFees),
            }}
            center
          />
        </Grid>
        <Grid item xs={3}>
          <Square
            lines={{
              'Total Disputes': getFormattedOrLoading(totalDisputes),
            }}
            center
          />
        </Grid>
        <Grid item xs={3}>
          <Square
            lines={{
              'Total Reserve Funds': getFormattedOrLoading(totalReserveFunds),
            }}
            center
          />
        </Grid>
      </Grid>
    </>
  );
};
