import {useCallback} from 'react';
import {useFormContext, useWatch} from 'react-hook-form';
import {
  AssigneeType,
  AUTOMATION_TICKET_COMMENT_AUTHOR,
  AUTOMATION_TICKET_COMMENT_ROLE,
  AUTOMATION_TICKET_COMMENT_UID,
  camelCaseToTitleCase,
  Collections,
  createAuditNonce,
  getAssigneeName,
  Profile,
  Ticket,
  TicketAssignee,
  TicketComment,
  TicketHistoryRecordType,
  TicketLastCommentInfo,
  TicketStatus,
  TicketStatusName,
  useUserInfo,
} from '../../..';
import {Firebase} from '../../../firebase';
import {
  FIELD_NAME_ASSIGNEE,
  FIELD_NAME_AUDIT,
  FIELD_NAME_BILLING_AUDIT,
  FIELD_NAME_IS_AUDITABLE,
  FIELD_NAME_STATUS,
} from '../constants/constants';
import {useGroupsOptions} from '../hooks/useGroupsOptions';
import {useHistoryRecord} from '../hooks/useHistoryRecord';
import {addHistoryRecordDirect} from '../hooks/useTicketHistoryRecord';

const useTicketUpdate = (ticketId?: string) => {
  const {uid, isPortal, userDisplayName} = useUserInfo();

  const {groups} = useGroupsOptions();

  const {clearErrors, setValue} = useFormContext();

  const assignee: TicketAssignee | undefined = useWatch({name: FIELD_NAME_ASSIGNEE});
  const status: TicketStatus | undefined = useWatch({name: FIELD_NAME_STATUS});
  const auditWatch: TicketStatus | undefined = useWatch({name: FIELD_NAME_AUDIT});
  const billingAuditWatch: TicketStatus | undefined = useWatch({name: FIELD_NAME_BILLING_AUDIT});
  const isAuditableWatch: boolean | undefined = useWatch({name: FIELD_NAME_IS_AUDITABLE});

  const {addHistoryRecord} = useHistoryRecord();

  const updateStatus = useCallback(
    async (nextStatus: TicketStatus) => {
      if (!ticketId) {
        return;
      }

      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);
      const newStatusUpdate: Ticket = {
        status: nextStatus,
        updatedAt: Firebase.now(),
        auditNonce,
      };

      if (nextStatus === TicketStatus.Open) {
        newStatusUpdate.openedAt = Firebase.now();
        // Save info about the original Assignee Department
        await addHistoryRecord(
          `Assignee Changed to ${getAssigneeName(assignee)}`,
          TicketHistoryRecordType.Assignee,
          true,
          `Assignee Changed to ${getAssigneeName(assignee, false)}`,
          true,
          assignee
        );
      }

      if ([TicketStatus.Closed, TicketStatus.Unresolved].includes(nextStatus)) {
        newStatusUpdate.resolvedAt = Firebase.now();
      }

      // Audit and Billing Audit
      // Close Ticket
      if (nextStatus === TicketStatus.Closed && isAuditableWatch) {
        newStatusUpdate.audit = true;
        newStatusUpdate.auditStartedAt = Firebase.now();
        newStatusUpdate.billingAudit = false;
        newStatusUpdate.billingAuditStartedAt = Firebase.FieldValue.delete();

        setValue(FIELD_NAME_AUDIT, true);

        await addHistoryRecordDirect(ticketId, {
          uid: AUTOMATION_TICKET_COMMENT_UID,
          userName: AUTOMATION_TICKET_COMMENT_AUTHOR,
          userRole: AUTOMATION_TICKET_COMMENT_ROLE,
          department: undefined,
          title: `Enabled ${camelCaseToTitleCase(FIELD_NAME_AUDIT)}`,
          recordType: TicketHistoryRecordType.Audit,
          createdAt: Firebase.now(),
          titlePortal: `Enabled ${camelCaseToTitleCase(FIELD_NAME_AUDIT)}`,
        });

        // Leave an Internal Comment
        const ref = await Firebase.firestore
          .collection(Collections.tickets)
          .doc(ticketId)
          .collection(Collections.commentsInternal)
          .add({
            comment:
              '<p>Hello Support, please audit the ticket accordingly, review all applicable items, ERP, PPM, TSYS EXPRESS, and/or FILE BUILDING PORTAL. If this ticket is related to new equipment being shipped out or a new gateway set up by Luqra, please enable the Billing Audit.</p>',
            uid: AUTOMATION_TICKET_COMMENT_UID,
            createdAt: Firebase.FieldValue.now(),
            author: AUTOMATION_TICKET_COMMENT_AUTHOR,
            authorRole: null,
          });

        /**
         * Update Last Internal Comment Info
         */
        const commentSnap = await ref.get();
        if (commentSnap.exists) {
          const comment = commentSnap.data() as TicketComment;
          const lastCommentInfo: TicketLastCommentInfo = {
            commentId: commentSnap.id,
            author: AUTOMATION_TICKET_COMMENT_AUTHOR,
            authorProfileId: AUTOMATION_TICKET_COMMENT_UID,
            authorDepartment: null,
            authorRole: null,
            createdAt: comment.createdAt,
          };

          const fieldsToUpdate: Partial<Ticket> = {};

          fieldsToUpdate.lastInternalCommentInfo = lastCommentInfo;

          await Firebase.firestore
            .collection(Collections.tickets)
            .doc(ticketId)
            .update(fieldsToUpdate);
        }
      }

      // Reopen Ticket
      if (
        status === TicketStatus.Closed &&
        [TicketStatus.Open, TicketStatus.InProgress].includes(nextStatus)
      ) {
        newStatusUpdate.audit = false;
        newStatusUpdate.auditStartedAt = Firebase.FieldValue.delete();
        newStatusUpdate.billingAudit = false;
        newStatusUpdate.auditStartedAt = Firebase.FieldValue.delete();

        setValue(FIELD_NAME_AUDIT, false);
        setValue(FIELD_NAME_BILLING_AUDIT, false);

        if (auditWatch) {
          await addHistoryRecordDirect(ticketId, {
            uid: AUTOMATION_TICKET_COMMENT_UID,
            userName: AUTOMATION_TICKET_COMMENT_AUTHOR,
            userRole: AUTOMATION_TICKET_COMMENT_ROLE,
            department: undefined,
            title: `Disabled ${camelCaseToTitleCase(FIELD_NAME_AUDIT)}`,
            recordType: TicketHistoryRecordType.Audit,
            createdAt: Firebase.now(),
            titlePortal: `Disabled ${camelCaseToTitleCase(FIELD_NAME_AUDIT)}`,
          });
        }

        if (billingAuditWatch) {
          await addHistoryRecordDirect(ticketId, {
            uid: AUTOMATION_TICKET_COMMENT_UID,
            userName: AUTOMATION_TICKET_COMMENT_AUTHOR,
            userRole: AUTOMATION_TICKET_COMMENT_ROLE,
            department: undefined,
            title: `Disabled ${camelCaseToTitleCase(FIELD_NAME_BILLING_AUDIT)}`,
            recordType: TicketHistoryRecordType.BillingAudit,
            createdAt: Firebase.now(),
            titlePortal: `Disabled ${camelCaseToTitleCase(FIELD_NAME_BILLING_AUDIT)}`,
          });
        }
      }
      // /Audit and Billing Audit

      // update adminsNotifiedAt to the current time for continue sending notification after 24 hours of inactivity
      if (status === TicketStatus.Unresolved) {
        newStatusUpdate.adminsNotifiedAt = Firebase.now();
      }

      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .set(newStatusUpdate, {merge: true});

      setValue(FIELD_NAME_STATUS, nextStatus, {shouldValidate: false});

      await addHistoryRecord(
        `Status Change to ${TicketStatusName[nextStatus]}`,
        TicketHistoryRecordType.Status,
        true
      );
    },
    [addHistoryRecord, setValue, status, ticketId, auditWatch, billingAuditWatch, isAuditableWatch]
  );

  const updateAssignee = useCallback(
    async (nextAssignee?: TicketAssignee) => {
      if (!ticketId) {
        return;
      }

      const auditNonce = createAuditNonce(Firebase.auth.currentUser!.uid);

      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .update({assignee: nextAssignee, updatedAt: Firebase.now(), auditNonce});

      setValue(FIELD_NAME_ASSIGNEE, nextAssignee);

      if (
        nextAssignee?.type === AssigneeType.erpDepartment &&
        assignee?.type !== AssigneeType.erpDepartment
      ) {
        await updateStatus(TicketStatus.Open);
      }

      // When a Replied Ticket is Assigned to a New Department, the Ticket status should be NEW
      if (
        status === TicketStatus.Replied &&
        nextAssignee?.type === AssigneeType.erpDepartment &&
        assignee?.erpDepartment !== nextAssignee.erpDepartment
      ) {
        await updateStatus(TicketStatus.Open);
      }

      // When a ticket has been replied to by an Agent or Merchant and sent back to the ERP dept the status is Replied,
      // IF the ERP user assigns the ticket back to Agent or Merchant, the status should be NEW.
      if (
        status === TicketStatus.Replied &&
        assignee?.type === AssigneeType.erpDepartment &&
        (nextAssignee?.type === AssigneeType.agent || nextAssignee?.type === AssigneeType.merchant)
      ) {
        await updateStatus(TicketStatus.Open);
      }

      if (nextAssignee) {
        clearErrors(FIELD_NAME_ASSIGNEE);
        await addHistoryRecord(
          `Assignee Changed to ${getAssigneeName(nextAssignee)}`,
          TicketHistoryRecordType.Assignee,
          undefined,
          `Assignee Changed to ${getAssigneeName(nextAssignee, false)}`,
          undefined,
          nextAssignee
        );
      }
    },
    [assignee?.type, clearErrors, setValue, ticketId, updateStatus, status]
  );

  const updateAssigneeToMe = useCallback(
    async (assigneeDraft?: TicketAssignee) => {
      if (isPortal) {
        await updateAssignee({
          type: AssigneeType.agent,
          group: groups[0],
          agent: {id: uid, name: userDisplayName},
        } as TicketAssignee);
      } else {
        const profileSnapshot = await Firebase.firestore
          .collection(Collections.profiles)
          .doc(uid)
          .get();

        const profile = profileSnapshot.data() as Profile;

        await updateAssignee({
          ...assigneeDraft,
          type: AssigneeType.erpUser,
          erpUser: {id: uid, name: userDisplayName, department: profile.department},
        } as TicketAssignee);
      }

      await updateStatus(TicketStatus.InProgress);
      //update openedAt explicitly because we skip Open status for that case
      await Firebase.firestore
        .collection(Collections.tickets)
        .doc(ticketId)
        .set({openedAt: Firebase.now()}, {merge: true});
    },
    [groups, userDisplayName, updateAssignee, updateStatus]
  );

  return {updateAssignee, updateAssigneeToMe, updateStatus} as const;
};

export {useTicketUpdate};
