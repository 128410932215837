import {useState} from 'react';
import {createContainer} from 'unstated-next';
import {AssigneeType, Department} from '../../..';
import {isAllTicketsViewStorageName} from '../constants/constants';

function useTicketsFilters() {
  // const [sorting, setSorting] = useState<SortingInfo>();
  const [isAllTicketsView, setIsAllTicketsView] = useState(
    localStorage.getItem(isAllTicketsViewStorageName) === 'true'
  );
  const [ticketTypeFilter, setTicketTypeFilter] = useState<string>();
  const [ticketCategoryFilter, setTicketCategoryFilter] = useState<string>();
  const [groupIdFilter, setGroupIdFilter] = useState<string>();
  const [assigneeTypeFilter, setAssigneeTypeFilter] = useState<AssigneeType>();
  const [departmentFilter, setDepartmentFilter] = useState<Department>();
  const [reporterIdFilter, setReporterIdFilter] = useState<string>();
  const [agentIdFilter, setAgentIdFilter] = useState<string>();

  const setIsAllTicketsViewInner = (state: boolean) => {
    setIsAllTicketsView(state);
    localStorage.setItem(isAllTicketsViewStorageName, state.toString());
  };

  return {
    isAllTicketsView,
    setIsAllTicketsView: setIsAllTicketsViewInner,
    ticketTypeFilter,
    setTicketTypeFilter,
    ticketCategoryFilter,
    setTicketCategoryFilter,
    groupIdFilter,
    setGroupIdFilter,
    departmentFilter,
    setDepartmentFilter,
    reporterIdFilter,
    setReporterIdFilter,
    assigneeTypeFilter,
    setAssigneeTypeFilter,
    agentIdFilter,
    setAgentIdFilter,
  };
}

export const TicketsFiltersContainer = createContainer(useTicketsFilters);
export const useTicketsFiltersContainer = TicketsFiltersContainer.useContainer;
