import {orderBy} from 'lodash';
import {useEffect, useState} from 'react';
import {AgentView, CalendarEventParticipant, UserRoles} from '../../..';
import {getAgents} from '../../../firebase/queries/agents';
import {getERPUsers} from '../../../firebase/queries/erpUsers';
import {useUserInfo} from '../../../hooks/useUserInfo';

export function useParticipants() {
  const {role} = useUserInfo();
  const [participantOptions, setParticipantOptions] = useState<CalendarEventParticipant[]>([]);

  useEffect(() => {
    if (!role) return;
    (async () => {
      switch (role) {
        // future requirements (TBD)
        case UserRoles.agent:
          // if i'm a agent master user - myself + erp creator + get all child agents?
          // if i'm a agent user - myself + erp creator?
          break;
        case UserRoles.merchant:
          // if i'm a merchant user - myself + erp creator (agent)?
          break;
        case UserRoles.admin:
        case UserRoles.agentSupport:
        case UserRoles.operations:
        case UserRoles.support:
        case UserRoles.underwriter:
          // if i'm a erp user - myself + get all erp users + all agents?
          const allAgents: CalendarEventParticipant[] = (await getAgents({activeOnly: true})).map(
            (a: AgentView) => {
              return {
                id: a.id,
                name: `${a.firstName} ${a.lastName}`,
                role: 'Agent',
              };
            }
          );
          const allErpUsers: CalendarEventParticipant[] = (
            await getERPUsers({activeOnly: true})
          ).map(u => {
            return {
              id: u.id,
              name: `${u.firstName} ${u.lastName}`,
              role: 'ERP User',
            };
          });
          setParticipantOptions(orderBy([...allAgents, ...allErpUsers], 'name'));
          break;
        default:
          console.error('useParticipants: unsupported role:', role);
          break;
      }
    })();
  }, [role]);

  return {
    participantOptions,
  };
}
