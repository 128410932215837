import FilterListIcon from '@mui/icons-material/FilterList';
import {Box, CircularProgress, Grid, IconButton, Typography} from '@mui/material';
import {AllMIDs, ScheduledJobType} from '@ozark/common';
import {DATE_FORMAT} from '@ozark/functions/src/functions/common/DateUtils';
import {DepositColumnsConfigRecord} from '@ozark/functions/src/functions/common/reports/columnConfigs/DepositColumnConfig';
import {
  DepositCpyDayView,
  DepositDayDetails,
  DepositTotals,
  PaginatedDepositsCpyDaysResponse,
  PaginatedDepositsDaysViewResponse,
} from '@ozark/functions/src/functions/express/private/types';
import {
  mapToDepositsCpyDaysCsvData,
  mapToPaginatedDepositsCpyDaysResponse,
} from '@ozark/functions/src/functions/express/private/utils/mapToDepositsCpyDaysCsvData';
import {isEmpty} from '@s-libs/micro-dash';
import {CancelTokenSource} from 'axios';
import {format, utcToZonedTime} from 'date-fns-tz';
import {useCallback, useEffect, useState} from 'react';
import {Column} from '../../../api/Column';
import {CancelOperationMessage} from '../../../api/Constants';
import {getScheduledReportParameters} from '../../../api/ScheduledReportParameters';
import {SearchCriteria} from '../../../api/SearchCriteria';
import {useApiContainer, useMidsContainer} from '../../../store';
import {ExportProps} from '../../common';
import {ScheduleReportProps} from '../../common/ButtonEmailReport';
import {ActiveFilter, Filters} from '../../Filters';
import {Loading} from '../../Loading';
import {IHistorySubtable, Table} from '../../Table';
import {Bar, Filter, FilterUpdate} from '../common';
import {forceMtdActiveFilter} from '../common/Utils/forceMtdActiveFilter';
import {getFiltersMidFirst} from '../common/Utils/getFiltersMidFirst';
import {getAmountSelector} from '../helpers';
import {forceActiveFilter, useReportingPageStyles} from '../Reporting';
import {DepositDayTotals} from './DepositDayTotal';
import {DepositsCpyDaysFilters} from './DepositsCpyDaysFilters';
import {DepositsDaysFilters} from './Types';

const DefaultCriteria: SearchCriteria = {
  limit: 20, // page size
  offset: 1, // page
  order: 'desc',
  orderBy: 'depositDate',
};
export const DepositsCpyDays = () => {
  const classes = useReportingPageStyles();
  const apiClient = useApiContainer();
  const [depositsCpyDaysView, setDepositsCpyDaysView] =
    useState<PaginatedDepositsDaysViewResponse | null>();
  const [depositsCpyDays, setDepositsCpyDays] = useState<PaginatedDepositsCpyDaysResponse | null>();
  const [depositTotals, setDepositTotals] = useState<DepositTotals | null>();
  const [depositDayDetails, setDepositDayDetails] = useState<DepositDayDetails[] | null>();
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>(DefaultCriteria);
  const [filters, setFilters] = useState<{[_: string]: ActiveFilter}>({});
  const {mids, midsOptions, selectedMid, handleSelectMid, forceSelectMidIfNeeded} =
    useMidsContainer();
  const [_, setCancelTokenSource] = useState<CancelTokenSource | undefined>();
  const setFiltersAndResetOffset = (_filters: Filter | null, filterUpdate?: FilterUpdate) => {
    if (filterUpdate) {
      setFilters(filters => getFiltersMidFirst(filters, filterUpdate));
    } else {
      setFilters(_filters ?? {});
    }
    setSearchCriteria(oldSearchCriteria => ({...oldSearchCriteria, offset: 1}));
  };

  const forceFilterByMid = (newMid: string) => {
    const forcedFilterMid = forceActiveFilter(DepositsDaysFilters, 'mid', '__eq', newMid);
    setFiltersAndResetOffset(null, {updateColumnName: 'mid', filter: forcedFilterMid});
  };

  const forceFilterByMtd = () => {
    const forcedFilter = forceMtdActiveFilter(DepositsDaysFilters, 'depositDate');
    setFiltersAndResetOffset(null, {updateColumnName: 'depositDate', filter: forcedFilter});
  };

  const getAllDataForExport = useCallback(async () => {
    if (!apiClient) return [];

    const pageConfigFull: SearchCriteria = {...searchCriteria, offset: 0, limit: 0};
    const days = await apiClient.depositsCpyDays.getDeposits(
      pageConfigFull,
      selectedMid,
      Object.values(filters)
    );

    const details = await apiClient.depositsCpyDays.getDepositsDetails(
      pageConfigFull,
      selectedMid,
      Object.values(filters)
    );

    return mapToDepositsCpyDaysCsvData(days, details);
  }, [searchCriteria, selectedMid, filters]);

  const exportProps: ExportProps = {
    filename: 'deposits-report',
    rows: depositsCpyDaysView?.data,
    columnsConfig: exportColumnsConfig,
    getRows: getAllDataForExport,
  };

  const getJobParametersFn = useCallback(() => {
    return getScheduledReportParameters(searchCriteria, filters);
  }, [filters, searchCriteria]);

  const scheduleReportProps: ScheduleReportProps = {
    jobType: ScheduledJobType.midReportingDeposits,
    getJobParameters: getJobParametersFn,
  };

  useEffect(() => {
    forceFilterByMtd();
  }, []);

  useEffect(() => {
    if (mids.promised || !mids.data) return;

    forceSelectMidIfNeeded();
  }, [mids, forceSelectMidIfNeeded]);

  useEffect(() => {
    if (selectedMid === AllMIDs) return;

    forceFilterByMid(selectedMid);
  }, [selectedMid]);

  useEffect(() => {
    if (isEmpty(filters)) return;
    const cancelRequestToken = cancelRequests();
    setDepositsCpyDaysView(null);
    setDepositTotals(null);
    setDepositsCpyDays(null);
    setDepositDayDetails(null);

    getDeposits(cancelRequestToken);
    getDepositsTotal(cancelRequestToken);
    getDepositsDayDetails(cancelRequestToken);
    // eslint-disable-next-line
  }, [searchCriteria, filters]);

  useEffect(() => {
    if (!depositsCpyDays) {
      return;
    }
    setPaginatedDepositsCpyDaysResponse(depositsCpyDays, depositDayDetails);
  }, [depositsCpyDays, depositDayDetails]);

  const setPaginatedDepositsCpyDaysResponse = (
    days: PaginatedDepositsCpyDaysResponse,
    details: DepositDayDetails[] | null | undefined
  ) => {
    if (!days) {
      setDepositsCpyDaysView(null);
      return;
    }

    const paginatedDepositsDaysViewResponse = mapToPaginatedDepositsCpyDaysResponse(
      days,
      details ? details : null,
      true
    );
    setDepositsCpyDaysView(paginatedDepositsDaysViewResponse);
  };

  const cancelRequests = () => {
    const cancelSource = apiClient?.depositsCpyDays.getCancelTokenSource();
    setCancelTokenSource(prev => {
      //Check if there are any previous pending requests
      if (prev !== undefined) {
        prev.cancel(CancelOperationMessage);
      }
      return cancelSource;
    });
    return cancelSource;
  };
  const getDeposits = (cancelSource: CancelTokenSource | undefined) => {
    apiClient?.depositsCpyDays
      .getDeposits(searchCriteria, selectedMid, Object.values(filters), cancelSource?.token)
      .then((result: any) => {
        setDepositsCpyDays(result);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getDepositsTotal = (cancelSource: CancelTokenSource | undefined) => {
    apiClient?.depositsCpyDays
      .getDepositsTotal(searchCriteria, selectedMid, Object.values(filters), cancelSource?.token)
      .then((result: any) => {
        setDepositTotals(result || null);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const getDepositsDayDetails = (cancelSource: CancelTokenSource | undefined) => {
    apiClient?.depositsCpyDays
      .getDepositsDetails(searchCriteria, selectedMid, Object.values(filters), cancelSource?.token)
      .then((result: any) => {
        setDepositDayDetails(result || null);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleRetrieveData = (searchCriteria: SearchCriteria) => {
    setSearchCriteria(searchCriteria);
  };

  const onMidSelect = useCallback(
    (mid: string) => {
      if (mid !== selectedMid) {
        handleSelectMid(mid);
        forceFilterByMid(mid);
      }
    },
    [selectedMid]
  );

  const handleDeleteFilter = (id: string) => () => {
    const _filters = {...filters};
    delete _filters[id];
    setFiltersAndResetOffset(_filters);
  };

  const handleApplyFilter = (filter: ActiveFilter) => {
    setFiltersAndResetOffset({...filters, [filter.option.column]: filter});
  };

  if (mids.promised || !mids.data) return <Loading />;

  return (
    <div className={classes.root}>
      <Bar
        title="Deposits"
        midsLoaded={!mids.promised}
        selectedMid={selectedMid}
        mids={midsOptions}
        onMidSelect={onMidSelect}
        Filters={<Filters options={DepositsDaysFilters} onApplyFilter={handleApplyFilter} />}
        exportProps={exportProps}
        scheduleReportProps={scheduleReportProps}
      />

      {!mids.promised && isEmpty(midsOptions) && (
        <Typography className={classes.noContent}>
          There are no MIDs associated with your account
        </Typography>
      )}

      <Grid container spacing={2} direction="row" alignItems="stretch">
        <Grid item xs={12}>
          {filters && !isEmpty(filters) && (
            <IconButton disabled size="large">
              <FilterListIcon />
            </IconButton>
          )}
          <DepositsCpyDaysFilters filters={filters} handleDeleteFilter={handleDeleteFilter} />
        </Grid>

        <DepositDayTotals
          totalNetDeposits={depositTotals?.totalNetDeposits}
          totalDailyFees={depositTotals?.totalDailyFees}
          totalDisputes={depositTotals?.totalDisputes}
          totalReserveFunds={depositTotals?.totalReserveFunds}
        />
        <Grid item xs={12}>
          {depositsCpyDaysView && isEmpty(depositsCpyDaysView) && (
            <Typography className={classes.noContent}>No Deposits</Typography>
          )}
          {!depositsCpyDaysView && (
            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </Box>
          )}
          {depositsCpyDaysView && !isEmpty(depositsCpyDaysView) && (
            <Table
              stickyHeader
              scrollableBody
              customHeight="75vh"
              columns={columnsConfig}
              historyColumns={historyColumnsConfig}
              data={depositsCpyDaysView}
              onRetrieveData={handleRetrieveData}
              paginate
              hideHistoryBorderBottom
              getCellProps={(column, row) => ({
                sx: [
                  column.id === 'history' && {
                    width: '180px',
                  },
                  {
                    borderBottom: 'none',
                  },
                  row.isTotal && {
                    fontWeight: 700,
                  },
                  row.isTotal &&
                    column.id == 'ddaNumber' && {
                      textAlign: 'right',
                    },
                ],
              })}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const columnsConfig: Column<DepositCpyDayView>[] = [
  {
    numeric: false,
    sortable: true,
    selector: row => getDate(row.depositDate),
    ...DepositColumnsConfigRecord.depositDate,
  },
  {
    numeric: false,
    sortable: true,
    selector: row =>
      row.statementDate
        ? format(utcToZonedTime(new Date(row.statementDate), 'UTC'), 'MMMM yyyy')
        : '',
    ...DepositColumnsConfigRecord.statementDate,
  },
  {
    numeric: false,
    sortable: false,
    ...DepositColumnsConfigRecord.routingNumber,
  },
  {
    numeric: false,
    sortable: true,
    ...DepositColumnsConfigRecord.ddaNumber,
  },
  {
    numeric: false,
    sortable: true,
    selector: row => getAmountSelector(row.depositAmount),
    ...DepositColumnsConfigRecord.depositAmount,
  },
];

const exportColumnsConfig: Column<DepositCpyDayView>[] = [
  ...columnsConfig,
  {
    numeric: false,
    sortable: false,
    selector: row => row.referenceNumber ?? '',
    ...DepositColumnsConfigRecord.referenceNumber,
  },
];

const historyColumnsConfig: IHistorySubtable<DepositDayDetails, DepositCpyDayView> = {
  title: '',
  calculatedTitle: (row: DepositCpyDayView) => '',
  hideIfEmpty: true,
  useLoadingBtn: true,
  buttonText: 'Details',
  historyProperty: 'depositDayDetails',
  columns: [
    {
      id: 'referenceNumber',
      numeric: false,
      sortable: false,
      export: true,
      label: 'Reference #/Dispute Case #',
      selector: row => `${row.chargebackCaseNumber || getReferenceNumber(row)}`,
    },
    {
      id: 'batchTotal',
      label: 'Batch Total(s)',
      numeric: false,
      sortable: true,
      export: true,
      selector: row => getAmountSelector(row.batchTotal),
    },
    {
      id: 'dailyFees',
      label: 'Daily Fees',
      numeric: false,
      sortable: true,
      export: true,
      selector: row => getAsNegative(row.dailyFees),
    },
    {
      id: 'chargeBackAmount',
      label: 'Disputes',
      numeric: false,
      sortable: true,
      export: true,
      selector: row => getAmountSelector(row.chargeBackAmount),
    },
    {
      id: 'netBatches',
      label: 'Net Batches',
      numeric: false,
      sortable: true,
      export: true,
      selector: row => getAmountSelector(row.netBatches),
    },
    {
      id: 'reservedFunds',
      label: 'Reserved Funds',
      numeric: false,
      sortable: true,
      export: true,
      selector: row => getAsNegative(row.reservedFunds),
    },
    {
      id: 'adjustmentAmount',
      label: 'Adjustments',
      numeric: false,
      sortable: true,
      export: true,
      selector: row => getAmountSelector(row.adjustmentAmount),
    },
  ],
};

// UTILS
const getDate = (date: Date | undefined): string => {
  if (!date) return '';
  const dateTime = new Date(date);
  const localTime = utcToZonedTime(dateTime, Intl.DateTimeFormat().resolvedOptions().timeZone);
  return format(localTime, DATE_FORMAT);
};

const getAsNegative = (amount: number) => {
  if (amount == 0) {
    return getAmountSelector(amount);
  }
  return getAmountSelector(-1 * amount);
};

const getReferenceNumber = (row: DepositDayDetails) => {
  const chargeBackAmount = row.chargeBackAmount;
  if (!chargeBackAmount) {
    return row.referenceNumber;
  }
  return null;
};
