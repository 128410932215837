import {
  AssigneeType,
  Collections,
  Firebase,
  InfiniteSnapshotOptions,
  selectTicketView,
  TicketStatus,
  TicketView,
  useInfiniteSnapshots,
  ViewableByType,
} from '@ozark/common';
import {subDays} from 'date-fns';
import firebase from 'firebase/compat/app';
import {collection, getCountFromServer, query, where} from 'firebase/firestore';
import {useEffect, useMemo, useState} from 'react';
import {useUserInfo} from '../../../hooks';
import {ticketStatusFolders} from '../constants/ticketStatusFolders';
import {FilterKey, TFolder, TicketsFolderId} from '../types';
import {useTicketsFiltersContainer} from './useTicketsFiltersContainer';
import {TicketsSortBy, useTicketsQuery} from './useTicketsQuery';

export function useTicketsAllErp(statusSet: TicketStatus[], filterKey?: FilterKey) {
  const {sortBy, sortDir, folderId} = useTicketsQuery();
  const {uid} = useUserInfo();
  const {
    assigneeTypeFilter,
    departmentFilter,
    groupIdFilter,
    ticketTypeFilter,
    ticketCategoryFilter,
  } = useTicketsFiltersContainer();

  const getTicketsFilter = useMemo(
    () =>
      (
        query: firebase.firestore.Query<firebase.firestore.DocumentData>
      ): firebase.firestore.Query<firebase.firestore.DocumentData> => {
        query = query.where('status', 'in', statusSet);

        const folder = ticketStatusFolders.find(folder => folder.id === folderId);

        // Draft are always local
        if (statusSet.includes(TicketStatus.Draft)) {
          query = query.where('author.id', '==', uid);
        }

        // Filters
        if (folder?.erpFilters?.whereQuery) {
          query = folder.erpFilters.whereQuery(query);
        }

        if (folderId === TicketsFolderId.Audit) {
          query = query.where('audit', '==', true);
        } else if (folderId === TicketsFolderId.BillingAudit) {
          query = query.where('billingAudit', '==', true);
        }

        if (ticketTypeFilter) {
          query = query.where('category.type.name', '==', ticketTypeFilter);
        }
        if (ticketCategoryFilter) {
          query = query.where('category.category.name', '==', ticketCategoryFilter);
        }
        if (assigneeTypeFilter) {
          query = query.where('assignee.type', '==', assigneeTypeFilter);
        }
        if (assigneeTypeFilter === AssigneeType.erpDepartment && departmentFilter) {
          query = query.where('assignee.erpDepartment', '==', departmentFilter);
        }
        if (assigneeTypeFilter === AssigneeType.erpUser && departmentFilter) {
          query = query.where('assignee.erpUser.department', '==', departmentFilter);
        }
        if (assigneeTypeFilter === AssigneeType.agent && groupIdFilter) {
          query = query.where('assignee.group.id', '==', groupIdFilter);
        }

        switch (filterKey) {
          case FilterKey.openedMoreThan24hAgo:
            query = query.where('openedAt', '<=', subDays(new Date(), 1));
            break;

          case FilterKey.auditStartedMoreThan24hAgo:
            query = query.where('auditStartedAt', '<=', subDays(new Date(), 1));
            break;

          case FilterKey.billingAuditStartedMoreThan24hAgo:
            query = query.where('billingAuditStartedAt', '<=', subDays(new Date(), 1));
            break;
        }

        return query;
      },
    [
      uid,
      statusSet,
      assigneeTypeFilter,
      departmentFilter,
      groupIdFilter,
      ticketTypeFilter,
      ticketCategoryFilter,
      filterKey,
      folderId,
    ]
  );

  const limit = 100;

  // Initially for drafts we used "createdAt" as order by. But in that case we have an error
  // FirebaseError: Invalid query. You have a where filter with an inequality (<, <=, !=, not-in, >, or >=) on field 'openedAt' and so you must also use 'openedAt' as your first argument to Query.orderBy(), but your first orderBy() is on field 'createdAt' instead.
  // For drafts it is fine to use openedAt in order by because long time ago we started to populate 'openedAt' with the same value as 'createdAt'
  const orderBy: TicketsSortBy = sortBy;

  const [options, setOptions] = useState<Partial<InfiniteSnapshotOptions>>({
    orderBy: orderBy,
    order: sortDir,
    limit: limit,
    filter: getTicketsFilter,
  });

  const {documents: infiniteTickets, next: infiniteTicketsNext} = useInfiniteSnapshots<TicketView>(
    Collections.tickets,
    selectTicketView,
    options
  );

  useEffect(() => {
    let nextOrderBy = orderBy;

    // override orderBy props to avoid inequality filter and orderBy
    if (filterKey === FilterKey.auditStartedMoreThan24hAgo) {
      nextOrderBy = 'auditStartedAt';
    } else if (filterKey === FilterKey.billingAuditStartedMoreThan24hAgo) {
      nextOrderBy = 'billingAuditStartedAt';
    }

    setOptions({
      orderBy: nextOrderBy,
      order: sortDir,
      limit: limit,
      filter: getTicketsFilter,
    });
  }, [sortBy, sortDir, getTicketsFilter, filterKey]);

  const hasData = Boolean(infiniteTickets?.size) || infiniteTickets.hasNextPage;
  const loading = Boolean(infiniteTickets?.promised) || (infiniteTickets?.size === 0 && hasData);

  return {
    tickets: infiniteTickets,
    counter: infiniteTickets.size ?? 0,
    infiniteTicketsNext,
    loading,
    hasData,
  };
}

type UseTicketsAllErpCountProps = {
  statusSet: TFolder['statusSet'];
  filterKey?: TFolder['filterKey'];
  erpFilters?: TFolder['erpFilters'];
};
export function useTicketsAllErpCount({
  statusSet,
  filterKey,
  erpFilters,
}: UseTicketsAllErpCountProps): {count: number; refetch: () => void} {
  const [ticketsCount, setTicketsCount] = useState(0);
  const {userGroupId, isErpUser, isAgent, uid} = useUserInfo();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    async function fetchCount() {
      const ref = collection(Firebase.firestore, Collections.tickets);
      let q = query(ref, where('status', 'in', statusSet));

      if (statusSet.includes(TicketStatus.Draft)) {
        q = query(q, where('author.id', '==', uid));
      }

      if (filterKey) {
        switch (filterKey) {
          case FilterKey.openedMoreThan24hAgo:
            q = query(q, where('openedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.auditStartedMoreThan24hAgo:
            q = query(q, where('auditStartedAt', '<=', subDays(new Date(), 1)));
            break;

          case FilterKey.billingAuditStartedMoreThan24hAgo:
            q = query(q, where('billingAuditStartedAt', '<=', subDays(new Date(), 1)));
            break;
        }
      }

      if (!isErpUser) {
        if (isAgent) {
          q = query(q, where('viewableBy', 'in', [ViewableByType.agentOrErp, ViewableByType.all]));
        } else {
          q = query(q, where('viewableBy', '==', ViewableByType.all));
        }
      }

      if (isErpUser && erpFilters?.whereCount) {
        q = query(q, erpFilters.whereCount);
      }

      const countQuery = await getCountFromServer(q);
      setTicketsCount(countQuery.data().count);
    }

    fetchCount();
  }, [statusSet, isErpUser, isAgent, filterKey, userGroupId, uid, counter]);

  return {count: ticketsCount, refetch: () => setCounter(counter => counter + 1)};
}
