import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import {TicketStatus} from '@ozark/common';
import {useWatch} from 'react-hook-form';
import {useUserInfo} from '../../../hooks/useUserInfo';
import {FIELD_NAME_IS_AUDITABLE} from '../constants/constants';
import {useTicketStatus} from '../hooks/useTicketStatus';
import {Assignee} from './Assignee/Assignee';
import {Association} from './Association/Association';
import {AuthorInfo} from './Author/AuthorInfo';
import {TicketCalendarEvents} from './Calendar/TicketCalendarEvents';
import {Category} from './Category/Category';
import {DetailsRow} from './DetailsRow';
import {IdInfo} from './Id/IdInfo';
import {InputLabels} from './Labels/InputLabels';
import {InputLinkedTickets} from './LinkedTickets/InputLinkedTickets';
import {InputPriority} from './Priority/InputPriority';
import {TicketAudits} from './TicketAudits/TicketAudits';
import {InputViewableByDetail} from './ViewableBy/InputViewableByDetail';

export function TicketEditDetails() {
  const {isDraft, status} = useTicketStatus();
  const {isPortal, isMerchant, isErpUser} = useUserInfo();
  const isAuditableWatch: boolean | undefined = useWatch({name: FIELD_NAME_IS_AUDITABLE});

  return (
    <TableContainer>
      <Table>
        <TableBody>
          <DetailsRow name={<span>Category*</span>}>
            <Category />
          </DetailsRow>
          <DetailsRow name={<span>Assignee*</span>}>
            <Assignee />
          </DetailsRow>
          <DetailsRow name={<span>Association*</span>}>
            <Association />
          </DetailsRow>
          <DetailsRow name="Creator">
            <AuthorInfo />
          </DetailsRow>
          {!isMerchant && (
            <DetailsRow name="Viewable By" verticalAlign="middle">
              <InputViewableByDetail />
            </DetailsRow>
          )}
          <DetailsRow name="Priority" verticalAlign="middle">
            <InputPriority />
          </DetailsRow>
          <DetailsRow name="ID" verticalAlign="middle">
            <IdInfo />
          </DetailsRow>
          <DetailsRow name="Labels" verticalAlign="middle">
            <InputLabels />
          </DetailsRow>

          {isErpUser && (
            <DetailsRow name="Linked Tickets" verticalAlign="middle">
              <InputLinkedTickets />
            </DetailsRow>
          )}

          {!isDraft && !isPortal && (
            <DetailsRow name="Calendar" verticalAlign="top" paddingTop={2}>
              <TicketCalendarEvents />
            </DetailsRow>
          )}

          {isAuditableWatch && !isDraft && !isPortal && status === TicketStatus.Closed && (
            <DetailsRow name="" verticalAlign="top" paddingTop={2}>
              <TicketAudits />
            </DetailsRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
