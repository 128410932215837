import {Box} from '@mui/material';
import {useAgent} from '../../hooks';
import {VipIcon} from './VipIcon';

type Props = {
  id?: string;
};
export const AgentVipStatus = ({id}: Props) => {
  const {
    document: {data: agent},
    set,
  } = useAgent(id);

  return agent?.isVip ? (
    <Box
      sx={{
        color: '#1cab1c',
        fontSize: '3rem',
      }}
    >
      <VipIcon />
    </Box>
  ) : null;
};
