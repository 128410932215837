import {Box, Divider, Tooltip, Typography} from '@mui/material';
import {TicketSearchItemModel} from '../../../../index';
import {BoxEllipsis, BoxFlexed} from '../../../common';

type Props = {
  ticketSearchItem: TicketSearchItemModel;
};

export const LinkedTicketSearchItem = ({ticketSearchItem}: Props) => {
  if (!ticketSearchItem) {
    return <Typography>no data item</Typography>;
  }

  return (
    <>
      <Typography variant="body2" width="100%" fontSize={12} component="div">
        <BoxFlexed justifyContent="space-between" width="100%" alignItems="center">
          <Box width="20%" pr={1}>
            {ticketSearchItem.dba ?? ''}
          </Box>

          <Divider orientation="vertical" flexItem variant="middle" />
          <Box width="20%" pr={1}>
            {ticketSearchItem.legalName ?? ''}
          </Box>

          <Divider orientation="vertical" flexItem variant="middle" />

          <Box width="10%" px={1} display="flex" alignItems="center">
            {ticketSearchItem.distinguishableId ?? ''}
          </Box>

          <Divider orientation="vertical" flexItem variant="middle" />

          <Box width="20%" px={1} display="flex" alignItems="center">
            {`${ticketSearchItem.typeName ?? ''}: ${ticketSearchItem.categoryName ?? ''}`}
          </Box>

          <Divider orientation="vertical" flexItem variant="middle" />

          <Box width="30%" px={1} display="flex" alignItems="center">
            <Tooltip title={ticketSearchItem.subject ?? ''}>
              <BoxEllipsis alignItems="flex-start" maxWidth={400} mb={0.5}>
                {ticketSearchItem.subject ?? ''}
              </BoxEllipsis>
            </Tooltip>
          </Box>
        </BoxFlexed>
      </Typography>
    </>
  );
};
