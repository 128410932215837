import {SearchOptions} from '@algolia/client-search';
import {
  TicketSearchItemModel,
  TicketStatus,
  TicketStatusName,
} from '@ozark/functions/src/documents';
import {flatten} from '@s-libs/micro-dash';
import algoliasearch, {SearchClient} from 'algoliasearch';
import {useCallback, useEffect, useRef} from 'react';
import {useCallable} from './useCallable';
import {useUserInfo} from './useUserInfo';

const attributesToRetrieveErp = [
  'distinguishableId',
  'subject',
  'description',
  'assignee',
  'association',
  'associationId',
  'labels',
  'creator',
  'status',
  'openedAt',
  'dba',
  'legalName',
  'typeName',
  'categoryName',
];

const attributesToRetrievePortal = [
  'distinguishableId',
  'subject',
  'description',
  'assigneePortal',
  'association',
  'associationId',
  'labels',
  'creatorPortal',
  'status',
  'openedAt',
];

const searchOptions: SearchOptions = {
  hitsPerPage: 100, // hard limit set by Algolia is 1000 hits per page
  page: 0,
  analytics: false,
  exactOnSingleWordQuery: 'word' as const,
  attributesToRetrieve: attributesToRetrievePortal,
  typoTolerance: 'min' as const,
};

export const useAlgoliaSearchTickets = () => {
  const algoliaClientRef = useRef<SearchClient>();
  const {getAlgoliaSearchKey} = useCallable();
  const {isErpUser} = useUserInfo();

  useEffect(() => {
    if (algoliaClientRef.current) {
      return;
    }

    getAlgoliaSearchKey('tickets').then(result => {
      if (!result || result.status !== 'ok' || !result.key) {
        return;
      }

      algoliaClientRef.current = algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID as string,
        result.key
      );
    });
  }, []);

  const search = useCallback((request: {input: string}, callback: (results?: any[]) => void) => {
    if (!algoliaClientRef.current) {
      return;
    }

    // We should re-generate key to avoid result memoization
    getAlgoliaSearchKey('tickets').then(result => {
      if (!result || result.status !== 'ok' || !result.key) {
        return;
      }

      algoliaClientRef.current = algoliasearch(
        process.env.REACT_APP_ALGOLIA_APP_ID as string,
        result.key
      );
    });

    const attributesToRetrieve = isErpUser ? attributesToRetrieveErp : attributesToRetrievePortal;

    let queries = [
      {
        indexName: process.env.REACT_APP_ALGOLIA_INDEX_TICKETS as string,
        query: request.input,
        params: {
          ...searchOptions,
          attributesToRetrieve: attributesToRetrieve,
        },
      },
    ];

    algoliaClientRef.current.search<TicketSearchItemModel>(queries).then(response => {
      const {results} = response;

      const suggestions = results.map(result => {
        if (result.hits.length === 0) {
          return [];
        }

        let suggestions = result.hits.map(hit => {
          return {
            id: hit.objectID,
            searchGroup: TicketStatusName[hit!.status as TicketStatus],
            ...hit,
            openedAt: hit.openedAt ? new Date(hit.openedAt) : undefined,
          };
        });

        return suggestions;
      });

      const flattened: TicketSearchItemModel[] = flatten(suggestions);

      const header: TicketSearchItemModel = {
        id: '',
      };

      flattened.unshift(header);

      callback(flattened);
    });
  }, []);

  return {
    client: algoliaClientRef.current,
    search: search,
  };
};
