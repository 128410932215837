import firebase from 'firebase/compat/app';
import {FieldValues, UseFormSetValue} from 'react-hook-form';
import {
  AssigneeType,
  Ticket,
  TicketHistoryRecord,
  TicketHistoryRecordType,
  TicketStatus,
  TicketSubCollections,
} from '..';
import {FIELD_NAME_ASSIGNEE, FIELD_NAME_STATUS} from '../components/Tickets/constants/constants';
import {getTicketCategory} from '../components/Tickets/utils/ticketCategory';

export const getAssigneeForRepliedStatus = async (
  ticketRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
) => {
  const ticketHistorySnap = await ticketRef
    .collection(TicketSubCollections.history)
    .orderBy('createdAt', 'desc')
    .get();

  for (const ticketHistoryRecordDoc of ticketHistorySnap.docs) {
    const ticketHistoryRecord = ticketHistoryRecordDoc.data() as TicketHistoryRecord;

    if (
      ticketHistoryRecord.recordType !== TicketHistoryRecordType.Assignee ||
      !ticketHistoryRecord.assignee
    ) {
      continue;
    }

    const assignee = ticketHistoryRecord.assignee;
    if (assignee.type === AssigneeType.erpDepartment) {
      return assignee;
    } else if (assignee.type === AssigneeType.erpUser && assignee.erpUser?.department) {
      return {
        type: AssigneeType.erpDepartment,
        erpDepartment: assignee.erpUser.department,
      };
    }
  }

  const ticketSnap = await ticketRef.get();
  const {category: typeAndCategory} = ticketSnap.data() as Ticket;
  if (typeAndCategory?.category && typeAndCategory?.type) {
    const ticketTypeCategory = await getTicketCategory(
      typeAndCategory.type.id,
      typeAndCategory.category.id
    );

    if (ticketTypeCategory && ticketTypeCategory.assignment) {
      return {
        type: AssigneeType.erpDepartment,
        erpDepartment: ticketTypeCategory.assignment,
      };
    }
  }
};

export const replyOnTicket = async (
  ticketRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>,
  setValue?: UseFormSetValue<FieldValues>
) => {
  const nextAssignee = await getAssigneeForRepliedStatus(ticketRef);

  if (!nextAssignee) {
    return {}; // we should not be here due to default department assigned and stored in the very first hidden history record
  }

  await ticketRef.update({assignee: nextAssignee, status: TicketStatus.Replied});

  if (setValue) {
    setValue(FIELD_NAME_ASSIGNEE, nextAssignee);
    setValue(FIELD_NAME_STATUS, TicketStatus.Replied);
  }

  return {nextAssignee} as const;
};

export const getIsTicketAllowedToAutoReply = async (
  ticketRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData>
) => {
  const ticketSnap = await ticketRef.get();
  const ticketData = ticketSnap.data() as Ticket;

  return (
    ticketData.assignee?.type !== AssigneeType.erpDepartment &&
    ticketData.assignee?.type !== AssigneeType.erpUser
  );
};
