import {Grid, Typography} from '@mui/material';
import {
  MidCbInfo,
  MidCbInfoByCardType,
} from '@ozark/functions/src/functions/express/private/types/Chargebacks';
import {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {ButtonExportCsv, percentFormatter, Square, Table} from '../..';
import {useApiContainer} from '../../..';
import {Column} from '../../../api/Column';
import {useMid} from '../../../hooks';
import {formatAsPercent, formatterCurrency, formatterNumber} from '../../../util';
import {CardImage, CardImagesDictionaryType} from '../../CardImage';
import {LoadingStatus} from '../common/LoadingStatus';
import {ReportingMonthTitle} from '../common/ReportingMonthTitle';
import {useDataSorted} from '../common/useDataSorted';
import {useQueryMonthYear} from '../common/useQueryMonthYear';

const TITLE = 'MIDs by Chargebacks';

export const MidsByChargebacksTableDetails = ({
  breadcrumbs,
  setBreadcrumbs,
}: {
  breadcrumbs: React.ReactNode[];
  setBreadcrumbs: (els: React.ReactNode[]) => void;
}) => {
  const api = useApiContainer();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const {year, month} = useQueryMonthYear();
  const {mid} = useParams<{mid: string}>();
  const [midCbInfo, setMidCbInfo] = useState<MidCbInfo>();
  const {dataSorted, sort, hasData, handleRetrieveData} = useDataSorted<MidCbInfoByCardType>(
    midCbInfo?.chargebacksByCardType
  );
  const midInfo = useMid(mid);

  useEffect(() => {
    if (Boolean(mid && year && month)) {
      setLoading(true);
      api?.chargebacks
        .getMidChargebacksInfo(mid, year, month)
        .then(result => {
          setLoading(false);
          setMidCbInfo(result ?? undefined);
        })
        .catch(err => {
          setLoading(false);
          console.error(err);
        });
    }
  }, [mid, year, month]);

  useEffect(() => {
    setBreadcrumbs([
      <Typography
        variant="body1"
        color="primary"
        style={{cursor: 'pointer'}}
        onClick={history.goBack}
      >
        {TITLE}
      </Typography>,
      <Typography variant="body1">{midInfo.dba === mid ? '' : midInfo.dba}</Typography>,
    ]);
  }, [setBreadcrumbs, midCbInfo?.dbaName, midInfo]);

  return (
    <>
      <ReportingMonthTitle breadcrumbs={breadcrumbs}>
        <ButtonExportCsv
          columnsConfig={columnsConfig}
          disabled={loading}
          filename="mids-by-chargebacks-details"
          rows={dataSorted}
        />
      </ReportingMonthTitle>

      {(loading || !hasData) && <LoadingStatus loading={loading} hasData={hasData} />}

      {!loading && hasData && (
        <div>
          <Grid container spacing={2} direction="row" alignItems="stretch">
            <Grid item xs={3}>
              <Square lines={{MID: midCbInfo?.mid}} center />
            </Grid>
            <Grid item xs={3}>
              <Square
                lines={{
                  'DBA Name': midInfo.dba,
                }}
                center
              />
            </Grid>
            <Grid item xs={3}>
              <Square
                lines={{
                  'Total V+MC CB Ratio by Count': percentFormatter.format(
                    midCbInfo?.totalCbRatioByCount ?? 0
                  ),
                }}
                center
              />
            </Grid>
            <Grid item xs={3}>
              <Square
                lines={{
                  'Total V+MC CB Ratio by Volume': percentFormatter.format(
                    midCbInfo?.totalCbRatioByVolume ?? 0
                  ),
                }}
                center
              />
            </Grid>
            <Grid item xs={12}>
              <Table
                columns={columnsConfig}
                data={{
                  sort,
                  data: dataSorted,
                  limit: 10,
                  offset: 0,
                  totalCount: 10,
                }}
                onRetrieveData={handleRetrieveData}
              />
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};

const columnsConfig: Column<MidCbInfoByCardType>[] = [
  {
    id: 'cardType',
    numeric: false,
    sortable: false,
    label: 'Card Type',
    selector: row => (
      <CardImage
        cardType={row.cardType.toString()}
        dictionaryType={CardImagesDictionaryType.Authorizations}
      />
    ),
    export: true,
  },
  {
    id: 'cbCount',
    numeric: true,
    sortable: true,
    label: 'CB Count',
    selector: row => formatterNumber.format(row.cbCount),
    export: true,
  },
  {
    id: 'cbVolume',
    numeric: true,
    sortable: true,
    label: 'CB Volume',
    selector: row => formatterCurrency.format(row.cbVolume),
    export: true,
  },
  {
    id: 'cbRatioByCount',
    numeric: true,
    sortable: false,
    label: 'CB Ratio by Count',
    selector: row => formatAsPercent(row.cbRatioByCount),
    export: true,
  },
  {
    id: 'cbRatioByVolume',
    numeric: true,
    sortable: false,
    label: 'CB Ratio by Volume',
    selector: row => formatAsPercent(row.cbRatioByVolume),
    export: true,
  },
];
