import {RateType} from '..';

export const formatterCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});
export const formatUSD = (num: number = 0, fractionDigits = 0) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(num);

export const formatCurrency = (value: number | null | '' | string | undefined) => {
  if (!value) return '$0';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return formatter.format(value as number);
};

export const formatterNumber = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
export const formatNumber = (num: number = 0) => formatterNumber.format(num);

export const formatAsPercent = (value: number) => {
  return value || value === 0
    ? value.toLocaleString(undefined, {
        style: 'percent',
        minimumFractionDigits: 2,
      })
    : '-';
};

export const formatPercent = (prop?: RateType, extraDecimals = false) => {
  const options: Intl.NumberFormatOptions = {
    style: 'percent',
    minimumFractionDigits: 2,
  };

  if (extraDecimals) {
    options.maximumFractionDigits = 6;
  }

  return prop && !isNaN(prop as number)
    ? ((prop as number) / 100).toLocaleString('en-US', options)
    : '-';
};

export const formatPercentWithExtraDecimals = (prop?: RateType) => formatPercent(prop, true);

export const formatDateWithoutTimezone = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // Date provides month index; not month number
  const day = date.getUTCDate();

  return `${month}/${day}/${year}`;
};

export const toDateWithoutTime = (date: Date) => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1; // Date provides month index; not month number
  const day = date.getUTCDate();

  return new Date(`${year}-${month}-${day}`);
};

export const toDateInUTCWithoutTime = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return new Date(Date.UTC(year, month, day, 0, 0, 0, 0));
};
