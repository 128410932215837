import {useEffect, useState} from 'react';
import {TicketStatus} from '../../../..';
import {useUserInfo} from '../../../../hooks';
import {
  getAgentApplicationMidsAsync,
  getMerchantRegisteredMidsAsync,
} from '../../../../hooks/useMerchant';
import {TFolder} from '../../types';
import {useTicketsMyAssociationX, useTicketsMyAssociationXCount} from './useTicketsMyAssociationX';

export function useTicketsMyAssociation(statusSet: TicketStatus[]) {
  const {uid, isMerchant, isAgentMember} = useUserInfo();
  const [merchantMids, setMerchantMids] = useState<string[]>([]);
  const {ticketsAssociated: ticketsMyAssociationMerchant} = useTicketsMyAssociationX(
    statusSet,
    merchantMids,
    'association.merchant.mid'
  );
  const {ticketsAssociated: ticketsMyAssociationApplication} = useTicketsMyAssociationX(
    statusSet,
    merchantMids,
    'association.application.mid'
  );

  useEffect(() => {
    if (!uid) return;
    isMerchant && getMerchantRegisteredMidsAsync(uid).then(setMerchantMids);
    isAgentMember && getAgentApplicationMidsAsync(uid).then(setMerchantMids);
  }, [uid]);

  return {
    ticketsMyAssociationMerchant,
    ticketsMyAssociationApplication,
  };
}

type UseTicketsMyAssociationCountProps = {
  statusSet: TFolder['statusSet'];
  filterKey?: TFolder['filterKey'];
  erpFilters?: TFolder['erpFilters'];
};
export function useTicketsMyAssociationCount({
  statusSet,
  filterKey,
  erpFilters,
}: UseTicketsMyAssociationCountProps): {
  ticketsMyAssociationMerchantCount: number;
  ticketsMyAssociationApplicationCount: number;
  refetch: () => void;
} {
  const {uid, isMerchant, isAgentMember} = useUserInfo();
  const [merchantMids, setMerchantMids] = useState<string[]>([]);
  const ticketsMyAssociationMerchantCount = useTicketsMyAssociationXCount({
    statusSet,
    merchantMids,
    associationPath: 'association.merchant.mid',
    filterKey,
    erpFilters,
  });
  const ticketsMyAssociationApplicationCount = useTicketsMyAssociationXCount({
    statusSet,
    merchantMids,
    associationPath: 'association.application.mid',
    filterKey,
    erpFilters,
  });

  useEffect(() => {
    if (!uid) return;
    isMerchant && getMerchantRegisteredMidsAsync(uid).then(setMerchantMids);
    isAgentMember && getAgentApplicationMidsAsync(uid).then(setMerchantMids);
  }, [uid]);

  return {
    ticketsMyAssociationMerchantCount: ticketsMyAssociationMerchantCount.count,
    ticketsMyAssociationApplicationCount: ticketsMyAssociationApplicationCount.count,
    refetch: () => {
      ticketsMyAssociationMerchantCount.refetch();
      ticketsMyAssociationApplicationCount.refetch();
    },
  };
}
